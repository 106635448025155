import { styled } from '@mui/material/styles';
import { DataGrid, gridClasses } from '@mui/x-data-grid';
import type { TaskState } from 'simulation/states';
import { theme } from 'utils/mui-theme';

export interface TaskSchedulerInfo {
  id: string | number;
  taskName: string;
  source?: string;
  destination?: string;
  duration: string;
  robotId: string | number;
  robotAuthorised?: string;
  waitingTime: string;
  priority?: number;
  status?: TaskState;
  dueDate?: string;
  stepLabel: string;
}

export interface RobotSummaryInfo {
  id: number;
  name: string;
  status?: TaskState;
  destination?: string;
  taskName: string;
  pin: boolean;
  batteryLevel?: number;
  isCharging?: boolean;
  state?: {
    task?: number;
    navigation?: number;
    traffic?: number;
    action?: number;
  };
  trafficData?: {
    otherRobotBlocking?: string;
  };
}

export const taskStatusColor: Partial<Record<TaskState, string>> = {
  running: theme.palette.info.main,
  waiting: theme.palette.grey[300],
  done: theme.palette.success.main,

  beforestart: theme.palette.common.black,
  prerunning: theme.palette.common.black,
};

export const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}:nth-of-type(odd)`]: {
    backgroundColor: theme.palette.grey[100],
  },
  [`& .${gridClasses.row}:nth-of-type(even)`]: {
    '&:hover, &.Mui-hovered': {
      backgroundColor: 'transparent',
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
  [`.${gridClasses.columnHeaders}`]: {
    backgroundColor: theme.palette.primary.light,
  },
  '& .task-status': {
    textTransform: 'capitalize',
  },
  [`& .${gridClasses.row}:has(.running)`]: {
    boxShadow: `${taskStatusColor['running']} 3px 0px 0px 0px inset`,
  },
  [`& .${gridClasses.row}:has(.waiting)`]: {
    boxShadow: `${taskStatusColor['waiting']} 3px 0px 0px 0px inset`,
  },
  [`& .${gridClasses.row}:has(.done)`]: {
    boxShadow: `${taskStatusColor['done']} 3px 0px 0px 0px inset`,
  },
  '& .battery-critical': {
    color: theme.palette.error.main,
  },
  '& .battery-low': {
    color: theme.palette.warning.main,
  },
  [`& .${gridClasses.columnHeader}, & .${gridClasses.cell}`]: {
    outline: 'transparent',
  },
  [`& .${gridClasses.columnHeader}:focus-within, & .${gridClasses.cell}:focus-within`]: {
    outline: 'none',
  },
}));

export const maxLinesFoldedArr = 4;
