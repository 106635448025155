import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Box,
  CircularProgress,
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import type { Dictionary } from '@reduxjs/toolkit';
import type { ProxyMethods, RemoteObject } from 'comlink';
import { Border } from 'components/utils/border.tsx';
import { HelpIconTooltip } from 'components/utils/tooltips.tsx';
import { isPointInSegmentBBox } from 'drawings/helpers';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import type { LoadedPoint, LoadedSegment } from 'reducers/circuit/state';
import type { Simulation } from 'simulation/simulation.model';
import store, { useAppSelector } from 'store';
import { toRad } from 'utils/helpers';
import { isDefined } from 'utils/ts/is-defined.ts';
import { LayerGroupSelect } from './layer-group-select';

type CantonData = {
  [key: number]: {
    start: { x: number; y: number };
    end: { x: number; y: number };
  };
};

type AllCantonData = {
  [key: string]: {
    [key: number]: {
      start: { x: number; y: number };
      end: { x: number; y: number };
    };
  };
};

type PointsChecked = { name: string; isOk: boolean }[];

interface TrafficCheckToolBoxProps {
  isComputingAndNoError: boolean;
  error: boolean;
}

export function TrafficCheckToolBox(props: TrafficCheckToolBoxProps): JSX.Element {
  const { isComputingAndNoError, error } = props;

  const pointsEntities = useAppSelector((state) => state.circuit.present.points.entities);
  const pointsIds = useAppSelector((state) => state.circuit.present.points.ids);
  const points = useMemo(() => pointsIds.map((id) => pointsEntities[id]), [pointsEntities, pointsIds]);

  const segments = useAppSelector((state) => state.circuit.present.segments.entities);

  const simulationServiceRef = useRef<(RemoteObject<Simulation> & ProxyMethods) | null>(null);

  const taxis = useMemo(() => points.filter((point) => point.properties.isTaxi), [points]);
  const chargers = useMemo(() => points.filter((point) => point.properties.isBattery), [points]);

  const taxisOnDeadEndPortion = useMemo(() => getPointsOnDeadEndPortion(taxis, segments), [segments, taxis]);
  const chargersOnDeadEndPortion = useMemo(() => getPointsOnDeadEndPortion(chargers, segments), [chargers, segments]);

  const taxisOnKernelPortion = useMemo(() => getPointsOnKernelPortion(taxis, segments), [segments, taxis]);
  const chargersOnKernelPortion = useMemo(() => getPointsOnKernelPortion(chargers, segments), [chargers, segments]);

  const noStopCantons = useAppSelector((state) => state.traffic.noStopCantons);
  const noStopZoneCantons = useAppSelector((state) => state.traffic.noStopZoneCantons);
  const selectedLayerGroup = useAppSelector((state) => state.routes.selectedLayerGroup);

  const trafficCheck = useCallback(
    async (points: LoadedPoint[], option: 'neutral' | 'not on a no stop canton'): Promise<PointsChecked> => {
      const simulationService =
        simulationServiceRef.current || (await import('../../services/simulation.service.ts'))?.simulationService;
      if (!simulationService) {
        // eslint-disable-next-line no-console
        console.error('Simulation service not initialized');

        return [];
      }

      const storeState = store.getState();
      if (!selectedLayerGroup) {
        // eslint-disable-next-line no-console
        console.error(`No selected layer group`);

        return [];
      }

      const layerGroup = storeState.circuit.present.layers.layerGroups[selectedLayerGroup];
      if (!layerGroup) {
        // eslint-disable-next-line no-console
        console.error(`No layer group found for ${selectedLayerGroup}`);

        return [];
      }

      const selectedLayerGroupName = storeState.circuit.present.layers.layerGroups[selectedLayerGroup].name;
      const layerNamePtr = await simulationService.allocateUTF8(selectedLayerGroupName);

      const pointsChecked = await Promise.all(
        points.map(async (point) => {
          const pointLayerId = point.properties.layerId;
          const selectedLayerIds = storeState.circuit.present.layers.layerGroups[selectedLayerGroup].children;

          if (!selectedLayerIds.includes(pointLayerId)) {
            return undefined;
          }

          const heading = toRad(point.properties.orientation);

          const x = point.geometry.coordinates[0] / 100;
          const y = point.geometry.coordinates[1] / 100;

          let cantonDataPtr: number | null = null;
          try {
            cantonDataPtr = await simulationService._TRAFFIC_WasmWrapper_getCanton(layerNamePtr, x, y, heading);
          } catch (e) {
            // eslint-disable-next-line no-console
            console.error('Error while getting the canton', e);
          }

          if (!cantonDataPtr) {
            return undefined;
          }

          let cantonDataStr: string | null = null;

          try {
            cantonDataStr = await simulationService.UTF8ToString(cantonDataPtr);
          } catch (e) {
            // eslint-disable-next-line no-console
            console.error('Error while getting the canton', e);
          }

          if (!cantonDataStr) {
            return undefined;
          }

          const cantonData = ((): AllCantonData | undefined => {
            try {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-return
              return JSON.parse(cantonDataStr || '');
            } catch (e) {
              // eslint-disable-next-line no-console
              console.error('Error while parsing the canton JSON', e);
            }
          })();

          if (!cantonData) {
            return undefined;
          }

          const currentCanton: CantonData = cantonData.currentCanton;

          if (!currentCanton) {
            return undefined;
          }

          if (option === 'neutral') {
            let deadendsDataPtr: number | null = null;
            try {
              deadendsDataPtr = await simulationService._TRAFFIC_WasmWrapper_getDeadend(layerNamePtr, x, y, heading);
            } catch (e) {
              // eslint-disable-next-line no-console
              console.error('Error while getting the deadends', e);
            }

            const deadendsDataStr = deadendsDataPtr ? await simulationService.UTF8ToString(deadendsDataPtr) : null;

            const deadendsData = ((): AllCantonData | undefined => {
              try {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-return
                return JSON.parse(deadendsDataStr || '');
              } catch (e) {
                // eslint-disable-next-line no-console
                console.error('Error while parsing the deadends JSON', e);
              }
            })();

            if (!deadendsData) {
              return { name: point.properties.name, isOk: false };
            }

            const currentCantonId = Object.keys(currentCanton)[0];

            const neutralCanton = deadendsData.neutral;

            if (neutralCanton && currentCantonId in neutralCanton) {
              return { name: point.properties.name, isOk: true };
            }

            return { name: point.properties.name, isOk: false };
          }

          if (option === 'not on a no stop canton') {
            const currentCantonId = Object.keys(currentCanton)[0];

            if (currentCantonId in noStopCantons || currentCantonId in noStopZoneCantons) {
              return { name: point.properties.name, isOk: false };
            }

            return { name: point.properties.name, isOk: true };
          }

          return undefined;
        })
      );

      const pointsCheckedFiltered = pointsChecked.filter(isDefined);

      // returns an array of all checked points, sorted alphabetically
      return pointsCheckedFiltered.sort((a, b) => {
        const matchA = a.name.match(/\d+/);
        const matchB = b.name.match(/\d+/);

        const numA = matchA ? parseInt(matchA[0], 10) : 0;
        const numB = matchB ? parseInt(matchB[0], 10) : 0;

        return numA - numB;
      });
    },
    [noStopCantons, noStopZoneCantons, selectedLayerGroup]
  );

  const [taxisOnDeadEndChecked, setTaxisOnDeadEndChecked] = useState<PointsChecked>([]);
  const [taxisOnKernelChecked, setTaxisOnKernelChecked] = useState<PointsChecked>([]);
  const [chargersOnDeadEndChecked, setChargersOnDeadEndChecked] = useState<PointsChecked>([]);
  const [chargersOnKernelChecked, setChargersOnKernelChecked] = useState<PointsChecked>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchTrafficCheck = async (): Promise<void> => {
      try {
        setLoading(true);

        const resTaxisDeadEnd = await trafficCheck(taxisOnDeadEndPortion, 'neutral');
        setTaxisOnDeadEndChecked(resTaxisDeadEnd);

        const resTaxisKernel = await trafficCheck(taxisOnKernelPortion, 'not on a no stop canton');
        setTaxisOnKernelChecked(resTaxisKernel);

        const resChargersDeadEnd = await trafficCheck(chargersOnDeadEndPortion, 'neutral');
        setChargersOnDeadEndChecked(resChargersDeadEnd);

        const resChargersKernel = await trafficCheck(chargersOnKernelPortion, 'not on a no stop canton');
        setChargersOnKernelChecked(resChargersKernel);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error('Error while checking traffic:', err);
      } finally {
        setLoading(false);
      }
    };

    if (!isComputingAndNoError) {
      fetchTrafficCheck();
    }
  }, [
    chargersOnDeadEndPortion,
    chargersOnKernelPortion,
    isComputingAndNoError,
    taxisOnDeadEndPortion,
    taxisOnKernelPortion,
    trafficCheck,
  ]);

  return (
    <>
      <LayerGroupSelect />
      <Box component="div" sx={{ mt: 3 }}>
        <Stack direction="row" alignItems="center" spacing={2} sx={{ ml: 2, mt: 2 }}>
          <Typography variant="body2" sx={{ ml: 2, mt: 2, fontWeight: 600 }}>
            Taxis
          </Typography>
          {loading && !error && <CircularProgress size={15} />}
        </Stack>

        <DisplayCheckedList
          checkedPoints={taxisOnDeadEndChecked}
          title={'Taxis on dead end'}
          portionType={'deadEnd'}
          loading={loading}
          type={'Taxis'}
        />

        <DisplayCheckedList
          checkedPoints={taxisOnKernelChecked}
          title={'Taxis on kernel'}
          portionType={'kernel'}
          loading={loading}
          type={'Taxis'}
        />
      </Box>

      <Box component="div" sx={{ mt: 3 }}>
        <Stack direction="row" alignItems="center" spacing={2} sx={{ ml: 2, mt: 2 }}>
          <Typography variant="body2" sx={{ fontWeight: 600 }}>
            Chargers
          </Typography>
          {loading && !error && <CircularProgress size={15} />}
        </Stack>

        <DisplayCheckedList
          checkedPoints={chargersOnDeadEndChecked}
          title={'Chargers on dead end'}
          portionType={'deadEnd'}
          loading={loading}
          type={'Chargers'}
        />

        <DisplayCheckedList
          checkedPoints={chargersOnKernelChecked}
          title={'Chargers on kernel'}
          portionType={'kernel'}
          loading={loading}
          type={'Chargers'}
        />
      </Box>
    </>
  );
}

interface DisplayCheckedListProps {
  checkedPoints: PointsChecked;
  title: string;
  portionType: 'deadEnd' | 'kernel';
  loading: boolean;
  type: 'Taxis' | 'Chargers';
}

function DisplayCheckedList(props: DisplayCheckedListProps): JSX.Element {
  const { checkedPoints, title, portionType, loading, type } = props;

  const [expanded, setExpanded] = useState(false);

  const handleChange = useCallback(() => {
    if (!loading) {
      setExpanded((state) => {
        const newState = !state;

        return newState;
      });
    }
  }, [loading]);

  useEffect(() => {
    if (!loading) {
      setExpanded(true);
    }
  }, [loading]);

  const [helper, helperOk, helperNotOk] = useMemo(() => {
    if (portionType === 'deadEnd')
      return [
        `${type} on dead end portions should be on neutral cantons`,
        `On a neutral canton`,
        `Not on a neutral canton`,
      ];

    return [
      `${type} on kernel portions should not be on no stop cantons`,
      `On a no stop canton`,
      `Not on a no stop canton`,
    ];
  }, [portionType, type]);

  const noData = useMemo(
    () => (portionType === 'deadEnd' ? `No ${type.toLowerCase()} on dead end` : `No ${type.toLowerCase()} on kernel`),
    [portionType, type]
  );

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        sx={{
          cursor: loading ? 'default' : 'pointer',
          ml: 3,
          mt: 2,
          mr: 3,
        }}
        onClick={handleChange}
      >
        <Stack direction="row">
          <Typography variant="body2">{title}</Typography>
          <HelpIconTooltip title={helper} />
        </Stack>

        <IconButton size="small" disabled={loading}>
          {expanded ? <ExpandLessIcon fontSize="small" /> : <ExpandMoreIcon fontSize="small" />}
        </IconButton>
      </Stack>

      <Collapse in={expanded} sx={{ ml: 4, mr: 2 }}>
        {checkedPoints.length > 0 ? (
          <Border
            sx={{
              paddingLeft: 0,
              marginLeft: 1,
            }}
          >
            <List dense>
              {checkedPoints.map((checkedPoint) => {
                return (
                  <ListItem key={checkedPoint.name}>
                    <ListItemText primary={checkedPoint.name} />
                    {checkedPoint.isOk ? (
                      <Tooltip title={helperOk}>
                        <CheckCircleIcon color="success" fontSize="small" />
                      </Tooltip>
                    ) : (
                      <Tooltip title={helperNotOk}>
                        <CancelIcon color="error" fontSize="small" />
                      </Tooltip>
                    )}
                  </ListItem>
                );
              })}
            </List>
          </Border>
        ) : (
          <List dense>
            <Border
              sx={{
                paddingLeft: 0,
                marginLeft: 1,
              }}
            >
              <ListItem>
                <ListItemText primary={noData} />
              </ListItem>
            </Border>
          </List>
        )}
      </Collapse>
    </>
  );
}

function getPointsOnDeadEndPortion(points: LoadedPoint[], segments: Dictionary<LoadedSegment>): LoadedPoint[] {
  const pointsOnDeadEnd: LoadedPoint[] = [];

  points.forEach((point) => {
    const linkedSegmentId = point.properties.segment?.id;

    if (linkedSegmentId) {
      const linkedSegment = segments[linkedSegmentId] as LoadedSegment;

      const segmentPortions = linkedSegment.properties.portions;

      segmentPortions.forEach((portion) => {
        const isPointOnThePortion = isPointInSegmentBBox(
          portion.points[0],
          point.geometry.coordinates,
          portion.points[1]
        );

        if (isPointOnThePortion && portion.trafficType && portion.trafficType === 'deadend') {
          pointsOnDeadEnd.push(point);
        }
      });
    }
  });

  return pointsOnDeadEnd;
}

function getPointsOnKernelPortion(points: LoadedPoint[], segments: Dictionary<LoadedSegment>): LoadedPoint[] {
  const pointsOnKernel: LoadedPoint[] = [];

  points.forEach((point) => {
    const linkedSegmentId = point.properties.segment?.id;

    if (linkedSegmentId) {
      const linkedSegment = segments[linkedSegmentId] as LoadedSegment;

      const segmentPortions = linkedSegment.properties.portions;

      segmentPortions.forEach((portion) => {
        const isPointOnThePortion = isPointInSegmentBBox(
          portion.points[0],
          point.geometry.coordinates,
          portion.points[1]
        );

        if (isPointOnThePortion && portion.trafficType && portion.trafficType === 'kernel') {
          pointsOnKernel.push(point);
        }
      });
    }
  });

  return pointsOnKernel;
}
