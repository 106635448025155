import { Button } from '@mui/material';
import {
  change90DegSnapAction,
  clearMapImageAction,
  openDialogAction,
  selectPreviousTool,
  selectToolAction,
  toggleStateHightlightTurns,
} from 'actions';
import {
  bringToBackAction,
  bringToFrontAction,
  changeLockStateSelectionAction,
  clearShapesSelectionAction,
  deleteSelectedCircuitShapesAction,
  saveCircuitToHistoryAction,
} from 'actions/circuit';
import {
  isSavingProject,
  resetSnackbarLoadingKey,
  saveProject,
  setIsSavingProject,
  snackbarLoadingKey,
} from 'components/export/save-project/save-project';
import * as d3 from 'd3';
import { copySelectionAction, pasteSelectionAction } from 'drawings/copy-paste';
import type { EditorDrawing } from 'drawings/editor.drawing';
import { setEditorMode } from 'editor/editor';
import type { EditorMode } from 'editor/model';
import { useConfirm } from 'material-ui-confirm';
import { DialogTypes } from 'models';
import { ShapeTypes } from 'models/circuit';
import { Tools } from 'models/tools';
import {
  clearedHistoryDueToMultiplayerChanges,
  lastCircuitTransactionOrigin,
  setClearedHistoryDueToMultiplayerChanges,
} from 'multiplayer/globals';
import type { SnackbarKey } from 'notistack';
import React, { startTransition, useCallback, useEffect, useMemo, useRef } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { setCameraPosition } from 'reducers/local/camera-position.reducer';
import { ActionCreators } from 'redux-undo';
import { CircuitService } from 'services/circuit.service';
import { getLidarBounds } from 'services/maps.service';
import { SnackbarUtils } from 'services/snackbar.service';
import store, { useAppDispatch, useAppSelector } from 'store';
import type { OrbitControls as OrbitControlsImpl } from 'three-stdlib';
import { useDebouncedCallback } from 'use-debounce';
import { PreferencesService } from 'utils/preferences';
import { rackDeletionConfirmText } from 'utils/texts';
import { handleMovement } from './movement-controls';

export interface UseShortcutsProps {
  drawing?: EditorDrawing | null;
  /** 3D engine camera control */
  controlsRef?: React.MutableRefObject<OrbitControlsImpl | null>;
}

/**
 * This function is used to handle the keyboard shortcuts of the editor. (for the 2D or the 3D engine)
 */
export function useShortcuts(props: UseShortcutsProps): undefined {
  const { drawing, controlsRef } = props;
  const dispatch = useAppDispatch();
  const confirm = useConfirm();
  const isLibCirEnabled = useAppSelector((state) => state.editor.isRender2Enabled);
  const selectedShapes = useAppSelector((state) => state.local.selectedShapesData);

  const selectNewTool = useCallback((toolName: Tools) => dispatch(selectToolAction({ toolName })), [dispatch]);
  const onDeletionRequired = useCallback(() => {
    startTransition(() => {
      dispatch(deleteSelectedCircuitShapesAction(true));
    });
  }, [dispatch]);

  const circuitMode = useAppSelector((state) => state.editor.editorMode === 'circuit');
  const toggleShortcutOnlyInCircuitModeOption = useMemo(
    () => ({
      enabled: circuitMode,
    }),
    [circuitMode]
  );

  const selectedToolIsSimulation = useAppSelector((state) => state.tool.activeTool === Tools.SimulationConfiguration);
  const disableShortcutsInSimulationOption = useMemo(
    () => ({
      enabled: !selectedToolIsSimulation,
    }),
    [selectedToolIsSimulation]
  );

  const editorMode = useAppSelector((state) => state.editor.editorMode);

  const changeEditorMode = useCallback(
    (newEditorMode: EditorMode) => {
      dispatch(
        selectToolAction({
          toolName: Tools.Move,
        })
      );

      dispatch(
        setEditorMode({
          editorMode: newEditorMode,
        })
      );

      if (newEditorMode === 'flow') {
        dispatch(clearShapesSelectionAction());
      }
    },
    [dispatch]
  );

  const handleHotkey = useCallback((): boolean => {
    const isDialogOpened = store.getState().dialog.open;

    // we disable the hotkeys if a dialog is opened
    return !isDialogOpened;
  }, []);

  const alreadySavingSnackbarKey = useRef<SnackbarKey | undefined>(undefined);
  useEffect(() => {
    if (!isSavingProject && alreadySavingSnackbarKey) {
      SnackbarUtils.closeSnackbar(alreadySavingSnackbarKey.current);
      alreadySavingSnackbarKey.current = undefined;
    }
  }, []);

  const askSaveProject = useCallback(() => {
    setIsSavingProject();
    if (snackbarLoadingKey) {
      SnackbarUtils.closeSnackbar(snackbarLoadingKey);
      resetSnackbarLoadingKey();
    }

    if (PreferencesService.arePreferencesFullyLoaded()) {
      saveProject();
    }
  }, []);

  /**  Delete shape / layout image */
  useHotkeys(
    'Delete, Backspace',
    (event, hotkeysEvent) => {
      if (!handleHotkey()) return;

      const storeState = store.getState();

      if (storeState.maps.mapImage.openProperties) {
        confirm({
          title: 'Delete the layout image?',
          allowClose: false,
        })
          .then(() => {
            if (!storeState.maps.mapImage.mapImages || !storeState.maps.mapImage.openProperties) return;

            dispatch(
              clearMapImageAction({
                name: storeState.maps.mapImage.mapImages[storeState.maps.mapImage.openProperties].name,
              })
            );
          })
          .catch(() => undefined);

        return;
      }

      const selectedShapes = storeState.local.selectedShapesData;
      const racks = store.getState().circuit.present.racks.entities;

      const doesSelectedShapesContainUnlockedRacks = selectedShapes.some((shape) => {
        if (shape.type !== ShapeTypes.RackShape) return false;

        return !racks[shape.id].properties.locked;
      });

      if (doesSelectedShapesContainUnlockedRacks) {
        confirm({
          title: rackDeletionConfirmText,
          allowClose: false,
          confirmationButtonProps: { autoFocus: true },
        }).then(() => {
          onDeletionRequired();
        });
      } else {
        onDeletionRequired();
      }
    },
    []
  );

  /** Select move tool */
  useHotkeys(
    'Escape',
    (event, hotkeysEvent) => {
      if (!handleHotkey()) return;

      // select the move tool
      selectNewTool(Tools.Move);
    },
    disableShortcutsInSimulationOption
  );

  /** draw shape? */
  useHotkeys(
    'Enter',
    (event, hotkeysEvent) => {
      if (!handleHotkey()) return;

      /* Disable event when active layer is hidden */
      if (
        !store.getState().circuit.present.layers.layers[store.getState().circuit.present.layers.selectedLayer]
          .visibility
      )
        return;

      const currentTool = store.getState().tool.activeTool;
      if (currentTool === Tools.DrawShape) {
        d3.select('[layer=draw] g').dispatch('draw-shape');
      }
    },
    toggleShortcutOnlyInCircuitModeOption
  );

  /** Select  zone tool */
  useHotkeys(
    'z',
    (event, hotkeysEvent) => {
      if (!handleHotkey()) return;

      selectNewTool(Tools.DrawZone);
    },
    toggleShortcutOnlyInCircuitModeOption
  );

  /** select  point tool  */
  useHotkeys(
    'p',
    (event, hotkeysEvent) => {
      if (!handleHotkey()) return;

      selectNewTool(Tools.DrawPoint);
    },
    toggleShortcutOnlyInCircuitModeOption
  );

  /** Select segment or turn tool */
  useHotkeys(
    'w',
    (event, hotkeysEvent) => {
      if (!handleHotkey()) return;

      selectNewTool(Tools.DrawSegmentOrTurn);
    },
    toggleShortcutOnlyInCircuitModeOption
  );

  /** Select  turn tool */
  useHotkeys(
    't',
    (event, hotkeysEvent) => {
      if (!handleHotkey()) return;

      selectNewTool(Tools.AddTurn);
    },
    toggleShortcutOnlyInCircuitModeOption
  );

  /** Select measurer tool */
  useHotkeys(
    'm',
    (event, hotkeysEvent) => {
      if (!handleHotkey()) return;

      selectNewTool(Tools.DrawMeasurer);
    },
    toggleShortcutOnlyInCircuitModeOption
  );

  // Select draw shape tool
  useHotkeys(
    'q',
    (event, hotkeysEvent) => {
      if (!handleHotkey()) return;

      selectNewTool(Tools.DrawShape);
    },
    toggleShortcutOnlyInCircuitModeOption
  );

  // select add note tool
  useHotkeys(
    'n',
    (event, hotkeysEvent) => {
      if (!handleHotkey()) return;

      selectNewTool(Tools.AddNote);
    },
    toggleShortcutOnlyInCircuitModeOption
  );

  /** change 90 deg snap */
  useHotkeys(
    'd',
    (event, hotkeysEvent) => {
      if (!handleHotkey()) return;

      dispatch(change90DegSnapAction({}));
    },
    toggleShortcutOnlyInCircuitModeOption
  );

  /** Save project */
  useHotkeys(
    'Ctrl+s',
    (event, hotkeysEvent): void => {
      event.preventDefault();

      if (isSavingProject) {
        if (alreadySavingSnackbarKey.current) SnackbarUtils.closeSnackbar(alreadySavingSnackbarKey.current);

        alreadySavingSnackbarKey.current = SnackbarUtils.toast(
          'Project is already being saved, please wait for the save to complete',
          {
            autoHideDuration: 3000,
            variant: 'info',
            action: () => (
              <Button
                onClick={() => {
                  if (alreadySavingSnackbarKey.current) SnackbarUtils.closeSnackbar(alreadySavingSnackbarKey.current);

                  askSaveProject();
                }}
                variant="contained"
              >
                Save anyway
              </Button>
            ),
          }
        );

        return;
      }

      askSaveProject();
    },
    []
  );

  /** bring to front */
  useHotkeys(
    'f',
    (event, hotkeysEvent): void => {
      if (!handleHotkey()) return;

      if (!selectedShapes.length) return;

      selectedShapes.forEach(({ id, type }) => {
        dispatch(bringToFrontAction({ id, type: type }));
      });
    },
    toggleShortcutOnlyInCircuitModeOption,
    [selectedShapes]
  );

  /** Bring to back */
  useHotkeys(
    'b',
    (event, hotkeysEvent): void => {
      if (!handleHotkey()) return;

      if (!selectedShapes.length) return;

      selectedShapes.forEach(({ id, type }) => {
        dispatch(bringToBackAction({ id, type: type }));
      });
    },
    toggleShortcutOnlyInCircuitModeOption,
    [selectedShapes]
  );

  const copyDialogTextToClipboard = useCallback(async (text: string) => {
    if ('clipboard' in navigator) {
      try {
        await navigator.clipboard.writeText(text);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error('Unable to copy to clipboard:', err);
      }
    }
  }, []);

  /** copy shape */
  useHotkeys(
    'Ctrl+c',
    (event, hotkeysEvent): void => {
      event.preventDefault();
      const selectedDialogText = window.getSelection()?.toString() || '';
      if (selectedDialogText.length > 0) {
        copyDialogTextToClipboard(selectedDialogText);
      }

      if (!handleHotkey()) return;

      store.dispatch(copySelectionAction());
    },
    toggleShortcutOnlyInCircuitModeOption
  );

  /** Paste shape */
  useHotkeys(
    'Ctrl+v',
    (event, hotkeysEvent): void => {
      event.preventDefault();

      if (!handleHotkey()) return;

      store.dispatch(pasteSelectionAction(true, true));
    },
    toggleShortcutOnlyInCircuitModeOption
  );

  const handleMovementDebounced = useDebouncedCallback(
    (params: Parameters<typeof handleMovement>[0]) => {
      handleMovement(params);
    },
    500,
    { leading: true, maxWait: 500 }
  );

  /** Move to cam to left */
  useHotkeys(
    'left',
    (event, hotkeysEvent): void => {
      event.preventDefault();
      if (!handleHotkey()) return;
      handleMovementDebounced({ direction: 'left', drawing, isLibCirEnabled, controlsRef });
    },
    [drawing, isLibCirEnabled]
  );

  /** Move to cam to right */
  useHotkeys(
    'right',
    (event, hotkeysEvent): void => {
      event.preventDefault();
      if (!handleHotkey()) return;
      handleMovementDebounced({ direction: 'right', drawing, isLibCirEnabled, controlsRef });
    },
    [drawing, isLibCirEnabled]
  );

  /** Move to cam to bottom */
  useHotkeys(
    'down',
    (event, hotkeysEvent): void => {
      event.preventDefault();
      if (!handleHotkey()) return;
      handleMovementDebounced({ direction: 'down', drawing, isLibCirEnabled, controlsRef });
    },
    [drawing, isLibCirEnabled]
  );

  /** Move cam to top */
  useHotkeys(
    'up',
    (event, hotkeysEvent): void => {
      event.preventDefault();
      if (!handleHotkey()) return;
      handleMovementDebounced({ direction: 'up', drawing, isLibCirEnabled, controlsRef });
    },
    [drawing, isLibCirEnabled]
  );

  /** lock */
  useHotkeys(
    'l',
    (event, hotkeysEvent): void => {
      if (!handleHotkey()) return;

      store.dispatch(
        changeLockStateSelectionAction({
          newLockState: true,
          userAction: true,
        })
      );
    },
    toggleShortcutOnlyInCircuitModeOption
  );

  /** unlock */
  useHotkeys(
    'u',
    (event, hotkeysEvent): void => {
      if (!handleHotkey()) return;

      store.dispatch(
        changeLockStateSelectionAction({
          newLockState: false,
          userAction: true,
        })
      );
    },
    toggleShortcutOnlyInCircuitModeOption
  );
  const undoSnackBarKey = useRef<SnackbarKey | undefined>(undefined);

  /** undo */
  useHotkeys(
    'Ctrl+z',
    (event, hotkeysEvent): void => {
      event.preventDefault();

      if (!handleHotkey()) return;
      // github link to the issue https://github.com/JohannesKlauss/react-hotkeys-hook/issues/1040
      if (event.key.toLowerCase() !== 'z') return;

      const sameStateAsUnfiltered = store.getState().circuit.present !== store.getState().circuit._latestUnfiltered;
      const lastActionIsLocal = lastCircuitTransactionOrigin !== 'remote';

      if (undoSnackBarKey?.current) SnackbarUtils.closeSnackbar(undoSnackBarKey.current);
      if (sameStateAsUnfiltered && lastActionIsLocal) {
        store.dispatch(saveCircuitToHistoryAction());
      }

      if (clearedHistoryDueToMultiplayerChanges && store.getState().circuit.past.length) {
        setClearedHistoryDueToMultiplayerChanges(false);
      }

      if (clearedHistoryDueToMultiplayerChanges && !store.getState().circuit.past.length) {
        SnackbarUtils.warning('Undo/redo history cleared due to multiplayer changes', { autoHideDuration: 1000 });

        return;
      }

      if (!store.getState().circuit.past.length) {
        undoSnackBarKey.current = SnackbarUtils.toast('Undo history is empty', {
          variant: 'info',
          autoHideDuration: 1000,
        });

        return;
      }

      store.dispatch(ActionCreators.undo());
      undoSnackBarKey.current = SnackbarUtils.toast('Undo change', {
        variant: 'success',
        autoHideDuration: 1000,
      });
    },
    toggleShortcutOnlyInCircuitModeOption
  );

  /** redo */
  useHotkeys(
    'Ctrl+y',
    (event, hotkeysEvent): void => {
      event.preventDefault();
      if (!handleHotkey()) return;
      // github link to the issue https://github.com/JohannesKlauss/react-hotkeys-hook/issues/1040
      if (event.key.toLowerCase() !== 'y') return;

      if (undoSnackBarKey?.current) SnackbarUtils.closeSnackbar(undoSnackBarKey.current);
      if (clearedHistoryDueToMultiplayerChanges && store.getState().circuit.future.length) {
        setClearedHistoryDueToMultiplayerChanges(false);
      }

      if (clearedHistoryDueToMultiplayerChanges && !store.getState().circuit.future.length) {
        SnackbarUtils.warning('Undo/redo history cleared due to multiplayer changes', { autoHideDuration: 1000 });

        return;
      }

      if (!store.getState().circuit.future.length) {
        undoSnackBarKey.current = SnackbarUtils.toast('Redo history is empty', {
          variant: 'info',
          autoHideDuration: 1000,
        });

        return;
      }

      store.dispatch(ActionCreators.redo());
      undoSnackBarKey.current = SnackbarUtils.toast('Redo changes', {
        variant: 'success',
        autoHideDuration: 1000,
      });
    },
    toggleShortcutOnlyInCircuitModeOption
  );

  const dispatchSelectPreviousToolDebounced = useDebouncedCallback(
    () => {
      dispatch(selectPreviousTool());
    },
    300,
    { leading: true, maxWait: 300 }
  );

  const changeEditorModeDebounced = useDebouncedCallback(
    (newEditorMode: EditorMode) => {
      changeEditorMode(newEditorMode);
    },
    300,
    { leading: true, maxWait: 300 }
  );

  /** select previous tool */
  useHotkeys(
    'space',
    (event, hotkeysEvent): void => {
      event.preventDefault();

      if (!handleHotkey()) return;

      dispatchSelectPreviousToolDebounced();
    },
    disableShortcutsInSimulationOption
  );

  /** center map */
  useHotkeys('c', (event, hotkeysEvent): void => {
    if (!handleHotkey()) return;

    // Center on the background for 2D lib
    CircuitService.getDrawingReference()?.centerZoomToBackground();

    // Retrieve map info to center on it for 3D lib
    const lidarData = store.getState().maps.lidar['background-lidar'];
    const mapImages = store.getState().maps.mapImage.mapImages;
    // 8: good scale level to see the whole circuit
    const chosenScale = 8;
    const mapImageTiles = store.getState().maps.mapImageTiles.mapImageTilesData?.tiles[chosenScale];
    const tilesPixelSize = store.getState().maps.mapImageTiles.mapImageTilesData?.tilePixelSize;

    if (lidarData) {
      const { minX, maxY, minY, maxX } = getLidarBounds(lidarData.coordinates);
      dispatch(
        setCameraPosition({ position: { minX: minX * 0.01, maxY: maxY * 0.01, minY: minY * 0.01, maxX: maxX * 0.01 } })
      );
    }
    //Works but we need to implement tiles in the libCir
    else if (mapImageTiles && tilesPixelSize) {
      let numberOfTilesOnTheWidth = 1;

      for (let i = 0; i < mapImageTiles.length - 1; i++) {
        if (mapImageTiles[i].y === mapImageTiles[i + 1].y) {
          numberOfTilesOnTheWidth = numberOfTilesOnTheWidth + 1;
        } else {
          break;
        }
      }

      let numberOfTilesOnTheHeight = 1;

      for (let i = 0; i < mapImageTiles.length - 1; i++) {
        if (mapImageTiles[i].y !== mapImageTiles[i + 1].y) {
          numberOfTilesOnTheHeight = numberOfTilesOnTheHeight + 1;
        }
      }

      const fullImgWidth = tilesPixelSize * chosenScale * numberOfTilesOnTheWidth;
      const fullImgHeight = tilesPixelSize * chosenScale * numberOfTilesOnTheHeight;

      const minX = mapImageTiles[0].x * 100;
      const minY = -mapImageTiles[0].y * 100 - fullImgHeight - fullImgHeight;
      const maxX = mapImageTiles[0].x * 100 + fullImgWidth;
      const maxY = -mapImageTiles[0].y * 100 + fullImgHeight;

      dispatch(
        setCameraPosition({
          position: { minX: minX * 0.01, maxY: maxY * 0.001, minY: minY * 0.001, maxX: maxX * 0.01 },
        })
      );
    } else if (mapImages && mapImages?.length > 0) {
      const mapImage = mapImages[0];

      if (mapImage.height) {
        const minX = mapImage.x;
        const minY = mapImage.y - mapImage.height;
        const maxX = mapImage.x + mapImage.originalWidth * (mapImage.height / mapImage.originalHeight);
        const maxY = mapImage.y;

        dispatch(
          setCameraPosition({
            position: { minX: minX * 0.01, maxY: maxY * 0.01, minY: minY * 0.01, maxX: maxX * 0.01 },
          })
        );
      }
    } else {
      // eslint-disable-next-line no-console
      console.warn('[shortcuts] - No lidar data or map image found to center on');
    }
  });

  /** Highlight turns */
  useHotkeys(
    'h',
    (event, hotkeysEvent): void => {
      if (!handleHotkey()) return;

      store.dispatch(toggleStateHightlightTurns());
    },
    toggleShortcutOnlyInCircuitModeOption
  );

  /** Change editor mode */
  useHotkeys(
    'Tab',
    (event, hotkeysEvent): void => {
      if (!handleHotkey()) return;

      event.stopImmediatePropagation();
      event.preventDefault();

      const simulation = store.getState().simulation;

      if (simulation.hasSimulationStarted) {
        dispatch(openDialogAction({ type: DialogTypes.ExitRunningSimulation, payload: { newToolOrMode: 'circuit' } }));
      } else {
        const newEditorMode: EditorMode =
          editorMode === 'circuit' ? 'traffic' : editorMode === 'traffic' ? 'flow' : 'circuit';

        changeEditorModeDebounced(newEditorMode);
      }
    },
    [editorMode]
  );
}
