import { Queue } from '@mui/icons-material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AssignmentReturnedIcon from '@mui/icons-material/AssignmentReturned';
import CenterFocusStrongIcon from '@mui/icons-material/CenterFocusStrong';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import SaveIcon from '@mui/icons-material/Save';
import TitleIcon from '@mui/icons-material/Title';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import { Badge, Button, ButtonGroup, CircularProgress, DialogTitle, Fade, Stack, TextField } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { createCellTemplateSuccessAction } from 'actions/cell-templates';
import { useConfirm } from 'material-ui-confirm';
import type { CircuitRack } from 'models/circuit';
import type { ChangeEvent } from 'react';
import React, { startTransition, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { TransitionGroup } from 'react-transition-group';
import type { CellTemplate } from 'reducers/circuit/state';
import store from 'store';
import { AnimatedIcon } from 'utils/animated-icon';
import { generateShapeId } from 'utils/circuit/next-free-id';
import { balyoGradient } from 'utils/colors/colors';
import { theme } from 'utils/mui-theme';
import type { ActionsRack } from '.';
import { zoomRackEdition, zoomToForm, zoomToRack } from '.';
import type { StationPositions } from './rack-edition';
import { AddPositionsToStationMenu } from './rack-edition-add-positions-to-station';
import { RenamePositionsMenu } from './rack-edition-rename-positions-menu';

interface RackEditionTopBarProps {
  /** name of the rack that we currently edit */
  name: string;
  /** whether the rack is locked or not */
  locked?: boolean;
  /** currentName */
  currentName: string;
  /** function to set the current name state */
  setCurrentName: (name: string) => void;
  /** cell templates of the project */
  cellTemplates: Record<string, CellTemplate>;
  /** actions functions that can be called */
  actions: ActionsRack;
  /** id of the cell template that is currently edited, null in the rack view */
  editCellTemplate: string | null;
  /** ref to a callback that reset the properties of the cell template that is currently being edited
   * resetCellTemplate.current() to call it */
  resetCellTemplate?: React.MutableRefObject<(() => void) | undefined>;
  /** ref to a callback that save the properties of the cell template that is currently being edited
   * saveCellTemplate.current() to call it */
  saveCellTemplate?: React.MutableRefObject<(() => void) | undefined>;
  /** ref to a callback that delete the cell template that is currently being edited
   * addCellTemplate.current() to call it */
  addCellTemplate?: React.MutableRefObject<((cellTemplateType?: 'rack' | 'conveyor') => void) | undefined>;

  cellTemplateChanged: boolean;
  /** do we display the name of the positions or not? */
  displayPositionNames: boolean;

  /** whether or not the currently edited rack is a conveyor or not */
  isConveyor?: boolean;

  /** id of the cell template that is currently selected to be assigned to a position */
  selectedCellTemplate: string | null;

  /** whether or not the rack name is valid */
  rackNameOk: boolean;

  /** function to call to delete a cell template */
  deleteCellTemplate?: (cellTemplateId?: string) => void;

  /** the rack that is currently being edited */
  rack?: CircuitRack;

  /** cell template to edit the parameters */
  cellTemplate?: CellTemplate;

  selectedCellsForStation: [string, number, number][];

  allCellsId: string[];

  stationPositions: StationPositions[];

  setStationPositions: React.Dispatch<React.SetStateAction<StationPositions[]>>;

  setUserRemovedStationPositions: React.Dispatch<React.SetStateAction<boolean>>;
}
export function RackEditionTopBar({
  name,
  locked,
  currentName,
  setCurrentName,
  cellTemplates,
  actions,
  editCellTemplate,
  resetCellTemplate,
  saveCellTemplate,
  addCellTemplate,
  cellTemplateChanged,
  displayPositionNames,
  isConveyor = false,
  selectedCellTemplate,
  rackNameOk,
  deleteCellTemplate,
  rack,
  cellTemplate,
  selectedCellsForStation,
  allCellsId,
  stationPositions,
  setStationPositions,
  setUserRemovedStationPositions,
}: RackEditionTopBarProps): JSX.Element {
  const [nameTmp, setNameTmp] = useState(name);
  const confirm = useConfirm();

  const handleChangeName = (e: ChangeEvent<HTMLInputElement>): void => {
    const newRackName = e.target.value;

    setNameTmp(newRackName);
  };

  const handleBlurName = (): void => {
    if (!nameTmp) {
      setNameTmp(name);
    }

    const rackNameTrimmed = nameTmp?.trim();
    setNameTmp(rackNameTrimmed);

    if (rackNameTrimmed) {
      if (currentName) setCurrentName(rackNameTrimmed);
    }
  };

  useEffect(() => {
    if (currentName !== nameTmp) {
      setNameTmp(currentName);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentName]);

  const handleClickAssignCellTemplate = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, cellTemplateId: string) => {
      e.stopPropagation();

      const newSelected = selectedCellTemplate === cellTemplateId ? null : cellTemplateId;

      actions.selectCellTemplateToAssign(newSelected);
    },
    [actions, selectedCellTemplate]
  );

  const handleClickResetZoom = useCallback(() => {
    if (!zoomRackEdition) return;

    zoomToRack();
  }, []);

  const handleClickZoomForm = useCallback(() => {
    if (!zoomRackEdition) return;

    zoomToForm(500);
  }, []);

  const animateCancelButton = useRef<((anim?: any) => void) | undefined>(undefined);
  const animateSaveButton = useRef<((anim?: any) => void) | undefined>(undefined);

  const handleResetCellTemplate = useCallback(
    (action: () => void) => {
      //resetCellTemplate?.current && resetCellTemplate.current()
      if (!editCellTemplate) return;

      if (resetCellTemplate?.current) {
        confirm({
          title: <>Reset the cell template "{cellTemplates[editCellTemplate].name}"?</>,
          description: 'Unsaved changes will be lost',
          cancellationText: 'Continue editing',
          confirmationText: 'Reset',
          cancellationButtonProps: {
            color: 'primary',
          },
          confirmationButtonProps: {
            color: 'secondary',
          },
          allowClose: true,
        })
          .then(() => {
            if (resetCellTemplate?.current) {
              resetCellTemplate.current();

              if (animateCancelButton?.current) {
                animateCancelButton.current();
              }
            }

            action();
          })
          .catch(() => undefined);
      }
    },
    [cellTemplates, confirm, editCellTemplate, resetCellTemplate]
  );

  const handleClickSaveCellTemplate = useCallback(() => {
    if (!editCellTemplate) return;

    if (saveCellTemplate?.current) {
      saveCellTemplate.current();

      if (animateSaveButton?.current) {
        animateSaveButton.current();
      }
    }
  }, [editCellTemplate, saveCellTemplate]);

  const onAddCellTemplate = (): void => {
    if (addCellTemplate?.current) {
      addCellTemplate.current(isConveyor ? 'conveyor' : undefined);
    }
  };

  const handleDuplicateCellTemplate = (): void => {
    if (!cellTemplate) {
      // eslint-disable-next-line no-console
      console.warn('No cell template to duplicate');

      return;
    }

    const newName = `${cellTemplate.name} copy`;
    const newId = generateShapeId();
    const newColor = balyoGradient[Math.floor(Math.random() * balyoGradient.length)];

    store.dispatch(
      createCellTemplateSuccessAction({
        ...cellTemplate,
        id: newId,
        name: newName,
        color: newColor,
      })
    );
  };

  const contrastColors = useMemo(() => {
    const colors: Record<string, string> = {};

    Object.values(cellTemplates).forEach(
      (cellTemplate) => (colors[cellTemplate.id] = theme.palette.getContrastText(cellTemplate.color))
    );

    return colors;
  }, [cellTemplates]);

  const [updatingDisplayPositionsNames, setUpdatingDisplayPositionsNames] = useState(false);
  const handleClickDisplayPositionsNames = useCallback(() => {
    setUpdatingDisplayPositionsNames(true);

    actions.toggleDisplayPositionNames();

    startTransition(() => {
      setUpdatingDisplayPositionsNames(false);
    });
  }, [actions]);

  const [openMenuRenamePosition, setOpenMenuRenamePosition] = useState(false);
  const handleClickRenamePositions = useCallback(() => {
    setOpenMenuRenamePosition(true);
  }, []);
  const handleCloseMenuRenamePosition = useCallback(() => {
    setOpenMenuRenamePosition(false);
  }, []);

  const renamePositionIconRef = useRef<null | HTMLElement>(null);

  const [openMenuAddPositionsToStation, setOpenMenuAddPositionsToStation] = useState(false);
  const handleClickAddPositionsToStation = useCallback(() => {
    setOpenMenuAddPositionsToStation(true);
  }, []);
  const handleCloseMenuAddPositionsToStation = useCallback(() => {
    setOpenMenuAddPositionsToStation(false);
  }, []);

  const addPositionsToStationIconRef = useRef<SVGSVGElement | null>(null);

  const filteredCellTemplatesIds = useMemo(() => {
    return Object.keys(cellTemplates).filter((id) => !!cellTemplates[id].forConveyor === isConveyor);
  }, [cellTemplates, isConveyor]);

  const nbCellTemplatesInTheProject = useMemo(() => {
    return Object.keys(cellTemplates).length;
  }, [cellTemplates]);
  const isLastCellTemplate = nbCellTemplatesInTheProject <= 1;

  const onDeleteCellTemplate = useCallback(() => {
    if (!deleteCellTemplate || !cellTemplate) return;

    // we count the number of cells that are using this cell template
    // in the rack that is currently being edited
    let nbCellTemplateInThisRack = 0;
    if (rack) {
      for (let i = 0; i < rack.properties.columns.length; i++) {
        const column = rack.properties.columns[i];
        for (let j = 0; j < column.cells.length; j++) {
          const cell = column.cells[j];
          if (cell.cellTemplate === cellTemplate.id) {
            nbCellTemplateInThisRack++;
          }
        }
      }
    }

    // we count the number of cells that are using this cell template
    // in the other racks
    let racksWithThisCellTemplate: { id: string; name: string; nbCells: number }[] = [];
    const storeState = window.getStoreState();
    const racksIds = storeState.circuit.present.racks.ids;
    const racks = storeState.circuit.present.racks.entities;
    for (let i = 0; i < racksIds.length; i++) {
      const r = racks[racksIds[i]];
      let n = 0;

      for (let j = 0; j < r.properties.columns.length; j++) {
        const column = r.properties.columns[j];
        for (let k = 0; k < column.cells.length; k++) {
          const cell = column.cells[k];
          if (cell.cellTemplate === cellTemplate.id) {
            n++;
          }
        }
      }

      if (n) {
        racksWithThisCellTemplate.push({
          id: r.id as string,
          name: r.properties.name,
          nbCells: n,
        });
      }
    }

    // we remove the current rack from the list of all racks
    racksWithThisCellTemplate = racksWithThisCellTemplate.filter((r) => r.id !== rack?.id);

    const descriptionThisRack = `${
      nbCellTemplateInThisRack
        ? `This cell template is used ${nbCellTemplateInThisRack} time${
            nbCellTemplateInThisRack > 1 ? 's' : ''
          } in this rack.`
        : 'This cell template is not used in this rack.'
    }`;
    const descriptionOtherRacks = racksWithThisCellTemplate.length ? (
      <>
        This cell template is used in the following racks:
        <br />
        <ul>
          {racksWithThisCellTemplate.map((r) => (
            <li key={r.id}>
              {r.name}: {r.nbCells} time{r.nbCells > 1 ? 's' : ''}
            </li>
          ))}
        </ul>
      </>
    ) : (
      <>This cell template is not used in any other rack.</>
    );

    const description = (
      <>
        <p>{descriptionThisRack}</p>
        <p>{descriptionOtherRacks}</p>
      </>
    );

    confirm({
      title: `Delete the cell template ${cellTemplate.name}?`,
      description,
      allowClose: false,
    })
      .then(() => {
        deleteCellTemplate();
      })
      .catch(() => undefined);
  }, [cellTemplate, confirm, deleteCellTemplate, rack]);

  return (
    <DialogTitle id="rack-edition-dialog-title" sx={editCellTemplate ? { paddingBottom: 0.5 } : {}}>
      <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
        {editCellTemplate ? (
          <Button
            onClick={() => {
              if (cellTemplateChanged) {
                handleResetCellTemplate(() => {
                  actions.changeRackEditionMenu(null);
                });

                return;
              }

              actions.changeRackEditionMenu(null);
            }}
            variant="outlined"
            color="error"
            startIcon={
              editCellTemplate ? (
                <ExitToAppIcon
                  sx={{
                    transform: 'rotate(180deg)',
                  }}
                />
              ) : undefined
            }
            size="small"
            disableElevation
          >
            <span>Go back to Rack Modification</span>
          </Button>
        ) : (
          <Stack direction="row">
            <TextField
              type="text"
              value={nameTmp}
              id="rack-name-input"
              name="rack-name"
              onChange={handleChangeName}
              onBlur={handleBlurName}
              disabled={locked}
              variant="outlined"
              size="small"
              title="Name of the rack"
              error={!rackNameOk}
            />
            <Tooltip title="Reset zoom">
              <IconButton onClick={handleClickResetZoom}>
                <ZoomOutMapIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Edit base parameters">
              <IconButton onClick={handleClickZoomForm}>
                <CenterFocusStrongIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Display the name of the positions">
              <IconButton onClick={handleClickDisplayPositionsNames}>
                <Badge
                  overlap="circular"
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  badgeContent={updatingDisplayPositionsNames ? <CircularProgress size={10} /> : undefined}
                >
                  <TitleIcon color={displayPositionNames ? 'primary' : undefined} />
                </Badge>
              </IconButton>
            </Tooltip>
            <Tooltip title="Rename positions">
              <IconButton onClick={handleClickRenamePositions}>
                <Badge
                  overlap="circular"
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  badgeContent={<EditIcon fontSize="small" color={openMenuRenamePosition ? 'primary' : undefined} />}
                  ref={renamePositionIconRef}
                >
                  <TitleIcon color={openMenuRenamePosition ? 'primary' : undefined} />
                </Badge>
              </IconButton>
            </Tooltip>
            {!editCellTemplate && renamePositionIconRef.current && openMenuRenamePosition ? (
              <RenamePositionsMenu
                anchorEl={renamePositionIconRef.current}
                open={openMenuRenamePosition}
                onClose={handleCloseMenuRenamePosition}
                actions={actions}
                nbSelectedCells={selectedCellsForStation.length}
              />
            ) : (
              <></>
            )}
            <Tooltip title="Add positions to station">
              <IconButton onClick={handleClickAddPositionsToStation}>
                <Queue
                  color={openMenuAddPositionsToStation ? 'primary' : undefined}
                  ref={addPositionsToStationIconRef}
                />
              </IconButton>
            </Tooltip>
            {!editCellTemplate && rack && addPositionsToStationIconRef.current && openMenuAddPositionsToStation ? (
              <AddPositionsToStationMenu
                anchorEl={addPositionsToStationIconRef.current}
                open={openMenuAddPositionsToStation}
                onClose={handleCloseMenuAddPositionsToStation}
                rack={rack}
                selectedCellsForStation={selectedCellsForStation}
                allCellsId={allCellsId}
                stationPositions={stationPositions}
                setStationPositions={setStationPositions}
                setUserRemovedStationPositions={setUserRemovedStationPositions}
              />
            ) : (
              <></>
            )}
          </Stack>
        )}

        <Stack direction="row" maxWidth="50%">
          <Stack
            direction="row"
            spacing={1}
            sx={{
              overflowX: 'auto',
              padding: 0.5,
            }}
          >
            <TransitionGroup>
              {!editCellTemplate && false && (
                <Fade>
                  <Button
                    variant="outlined"
                    startIcon={<EditIcon />}
                    color="primary"
                    sx={{
                      //transition: 'opacity 0.25s ease-in-out',
                      //opacity: editCellTemplate ? 0 : 1,
                      pointerEvents: editCellTemplate ? 'none' : 'all',
                    }}
                  >
                    Slot Names
                  </Button>
                </Fade>
              )}
            </TransitionGroup>

            {filteredCellTemplatesIds.map((cellTemplateId) => {
              const cellTemplate = cellTemplates[cellTemplateId];

              return (
                <ButtonGroup
                  key={cellTemplateId}
                  sx={{
                    whiteSpace: 'nowrap',
                    outline: `2px solid ${cellTemplate.color}`,
                    '& button': {
                      backgroundColor: editCellTemplate === cellTemplateId ? cellTemplate.color : undefined,
                    },
                    '& .MuiButtonGroup-grouped:not(:last-of-type)': {
                      borderRight: `1px solid ${cellTemplate.color}`,
                    },
                    '&:hover': {
                      outline: `2px solid ${cellTemplate.color}`,
                    },
                  }}
                  color={'inherit'}
                  variant="contained"
                  size="small"
                  disableElevation
                >
                  <Button
                    sx={{
                      textTransform: 'none',
                      color: editCellTemplate === cellTemplateId ? contrastColors[cellTemplateId] : undefined,
                      '&:hover': {
                        color: theme.palette.common.black,
                      },
                    }}
                    onClick={() => {
                      if (cellTemplateChanged) {
                        handleResetCellTemplate(() => {
                          actions.changeRackEditionMenu(cellTemplateId);
                        });

                        return;
                      }

                      actions.changeRackEditionMenu(cellTemplateId);
                    }}
                  >
                    <Tooltip title={`Edit ${cellTemplate.name}`} arrow>
                      <span>{cellTemplate.name}</span>
                    </Tooltip>
                  </Button>
                  {!editCellTemplate && (
                    <Button
                      onClick={(e) => handleClickAssignCellTemplate(e, cellTemplateId)}
                      disabled={!!editCellTemplate}
                      //color={selectedCellTemplate === cellTemplateId ? 'primary' : undefined}
                      sx={{
                        background: selectedCellTemplate === cellTemplateId ? cellTemplate.color : undefined,
                        '&:hover': {
                          background: selectedCellTemplate === cellTemplateId ? cellTemplate.color : undefined,
                        },

                        color: selectedCellTemplate === cellTemplateId ? contrastColors[cellTemplateId] : undefined,
                      }}
                    >
                      <Tooltip title={`Assign ${cellTemplate.name}`} arrow>
                        <AssignmentReturnedIcon sx={{ verticalAlign: 'middle' }} />
                      </Tooltip>
                    </Button>
                  )}
                  {editCellTemplate === cellTemplateId && (
                    <>
                      <Tooltip title="Save" arrow>
                        <Button
                          onClick={handleClickSaveCellTemplate}
                          sx={{
                            color: editCellTemplate === cellTemplateId ? contrastColors[cellTemplateId] : undefined,
                            '&:hover': { color: theme.palette.success.main },
                            '&:has(~ button)': {
                              borderRadius: 0,
                            },
                          }}
                        >
                          <AnimatedIcon
                            defaultAnimation="flash"
                            animateRef={animateSaveButton}
                            animationTimingFunction="ease-in-out"
                            animationDuration={500}
                          >
                            <SaveIcon sx={{ verticalAlign: 'middle' }} />
                          </AnimatedIcon>
                        </Button>
                      </Tooltip>
                      <Tooltip title="Duplicate" arrow>
                        <Button
                          onClick={handleDuplicateCellTemplate}
                          sx={{
                            color: editCellTemplate === cellTemplateId ? contrastColors[cellTemplateId] : undefined,
                            '&:hover': { color: theme.palette.info.main },
                            '&:has(~ button)': {
                              borderRadius: 0,
                            },
                          }}
                        >
                          <AnimatedIcon
                            defaultAnimation="flash"
                            animateRef={animateSaveButton}
                            animationTimingFunction="ease-in-out"
                            animationDuration={500}
                          >
                            <ContentCopyIcon sx={{ verticalAlign: 'middle' }} />
                          </AnimatedIcon>
                        </Button>
                      </Tooltip>
                      {!isLastCellTemplate && (
                        <Tooltip title="Delete" arrow>
                          <Button
                            onClick={onDeleteCellTemplate}
                            sx={{
                              color: editCellTemplate === cellTemplateId ? contrastColors[cellTemplateId] : undefined,
                              '&:hover': { color: theme.palette.warning.main },
                            }}
                          >
                            <AnimatedIcon
                              defaultAnimation="turn-on-itself"
                              animateRef={animateCancelButton}
                              animationTimingFunction="ease-out"
                            >
                              <DeleteIcon sx={{ verticalAlign: 'middle' }} />
                            </AnimatedIcon>
                          </Button>
                        </Tooltip>
                      )}
                    </>
                  )}
                </ButtonGroup>
              );
            })}
          </Stack>
          <Tooltip title="Create a cell template">
            <span>
              <IconButton onClick={onAddCellTemplate} disabled={cellTemplateChanged}>
                <AddCircleIcon />
              </IconButton>
            </span>
          </Tooltip>
        </Stack>
      </Stack>
    </DialogTitle>
  );
}
