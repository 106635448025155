import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { CardContent, IconButton, Table, TableBody, TableHead, Tooltip } from '@mui/material';
import type { Flow } from 'flows/flows';
import { useState } from 'react';
import { StyledTableCell, StyledTableRow } from 'simulation/styled-table';

interface ListOfFlowsTableProps {
  flows: Flow[];
  stationsWithPositionsNames: {
    stationName: string;
    id: string;
    names: string[];
  }[];
}

export function ListOfFlowsTable({ flows, stationsWithPositionsNames }: ListOfFlowsTableProps): JSX.Element {
  return (
    <CardContent>
      <Table size="small">
        <TableHead>
          <StyledTableRow>
            <StyledTableCell>Name</StyledTableCell>
            <StyledTableCell>Source</StyledTableCell>
            <StyledTableCell>Intermediate Step(s)</StyledTableCell>
            <StyledTableCell>Destination</StyledTableCell>
            <StyledTableCell>Steps</StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {flows.map((flow) => {
            const stationsSource = stationsWithPositionsNames.filter(
              (station) => flow.stations[0]?.id.includes(station.id)
            );
            const stationsDestination = stationsWithPositionsNames.filter(
              (station) => flow.stations?.at(-1)?.id.includes(station.id)
            );
            const intermediateStations =
              flow.stations
                .slice(1, -1)
                .map((station) => {
                  const stationNames = stationsWithPositionsNames
                    .filter((s) => station.id.includes(s.id))
                    ?.map((station) => station.stationName);

                  return stationNames.join(', ');
                })
                .join(' → ') ?? '';

            const sources: string[] = [];

            for (let i = 0; i < stationsSource.length; i++) {
              const names = stationsSource[i].names;

              names.forEach((name) => {
                sources.push(name);
              });
            }

            const destinations: string[] = [];

            for (let i = 0; i < stationsDestination.length; i++) {
              const names = stationsDestination[i].names;

              names.forEach((name) => {
                destinations.push(name);
              });
            }

            const sourcesNames = sources.join(', ');
            const destinationsNames = destinations.join(', ');

            return (
              <TableContent
                key={flow.id}
                flow={flow}
                sourcesNames={sourcesNames}
                intermediateStations={intermediateStations}
                destinationsNames={destinationsNames}
              />
            );
          })}
        </TableBody>
      </Table>
    </CardContent>
  );
}

interface TableContentProps {
  flow: Flow;
  sourcesNames: string;
  intermediateStations: string;
  destinationsNames: string;
}

function TableContent({ flow, sourcesNames, intermediateStations, destinationsNames }: TableContentProps): JSX.Element {
  const [showMore, setShowMore] = useState(false);

  const maxCharacters = 200;

  return (
    <StyledTableRow key={flow.id}>
      <StyledTableCell>{flow.name}</StyledTableCell>
      <StyledTableCell>{sourcesNames}</StyledTableCell>
      <StyledTableCell>{intermediateStations}</StyledTableCell>
      <StyledTableCell>
        <>
          {showMore
            ? destinationsNames
            : destinationsNames.length > maxCharacters
              ? `${destinationsNames.substring(0, maxCharacters)}...`
              : destinationsNames}

          {destinationsNames.length > maxCharacters && (
            <>
              {showMore ? (
                //To hide the tooltip after the click
                <Tooltip title={showMore ? 'Show less' : ''}>
                  <IconButton onClick={() => setShowMore(!showMore)}>
                    <ArrowDropUpIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip title={showMore ? '' : 'Show more'}>
                  <IconButton onClick={() => setShowMore(!showMore)}>
                    <ArrowDropDownIcon />
                  </IconButton>
                </Tooltip>
              )}
            </>
          )}
        </>
      </StyledTableCell>

      <StyledTableCell>{flow.stations.length}</StyledTableCell>
    </StyledTableRow>
  );
}
