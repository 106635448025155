import type { Position } from '@turf/helpers';
import { saveRackAction } from 'actions/racks';
import { rotatePoint } from 'drawings/helpers';
import type { CircuitRack } from 'models/circuit';
import { getConnectedTurnIds } from '../utils/findConnectedTurn';

/**
 * Processes a selected rack, applies rotation, and updates its geometry and properties.
 * It also tracks the associated turn IDs to be modified.
 *
 * @param selectedShape - The selected shape (rack) to process.
 * @param rotateValue - The rotation value to apply to the rack.
 * @param centerSelectedShapes - The center point for rotation.
 * @param actions - The array to collect actions to dispatch.
 * @param modifiedShapes - A set of modified shape IDs (including turns).
 */
export function processRotateRack(
  selectedShape: CircuitRack,
  rotateValue: number,
  centerSelectedShapes: Position,
  actions: any[],
  modifiedShapes: Set<string>
): void {
  const rack = selectedShape;
  if (!rack.id || rack.properties.locked) return;

  const { id, properties, geometry } = rack;

  // Apply rotation
  const newCap = properties.cap + rotateValue;
  const newCoords = geometry.coordinates[0].map((coord) => rotatePoint(centerSelectedShapes, [...coord], -rotateValue));

  actions.push(
    saveRackAction({
      ...rack,
      geometry: { ...geometry, coordinates: [newCoords] },
      properties: { ...properties, cap: newCap },
    })
  );

  // Add associated turns to modifiedShapes
  getConnectedTurnIds(id, 'rack').forEach((turnId) => modifiedShapes.add(turnId));
}
