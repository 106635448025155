import type { SelectChangeEvent } from '@mui/material';
import { TextField } from '@mui/material';
import { Stack } from '@mui/system';
import { Border } from 'components/utils/border';
import { HelpIconTooltip } from 'components/utils/tooltips';
import type { IntervalTrigger, Trigger } from 'models/simulation';
import { useCallback, useMemo, useRef, useState } from 'react';
import { setTrigger } from 'simulation/triggers';
import { useAppDispatch, useAppSelector } from 'store';
import { integerInputIfNeeded } from 'utils/helpers';
import { theme } from 'utils/mui-theme';
import { ListFlowsInTriggers } from './list-flows-in-triggers';
import { TriggerPriorityTextfield } from './priority-textfield';

interface IntervalTriggerConfigurationProps {
  trigger: IntervalTrigger;
}
export function IntervalTriggerConfiguration(props: IntervalTriggerConfigurationProps): JSX.Element {
  const { trigger } = props;

  const minCount = 1;

  const [errorValue, setErrorValue] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const inputRefStartAt = useRef<HTMLInputElement>(null);
  const inputRefStopAt = useRef<HTMLInputElement>(null);
  const inputRefInterval = useRef<HTMLInputElement>(null);
  const inputRefThroughput = useRef<HTMLInputElement>(null);
  const inputRefCount = useRef<HTMLInputElement>(null);
  const inputRefPriority = useRef<HTMLInputElement>(null);
  const dispatch = useAppDispatch();
  const flows = useAppSelector((state) => state.flows.flows);

  const handleUpdateTrigger = useCallback(() => {
    const interval = inputRefInterval.current?.valueAsNumber;
    if (!interval || isNaN(interval)) {
      return;
    }

    let count = inputRefCount.current?.valueAsNumber;
    if (!count || isNaN(count) || count < minCount) {
      count = minCount;

      if (inputRefCount.current) inputRefCount.current.value = count.toString();
    }

    let priority = inputRefPriority.current?.valueAsNumber;
    if (priority === undefined || isNaN(priority)) {
      priority = 1;

      if (inputRefPriority.current) inputRefPriority.current.value = priority.toString();
    }

    dispatch(
      setTrigger({
        ...trigger,
        interval: Math.round(interval),
        count: Math.round(count),
        priority: Math.round(priority),
      })
    );
  }, [dispatch, trigger]);

  const handleUpdateTriggerFromThroughput = useCallback(() => {
    const throughput = inputRefThroughput.current?.valueAsNumber;
    if (!throughput || isNaN(throughput)) {
      return;
    }

    const count = inputRefCount.current?.valueAsNumber;
    if (!count || isNaN(count)) {
      return;
    }

    const interval = (count * 3600) / throughput;
    const intervalRounded = integerInputIfNeeded(interval, 1);

    const otherInputEl = inputRefInterval.current;
    if (otherInputEl) {
      otherInputEl.value = intervalRounded;
    }

    handleUpdateTrigger();
  }, [handleUpdateTrigger]);

  const handleUpdateTriggerFromCountOrInterval = useCallback(() => {
    const count = inputRefCount.current?.valueAsNumber;
    if (!count || isNaN(count)) {
      return;
    }

    const interval = inputRefInterval.current?.valueAsNumber;
    if (!interval || isNaN(interval)) {
      return;
    }

    const throughput = Math.round((count * 3600) / interval);

    const otherInputEl = inputRefThroughput.current;
    if (otherInputEl) {
      otherInputEl.value = throughput.toString();
    }

    handleUpdateTrigger();
  }, [handleUpdateTrigger]);

  const handleUpdateStartAtValue = useCallback(() => {
    let startAt = inputRefStartAt.current?.valueAsNumber;

    if (startAt !== undefined && (isNaN(startAt) || startAt < 0)) {
      startAt = 0;
    }

    dispatch(
      setTrigger({
        ...trigger,
        startAt: startAt ?? 0,
      })
    );
  }, [dispatch, trigger]);

  const handleUpdateStopAtValue = useCallback(() => {
    let stopAt = inputRefStopAt.current?.valueAsNumber;

    if ((stopAt !== undefined && (isNaN(stopAt) || stopAt < 0)) || stopAt === 0) {
      stopAt = Infinity;
    }

    if (trigger.startAt && stopAt && trigger.startAt >= stopAt) {
      setErrorValue(true);

      setErrorMessage(trigger.startAt === stopAt ? 'Equal to start at' : 'Less than start at');

      // To avoid saving a wrong stop at value
      dispatch(
        setTrigger({
          ...trigger,
          stopAt: Infinity,
        })
      );
    } else {
      setErrorValue(false);
      setErrorMessage('');

      dispatch(
        setTrigger({
          ...trigger,
          stopAt: stopAt ?? Infinity,
        })
      );
    }
  }, [dispatch, trigger]);

  const flowIds = useMemo(() => (Array.isArray(trigger.flowId) ? trigger.flowId : [trigger.flowId]), [trigger.flowId]);

  const handleChangeFlow = useCallback(
    (e: SelectChangeEvent<string>, index: number) => {
      const selectFlowId = e.target.value;

      const newFlowIds = flowIds.map((flowId, i) => (i === index ? selectFlowId : flowId));

      dispatch(
        setTrigger({
          ...trigger,
          flowId: newFlowIds,
        })
      );
    },
    [dispatch, flowIds, trigger]
  );

  const handleAddFlow = useCallback(() => {
    dispatch(
      setTrigger({
        ...trigger,
        flowId: [...flowIds, flows[0].id],
      })
    );
  }, [dispatch, flowIds, flows, trigger]);

  const handleRemoveFlow = useCallback(
    (index: number) => {
      const newFlowIds = flowIds.filter((_, i) => i !== index);

      dispatch(
        setTrigger({
          ...trigger,
          flowId: newFlowIds,
        })
      );
    },
    [dispatch, flowIds, trigger]
  );

  const defaultIntervalValue = useMemo(() => {
    const interval = trigger.interval;

    return integerInputIfNeeded(interval, 1);
  }, [trigger.interval]);

  const defaultThroughputValue = useMemo(
    () => Math.round(((trigger.count ?? 1) * 3600) / trigger.interval).toString(),
    [trigger.count, trigger.interval]
  );

  const intervalLabel = (
    <>
      Interval <HelpIconTooltip title="A task will be triggered every X seconds." />
    </>
  );
  const throughputLabel = (
    <>
      Throughput{' '}
      <HelpIconTooltip
        title={`A task will be triggered every ${trigger.interval.toFixed(0)} seconds to reach the target throughput.`}
      />
    </>
  );
  const countLabel = (
    <>
      Count <HelpIconTooltip title="Number of tasks to create every time the trigger runs." />
    </>
  );

  const startAtLabel = (
    <>
      Start At <HelpIconTooltip title="Time of the simulation from which the first task will be created" />
    </>
  );

  const stopAtLabel = (
    <>
      Stop At <HelpIconTooltip title="Simulation time from which no more tasks will be created" />
    </>
  );

  return (
    <Border
      sx={{
        marginTop: theme.spacing(1),
      }}
    >
      <ListFlowsInTriggers
        flowIds={flowIds}
        flows={flows}
        handleAddFlow={handleAddFlow}
        handleChangeFlow={handleChangeFlow}
        handleRemoveFlow={handleRemoveFlow}
        disabled={trigger.linked}
      />

      <Stack direction="row" spacing={1} useFlexGap>
        <TextField
          key={`startAt-${trigger.id}`}
          label={startAtLabel}
          InputLabelProps={{ shrink: true }}
          type="number"
          defaultValue={trigger.startAt && trigger.startAt > 0 ? trigger.startAt.toString() : ''}
          size="small"
          variant="outlined"
          sx={{
            marginTop: theme.spacing(1),
          }}
          InputProps={{
            endAdornment: 's',
          }}
          inputProps={{
            min: 0,
            step: 1,
          }}
          inputRef={inputRefStartAt}
          onBlur={handleUpdateStartAtValue}
          fullWidth
          disabled={trigger.linked}
          // Prevent negative value
          onKeyDown={(e) => {
            if (e.key === '-') {
              e.preventDefault();
            }
          }}
        />
        <TextField
          key={`stopAt-${trigger.id}`}
          label={stopAtLabel}
          InputLabelProps={{ shrink: true }}
          type="number"
          defaultValue={trigger.stopAt && trigger.stopAt > 0 ? trigger.stopAt.toString() : ''}
          size="small"
          variant="outlined"
          sx={{
            marginTop: theme.spacing(1),
          }}
          InputProps={{
            endAdornment: 's',
          }}
          inputProps={{
            min: 1,
            step: 1,
          }}
          inputRef={inputRefStopAt}
          onBlur={handleUpdateStopAtValue}
          error={errorValue}
          helperText={errorMessage}
          fullWidth
          disabled={trigger.linked}
          // Prevent negative value
          onKeyDown={(e) => {
            if (e.key === '-') {
              e.preventDefault();
            }
          }}
        />
      </Stack>

      <Stack direction="row" spacing={1} useFlexGap>
        <TextField
          key={`interval-${trigger.id}`}
          label={intervalLabel}
          type="number"
          defaultValue={defaultIntervalValue}
          size="small"
          variant="outlined"
          sx={{
            marginTop: theme.spacing(1),
          }}
          InputProps={{
            endAdornment: 's',
          }}
          InputLabelProps={{
            shrink: true,
          }}
          inputRef={inputRefInterval}
          onBlur={handleUpdateTriggerFromCountOrInterval}
          inputProps={{
            min: 1,
            step: 1,
          }}
          fullWidth
          disabled={trigger.linked}
        />

        <TextField
          key={`throughput-${trigger.id}`}
          label={throughputLabel}
          type="number"
          defaultValue={defaultThroughputValue}
          size="small"
          variant="outlined"
          sx={{
            marginTop: theme.spacing(1),
          }}
          InputProps={{
            endAdornment: 'tasks/hr',
          }}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            min: 1,
            step: 0.1,
          }}
          onBlur={handleUpdateTriggerFromThroughput}
          inputRef={inputRefThroughput}
          fullWidth
          disabled={trigger.linked}
        />
      </Stack>

      <Stack direction="row" spacing={1} useFlexGap>
        <TextField
          key={`count-${trigger.id}`}
          label={countLabel}
          type="number"
          defaultValue={trigger.count ?? 1}
          size="small"
          variant="outlined"
          sx={{
            marginTop: theme.spacing(1),
          }}
          inputProps={{
            min: minCount,
            step: 1,
          }}
          onBlur={handleUpdateTriggerFromCountOrInterval}
          inputRef={inputRefCount}
          fullWidth
          disabled={trigger.linked}
        />

        <TriggerPriorityTextfield
          key={`priority-${trigger.id}`}
          trigger={trigger}
          handleUpdateTrigger={handleUpdateTrigger}
          inputRef={inputRefPriority}
        />
      </Stack>
    </Border>
  );
}

/**
 * Type guard for intervalTrigger
 * @param trigger - The trigger to be checked
 * @returns A boolean indicating whether the trigger is a intervalTrigger
 */
export function isIntervalTrigger(trigger: Trigger): trigger is IntervalTrigger {
  return trigger.type === 'interval';
}
