import EditLocationAltIcon from '@mui/icons-material/EditLocationAlt';
import FactoryIcon from '@mui/icons-material/Factory';
import ModeOfTravelIcon from '@mui/icons-material/ModeOfTravel';
import { TabContext, TabPanel } from '@mui/lab';
import { Box, Card, CardContent, CardHeader, Tab, Tabs } from '@mui/material';
import { setFlow } from 'flows/flows';
import { useEffect, useMemo, useState } from 'react';
import type { ItineraryOfAFlow } from 'simulation/check-flows';
import store, { useAppSelector } from 'store';
import { theme } from 'utils/mui-theme';
import { FlowsContent } from './flow/flows';
import { StationsToolbox } from './station/station';
import { SummaryFlowsModal } from './summary-flows-modal/summary-flows-modal';
import { TriggersToolbox } from './triggers/triggers';

export const FlowConfigurationTab = {
  Flow: 'Flow',
  Station: 'Station',
  Trigger: 'Trigger',
};

const iconTab = {
  [FlowConfigurationTab.Flow]: ModeOfTravelIcon,
  [FlowConfigurationTab.Station]: EditLocationAltIcon,
  [FlowConfigurationTab.Trigger]: FactoryIcon,
  default: ModeOfTravelIcon,
};

export interface FlowCheckResult {
  /** number of itineraries checked */
  nbItineraries: number;
  /** itineraries with issues */
  issues: ItineraryOfAFlow[];
}
interface FlowsToolboxProps {
  selectedTab?: string;
}
export function FlowsToolbox(props: FlowsToolboxProps): JSX.Element {
  const flows = useAppSelector((state) => state.flows.flows);
  const [selectedTab, setSelectedTab] = useState(props.selectedTab ?? FlowConfigurationTab.Flow);

  const changeTabAction = (tabToSelect: string): void => setSelectedTab(tabToSelect);

  const handleChange = (_event: React.SyntheticEvent, newValue: string): void => {
    changeTabAction(newValue);
  };

  // Add the default palletsPerTask value for the already created flows
  useEffect(() => {
    flows.forEach((flow) => {
      if (!flow.palletsPerTask) {
        store.dispatch(setFlow({ ...flow, palletsPerTask: 1 }));
      }
    });
  }, [flows]);

  const AvatarIcon = useMemo(() => {
    return iconTab[selectedTab] || iconTab.default;
  }, [selectedTab]);

  return (
    <Card
      sx={{
        position: 'absolute',
        right: theme.spacing(2),
        top: theme.spacing(2),
        width: '450px',
        overflowY: 'auto',
        maxHeight: '95%',
      }}
    >
      <CardHeader
        title={`${selectedTab} Configuration`}
        avatar={!!AvatarIcon ? <AvatarIcon /> : undefined}
        sx={{
          paddingBottom: theme.spacing(0.5),
        }}
        action={<SummaryFlowsModal />}
      ></CardHeader>

      <CardContent
        sx={{
          textAlign: 'left',
        }}
      >
        <Box component="div">
          <TabContext value={selectedTab}>
            <Box component="div" sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs variant="fullWidth" onChange={handleChange} aria-label="Flow configuration" value={selectedTab}>
                <Tab label="Station" value={FlowConfigurationTab.Station} />
                <Tab label="Flow" value={FlowConfigurationTab.Flow} />
                <Tab label="Trigger" value={FlowConfigurationTab.Trigger} />
              </Tabs>
            </Box>
            <TabPanel sx={{ p: 1 }} value={FlowConfigurationTab.Station}>
              <StationsToolbox />
            </TabPanel>
            <TabPanel sx={{ p: 1 }} value={FlowConfigurationTab.Flow}>
              <FlowsContent setSelectedTab={changeTabAction} />
            </TabPanel>
            <TabPanel value={FlowConfigurationTab.Trigger}>
              <TriggersToolbox />
            </TabPanel>
          </TabContext>
        </Box>
      </CardContent>
    </Card>
  );
}
