import type {
  NewTaskDispatcherWithSerialsAndPrev,
  NewTaskDispatcherWithTime,
  NewTaskDispatcherWithTimeAndSerialsAndPrev,
  NewTasksDispatcherWithTime,
} from 'models/simulation';

/**
 * Converts an array of NewTaskDispatcherWithTimeAndSerialsAndPrev to NewTasksDispatcherWithTime
 * @param {NewTaskDispatcherWithTimeAndSerialsAndPrev[]} tasksToFormat - Array of tasks to convert
 * @returns {NewTasksDispatcherWithTime} - Object containing the converted tasks and the minimum time
 */
export function convertTask(
  tasksToFormat: (NewTaskDispatcherWithTimeAndSerialsAndPrev | NewTaskDispatcherWithTime)[]
): NewTasksDispatcherWithTime {
  if (!tasksToFormat || tasksToFormat.length === 0) {
    return {
      tasks: [],
      time: 0,
    };
  }

  let minTime = tasksToFormat[0].time;
  const tasks: NewTaskDispatcherWithSerialsAndPrev[] = tasksToFormat.map((task) => {
    if (task.time < minTime) {
      minTime = task.time;
    }

    const { time, prev, serials, ...taskWithoutTime } = task as NewTaskDispatcherWithTimeAndSerialsAndPrev;

    if (!('step1' in taskWithoutTime)) {
      taskWithoutTime.step1 = taskWithoutTime.src;
    }

    if (!('step2' in taskWithoutTime)) {
      taskWithoutTime.step2 = taskWithoutTime.dst;
    }

    if ('id' in taskWithoutTime) {
      delete taskWithoutTime.id;
    }

    return taskWithoutTime;
  });

  return {
    tasks,
    time: minTime,
  };
}
