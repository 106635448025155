import FilterListIcon from '@mui/icons-material/FilterList';
import { Box, Button, Popover, Slider, Switch, Typography } from '@mui/material';
import { HelpIconTooltip } from 'components/utils/tooltips';
import { useState } from 'react';

interface RackAnalysisFiltersProps {
  enableHeightFilter: boolean;
  heightRange: [number, number];
  onEnableHeightFilterChange: (enabled: boolean) => void;
  onHeightRangeChange: (event: Event, newValue: number | number[]) => void;
}

export function RackAnalysisFilters({
  enableHeightFilter,
  heightRange,
  onEnableHeightFilterChange,
  onHeightRangeChange,
}: RackAnalysisFiltersProps): JSX.Element {
  const [filterAnchorEl, setFilterAnchorEl] = useState<HTMLButtonElement | null>(null);
  const openFilter = Boolean(filterAnchorEl);

  const handleFilterClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setFilterAnchorEl(event.currentTarget);
  };

  const handleFilterClose = (): void => {
    setFilterAnchorEl(null);
  };

  return (
    <>
      <Button startIcon={<FilterListIcon />} onClick={handleFilterClick} variant="outlined" size="small">
        Filters
      </Button>
      <Popover
        open={openFilter}
        anchorEl={filterAnchorEl}
        onClose={handleFilterClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Box component="div" sx={{ p: 2, minWidth: 700 }}>
          <Box component="div" sx={{ display: 'flex', alignItems: 'center', gap: 1, marginBottom: 1 }}>
            <Typography>
              Height Range Filter
              <HelpIconTooltip
                title="Filter and automatically enable/disable measures based on height range"
                sx={{ fontSize: '1rem', marginLeft: 1 }}
              />
            </Typography>
            <Switch checked={enableHeightFilter} onChange={(e) => onEnableHeightFilterChange(e.target.checked)} />
          </Box>
          {enableHeightFilter && (
            <Box component="div" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 2 }}>
              <Slider
                value={heightRange}
                onChange={onHeightRangeChange}
                valueLabelDisplay="on"
                min={0}
                max={11000}
                step={100}
                valueLabelFormat={(value) => `${value} mm`}
                sx={{
                  '& .MuiSlider-valueLabel': {
                    fontSize: '0.5rem',
                  },
                }}
              />
            </Box>
          )}
        </Box>
      </Popover>
    </>
  );
}
