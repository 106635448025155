import ExploreIcon from '@mui/icons-material/Explore';
import {
  Alert,
  Checkbox,
  DialogActions,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { HelpIconTooltip } from 'components/utils/tooltips';
import { memo, startTransition, useCallback, useEffect, useMemo, useState } from 'react';
import { CircuitService } from 'services/circuit.service';
import { useDebouncedCallback } from 'use-debounce';
import { getPositionHeightMemoized } from 'utils/circuit/position-height';
import { getAllPositionsNames } from 'utils/circuit/racks-naming';
import { theme } from 'utils/mui-theme';
import { z } from 'zod';
import type { RobotMeasuresWithEnable } from './rack-analysis-dialog';
import { StyledTableRowRackAnalysis } from './rack-analysis-dialog';
import { RackAnalysisFilters } from './rack-analysis-filters';
import { convertToDisplayUnitRackAnalysis, getMeasureColor, secondRowStickyHeaderTopOffset } from './utils';

interface StepMeasuresProps {
  dataList: RobotMeasuresWithEnable[];
  serialToRobotNames: Record<string, string>;
  handleGoToCell: (cellName: string) => void;
  page: number;
  rowsPerPage: number;
  handleChangePage: (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => void;
  handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  handleChangeDisableMeasure: (index: number) => void;
  heightRange?: [number, number];
}

const enableHeightFilterSchema = z.boolean();
const heightRangeSchema = z
  .tuple([z.number(), z.number()])
  .refine(([min, max]) => min <= max, 'Min value must be less than or equal to max value');

function StepMeasures(props: StepMeasuresProps): JSX.Element {
  const {
    dataList,
    serialToRobotNames,
    handleGoToCell,
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
    handleChangeDisableMeasure,
  } = props;

  const [enableHeightFilter, setEnableHeightFilter] = useState(() => {
    const savedValue = sessionStorage.getItem('rackAnalysis.enableHeightFilter');
    if (!savedValue) return false;

    try {
      const parsed: unknown = JSON.parse(savedValue);

      return enableHeightFilterSchema.parse(parsed);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn('Invalid enableHeightFilter value in sessionStorage:', error);

      return false;
    }
  });

  const [heightRange, setHeightRange] = useState<[number, number]>(() => {
    const savedValue = sessionStorage.getItem('rackAnalysis.heightRange');
    if (!savedValue) return [0, 11000];

    try {
      const parsed: unknown = JSON.parse(savedValue);

      return heightRangeSchema.parse(parsed);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn('Invalid heightRange value in sessionStorage:', error);

      return [0, 11000];
    }
  });

  useEffect(() => {
    try {
      const validated = enableHeightFilterSchema.parse(enableHeightFilter);
      sessionStorage.setItem('rackAnalysis.enableHeightFilter', JSON.stringify(validated));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Failed to save enableHeightFilter to sessionStorage:', error);
    }
  }, [enableHeightFilter]);

  useEffect(() => {
    try {
      const validated = heightRangeSchema.parse(heightRange);
      sessionStorage.setItem('rackAnalysis.heightRange', JSON.stringify(validated));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Failed to save heightRange to sessionStorage:', error);
    }
  }, [heightRange]);

  const displayedData = useMemo(
    () => dataList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [dataList, page, rowsPerPage]
  );

  const updateMeasureEnableState = useCallback(
    (dataList: RobotMeasuresWithEnable[], newValue: [number, number]) => {
      startTransition(() => {
        dataList.forEach((measure, index) => {
          const height = getPositionHeightMemoized(measure['Slot Name']);
          if (height === null) return;

          const shouldBeEnabled = height * 1000 >= newValue[0] && height * 1000 <= newValue[1]; // height are in mm

          if (measure.enable !== shouldBeEnabled) {
            handleChangeDisableMeasure(index);
          }
        });
      });
    },
    [handleChangeDisableMeasure]
  );
  const updateMeasureEnableStateDebounced = useDebouncedCallback(updateMeasureEnableState, 1000, {
    trailing: true,
    maxWait: 1000,
  });

  const handleHeightRangeChange = useCallback(
    (event: Event, newValue: number | number[]): void => {
      setHeightRange(newValue as [number, number]);

      updateMeasureEnableStateDebounced(dataList, newValue as [number, number]);
    },
    [dataList, updateMeasureEnableStateDebounced]
  );

  const allPositionsNamesInCircuit = useMemo(() => {
    const racksArr = CircuitService.racks;

    return getAllPositionsNames({
      racksArr,
    });
  }, []);

  return (
    <>
      <Alert severity="info" sx={{ margin: '0 24px' }}>
        Empty distance error does not mean that the rack is correctly placed but that it is irrelevant
      </Alert>

      <TableContainer sx={{ padding: '0 24px 24px 24px', width: 'unset' }}>
        <Table size="small" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Rack name</TableCell>
              <TableCell>Position</TableCell>
              <TableCell>Robot name</TableCell>
              <TableCell>Reference mode</TableCell>
              <TableCell colSpan={3} sx={{ textAlign: 'center', paddingLeft: theme.spacing(4) }}>
                Distance error (mm)
                <HelpIconTooltip
                  title="In the rack frame of reference"
                  sx={{
                    fontSize: '1rem',
                  }}
                />
              </TableCell>

              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ top: secondRowStickyHeaderTopOffset }}></TableCell>
              <TableCell sx={{ top: secondRowStickyHeaderTopOffset }}></TableCell>
              <TableCell sx={{ top: secondRowStickyHeaderTopOffset }}></TableCell>
              <TableCell sx={{ top: secondRowStickyHeaderTopOffset }}></TableCell>
              <TableCell sx={{ top: secondRowStickyHeaderTopOffset }}></TableCell>
              <TableCell sx={{ textAlign: 'right', top: secondRowStickyHeaderTopOffset }}>ΔX</TableCell>
              <TableCell sx={{ textAlign: 'right', top: secondRowStickyHeaderTopOffset }}>ΔY</TableCell>
              <TableCell sx={{ textAlign: 'right', top: secondRowStickyHeaderTopOffset }}>ΔZ</TableCell>

              <TableCell sx={{ top: secondRowStickyHeaderTopOffset }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {displayedData.map((v, i) => {
              const index = i + page * rowsPerPage;

              const enabled = v.enable;
              const errorInX = +v?.['Offset on beam position (X)'];
              const errorInY = +v?.['Offset on Reference position (Y)'];
              const errorInZ = +v?.['Offset on beam height (Z)'];

              const displayErrorInX = errorInX !== 0 && !isNaN(errorInX);
              const displayErrorInY = errorInY !== 0 && !isNaN(errorInY);
              const displayErrorInZ = errorInZ !== 0 && !isNaN(errorInZ);

              const rackName = v?.['Rack Name'] ?? 'Unknown';
              const slotName = v?.['Slot Name'] ?? 'Unknown';

              const serial = v?.['Serial'];
              const robotName = serialToRobotNames[serial] ?? serial ?? 'Unknown';

              const referenceMode = v?.['Reference Mode'];

              const toleranceInX = +v?.['Beam position tolerance'];
              const toleranceInY = +v?.['Reference position tolerance'];
              const toleranceInZ = +v?.['Beam height tolerance'];

              const sameTableCellHeight = 47;

              const positionFound = allPositionsNamesInCircuit.has(slotName);

              return (
                <StyledTableRowRackAnalysis
                  key={`analysisListRow-${index}`}
                  sx={{
                    textDecoration: positionFound ? 'none' : 'line-through',
                    opacity: enabled ? 1 : 0.5,
                  }}
                >
                  <TableCell>
                    <Checkbox checked={enabled} onChange={() => handleChangeDisableMeasure(index)} size="small" />
                  </TableCell>
                  <TableCell>{rackName}</TableCell>
                  <TableCell>{slotName}</TableCell>
                  <Tooltip title={serial} placement="left">
                    <TableCell>
                      <>{robotName}</>
                    </TableCell>
                  </Tooltip>
                  <TableCell>{referenceMode === 'noReference' ? 'none' : referenceMode}</TableCell>
                  <TableCell sx={{ textAlign: 'right' }}>
                    <Typography sx={{ color: getMeasureColor(errorInX, toleranceInX) }}>
                      {displayErrorInX ? `${convertToDisplayUnitRackAnalysis(errorInX)}` : ''}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ textAlign: 'right' }}>
                    <Typography sx={{ color: getMeasureColor(errorInY, toleranceInY) }}>
                      {displayErrorInY ? `${convertToDisplayUnitRackAnalysis(errorInY)}` : ''}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ textAlign: 'right' }}>
                    <Typography sx={{ color: getMeasureColor(errorInZ, toleranceInZ) }}>
                      {displayErrorInZ ? `${convertToDisplayUnitRackAnalysis(errorInZ)}` : ''}
                    </Typography>
                  </TableCell>
                  <TableCell height={sameTableCellHeight}>
                    <Tooltip title="Go to cell">
                      <IconButton
                        onClick={() => handleGoToCell(slotName)}
                        size="small"
                        sx={{
                          color: positionFound ? undefined : theme.palette.grey[400],
                        }}
                      >
                        <ExploreIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </StyledTableRowRackAnalysis>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <DialogActions sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', px: 3 }}>
        <RackAnalysisFilters
          enableHeightFilter={enableHeightFilter}
          heightRange={heightRange}
          onEnableHeightFilterChange={setEnableHeightFilter}
          onHeightRangeChange={handleHeightRangeChange}
        />
        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          component="div"
          count={dataList.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </DialogActions>
    </>
  );
}

const MemoizedStepMeasures = memo(StepMeasures);

export default MemoizedStepMeasures;
