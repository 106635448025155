import { Button } from '@mui/material';
import { Box } from '@mui/system';
import type { DetectedShapes } from 'actions';
import { openDialogAction } from 'actions';
import { DialogTypes } from 'models';
import type {
  CircuitMeasurer,
  CircuitPoint,
  CircuitRack,
  CircuitSegment,
  CircuitStockZone,
  CircuitTurn,
  CircuitZone,
} from 'models/circuit';
import { SnackbarUtils } from 'services/snackbar.service';
import store from 'store';
import { getConfig } from 'utils/config';
import { PreferencesService } from 'utils/preferences';
import { versionCompare } from 'utils/version-compare';
import type { GenerateXMLWorkerAdditionalData } from './generate-xml-utils';
import { generateXMLWorker } from './generate-xml-utils';
import { getGenerateXmlWorkerProxy } from './generate-xml.worker';
import { getLayerGroupModelPreferences } from './get-layer-group-model-preferences';
import type { LayerGroupModelPreferences } from './layer-group-model-preferences.model';

// see https://redmine.balyo.com/issues/42354 & https://redmine.balyo.com/issues/37871
const minimumVersionExportMultiplePointTypes = '4.12.4.3';

/**
 * Generates a Kiwi/Circuit Editor compatible XML circuit file
 * @param zones The zones to include in the XML string
 * @param points The points to include in the XML string
 * @param segments The segments to include in the XML string
 * @param measurers The measurers to include in the XML string
 * @param turns The turns to include in the XML string
 * @param racks The racks to include in the XML string
 * @param pretty Minify the XML if false (P.-S. it looks like Circuit Editor cannot open a minified XML)
 * @returns an array that contains: [0] the XML string, [1] a javascript object with the map of all the ids (geojson ids -> xml ids)
 */

export async function generateXML({
  zones,
  points,
  segments,
  stockZones,
  measurers,
  turns,
  racks,
  useWorker = true,
}: {
  zones?: CircuitZone[];
  points?: CircuitPoint[];
  segments?: CircuitSegment[];
  stockZones?: CircuitStockZone[];
  measurers?: CircuitMeasurer[];
  turns?: CircuitTurn[];
  racks?: CircuitRack[];
  pretty?: boolean;
  useWorker?: boolean;
} = {}): Promise<[string, Record<string, number>]> {
  const storeState = store.getState();
  const dispatch = store.dispatch;

  const layerGroups = storeState.circuit.present.layers.layerGroups;
  const editorLayers = storeState.circuit.present.layers.layers;

  const defaultMaxOvershoot = getConfig('defaultValues').maxOvershoot.toString();

  const projectVersion = storeState.project.circuitVersion;
  const projectDescription = storeState.project.circuitDescription;

  const sdkVersion = PreferencesService.arePreferencesFullyLoaded() ? PreferencesService.getSDKVersion() : '0.0.0';
  const exportMultiplePointType = versionCompare(sdkVersion ?? '0.0.0', minimumVersionExportMultiplePointTypes) >= 0;

  const cellTemplates = storeState.circuit.present.cellTemplates.entities;

  const layerGroupsNames = Object.keys(layerGroups);
  const preferencesModel: Record<string, LayerGroupModelPreferences> = {};
  layerGroupsNames.forEach((layerGroupName) => {
    const layerGroup = layerGroups[layerGroupName];
    preferencesModel[layerGroupName] = getLayerGroupModelPreferences(layerGroup);
  });

  const payload = {
    zones,
    segments,
    stockZones,
    measurers,
    turns,
    racks,
    points,
    pretty: true,

    layerGroups,
    editorLayers,
    cellTemplates,

    projectVersion,
    projectDescription,

    preferencesModel,

    otherOptions: {
      defaultMaxOvershoot,
    },

    exportMultiplePointType,

    inWorker: useWorker,
  };

  let workerResult: [string, Record<string, number>, GenerateXMLWorkerAdditionalData?] | null;
  try {
    const proxy = getGenerateXmlWorkerProxy();
    workerResult = useWorker ? await proxy.generateXMLWorker(payload) : null;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.warn('Failed to use XML worker, falling back to non-worker version:', error);
    workerResult = null;
  }

  const [xml, mapIds, additionalData]: [string, Record<string, number>, GenerateXMLWorkerAdditionalData?] =
    workerResult || (await generateXMLWorker(payload));

  if (additionalData?.missingAtLeastOneCommonLayer) {
    SnackbarUtils.warning(`A layer group named 'Common' is required to properly work with Kiwi`);
  }

  if (additionalData?.problemWhileExportingRackSlots) {
    SnackbarUtils.error('A problem happened while exporting at last one name of a rack slot.');
  }

  if (
    additionalData?.segmentsIdsInTheLayerGroupCommon?.length ||
    additionalData?.pointsIdsInTheLayerGroupCommon?.length ||
    additionalData?.turnsIdsInTheLayerGroupCommon?.length
  ) {
    if (additionalData?.segmentsIdsInTheLayerGroupCommon?.length) {
      // eslint-disable-next-line no-console
      console.warn(
        `The following segments are in the layer group "Common": ${additionalData.segmentsIdsInTheLayerGroupCommon.join(
          ', '
        )} (${additionalData.segmentsIdsInTheLayerGroupCommon.length} segments)`
      );
    }

    if (additionalData?.pointsIdsInTheLayerGroupCommon?.length) {
      // eslint-disable-next-line no-console
      console.warn(
        `The following points are in the layer group "Common": ${additionalData.pointsIdsInTheLayerGroupCommon.join(
          ', '
        )} (${additionalData.pointsIdsInTheLayerGroupCommon.length} points)`
      );
    }

    if (additionalData?.turnsIdsInTheLayerGroupCommon?.length) {
      // eslint-disable-next-line no-console
      console.warn(
        `The following turns are in the layer group "Common": ${additionalData.turnsIdsInTheLayerGroupCommon.join(
          ', '
        )} (${additionalData.turnsIdsInTheLayerGroupCommon.length} turns)`
      );
    }

    const nbElementsInTheLayerGroupCommon =
      (additionalData?.segmentsIdsInTheLayerGroupCommon?.length ?? 0) +
      (additionalData?.pointsIdsInTheLayerGroupCommon?.length ?? 0) +
      (additionalData?.turnsIdsInTheLayerGroupCommon?.length ?? 0);

    const warningsStr: string[] = [];
    if (additionalData?.segmentsIdsInTheLayerGroupCommon?.length) {
      warningsStr.push(
        `${additionalData.segmentsIdsInTheLayerGroupCommon.length} segment${
          additionalData.segmentsIdsInTheLayerGroupCommon.length > 1 ? 's' : ''
        }`
      );
    }

    if (additionalData?.pointsIdsInTheLayerGroupCommon?.length) {
      warningsStr.push(
        `${additionalData.pointsIdsInTheLayerGroupCommon.length} point${
          additionalData.pointsIdsInTheLayerGroupCommon.length > 1 ? 's' : ''
        }`
      );
    }

    if (additionalData?.turnsIdsInTheLayerGroupCommon?.length) {
      warningsStr.push(
        `${additionalData.turnsIdsInTheLayerGroupCommon.length} turn${
          additionalData.turnsIdsInTheLayerGroupCommon.length > 1 ? 's' : ''
        }`
      );
    }

    SnackbarUtils.warning(
      `${warningsStr.join(', ')} ${
        nbElementsInTheLayerGroupCommon > 1 ? 'are' : 'is'
      } in the layer group "Common". This is not recommended. Please move them to another layer group.`
    );
  }

  const onlyStockZoneInOtherGroup = new Set<string>(additionalData?.stocksInOtherLayerGroupThanCommon || []);
  const onlyRackInOtherGroup = new Set<string>(additionalData?.racksInOtherLayerGroupThanCommon || []);

  if (
    additionalData?.stocksInOtherLayerGroupThanCommon?.length ||
    additionalData?.racksInOtherLayerGroupThanCommon?.length
  ) {
    if (additionalData?.stocksInOtherLayerGroupThanCommon?.length) {
      // eslint-disable-next-line no-console
      console.warn(
        `The following stocks are in a layer group other than "Common": ${additionalData.stocksInOtherLayerGroupThanCommon.join(
          ', '
        )} (${additionalData.stocksInOtherLayerGroupThanCommon.length} stocks)`
      );
    }

    if (additionalData?.racksInOtherLayerGroupThanCommon?.length) {
      // eslint-disable-next-line no-console
      console.warn(
        `The following racks are in a layer group other than "Common": ${additionalData.racksInOtherLayerGroupThanCommon.join(
          ', '
        )} (${additionalData.racksInOtherLayerGroupThanCommon.length} racks)`
      );
    }

    const warningsStr: string[] = [];
    if (additionalData?.stocksInOtherLayerGroupThanCommon?.length) {
      warningsStr.push(`${onlyStockZoneInOtherGroup.size} stock zone${onlyStockZoneInOtherGroup.size > 1 ? 's' : ''}`);
    }

    if (additionalData?.racksInOtherLayerGroupThanCommon?.length) {
      warningsStr.push(`${onlyRackInOtherGroup.size} rack${onlyRackInOtherGroup.size > 1 ? 's' : ''}`);
    }

    if (onlyStockZoneInOtherGroup?.size || onlyRackInOtherGroup?.size) {
      // Create an array to hold both stocks and racks if they are available
      const allShapesInOtherLayerGroupsSet = new Set([
        ...(additionalData?.stocksInOtherLayerGroupThanCommon ?? []),
        ...(additionalData?.racksInOtherLayerGroupThanCommon ?? []),
      ]);

      const allShapesInOtherLayerGroups = Array.from(allShapesInOtherLayerGroupsSet);

      SnackbarUtils.warning(
        <>
          <Box component="div" sx={{ display: 'flex' }}>
            {`${warningsStr.join(', ')} ${
              allShapesInOtherLayerGroups.length > 1 ? 'are' : 'is'
            } in a layer group other than "Common". This is not recommended. Please move them to the layer group "Common".`}
          </Box>
        </>,
        {
          persist: true,
          action: (
            <>
              <Button
                color="warning"
                variant="contained"
                sx={{
                  textTransform: 'none',
                }}
                onClick={() =>
                  dispatch(
                    openDialogAction({
                      type: DialogTypes.SearchShape,
                      payload: {
                        // Send all available shapes as payload
                        searchedShapesIds: allShapesInOtherLayerGroups,
                      } as DetectedShapes,
                    })
                  )
                }
              >
                Find the shapes
              </Button>
            </>
          ),
        }
      );
    }
  }

  if (additionalData?.turnsNotExportedInAtLeastOneLayerGroup?.length) {
    // eslint-disable-next-line no-console
    console.warn(
      `The following turns join two segments that are not in the same layer group: ${additionalData.turnsNotExportedInAtLeastOneLayerGroup.join(
        ', '
      )} (${additionalData.turnsNotExportedInAtLeastOneLayerGroup.length} turns)`
    );

    SnackbarUtils.warning(
      <>
        <Box component="div" sx={{ display: 'flex' }}>
          {additionalData.turnsNotExportedInAtLeastOneLayerGroup.length} turn
          {additionalData.turnsNotExportedInAtLeastOneLayerGroup.length > 1 ? 's' : ''} link
          {additionalData.turnsNotExportedInAtLeastOneLayerGroup.length === 1 ? 's' : ''} two segments that are not in
          the same layer group.
          <br />
          Please move {additionalData.turnsNotExportedInAtLeastOneLayerGroup.length > 1 ? 'them' : 'it'} to the same
          layer group if would like them to be exported. <br />
        </Box>
      </>,
      {
        persist: true,
        action: (
          <>
            <Button
              color="warning"
              variant="contained"
              sx={{
                textTransform: 'none',
              }}
              onClick={() =>
                dispatch(
                  openDialogAction({
                    type: DialogTypes.SearchShape,
                    payload: {
                      searchedShapesIds: additionalData.turnsNotExportedInAtLeastOneLayerGroup,
                    } as DetectedShapes,
                  })
                )
              }
            >
              Find the shapes
            </Button>
          </>
        ),
      }
    );
  }

  return [xml, mapIds];
}
