/* eslint-disable @typescript-eslint/no-unsafe-call */

import * as Comlink from 'comlink';
import {
  findShapeOrientation,
  findVectorsAngle,
  getClosestPointInSegment,
  isPointInSegmentBBox,
  pDistance,
} from 'drawings/helpers';
import type { Feature, LineString as LineStringGeoJSON, Position } from 'geojson';
import { getDistanceBetweenPoints, getUnitaryVector } from 'librarycircuit/utils/geometry/vectors';
import type { TurnProperties } from 'models/circuit';
import { ShapeTypes } from 'models/circuit';
import { UNIT_COUNT_PER_METER } from 'models/drawings';
import type { LoadedSegment } from 'reducers/circuit/state.js';
import { defaultMaxOvershoot } from 'utils/circuit/default-values';
import { z } from 'zod';
import libTurnGeneratorModule from './libs/libTurnGenerator.js';

const deltaCapThreshold = 1; // deg
const distancePointsThreshold = 0.002; // m

export const minimumDistanceBetweenPointsTrackless = 0.01; // m

interface TurnGeneratorResult {
  computedStartPointX: number; // int64
  computedStartPointY: number; // int64
  computedFinishPointX: number; // int64
  computedFinishPointY: number; // int64

  numberOfPoint: number; // int64

  errorCode: number; // int64
}

const pointTurnSchema = z.object({
  x: z.number(),
  y: z.number(),
  pointAngDeg: z.number(),
  turretAngDeg: z.number(),
  steeredDist: z.number(),
  rollerDist: z.number(),
});

type PointTurn = z.infer<typeof pointTurnSchema>;

export interface GetTurnFeatureServiceProps {
  radius: number;
  maxOvershoot: number;
  startLine: number[][];
  endLine: number[][];
  originSegmentLocked: boolean | undefined;
  destSegmentLocked: boolean | undefined;
  stopBeforeTurn?: boolean;
  startPointPositionFactor?: number;
  extendedLength?: boolean;
  startPointOffset?: number;
  layerId: string;
  prio: number;
  stockLineSegment?: LoadedSegment | undefined;
}

export class TrajectoryService {
  private static libTurnGenerator: any;
  private static libTurnGeneratorLoaded = false;

  private static sampleStep = 0.05;
  private static scale = UNIT_COUNT_PER_METER;
  private static maxNbPointsFeature = 200;

  public static turnGenerator: any;
  public static turnGeneratorResult: any;

  public static async loadLibTurnGenerator(): Promise<void> {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    TrajectoryService.libTurnGenerator = await libTurnGeneratorModule();
    TrajectoryService.libTurnGeneratorLoaded = true;
  }

  public static initTurnGenerator(): void {
    if (!this.libTurnGeneratorLoaded) throw new Error('libTurnGenerator is not loaded');

    const robotTurretPositionX = 1.15;
    // const robotTurretPositionY = 0.0;
    const robotOvershootCornerX = 1.5;
    const robotOvershootCornerY = 0.5;
    const scale = this.scale;
    const robotTurretAngularDegSpeedMax = 45;
    const robotNormalSpeedMax = 0.3;

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    this.turnGenerator = this.libTurnGenerator._turnGenerator_init(
      robotTurretPositionX,
      robotOvershootCornerX,
      robotOvershootCornerY,
      scale,
      this.sampleStep,
      robotTurretAngularDegSpeedMax,
      robotNormalSpeedMax
    );
  }

  // this function is super important, we deserialize the C struct into an array made of int64 and then we reput the values in an interface easy to use in ts
  public static getResultTurnGenerator(): TurnGeneratorResult {
    if (!this.libTurnGeneratorLoaded) throw new Error('libTurnGenerator is not loaded');

    const nbElem = 6;

    const ptr: number = this.libTurnGenerator._malloc(nbElem * BigInt64Array.BYTES_PER_ELEMENT) as number;

    this.libTurnGenerator._turnGenerator_getResult(this.turnGenerator, ptr);

    const arrRes: number[] = new Array(nbElem) as number[];

    for (let i = 0; i < nbElem; i++) {
      arrRes[i] = this.libTurnGenerator.getValue(ptr + BigInt64Array.BYTES_PER_ELEMENT * i, 'i64') as number;
    }

    const res = {
      // order of the fields matters
      computedStartPointX: arrRes[0],
      computedStartPointY: arrRes[1],
      computedFinishPointX: arrRes[2],
      computedFinishPointY: arrRes[3],
      numberOfPoint: arrRes[4],
      errorCode: arrRes[5],
    };
    this.libTurnGenerator._free(ptr);

    return res;
  }

  /**
   * This function call the TurnGenerator library
   * and generates a proper RoadEditor GeoJSON Feature for this turn
   *
   * The following function calls WebAssembly functions of the SDK libTurnGenerator to generate a GeoJSON feature
   *
   * There are several cases to handle:
   * 1. The origin and destination segments are colinears (or almost, with a tolerance)
   *    1.1 Both segments are in front of each other, then we consider it as a straight line
   *        The generated turn is actually just a straight line between the two segments
   *    1.2 Otherwise, we consider it is a line change, we generate turn between the two parellel segments
   *        The generated turn is actually two turns (for the libTurnGenerator) and a straight line between the two turns (list of points mixed into the generated geojson feature)
   * 2. The origin and destination segments are not colinears
   *    We generated a turn between the two segments with the libTurnGenerator
   *
   * @param radius the turn radius [m]
   * @param maxOvershoot the max overshoot
   * @param startLine the origin segment line
   * @param endLine the destination segment line
   * @param originSegmentLocked is the origin segment line locked
   * @param destSegmentLocked is the destination segment line locked
   * @param stopBeforeTurn is it a stop before turn turn?
   * @param startPointPositionFactor % of the startLine where the turn starts
   * @param extendedLength is the turn connected to an extended length (of a rack or a stockzone)
   * @param startPointOffset start point offset parameter, it adds a start line before starting the turn [m]
   * @returns the generated geojson feature
   */
  public static getTurnFeature(props: GetTurnFeatureServiceProps): Feature<LineStringGeoJSON, TurnProperties> | void {
    const {
      radius,
      startLine,
      endLine,
      originSegmentLocked,
      destSegmentLocked,
      stopBeforeTurn = false,
      startPointPositionFactor = 0,
      extendedLength = false,
      startPointOffset = 0,
      layerId,
      prio,
      stockLineSegment,
    } = props;
    let { maxOvershoot } = props;

    TrajectoryService.initTurnGenerator();

    const originalMaxOvershoot = maxOvershoot;
    if (stopBeforeTurn) maxOvershoot = defaultMaxOvershoot;

    const V1 = getUnitaryVector({ start: startLine[0], end: startLine[1] });
    const V2 = getUnitaryVector({ start: endLine[0], end: endLine[1] });
    const angleBetweenSeg = Math.abs(findVectorsAngle(V1, V2));

    const colinearSegmentsThreshold = 5; // deg
    const areSegmentsColinear = angleBetweenSeg < colinearSegmentsThreshold;

    // colinear segments
    if (areSegmentsColinear) {
      // distance line/point to know if the two parallel lines are close or not
      const x1 = endLine[0][0];
      const y1 = endLine[0][1];
      const x2 = endLine[1][0];
      const y2 = endLine[1][1];
      const x0 = startLine[0][0];
      const y0 = startLine[0][1];
      const distance =
        Math.abs((x2 - x1) * (y1 - y0) - (x1 - x0) * (y2 - y1)) /
        Math.sqrt((x2 - x1) * (x2 - x1) + (y2 - y1) * (y2 - y1));

      const slope = distance ? distance / getDistanceBetweenPoints(startLine[1], endLine[0]) : 0;
      const slopeThreshold = 0.05; // to decrease if the discontinuity of the turn is too important for the robots, can be discrimined between the stopBeforeTurn turns and the normal turns slopeThreshold_stop > slopeThreshold_normal

      // code in the next if, to create a line change only, see after the block for a "normal" turn
      if (slope < slopeThreshold) {
        // both lines are (almost) aligned
        // now, we want to test if both segments intersects
        // to do so, we look at the distance of the end points
        const d1 = pDistance(
          startLine[0][0],
          startLine[0][1],
          endLine[0][0],
          endLine[0][1],
          endLine[1][0],
          endLine[1][1]
        );
        const d2 = pDistance(
          startLine[0][0],
          startLine[0][1],
          endLine[0][0],
          endLine[0][1],
          endLine[1][0],
          endLine[1][1]
        );

        const d3 = pDistance(
          endLine[0][0],
          endLine[0][1],
          startLine[0][0],
          startLine[0][1],
          startLine[1][0],
          startLine[1][1]
        );
        const d4 = pDistance(
          endLine[0][0],
          endLine[0][1],
          startLine[0][0],
          startLine[0][1],
          startLine[1][0],
          startLine[1][1]
        );

        const distances = [d1, d2, d3, d4];

        const dThresholdForSingularyTurn = 1;

        const areSegmentOnTopOfEachOther = distances.some((d) => d < dThresholdForSingularyTurn);

        if (areSegmentOnTopOfEachOther) {
          if (extendedLength) {
            if (!stockLineSegment) return;

            const stockLineSegmentCoords = stockLineSegment.geometry.coordinates;

            const angleSeg = findShapeOrientation(stockLineSegmentCoords[0], stockLineSegmentCoords[1]);
            const cap = [angleSeg, angleSeg];

            const [pt2] = getClosestPointInSegment(stockLineSegmentCoords[1], endLine[0], endLine[1]);

            //To make sure the turn will work correctly we need at least one point on each segment
            const coordinates = [stockLineSegmentCoords[1], pt2];

            const feature = {
              type: 'Feature',
              geometry: {
                type: 'LineString',
                coordinates,
              },
              properties: {
                type: ShapeTypes.TurnShape,
                layerId,
                turnType: stopBeforeTurn ? 'StopBeforeTurn' : 'Normal',
                originId: undefined,
                destinationId: undefined,
                positionFactorOrigin: NaN,
                positionFactorDest: NaN,
                maxOvershoot: originalMaxOvershoot,
                drivenBy: 'StraightLine',
                coordinateProperties: {
                  cap,
                },
                isSingularTurn: true,
              },
            } as Feature<LineStringGeoJSON, TurnProperties>;

            return feature;
          }

          const angleSeg = findShapeOrientation(startLine[0], startLine[1]);
          const cap = [angleSeg, angleSeg];

          const isLastPointOfEndLineInStartLine = isPointInSegmentBBox(startLine[0], endLine[1], startLine[1], 5);
          const isLastPointOfStartLineInEndLine = isPointInSegmentBBox(endLine[0], startLine[1], endLine[0], 5);

          const lastPointToComputeTurn = isLastPointOfEndLineInStartLine
            ? endLine[1]
            : isLastPointOfStartLineInEndLine
              ? startLine[1]
              : undefined;

          if (!lastPointToComputeTurn) return;

          const otherSegment = isLastPointOfEndLineInStartLine ? startLine : endLine;

          const [pt2] = getClosestPointInSegment(lastPointToComputeTurn, otherSegment[0], otherSegment[1]);

          //To make sure the turn will work correctly we need at least one point on each segment
          const coordinates = [lastPointToComputeTurn, pt2];

          const feature = {
            type: 'Feature',
            geometry: {
              type: 'LineString',
              coordinates,
            },
            properties: {
              type: ShapeTypes.TurnShape,
              layerId,
              turnType: stopBeforeTurn ? 'StopBeforeTurn' : 'Normal',
              originId: undefined,
              destinationId: undefined,
              positionFactorOrigin: NaN,
              positionFactorDest: NaN,
              maxOvershoot: originalMaxOvershoot,
              drivenBy: 'StraightLine',
              coordinateProperties: {
                cap,
              },
              isSingularTurn: true,
            },
          } as Feature<LineStringGeoJSON, TurnProperties>;

          return feature;
        }

        const dThreshold = 1e-3;

        const areBothSegmentsNotTouching = distances.every((d) => d > dThreshold);

        if (areBothSegmentsNotTouching) {
          // both segments are not touching each other (and are aligned)
          // we can continue the segments with a "straight turn"
          const coordinates = [startLine[1], endLine[0]];
          const angleSeg = findShapeOrientation(startLine[0], startLine[1]);
          const cap = [angleSeg, angleSeg];

          const feature = {
            type: 'Feature',
            geometry: {
              type: 'LineString',
              coordinates,
            },
            properties: {
              type: ShapeTypes.TurnShape,
              layerId,
              turnType: stopBeforeTurn ? 'StopBeforeTurn' : 'Normal',
              originId: undefined,
              destinationId: undefined,
              positionFactorOrigin: NaN,
              positionFactorDest: NaN,
              maxOvershoot: originalMaxOvershoot,
              drivenBy: 'StraightLine',
              coordinateProperties: {
                cap,
              },
            },
          } as Feature<LineStringGeoJSON, TurnProperties>;

          return feature;
        }
      }

      // it is not straight, we have to do generate a line change turn!
      // the idea is quite simple, we compute a virtual segment between the two segments
      // and then we generate a turn between this virtual segments and both segments (= 2 turns)
      let virtualSegment: Position[];
      if (extendedLength) {
        virtualSegment = [startLine[1], endLine[0]];
      } else {
        virtualSegment = [
          getClosestPointInSegment(endLine[0], startLine[0], startLine[1])[0],
          getClosestPointInSegment(startLine[1], endLine[0], endLine[1])[0],
        ];
      }

      const angle1 = findShapeOrientation(startLine[0], startLine[1]);
      const angle2 = findShapeOrientation(endLine[0], endLine[1]);
      const angleVirtual = findShapeOrientation(virtualSegment[0], virtualSegment[1]);

      const defaultRadiusExtendedLength = 0.3; // meters, arbitrary value; it's a small value that works for all trucks for sure, the idea is to generate a small turn in front of the extended length, then we have the virtual segment, and then a proper turn to finish

      const resultTurn1 = !(extendedLength && false) // see #42797, we disable the get turn by start point for line change extended length
        ? TrajectoryService.getTurnByCurve(
            extendedLength && radius > defaultRadiusExtendedLength ? defaultRadiusExtendedLength : radius,
            maxOvershoot,
            startLine[0][0] / 100,
            startLine[0][1] / 100,
            angle1,
            virtualSegment[1][0] / 100,
            virtualSegment[1][1] / 100,
            angleVirtual,
            stopBeforeTurn
          )
        : TrajectoryService.getTurnByStartPoint(
            startPointOffset,
            maxOvershoot,
            startLine[1][0] / 100,
            startLine[1][1] / 100,
            angle1,
            (virtualSegment[0][0] + virtualSegment[1][0]) / 10 / 100,
            (virtualSegment[0][1] + virtualSegment[1][1]) / 10 / 100,
            angleVirtual,
            stopBeforeTurn
          );

      if (resultTurn1.errorCode !== 0) {
        // eslint-disable-next-line no-console
        console.warn(`turn1 generation failed with error code: ${resultTurn1.errorCode}`);

        return;
      }

      if (Math.abs(resultTurn1.numberOfPoint) > this.maxNbPointsFeature)
        resultTurn1.numberOfPoint = this.maxNbPointsFeature;

      const turn1 = TrajectoryService.getPointTurns(resultTurn1.numberOfPoint);

      const areAllPointsOK = checkPoints(turn1);
      if (!areAllPointsOK) {
        // eslint-disable-next-line no-console
        console.warn('Some points are not valid according to the schema', turn1);

        return;
      }

      // because we generated the turn with an offset, we need to add this offset into the turn to be properly connected to the extended length
      if (extendedLength) {
        const dx = startLine[1][0] - turn1[0].x;
        const dy = startLine[1][1] - turn1[0].y;

        const sign = !extendedLength ? -1 : 1;

        for (let i = 0; i < turn1.length; i++) {
          turn1[i].x += dx * sign;
          turn1[i].y += dy * sign;
        }
      }

      const resultTurn2 = !(extendedLength && false)
        ? TrajectoryService.getTurnByCurve(
            radius,
            maxOvershoot,
            turn1[turn1.length - 1].x / 100,
            turn1[turn1.length - 1].y / 100,
            turn1[turn1.length - 1].pointAngDeg,
            endLine[1][0] / 100,
            endLine[1][1] / 100,
            angle2,
            stopBeforeTurn
          )
        : TrajectoryService.getTurnByStartPoint(
            startPointOffset,
            maxOvershoot,
            turn1[turn1.length - 1].x / 100,
            turn1[turn1.length - 1].y / 100,
            turn1[turn1.length - 1].pointAngDeg,
            endLine[1][0] / 100,
            endLine[1][1] / 100,
            angle2,
            stopBeforeTurn
          );

      if (resultTurn2.errorCode !== 0) {
        // eslint-disable-next-line no-console
        console.warn(`turn2 generation failed with error code: ${resultTurn2.errorCode}`);

        return;
      }

      if (Math.abs(resultTurn2.numberOfPoint) > this.maxNbPointsFeature)
        resultTurn2.numberOfPoint = this.maxNbPointsFeature;

      const turn2 = TrajectoryService.getPointTurns(resultTurn2.numberOfPoint);
      const areAllPointsOK2 = checkPoints(turn1);
      if (!areAllPointsOK2) {
        // eslint-disable-next-line no-console
        console.warn('Some points are not valid according to the schema (2)', turn2);

        return;
      }

      const lastPointTurn1 = [turn1[turn1.length - 1].x, turn1[turn1.length - 1].y];
      const firstPointTurn2 = [turn2[0].x, turn2[0].y];

      const dTurn1endLine =
        pDistance(lastPointTurn1[0], lastPointTurn1[1], endLine[0][0], endLine[0][1], endLine[1][0], endLine[1][1]) /
        100;
      const dTurn1startLine =
        pDistance(
          lastPointTurn1[0],
          lastPointTurn1[1],
          startLine[0][0],
          startLine[0][1],
          startLine[1][0],
          startLine[1][1]
        ) / 100;

      if (dTurn1startLine > dTurn1endLine) return;

      const angleBetweenTurns = findShapeOrientation(lastPointTurn1, firstPointTurn2);
      const deltaAngle = Math.abs(angleBetweenTurns - angleVirtual);
      const maxDeltaAngleThreshold = 0.1; // degrees, arbirtrary chosen value

      if (deltaAngle > maxDeltaAngleThreshold) {
        // eslint-disable-next-line no-console
        console.warn(
          `Line change delta angle between turns is too high: ${deltaAngle}°, max allowed: ${maxDeltaAngleThreshold}° => skipping line change generation`
        );

        return;
      }

      const coordinates: number[][] = [];
      const cap: number[] = [];

      turn1.forEach((pt) => {
        coordinates.push([pt.x, pt.y]);
        cap.push(pt.pointAngDeg);
      });

      turn2.forEach((pt) => {
        coordinates.push([pt.x, pt.y]);
        cap.push(pt.pointAngDeg);
      });

      const feature = {
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates,
        },
        properties: {
          type: ShapeTypes.TurnShape,
          layerId,
          turnType: stopBeforeTurn ? 'StopBeforeTurn' : 'Normal',
          radius,
          originId: undefined,
          destinationId: undefined,
          positionFactorOrigin: NaN,
          positionFactorDest: NaN,
          maxOvershoot: originalMaxOvershoot,
          drivenBy: 'LineChange',
          coordinateProperties: {
            cap,
          },
        },
      } as Feature<LineStringGeoJSON, TurnProperties>;

      return feature;
    }

    const startPointNeeded = (angleBetweenSeg > 150 && angleBetweenSeg < 210) || extendedLength;

    const dStartLine = getDistanceBetweenPoints(startLine[0], startLine[1]);

    const ptStartX = startPointNeeded
      ? startLine[0][0] + dStartLine * startPointPositionFactor * V1[0]
      : startLine[0][0];
    const ptStartY = startPointNeeded
      ? startLine[0][1] + dStartLine * startPointPositionFactor * V1[1]
      : startLine[0][1];
    const ptFinishX = endLine[0][0];
    const ptFinishY = endLine[0][1];
    const ptStartAngle = findShapeOrientation(startLine[0], startLine[1]);
    const ptFinishAngle = findShapeOrientation(endLine[0], endLine[1]);

    const resultTurn = startPointNeeded
      ? TrajectoryService.getTurnByStartPoint(
          startPointOffset,
          maxOvershoot,
          ptStartX / 100,
          ptStartY / 100,
          ptStartAngle,
          ptFinishX / 100,
          ptFinishY / 100,
          ptFinishAngle,
          stopBeforeTurn
        )
      : TrajectoryService.getTurnByCurve(
          radius,
          maxOvershoot,
          ptStartX / 100,
          ptStartY / 100,
          ptStartAngle,
          ptFinishX / 100,
          ptFinishY / 100,
          ptFinishAngle,
          stopBeforeTurn
        );

    if (resultTurn.errorCode) return;

    if (Math.abs(resultTurn.numberOfPoint) > this.maxNbPointsFeature)
      resultTurn.numberOfPoint = this.maxNbPointsFeature;

    const pointsNotFiltered = TrajectoryService.getPointTurns(resultTurn.numberOfPoint);

    const areAllPointsOK = checkPoints(pointsNotFiltered);
    if (!areAllPointsOK) {
      // eslint-disable-next-line no-console
      console.warn('Some points are not valid according to the schema', pointsNotFiltered);

      return;
    }

    let points: PointTurn[] = [pointsNotFiltered[0]];

    for (let i = 1, l = pointsNotFiltered.length - 1; i < l; i++) {
      const point = pointsNotFiltered[i];
      const lastPoint = points[points.length - 1];
      const deltaCap = Math.abs(point.pointAngDeg - lastPoint.pointAngDeg) % 360;
      if (deltaCap > deltaCapThreshold) {
        const distance = getDistanceBetweenPoints([lastPoint.x, lastPoint.y], [point.x, point.y]);
        if (distance > distancePointsThreshold * 100) {
          points.push(point);
        }
      }
    }

    const firstPointGenerated = pointsNotFiltered[0];
    const lastPointGenerated = pointsNotFiltered[pointsNotFiltered.length - 1];

    // see #46931, we snap the last point to be exactly on the segment
    let firstPointPosition: Position;
    if (originSegmentLocked === true) {
      [firstPointPosition] = getClosestPointInSegment(
        [firstPointGenerated.x, firstPointGenerated.y],
        startLine[0],
        startLine[1]
      );
    } else {
      [firstPointPosition] = getClosestPointInSegment(
        [firstPointGenerated.x, firstPointGenerated.y],
        startLine[0],
        startLine[1],
        false
      );
    }

    const isLastPointGeneratedOnTheSegment = isPointInSegmentBBox(
      endLine[0],
      [lastPointGenerated.x, lastPointGenerated.y],
      endLine[1]
    );

    let lastPointPosition: Position;
    if (destSegmentLocked === true && isLastPointGeneratedOnTheSegment === false) {
      [lastPointPosition] = getClosestPointInSegment(
        [lastPointGenerated.x, lastPointGenerated.y],
        endLine[0],
        endLine[1]
      );
    } else {
      [lastPointPosition] = getClosestPointInSegment(
        [lastPointGenerated.x, lastPointGenerated.y],
        endLine[0],
        endLine[1],
        false
      );
    }

    const firstPoint: typeof firstPointGenerated = {
      ...firstPointGenerated,
      x: firstPointPosition[0],
      y: firstPointPosition[1],
    };
    const lastPoint: typeof lastPointGenerated = {
      ...lastPointGenerated,
      x: lastPointPosition[0],
      y: lastPointPosition[1],
    };

    const snappedDistanceFirstPoint = getDistanceBetweenPoints(
      [firstPoint.x, firstPoint.y],
      [points[0].x, points[0].y]
    );
    const distanceSnappedDistance = 0.2; // 2 mm in cm
    /**
     * For turns connected to an extended length with a start point offset, we want to duplicate the point instead of translating it to keep the angle of the first point of the turn
     */
    if (snappedDistanceFirstPoint > distanceSnappedDistance) {
      points.unshift(firstPoint);
    } else {
      points[0] = firstPoint;
    }

    points.push(lastPoint);

    const distanceFirstAndSecondPoint = getDistanceBetweenPoints(
      [points[0].x, points[0].y],
      [points[1].x, points[1].y]
    ); // [cm]
    const distanceLastAndBeforeLastPoint = getDistanceBetweenPoints(
      [points[points.length - 1].x, points[points.length - 1].y],
      [points[points.length - 2].x, points[points.length - 2].y]
    ); // [cm]

    if (distanceFirstAndSecondPoint < distancePointsThreshold * 100) {
      // remove second point
      points.splice(1, 1);
    }

    if (distanceLastAndBeforeLastPoint < distancePointsThreshold * 100) {
      // remove before last point
      points.splice(points.length - 2, 1);
    }

    if (points.length < 2) {
      points = [firstPoint, lastPoint];
    }

    // we transform the data for to be feature friendly
    const coordinates = points.map((pt) => [pt.x, pt.y]);

    const cap = points.map((pt) => pt.pointAngDeg);

    const feature = {
      type: 'Feature',
      geometry: {
        type: 'LineString',
        coordinates,
      },
      properties: {
        type: ShapeTypes.TurnShape,
        layerId,
        prio,
        turnType: stopBeforeTurn ? 'StopBeforeTurn' : 'Normal',
        radius,
        originId: undefined,
        destinationId: undefined,
        positionFactorOrigin: NaN,
        positionFactorDest: NaN,
        maxOvershoot: originalMaxOvershoot,
        drivenBy: startPointNeeded ? 'StartPoint' : 'Radius',
        coordinateProperties: {
          cap,
        },
      },
    } as Feature<LineStringGeoJSON, TurnProperties>;

    return feature;
  }

  public static getTurnByCurve(
    radius: number, // meters
    maxOvershoot: number, // meters
    ptStartX: number, // meters
    ptStartY: number, // meters
    ptStartAngle: number, // deg
    ptFinishX: number, // meters
    ptFinishY: number, // meters
    ptFinishAngle: number, // deg
    stopBeforeTurn = false
  ): TurnGeneratorResult {
    if (!this.libTurnGeneratorLoaded) throw new Error('libTurnGenerator is not loaded');

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const error = this.libTurnGenerator._turnGenerator_setTurnByCurveAndOptions(
      this.turnGenerator,
      radius,
      maxOvershoot,
      ptStartAngle,
      BigInt(Math.round(ptStartX * UNIT_COUNT_PER_METER)),
      BigInt(Math.round(ptStartY * UNIT_COUNT_PER_METER)),
      ptFinishAngle,
      BigInt(Math.round(ptFinishX * UNIT_COUNT_PER_METER)),
      BigInt(Math.round(ptFinishY * UNIT_COUNT_PER_METER)),
      BigInt(!!stopBeforeTurn) // 0 = Normal, 1 = StopBeforeTurn
    );
    if (error) {
      // eslint-disable-next-line no-console
      console.error(`libTurnGenerator raised an error during the computation of a turn, error code: ${error}`);
    }

    return this.getResultTurnGenerator();
  }

  public static getTurnByStartPoint(
    startPointOffset: number, // ?
    maxOvershoot: number, // meters
    ptStartX: number, // meters
    ptStartY: number, // meters
    ptStartAngle: number, // deg
    ptFinishX: number, // meters
    ptFinishY: number, // meters
    ptFinishAngle: number, // deg
    stopBeforeTurn = false
  ): TurnGeneratorResult {
    if (!this.libTurnGeneratorLoaded) throw new Error('libTurnGenerator is not loaded');

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const error = this.libTurnGenerator._turnGenerator_setTurnByStartPointAndOptions(
      this.turnGenerator,
      -startPointOffset,
      maxOvershoot,
      ptStartAngle,
      BigInt(Math.round(ptStartX * UNIT_COUNT_PER_METER)),
      BigInt(Math.round(ptStartY * UNIT_COUNT_PER_METER)),
      ptFinishAngle,
      BigInt(Math.round(ptFinishX * UNIT_COUNT_PER_METER)),
      BigInt(Math.round(ptFinishY * UNIT_COUNT_PER_METER)),
      BigInt(!!stopBeforeTurn) // 0 = Normal, 1 = StopBeforeTurn
    );
    if (error) {
      // eslint-disable-next-line no-console
      (error === 1 ? console.warn : console.error)(
        `libTurnGenerator raised an error during the computation of a turn, error code: ${error}`
      );
    }

    return this.getResultTurnGenerator();
  }

  public static getPointTurns(nbPoints: number): PointTurn[] {
    const nbElems = 6; // nb of fields of PointTurn, unfortunately it's far from simple to get it in ts, so i harcorded the value
    const sizePoint = Float64Array.BYTES_PER_ELEMENT * nbElems; // nb of bytes taken by a point 'struct'
    const ptr: number = this.libTurnGenerator._malloc(sizePoint * nbPoints) as number;

    this.libTurnGenerator._turnGenerator_writeResultList(this.turnGenerator, ptr, nbPoints);

    const res: PointTurn[] = []; // we retrieve the data from the C array
    for (let i = 0; i < nbPoints; i++) {
      const ptrPoint: number = ptr + i * sizePoint; // pointer to the beginning of the point
      const point: PointTurn = {
        // order of the fields is important
        x: NaN,
        y: NaN,
        pointAngDeg: NaN,
        turretAngDeg: NaN,
        steeredDist: NaN,
        rollerDist: NaN,
      };

      let j = 0;
      for (const key in point) {
        point[key] = this.libTurnGenerator.getValue(
          ptrPoint + j++ * Float64Array.BYTES_PER_ELEMENT,
          'double'
        ) as number;
      }

      point.x *= 100;
      point.y *= 100;
      point.steeredDist *= 100;
      point.rollerDist *= 100;

      res.push(point);
    }

    this.libTurnGenerator._free(ptr);

    return res;
  }
}

/**
 * Validates an array of PointTurn objects against the pointTurnSchema.
 *
 * @param {PointTurn[]} points - The array of PointTurn objects to validate.
 * @returns {boolean} - Returns true if all PointTurn objects are valid according to the schema, otherwise false.
 */
function checkPoints(points: PointTurn[]): boolean {
  return points.every((point) => pointTurnSchema.safeParse(point).success);
}

TrajectoryService.loadLibTurnGenerator();

const getTurnFeature = TrajectoryService.getTurnFeature;

export type GetTurnFeature = typeof getTurnFeature;

Comlink.expose({ getTurnFeature });
