import AnalyticsIcon from '@mui/icons-material/Analytics';
import { Button, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { openDialogAction } from 'actions';
import { HelpIconTooltip } from 'components/utils/tooltips';
import { DialogTypes } from 'models';
import { useAppDispatch } from 'store';

export function RackAnalysisBtn(): JSX.Element {
  const dispatch = useAppDispatch();

  const handleClick = (): void => {
    dispatch(
      openDialogAction({
        type: DialogTypes.RackAnalysis,
        payload: undefined,
      })
    );
  };

  return (
    <ListItem>
      <ListItemIcon>
        <AnalyticsIcon />
      </ListItemIcon>
      <ListItemText
        primary={
          <Button sx={{ marginBottom: '0.5rem' }} onClick={handleClick} variant="outlined">
            Rack analysis
          </Button>
        }
        secondary={
          <>
            Rack analysis
            <HelpIconTooltip
              title="This tool analyzes robot perception data during drops, providing a report with results and recommendations to improve pick and drop success rates by moving racks."
              sx={{
                fontSize: '1rem',
              }}
            />
          </>
        }
      />
    </ListItem>
  );
}
