import AddCircleIcon from '@mui/icons-material/AddCircle';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import CallSplitIcon from '@mui/icons-material/CallSplit';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import ErrorIcon from '@mui/icons-material/Error';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import HeightIcon from '@mui/icons-material/Height';
import LinkIcon from '@mui/icons-material/Link';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import ShieldIcon from '@mui/icons-material/Shield';
import StraightenIcon from '@mui/icons-material/Straighten';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import WarningIcon from '@mui/icons-material/Warning';
import {
  Badge,
  Box,
  Checkbox,
  CircularProgress,
  Collapse,
  Divider,
  FormControlLabel,
  InputAdornment,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Popover,
  Stack,
  SvgIcon,
  Switch,
  TextField,
  Tooltip,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { saveCellTemplateAction } from 'actions/cell-templates';
import * as d3 from 'd3';
import { groupBy } from 'lodash';
import type { CellAnomaly, CircuitRack, FootProtection, RackCell, RackColumn, RackUpright } from 'models/circuit';
import { RackAnomalyIds } from 'models/circuit';
import React, {
  startTransition,
  useCallback,
  useDeferredValue,
  useEffect,
  useMemo,
  useRef,
  useState,
  useTransition,
} from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import type { CellTemplate, LoadedRack } from 'reducers/circuit/state';
import { useAppDispatch } from 'store';
import { generateShapeId } from 'utils/circuit/next-free-id';
import {
  beamThicknessMax,
  beamThicknessMin,
  cellHeightMaxHeight,
  cellHeightMinHeight,
  columnWidthMax,
  columnWidthMin,
  computeRackHeight,
  computeRackLength,
  computeWidthTakenByLoads,
  extendedLengthMin,
  nbLevelsMax,
  nbLevelsMin,
  startHeightMax,
  startHeightMin,
  uprightOverflow,
} from 'utils/circuit/racks';
import { computeLoadsPosition } from 'utils/circuit/racks-compute-load-position';
import { generatePositionNames } from 'utils/circuit/racks-naming';
import { getConfig } from 'utils/config';
import { theme } from 'utils/mui-theme';
import { isDefined } from 'utils/ts/is-defined';
import type { ActionsRack, CellAnomaliesDict } from '.';
import { setZoomRackEdition, zoomRackEdition, zoomToForm } from '.';
import { RackEditionPropertiesForm } from './RackEditionPropertiesForm';
import { PalletRackPreview } from './rack-edition-celltemplate';
import { CutRackIcon } from './rack-edition-cut-rack';
import { FootProtectionMenu } from './rack-edition-foot-protection-menu';

const epsilon = 1e-5;

const borderBottomUnlinkedInputs = '3px dashed black';
const strokeWidthSelectedCell = 5;

export const minDistanceLoadUpright = 0.05; // m (= 50 mm)

const displaySmallGridThreshold = 2.0; // zoom factor

interface RackEditionRackViewProps {
  rack: LoadedRack;
  cellTemplatesIds: string[];
  cellTemplates: Record<string, CellTemplate>;
  actions: ActionsRack;
  /** the cells that are currently selected */
  selectedCells?: number[][];
  cellsAnomalies?: CellAnomaliesDict;

  autoZoomToForm?: React.MutableRefObject<boolean>;
  /** do we display the name of the positions? */
  displayPositionNames: boolean;

  cellsNames: React.MutableRefObject<Record<string, string[]>>;
}
export function RackEditionRackView({
  rack,
  cellTemplates,
  actions,
  selectedCells,
  cellsAnomalies,
  autoZoomToForm,
  displayPositionNames,
  cellsNames,
}: RackEditionRackViewProps): JSX.Element {
  const rackProps = rack.properties;
  const columns = rackProps.columns;
  const uprights = rackProps.uprights;

  const [editMode, setEditMode] = useState(false);

  /** height of the container of the view, in px */
  const [heightContainer, setHeightContainer] = useState(1000);

  const computeSizeContainer = useCallback(() => {
    const el = document.querySelector('#rack-edition-dialog-content');
    if (!el) return;

    const bounds = el.getBoundingClientRect();

    setHeightContainer(bounds.height - 8);
  }, []);

  useEffect(() => {
    computeSizeContainer();

    window.addEventListener('resize', computeSizeContainer);

    return () => window.removeEventListener('resize', computeSizeContainer);
  }, [computeSizeContainer]);

  const firstRender = useRef(true);
  useEffect(() => {
    firstRender.current = false;
  }, []);

  useHotkeys(
    'Delete',
    () => {
      const selectedCellsByColumn = groupBy(selectedCells, (cell) => cell[0]);
      const columnNumbers = Object.keys(selectedCellsByColumn);
      const columnsToUpdate: RackColumn[] = [];

      for (const columnNumber of columnNumbers) {
        const cellsOfThisColumn = selectedCellsByColumn[columnNumber];
        const column = columns[parseInt(columnNumber, 10)];

        const newCells = column.cells.map((cell, level) => {
          if (cellsOfThisColumn.findIndex(([, levelCell]) => level === levelCell) !== -1) {
            return {
              ...cell,
              cellTemplate: undefined,
              names: [],
            };
          }

          return cell;
        });
        columnsToUpdate.push({
          ...column,
          cells: newCells,
        });
      }

      actions.updateColumns(columnsToUpdate);
    },
    [selectedCells, columns]
  );

  const translateRackView = useCallback((x: number, y: number): void => {
    if (!zoomRackEdition) return;

    const root = d3.select('#rack-edition-svg-zoom');
    const rootNode = root.node();

    if (!(rootNode instanceof SVGGraphicsElement)) {
      // eslint-disable-next-line no-console
      console.error('rootNode is not an instance of SVGGraphicsElement');

      return;
    }

    const parent = rootNode.parentElement as unknown as SVGSVGElement;
    const transform = d3.zoomTransform(rootNode);

    d3.select(parent).call(
      zoomRackEdition.transform as any,
      d3.zoomIdentity.translate(transform.x + x, transform.y + y).scale(transform.k)
    );
  }, []);

  useHotkeys(
    'left',
    (event, hotkeysEvent): void => {
      translateRackView(getConfig('editor').zoom.translateArrowKeys, 0);
    },
    []
  );

  useHotkeys(
    'right',
    (event, hotkeysEvent): void => {
      translateRackView(-getConfig('editor').zoom.translateArrowKeys, 0);
    },
    []
  );
  useHotkeys(
    'down',
    (event, hotkeysEvent): void => {
      translateRackView(0, -getConfig('editor').zoom.translateArrowKeys);
    },
    []
  );
  useHotkeys(
    'up',
    (event, hotkeysEvent): void => {
      translateRackView(0, getConfig('editor').zoom.translateArrowKeys);
    },
    []
  );

  const offsetGrid = 0.2; // m
  const offsetView = 1.2; // m

  const width = useMemo(() => {
    return computeRackLength(columns, uprights);
  }, [columns, uprights]);

  const height = useMemo(() => {
    return computeRackHeight(columns);
  }, [columns]);

  const xAxis = useMemo(() => {
    const stepSize = 0.5; // m
    const xAxis: number[] = [];
    for (let x = -stepSize; x < width + stepSize; x += stepSize) {
      xAxis.push(x);
    }

    return xAxis;
  }, [width]);
  const yAxis = useMemo(() => {
    const stepSize = 0.5; // m
    const yAxis: number[] = [];
    for (let y = 0; y < height + stepSize; y += stepSize) {
      yAxis.push(y);
    }

    return yAxis;
  }, [height]);

  /** next values have been fixed because they are not that useful anymore because we added a zoom */
  const svgMinX = -120;
  const svgWidth = 606;
  const svgMinY = -440;
  const svgHeight = 560;

  const [currentZoom, setCurrentZoom] = useState(1);

  /** enable the zoom with d3 */
  useEffect(() => {
    const svg = d3.select('#rack-edition-svg');
    const zoomContainer = svg.select('#rack-edition-svg-zoom');

    setZoomRackEdition(
      d3
        .zoom()
        .filter(() => {
          // the next line is mandatory otherwise it messes up with the textfields
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          return d3.event.target.tagName.toLowerCase() !== 'input';
        })
        .on('zoom', () => {
          const tranform = d3.event.transform as d3.ZoomTransform;
          zoomContainer.attr('transform', d3.event.transform);

          const newZoom = tranform.k;

          startTransition(() => {
            setCurrentZoom(newZoom);
          });
        })
    );

    svg.call(zoomRackEdition as any).on('dblclick.zoom', null);
  }, []);

  // zoom to the form at the opening
  useEffect(() => {
    // we zoom to the form only if asked by the parent component
    if (!autoZoomToForm || !autoZoomToForm.current) return;

    autoZoomToForm.current = false;

    if (!zoomRackEdition) return;

    zoomToForm(0);
  }, [autoZoomToForm]);

  const handleMouseMove = useCallback(() => {
    const svgContainerEl = document.querySelector('#rack-edition-svg');
    const mouseCoordinatesEl = document.querySelector('#rack-editor-mouse-coordinates');

    if (!mouseCoordinatesEl || !svgContainerEl) return;

    if (!(svgContainerEl instanceof SVGSVGElement)) {
      // eslint-disable-next-line no-console
      console.error('svgContainerEl is not an instance of SVGSVGElement');

      return;
    }

    const unscaledMousePosition = d3.mouse(svgContainerEl); // relative to specified container
    const transformer = d3.zoomTransform(svgContainerEl);

    const scaledMousePosition = transformer.invert(unscaledMousePosition);

    const x = scaledMousePosition[0] / 100;
    const y = -scaledMousePosition[1] / 100;

    mouseCoordinatesEl.innerHTML = `(${x.toFixed(3)}, ${y.toFixed(3)})`;
  }, []);

  return (
    <>
      <svg
        id="rack-edition-svg"
        width="1000"
        height={heightContainer}
        style={{
          width: '100%',
          overflow: 'hidden',
          margin: 'auto',
          objectFit: 'contain',
          border: `1px solid ${theme.palette.grey[400]}`,
          borderRadius: '4px',
        }}
        viewBox={`${svgMinX} ${svgMinY} ${svgWidth} ${svgHeight}`}
        ref={(el) => {
          d3.select(el).on('mousemove', handleMouseMove);
        }}
      >
        <defs>
          <pattern id="smallGrid" width="10" height="10" patternUnits="userSpaceOnUse">
            <path d="M 10 0 L 0 0 0 10" fill="none" stroke={theme.palette.grey.A400} strokeWidth="0.5" />
          </pattern>
          <pattern id="largeGrid" width="100" height="100" patternUnits="userSpaceOnUse">
            <path d="M 100 0 L 0 0 0 100" fill="none" stroke={theme.palette.grey.A400} strokeWidth="1" />
          </pattern>
          <pattern id="grid" width="100" height="100" patternUnits="userSpaceOnUse">
            <rect width="100" height="100" fill="url(#smallGrid)" />
            <rect width="100" height="100" fill="url(#largeGrid)" />
          </pattern>
          <pattern id="disabledCell" patternUnits="userSpaceOnUse" width="4" height="4">
            <path
              d="M-1,1 l2,-2
                M0,4 l4,-4
                M3,5 l2,-2"
              style={{
                stroke: theme.palette.grey[500],
                strokeWidth: 1,
              }}
            />
          </pattern>
          <filter x="0" y="0" width="1" height="1" id="bg-name-positions">
            <feFlood floodColor="rgba(255, 255, 255, 0.6)" />
            <feComposite in="SourceGraphic" operator="xor" />
          </filter>
        </defs>

        <g id="rack-edition-svg-zoom">
          {!firstRender.current && (
            <>
              {columns.map((column, i) => (
                <RackEditionRackViewColumn
                  key={column.id}
                  column={column}
                  uprights={uprights}
                  cellTemplates={cellTemplates}
                  columnNb={i}
                  rackHeight={height}
                  actions={actions}
                  editMode={editMode}
                  numberOfColumns={columns.length}
                  selectedCells={selectedCells}
                  cellsAnomalies={cellsAnomalies}
                  defaultCellHeight={rack.properties.defaultCellHeight}
                  defaultBeamThickness={rack.properties.defaultBeamThickness}
                  displayPositionNames={displayPositionNames}
                  rack={rack}
                  cellsNames={cellsNames}
                />
              ))}

              {columns.map((column, i) => (
                <RackEditionUprightView
                  key={`upright-${i}`}
                  x={column.x - uprights[i].width}
                  upright={uprights[i]}
                  height={height}
                  first={i === 0}
                  last={false}
                  actions={actions}
                  index={i}
                  uprights={uprights}
                  defaultUprightWidth={rack.properties.defaultUprightWidth}
                  defaultFootProtection={rack.properties.defaultFootProtection}
                  editMode={editMode}
                  rack={rack}
                />
              ))}

              <RackEditionUprightView
                key={`upright-${columns.length}`}
                x={columns[columns.length - 1].x + columns[columns.length - 1].width}
                upright={uprights[columns.length]}
                height={height}
                first={false}
                last={true}
                actions={actions}
                index={columns.length}
                uprights={uprights}
                defaultUprightWidth={rack.properties.defaultUprightWidth}
                defaultFootProtection={rack.properties.defaultFootProtection}
                editMode={editMode}
                rack={rack}
              />

              <foreignObject x={-1 * 100} y={0.5 * 100} className="overflow-visible">
                <EditModeButton editMode={editMode} setEditMode={setEditMode} />
              </foreignObject>

              <RackEditionPropertiesForm
                rack={rack}
                columns={columns}
                actions={actions}
                editMode={editMode}
                uprights={uprights}
              />
              <foreignObject x={-3.2 * 100} y={-2 * 100} className="overflow-visible">
                <DoubleArrowIcon sx={{ fontSize: '100px' }} htmlColor={theme.palette.grey[400]} />
              </foreignObject>

              {/** the grid */}
              <g name="rack-edition-grid" style={{ pointerEvents: 'none' }}>
                <rect
                  x={-offsetGrid * 100}
                  y={-(height + offsetGrid) * 100}
                  width={(width + 2 * offsetGrid) * 100}
                  height={(height + 2 * offsetGrid) * 100}
                  fill={currentZoom > displaySmallGridThreshold ? 'url(#grid)' : 'url(#largeGrid)'}
                  style={{ opacity: 0.3 }}
                />
                <g name="rack-edition-axes">
                  <g name="rack-edition-x-axis">
                    {xAxis.map((x) => (
                      <text
                        key={`x-axis-${x}`}
                        x={x * 100}
                        y={height + offsetGrid * 100 + 10}
                        style={{ fontSize: '10px', textAnchor: 'middle' }}
                      >
                        {x}
                      </text>
                    ))}
                  </g>
                  <g name="rack-edition-y-axis">
                    {yAxis.map((y) => (
                      <text key={`y-axis-${y}`} x={-offsetView * 100} y={-y * 100 + 3} style={{ fontSize: '10px' }}>
                        {y}
                      </text>
                    ))}
                  </g>
                </g>
              </g>
            </>
          )}
        </g>
      </svg>
    </>
  );
}

interface RackEditionRackViewColumnProps {
  /** the column object in the columns array of the rack */
  column: RackColumn;
  /** all the uprights of the rack */
  uprights: RackUpright[];
  /** all the cell templates of the rack */
  cellTemplates: Record<string, CellTemplate>;
  /** index of the column in the columns array of the rack */
  columnNb: number;
  /** the height of the rack (maximum) [m] */
  rackHeight: number;
  /** actions callback */
  actions: ActionsRack;

  /** did we enter the edit mode of the rack? */
  editMode?: boolean;
  /** the number of columns in the rack */
  numberOfColumns?: number;
  /** the currently selected cells */
  selectedCells?: number[][];
  /** default height of the cells in the rack [m] */
  defaultCellHeight: number;
  /** default thickness of the beams in the rack [m] */
  defaultBeamThickness: number;

  cellsAnomalies?: CellAnomaliesDict;
  /** do we display the name of the positions? */
  displayPositionNames: boolean;
  /** the Rack */
  rack: LoadedRack;
  /** object with all the names of the cells by id */
  cellsNames: React.MutableRefObject<Record<string, string[]>>;
}
export function RackEditionRackViewColumn({
  column,
  cellTemplates,
  columnNb,
  rackHeight,
  actions,
  editMode,
  numberOfColumns,
  selectedCells,
  defaultCellHeight,
  defaultBeamThickness,
  displayPositionNames,
  rack,
  cellsNames,
}: RackEditionRackViewColumnProps): JSX.Element {
  const cells = column.cells;
  const isConveyor = !!rack.properties.conveyor;

  const [columnWidth, setColumnWidth] = useState((column.width * 1000).toFixed(0));

  const [nbLevels, setNbLevels] = useState(column.cells.length.toString());
  const [extendedLength, setExtendedLength] = useState((column.extendedLength * 1000).toFixed(0));
  const [startHeight, setStartHeight] = useState((column.startHeight * 1000).toFixed(0));

  const inputNbLevels = useRef<HTMLInputElement | undefined>(undefined);
  const inputWidth = useRef<HTMLInputElement | undefined>(undefined);

  useEffect(() => {
    setColumnWidth((column.width * 1000).toFixed(0));
  }, [column.width]);
  useEffect(() => {
    setNbLevels(column.nbLevels.toString());
  }, [column.nbLevels]);
  useEffect(() => {
    setExtendedLength((column.extendedLength * 1000).toFixed(0));
  }, [column.extendedLength]);
  useEffect(() => {
    setStartHeight((column.startHeight * 1000).toFixed(0));
  }, [column.startHeight]);

  const handleUpdateColumnWidth = useCallback(() => {
    let newWidth = parseFloat(columnWidth) / 1000;
    const input = inputWidth.current;
    if (!input) return;
    const min = input.min ? parseFloat(input.min) / 1000 : 0;

    if (!isNaN(newWidth)) {
      if (newWidth < min) newWidth = min;

      setColumnWidth((newWidth * 1000).toFixed(0));

      actions.updateColumns([
        {
          ...column,
          width: newWidth,
        },
      ]);
    } else {
      setColumnWidth((column.width * 1000).toFixed(0));
    }
  }, [actions, column, columnWidth]);
  const handleColumnWidthKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        handleUpdateColumnWidth();
      } else if (e.key === 'Escape') {
        e.stopPropagation();

        setColumnWidth((column.width * 1000).toFixed(0));
        setTimeout(() => (e.target as HTMLInputElement).blur(), 0);
      }
    },
    [column.width, handleUpdateColumnWidth]
  );
  const handleColumnWidthClickLink = useCallback(() => {
    actions.updateColumns([
      {
        ...column,
        linkedProperties: {
          ...column.linkedProperties,
          width: !column.linkedProperties.width,
        },
      },
    ]);
  }, [actions, column]);

  const handleColumnNbLevelsClickLink = useCallback(() => {
    actions.updateColumns([
      {
        ...column,
        linkedProperties: {
          ...column.linkedProperties,
          nbLevels: !column.linkedProperties.nbLevels,
        },
      },
    ]);
  }, [actions, column]);
  const handleUpdateColumnNbLevels = useCallback(() => {
    let newNbLevels = parseInt(nbLevels, 10);
    const input = inputNbLevels.current;
    if (!input) return;
    const min = input.min ? parseInt(input.min, 10) : 0;
    const max = input.max ? parseInt(input.max, 10) : 100;

    if (!isNaN(newNbLevels)) {
      if (newNbLevels < min) newNbLevels = min;
      if (newNbLevels > max) newNbLevels = max;

      setNbLevels(newNbLevels.toString());

      actions.updateColumns([
        {
          ...column,
          nbLevels: newNbLevels,
        },
      ]);
    } else {
      setNbLevels(column.cells.length.toFixed(0));
    }
  }, [actions, column, nbLevels]);
  const handleColumnNbLevelsKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        handleUpdateColumnNbLevels();
      } else if (e.key === 'Escape') {
        e.preventDefault();

        setNbLevels(column.cells.length.toFixed(0));
        (e.target as HTMLInputElement).blur();
      }
    },
    [column.cells.length, handleUpdateColumnNbLevels]
  );

  const handleColumnExtendedLengthClickLink = useCallback(() => {
    actions.updateColumns([
      {
        ...column,
        linkedProperties: {
          ...column.linkedProperties,
          extendedLength: !column.linkedProperties.extendedLength,
        },
      },
    ]);
  }, [actions, column]);
  const handleUpdateColumnExtendedLength = useCallback(() => {
    const newExtendedLength = parseInt(extendedLength, 10) / 1000;

    if (!isNaN(newExtendedLength)) {
      actions.updateColumns([
        {
          ...column,
          extendedLength: newExtendedLength,
        },
      ]);
    } else {
      setExtendedLength(column.extendedLength.toFixed(0));
    }
  }, [actions, column, extendedLength]);
  const handleColumnExtendedLengthKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        handleUpdateColumnExtendedLength();
      } else if (e.key === 'Escape') {
        e.preventDefault();

        setExtendedLength(column.extendedLength.toFixed(0));
        (e.target as HTMLInputElement).blur();
      }
    },
    [column.extendedLength, handleUpdateColumnExtendedLength]
  );
  const handleColumnStartHeightClickLink = useCallback(() => {
    actions.updateColumns([
      {
        ...column,
        linkedProperties: {
          ...column.linkedProperties,
          startHeight: !column.linkedProperties.startHeight,
        },
      },
    ]);
  }, [actions, column]);

  const handleUpdateColumnStartHeight = useCallback(() => {
    const newStartHeight = parseInt(startHeight, 10) / 1000;

    if (!isNaN(newStartHeight)) {
      actions.updateColumns([
        {
          ...column,
          startHeight: newStartHeight,
        },
      ]);
    } else {
      setStartHeight(column.startHeight.toFixed(0));
    }
  }, [actions, column, startHeight]);
  const handleColumnStartHeightKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        handleUpdateColumnStartHeight();
      } else if (e.key === 'Escape') {
        e.preventDefault();

        setStartHeight(column.startHeight.toFixed(0));
        (e.target as HTMLInputElement).blur();
      }
    },
    [column.startHeight, handleUpdateColumnStartHeight]
  );

  const yColumnDataBottom = +0.4 * 100;

  const nbSelectedCells = useMemo(() => {
    const selectedCellsSet = new Set();
    selectedCells?.forEach((cellPos) => {
      selectedCellsSet.add(`${cellPos[0]}-${cellPos[1]}`);
    });

    return selectedCellsSet.size;
  }, [selectedCells]);

  const isSelected = useMemo(() => {
    return cells.map((cell, i) =>
      selectedCells ? !!selectedCells.find((val) => val[0] === columnNb && val[1] === i) : false
    );
  }, [cells, columnNb, selectedCells]);

  const renderedCells = useMemo(() => {
    const positions = cells.reduce((acc: number[], cell, i) => {
      acc.push(i === 0 ? column.startHeight : acc[i - 1] + cells[i - 1].height);

      return acc;
    }, []);

    return cells.map((cell, i) => {
      const cellTemplate = cell.cellTemplate ? cellTemplates[cell.cellTemplate] : undefined;

      return (
        <RackEditionCellView
          key={cell.id}
          cell={cell}
          posX={column.x}
          posY={positions[i]}
          x={columnNb}
          y={i}
          cellTemplate={cellTemplate}
          column={column}
          actions={actions}
          defaultCellHeight={defaultCellHeight}
          defaultBeamThickness={defaultBeamThickness}
          selected={isSelected[i]}
          nbSelectedCells={nbSelectedCells}
          displayPositionNames={displayPositionNames}
          rack={rack}
          cellsNames={cellsNames}
        />
      );
    });
  }, [
    actions,
    cellTemplates,
    cells,
    cellsNames,
    column,
    columnNb,
    defaultBeamThickness,
    defaultCellHeight,
    displayPositionNames,
    isSelected,
    nbSelectedCells,
    rack,
  ]);

  const nbOfSelectedColumns = useMemo(() => {
    const selectedColumns = new Set();
    selectedCells?.forEach(([columnNb]) => selectedColumns.add(columnNb));

    return selectedColumns.size;
  }, [selectedCells]);

  return (
    <g name={`column-${column.id}`}>
      {/* if there's a startheight > 0, we display this startheight like if it was a beam */}
      {column.startHeight > 0 && (
        <rect
          x={column.x * 100}
          y={-column.startHeight * 100}
          width={column.width * 100}
          height={column.startHeight * 100}
          fill={'#50C878'}
          type="start-height"
        />
      )}

      {renderedCells}

      <foreignObject
        x={(column.x + column.width / 4) * 100}
        y={-(rackHeight + 0.9) * 100}
        className="overflow-visible"
        name="rack-edition-column-width"
      >
        <TextField
          type="number"
          variant="standard"
          value={columnWidth}
          inputProps={{
            step: 10,
            min: columnWidthMin * 1000,
            max: columnWidthMax * 1000,
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Tooltip title={'To link or unlink this value with the master value'}>
                  <IconButton
                    onClick={handleColumnWidthClickLink}
                    color={!column.linkedProperties.width ? 'primary' : undefined}
                  >
                    {column.linkedProperties.width ? <LinkIcon /> : <LinkOffIcon />}
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                mm
                <Tooltip
                  title={`Apply this value to all the selected columns (${nbOfSelectedColumns} column${
                    nbOfSelectedColumns !== 1 ? 's' : ''
                  })`}
                >
                  <span>
                    <IconButton
                      onClick={() => {
                        const columnValue: Partial<RackColumn> = {
                          width: parseFloat(columnWidth) / 1000,
                        };

                        const linkedPropertiesToChange = {
                          width: column.linkedProperties.width,
                        };

                        actions.applyColumnValueToAllSelectedColumns(columnValue, linkedPropertiesToChange);
                      }}
                    >
                      <CallSplitIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              </InputAdornment>
            ),
          }}
          sx={{
            width: `${Math.max(columnWidth.length, 4) + 17}ch`,
            transform: 'scale(0.5)',
            borderBottom: !column.linkedProperties.width ? borderBottomUnlinkedInputs : undefined,
          }}
          margin="none"
          title="Column width"
          onChange={(e) => setColumnWidth(e.target.value)}
          onBlur={handleUpdateColumnWidth}
          onKeyDown={handleColumnWidthKeyDown}
          disabled={column.linkedProperties.width}
          inputRef={inputWidth}
        />
      </foreignObject>
      <foreignObject
        x={(column.x + column.width / 4) * 100}
        y={yColumnDataBottom}
        className="overflow-visible"
        name="rack-edition-column-data"
      >
        <TextField
          type="number"
          variant="standard"
          value={nbLevels}
          inputProps={{
            step: 1,
            min: nbLevelsMin,
            max: nbLevelsMax,
          }}
          InputProps={{
            startAdornment: !isConveyor && (
              <InputAdornment position="start">
                <Tooltip title={'To link or unlink this value with the master value'}>
                  <span>
                    <IconButton
                      onClick={handleColumnNbLevelsClickLink}
                      color={!column.linkedProperties.nbLevels ? 'primary' : undefined}
                    >
                      {column.linkedProperties.nbLevels ? <LinkIcon /> : <LinkOffIcon />}
                    </IconButton>
                  </span>
                </Tooltip>
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                level{nbLevels !== '1' ? 's' : ''}
                <Tooltip
                  title={`Apply this value to all the selected columns (${nbOfSelectedColumns} column${
                    nbOfSelectedColumns !== 1 ? 's' : ''
                  })`}
                >
                  <span>
                    <IconButton
                      onClick={() => {
                        const columnValue: Partial<RackColumn> = {
                          nbLevels: parseInt(nbLevels, 10),
                        };

                        const linkedPropertiesToChange = {
                          nbLevels: column.linkedProperties.nbLevels,
                        };

                        actions.applyColumnValueToAllSelectedColumns(columnValue, linkedPropertiesToChange);
                      }}
                    >
                      <CallSplitIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              </InputAdornment>
            ),
          }}
          sx={{
            width: `${Math.max(nbLevels.length, 1) + 18}ch`,
            transform: 'scale(0.5)',
            borderBottom: !column.linkedProperties.nbLevels ? borderBottomUnlinkedInputs : undefined,
          }}
          margin="none"
          title="Number of levels of the column"
          onChange={(e) => setNbLevels(e.target.value)}
          onBlur={handleUpdateColumnNbLevels}
          onKeyDown={handleColumnNbLevelsKeyDown}
          disabled={column.linkedProperties.nbLevels || isConveyor}
          inputRef={inputNbLevels}
        />

        <TextField
          type="number"
          variant="standard"
          value={extendedLength}
          inputProps={{
            step: 1,
            min: extendedLengthMin * 1000,
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                Extended Length
                {!isConveyor && (
                  <IconButton
                    onClick={handleColumnExtendedLengthClickLink}
                    color={!column.linkedProperties.extendedLength ? 'primary' : undefined}
                  >
                    {column.linkedProperties.extendedLength ? <LinkIcon /> : <LinkOffIcon />}
                  </IconButton>
                )}
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                mm
                <Tooltip
                  title={`Apply this value to all the selected columns (${nbOfSelectedColumns} column${
                    nbOfSelectedColumns !== 1 ? 's' : ''
                  })`}
                >
                  <span>
                    <IconButton
                      onClick={() => {
                        const columnValue: Partial<RackColumn> = {
                          extendedLength: parseFloat(extendedLength) / 1000,
                        };

                        const linkedPropertiesToChange = {
                          extendedLength: column.linkedProperties.extendedLength,
                        };

                        actions.applyColumnValueToAllSelectedColumns(columnValue, linkedPropertiesToChange);
                      }}
                    >
                      <CallSplitIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              </InputAdornment>
            ),
          }}
          sx={{
            width: `${Math.max(extendedLength.length, 4) + 31}ch`,
            transform: 'scale(0.5)',
            marginTop: '-10px',
            marginLeft: '-96.5px',
            borderBottom: !column.linkedProperties.extendedLength ? borderBottomUnlinkedInputs : undefined,
          }}
          margin="none"
          title="The length of the line in front of the column"
          onChange={(e) => setExtendedLength(e.target.value)}
          onBlur={handleUpdateColumnExtendedLength}
          onKeyDown={handleColumnExtendedLengthKeyDown}
          disabled={column.linkedProperties.extendedLength || isConveyor}
        />

        {
          <TextField
            type="number"
            variant="standard"
            value={startHeight}
            inputProps={{
              step: 10,
              min: startHeightMin * 1000,
              max: startHeightMax * 1000,
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  Start Height
                  {!isConveyor && (
                    <IconButton
                      onClick={handleColumnStartHeightClickLink}
                      color={!column.linkedProperties.startHeight ? 'primary' : undefined}
                    >
                      {column.linkedProperties.startHeight ? <LinkIcon /> : <LinkOffIcon />}
                    </IconButton>
                  )}
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  mm
                  <Tooltip
                    title={`Apply this value to all the selected columns (${nbOfSelectedColumns} column${
                      nbOfSelectedColumns !== 1 ? 's' : ''
                    })`}
                  >
                    <span>
                      <IconButton
                        onClick={() => {
                          const columnValue: Partial<RackColumn> = {
                            startHeight: parseFloat(startHeight) / 1000,
                          };

                          const linkedPropertiesToChange = {
                            startHeight: column.linkedProperties.startHeight,
                          };

                          actions.applyColumnValueToAllSelectedColumns(columnValue, linkedPropertiesToChange);
                        }}
                      >
                        <CallSplitIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
            sx={{
              width: `${Math.max(startHeight.length, 4) + 26}ch`,
              transform: 'scale(0.5)',
              marginTop: '-10px',
              marginLeft: '-66.5px',
              borderBottom: !column.linkedProperties.startHeight ? borderBottomUnlinkedInputs : undefined,
            }}
            margin="none"
            title="Height at which the first cell begin (offset)"
            onChange={(e) => setStartHeight(e.target.value)}
            onBlur={handleUpdateColumnStartHeight}
            onKeyDown={handleColumnStartHeightKeyDown}
            disabled={column.linkedProperties.startHeight}
          />
        }

        {editMode && (
          <>
            {numberOfColumns && numberOfColumns > 1 && (
              <Tooltip title="Delete this column">
                <IconButton
                  sx={{ marginTop: -3, marginLeft: 5, transform: 'scale(0.5)' }}
                  onClick={() => actions.deleteColumn(columnNb)}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title="Add a column">
              <IconButton
                sx={{ marginTop: -8.5, marginLeft: 20, transform: 'scale(0.5)' }}
                onClick={() => actions.addColumn(columnNb + 1)}
                disabled={isConveyor}
              >
                <AddCircleIcon />
              </IconButton>
            </Tooltip>
            {columnNb === 0 && (
              <Tooltip title="Add a column">
                <IconButton
                  sx={{ marginTop: -13.8, marginLeft: -15, transform: 'scale(0.5)' }}
                  onClick={() => actions.addColumn(0)}
                  disabled={isConveyor}
                >
                  <AddCircleIcon />
                </IconButton>
              </Tooltip>
            )}
          </>
        )}
      </foreignObject>
    </g>
  );
}

interface RackEditionUprightViewProps {
  /** position along the x axis [m] */
  x: number;
  /** upright data */
  upright: RackUpright;
  /** height of the rack [m] */
  height: number;
  /** uprights data of the rack */
  uprights: RackUpright[];

  /** is it the first upright (from the left)? */
  first?: boolean;
  /** is it the last upright (from the left)? */
  last?: boolean;
  /** index of the upright in the rack.uprights array */
  index: number;
  /** actions to update the state of the rack */
  actions: ActionsRack;
  /** default width of the uprights in the rack [m] */
  defaultUprightWidth: number;
  /** default foot protection of the uprights in the rack */
  defaultFootProtection: FootProtection;

  rack: CircuitRack;
  editMode: boolean;
}
export function RackEditionUprightView({
  upright,
  x,
  height,
  first,
  last,
  actions,
  index,
  uprights,
  defaultUprightWidth,
  defaultFootProtection,
  editMode,
  rack,
}: RackEditionUprightViewProps): JSX.Element {
  const scaleFactor = 0.5;

  const color = first ? theme.palette.secondary.light : last ? theme.palette.primary.light : 'yellow';
  const [width, setWidth] = useState((upright.width * 1000).toFixed(0));

  useEffect(() => {
    setWidth((upright.width * 1000).toFixed(0));
  }, [upright.width, upright]);
  useEffect(() => {
    if (upright.linkedWidth && Math.abs(upright.width - defaultUprightWidth) > epsilon) {
      actions.updateUprights([
        {
          uprightIndex: index,
          newUpright: {
            ...upright,
            width: defaultUprightWidth,
          },
        },
      ]);
    }
  }, [actions, defaultUprightWidth, index, upright, upright.linkedWidth, uprights]);
  useEffect(() => {
    if (upright.linkedFootProtection) {
      if (
        !!upright.footProtection?.enabled !== defaultFootProtection.enabled ||
        (defaultFootProtection.enabled && !upright.footProtection) ||
        (defaultFootProtection.enabled &&
          upright.footProtection &&
          Math.abs(upright.footProtection.width - defaultFootProtection.width) > epsilon) ||
        (defaultFootProtection.enabled &&
          upright.footProtection &&
          Math.abs(upright.footProtection.height - defaultFootProtection.height) > epsilon)
      ) {
        actions.updateUprights([
          {
            newUpright: {
              ...upright,
              footProtection: defaultFootProtection.enabled ? defaultFootProtection : undefined,
            },
            uprightIndex: index,
          },
        ]);
      }
    }
  }, [actions, defaultFootProtection, index, upright, uprights]);

  const footProtection = upright.footProtection;
  const footProtectionEnabled = footProtection && footProtection.enabled && upright.enabled;
  const [openMenuFootProtection, setOpenMenuFootProtection] = useState(false);
  const [footProtectionIconRef, setFootProtectionIconRef] = useState<SVGSVGElement | null>(null);

  const uprightRect = upright.enabled ? (
    <rect
      x={x * 100}
      y={-(height + uprightOverflow) * 100}
      width={upright.width * 100}
      height={(height + uprightOverflow) * 100}
      fill={color}
      type="upright"
    />
  ) : (
    <></>
  );

  const footProtectionRect = footProtectionEnabled ? (
    <rect
      x={x * 100 + (upright.width / 2 - footProtection.width / 2) * 100}
      y={-footProtection.height * 100}
      width={footProtection.width * 100}
      height={footProtection.height * 100}
      fill={color}
      stroke={theme.palette.common.black}
      strokeWidth={0.5}
      type="footProtection"
    />
  ) : (
    <></>
  );

  const toggleStateUpright = useCallback(() => {
    actions.updateUprights([
      {
        uprightIndex: index,
        newUpright: {
          ...upright,
          enabled: !upright.enabled,
        },
      },
    ]);
  }, [actions, index, upright]);

  const updateUprightWidth = useCallback(() => {
    const newWidth = parseFloat(width) / 1000;
    if (isNaN(newWidth)) {
      setWidth((upright.width * 1000).toFixed(0));
    } else {
      actions.updateUprights([
        {
          uprightIndex: index,
          newUpright: {
            ...upright,
            width: newWidth,
          },
        },
      ]);
    }
  }, [actions, index, upright, width]);

  const handleUprightWidthKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        updateUprightWidth();
      } else if (e.key === 'Escape') {
        e.preventDefault();

        setWidth((upright.width * 1000).toFixed(0));
        (e.target as HTMLInputElement).blur();
      }
    },
    [updateUprightWidth, upright.width]
  );

  const handleClickUprightWidthLink = useCallback(() => {
    const newLinkedWidthState = !upright.linkedWidth;

    actions.updateUprights([{ uprightIndex: index, newUpright: { ...upright, linkedWidth: newLinkedWidthState } }]);
  }, [actions, index, upright]);

  const footProtectionIconSize = 8; // px

  return (
    <>
      {uprightRect}
      {footProtectionRect}
      <foreignObject
        x={((x - 0.07) / scaleFactor) * 100}
        y={(-(height + 0.6) / scaleFactor) * 100}
        style={{ textAlign: 'center', transform: `scale(${scaleFactor})` }}
        className="overflow-visible"
        name="rack-edition-upright-data"
      >
        <TextField
          type="number"
          variant="standard"
          value={width}
          inputProps={{
            step: 1,
            min: 0,
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <IconButton onClick={handleClickUprightWidthLink} color={!upright.linkedWidth ? 'primary' : undefined}>
                  {upright.linkedWidth ? <LinkIcon /> : <LinkOffIcon />}
                </IconButton>
              </InputAdornment>
            ),
            endAdornment: <InputAdornment position="end">mm</InputAdornment>,
          }}
          sx={{
            width: `${Math.max(width.length, 2) + 12}ch`,
            borderBottom: !upright.linkedWidth ? borderBottomUnlinkedInputs : undefined,
          }}
          margin="none"
          title="Upright width"
          onChange={(e) => setWidth(e.target.value)}
          onBlur={updateUprightWidth}
          onKeyDown={handleUprightWidthKeyDown}
          disabled={!upright.enabled || upright.linkedWidth}
        />
        {(first || last) && <Checkbox checked={upright.enabled} onChange={toggleStateUpright} />}
      </foreignObject>

      {upright.enabled && (
        <foreignObject x={x * 100} y={0} className="overflow-visible" name="rack-edition-upright-foot-protection">
          <SvgIcon
            key={(footProtectionEnabled || 'undefined').toString()}
            component={footProtectionEnabled ? VerifiedUserIcon : ShieldIcon}
            inheritViewBox
            fontSize="small"
            sx={{
              color: footProtectionEnabled ? theme.palette.primary.main : theme.palette.grey[400],
              fontSize: footProtectionIconSize,
              left: ((upright.width - footProtectionIconSize / 100) / 2) * 100,

              padding: 0,
              margin: 0,
              cursor: 'pointer',
              position: 'relative',
            }}
            ref={(node: SVGSVGElement | null) => {
              setFootProtectionIconRef(node);
            }}
            onClick={() => setOpenMenuFootProtection(!openMenuFootProtection)}
          />
          {openMenuFootProtection && (
            <FootProtectionMenu
              open={openMenuFootProtection && !!footProtectionIconRef}
              setOpen={setOpenMenuFootProtection}
              anchorEl={footProtectionIconRef}
              actions={actions}
              index={index}
              upright={upright}
              uprights={uprights}
              footProtectionEnabled={!!footProtectionEnabled}
              defaultFootProtection={defaultFootProtection}
            />
          )}
        </foreignObject>
      )}

      {editMode && !first && !last && (
        <foreignObject
          x={x * 100 - upright.width * 100}
          y={30}
          className="overflow-visible"
          name="rack-edition-upright-cut-button"
        >
          <CutRackIcon rack={rack} upright={upright} uprightIndex={index} actions={actions} />
        </foreignObject>
      )}
    </>
  );
}

interface RackEditionCellViewProps {
  /** data about the cell (not the cell template) */
  cell: RackCell;
  /** position along the x axis [m] */
  posX: number;
  /** position along the y axis [m] */
  posY: number;
  /** column of the cell (position, left = 0) */
  x: number;
  /** level of the cell (position, bottom = 0) */
  y: number;
  /** the cell template of the cell */
  cellTemplate?: CellTemplate;
  /** column in which the cell is contained */
  column: RackColumn;
  /** actions callback */
  actions: ActionsRack;
  /** is the cell currently selected? */
  selected: boolean;
  /** default cell height of the rack [m] */
  defaultCellHeight: number;
  /** default thickness of the beams in the rack [m] */
  defaultBeamThickness: number;
  /** do we display the name of the positions? */
  displayPositionNames: boolean;
  /** the rack */
  rack: LoadedRack;
  /** number of selected cells */
  nbSelectedCells: number;
  /** names of all the cells by id */
  cellsNames: React.MutableRefObject<Record<string, string[]>>;
}

export const minRecommendedBeamThickness = 0.08; // [m]
export const minRecommendedUprightWidth = 0.075; // [m]
export const minRecommendedPalletOverflow = 0.05; // [m]
export const maxRecommendedPalletOverflow = 0.1; // [m]

export function RackEditionCellView({
  cell,
  posX,
  posY,
  x,
  y,
  cellTemplate,
  column,
  actions,
  selected,
  defaultCellHeight,
  defaultBeamThickness,
  displayPositionNames,
  rack,
  nbSelectedCells,
  cellsNames,
}: RackEditionCellViewProps): JSX.Element {
  const isConveyor = !!rack.properties.conveyor;
  const load = cellTemplate?.loads[cellTemplate?.selectedLoad];
  const xPosLoads = load ? computeLoadsPosition(load, column.width) : undefined;

  const names = useMemo(() => {
    if (
      cellTemplate &&
      load &&
      cellTemplate.selectedLoad !== undefined &&
      cell.names[cellTemplate.selectedLoad]?.length
    ) {
      return cell.names[cellTemplate.selectedLoad];
    }

    if (!displayPositionNames || !cellTemplate || cellTemplate.selectedLoad === undefined) return [];

    return generatePositionNames(rack, { column: x, level: y }, cellTemplate)[cellTemplate.selectedLoad].map(
      (name, nameIndex) => ({
        value: name,
        user: false,
        id: cell.names[cellTemplate.selectedLoad]?.[nameIndex]?.id ?? generateShapeId(),
      })
    );
  }, [cell, cellTemplate, displayPositionNames, load, rack, x, y]);

  useEffect(() => {
    cellsNames.current[cell.id] = names.map((n) => (n ? n.value : ''));

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      delete cellsNames.current[cell.id];
    };
  }, [cell.id, cellsNames, names]);

  const dispatch = useAppDispatch();

  const changeCellState = useCallback(
    (cellId: string, enabled: boolean) => {
      actions.updateColumns([
        {
          ...column,
          cells: column.cells.map((c) => (c.id === cellId ? { ...c, disabled: enabled ? undefined : true } : c)),
        },
      ]);
    },
    [actions, column]
  );

  const [propagateFixToSelection, setPropagateFixToSelection] = useState(false);
  const anomalies: CellAnomaly[] = useMemo(() => {
    const cellAnomalies: CellAnomaly[] = [];

    if (!cellTemplate) return cellAnomalies;

    const cellWidth = column.width;
    for (let i = 0; i < cellTemplate.loads.length; i++) {
      const load = cellTemplate.loads[i];
      const widthTakenByLoads = computeWidthTakenByLoads(load, true);

      const beamHeight = column.cells[0].id === cell.id ? 0 : cell.beamThickness;
      const cellRealHeight = cell.height - beamHeight;

      if (cellTemplate.maximumLoadHeight) {
        const verticalClearance = cellRealHeight - cellTemplate.maximumLoadHeight + epsilon;

        const recommendedVerticalClearance = 0.15;
        const groundLevelRecommendedVerticalClearance = 0.5;
        const minimumCellHeight = +(cellTemplate.maximumLoadHeight + recommendedVerticalClearance + beamHeight).toFixed(
          2
        );
        if (cellRealHeight < cellTemplate.maximumLoadHeight && !cell.disabled) {
          cellAnomalies.push({
            type: RackAnomalyIds.cellNotEnoughHeight,
            state: 1,
            description: (
              <Stack direction="row" justifyContent="space-between">
                <p>
                  The cell height is lower than the maximum pallet height.
                  <br />
                  Minimum height: {minimumCellHeight} m
                </p>
                <IconButton
                  title={`Autofix: set the height of the cell to ${minimumCellHeight} m`}
                  onClick={(e) => {
                    e.stopPropagation();
                    (e.target as HTMLButtonElement).style.animation = 'shake 0.5s ease';
                    setTimeout(() => {
                      actions.updateColumns([
                        {
                          ...column,
                          cells: column.cells.map((c) =>
                            c.id === cell.id ? { ...c, height: minimumCellHeight, linkedHeight: false } : c
                          ),
                        },
                      ]);

                      if (propagateFixToSelection) {
                        actions.applyCellValueToAllSelectedCells({ height: minimumCellHeight, linkedHeight: false });
                      }
                    }, 500);
                  }}
                  size="small"
                >
                  <AutoFixHighIcon sx={{ color: 'white' }} />
                </IconButton>
              </Stack>
            ),
          });
        } else if (verticalClearance > 0 && !cell.disabled) {
          if (column.cells[0].id === cell.id && verticalClearance < groundLevelRecommendedVerticalClearance) {
            const minimumGroundCellHeight = +(
              cellTemplate.maximumLoadHeight + groundLevelRecommendedVerticalClearance
            ).toFixed(2);
            cellAnomalies.push({
              type: RackAnomalyIds.cellNotEnoughHeight,
              state: 1,
              description: (
                <Stack direction="row" justifyContent="space-between">
                  <p>
                    The vertical clearance might be too small on ground level (for R-Matics).
                    <br />
                    Actual vertical clearance: {verticalClearance.toFixed(2)} m
                    <br />
                    Recommended vertical clearance: {groundLevelRecommendedVerticalClearance} m
                  </p>
                  <IconButton
                    title={`Autofix: set the height of the cell to ${minimumGroundCellHeight} m`}
                    onClick={(e) => {
                      e.stopPropagation();
                      (e.target as HTMLButtonElement).style.animation = 'shake 0.5s ease';
                      setTimeout(() => {
                        actions.updateColumns([
                          {
                            ...column,
                            cells: column.cells.map((c) =>
                              c.id === cell.id ? { ...c, height: minimumGroundCellHeight, linkedHeight: false } : c
                            ),
                          },
                        ]);

                        if (propagateFixToSelection) {
                          actions.applyCellValueToAllSelectedCells({
                            height: minimumGroundCellHeight,
                            linkedHeight: false,
                          });
                        }
                      }, 500);
                    }}
                    size="small"
                  >
                    <AutoFixHighIcon sx={{ color: 'white' }} />
                  </IconButton>
                </Stack>
              ),
            });
          } else if (verticalClearance < recommendedVerticalClearance) {
            cellAnomalies.push({
              type: RackAnomalyIds.cellNotEnoughHeight,
              state: 1,
              description: (
                <Stack direction="row" justifyContent="space-between">
                  <p>
                    The vertical clearance might be too small.
                    <br />
                    Actual vertical clearance: {verticalClearance.toFixed(2)} m
                    <br />
                    Recommended vertical clearance: {recommendedVerticalClearance} m
                    <br />
                    See{' '}
                    <a
                      href="https://drive.google.com/file/d/1Hy6LX-Qsvf4_VnU4wSPxDgDliby2RKUI/view?usp=sharing"
                      target="_blank"
                      rel="noreferrer"
                    >
                      TTC page 14
                    </a>{' '}
                    for more information.
                  </p>
                  <IconButton
                    title={`Autofix: set the height of the cell to ${minimumCellHeight} m`}
                    onClick={(e) => {
                      e.stopPropagation();
                      (e.target as HTMLButtonElement).style.animation = 'shake 0.5s ease';
                      setTimeout(() => {
                        actions.updateColumns([
                          {
                            ...column,
                            cells: column.cells.map((c) =>
                              c.id === cell.id ? { ...c, height: minimumCellHeight, linkedHeight: false } : c
                            ),
                          },
                        ]);

                        if (propagateFixToSelection) {
                          actions.applyCellValueToAllSelectedCells({ height: minimumCellHeight, linkedHeight: false });
                        }
                      }, 500);
                    }}
                    size="small"
                  >
                    <AutoFixHighIcon sx={{ color: 'white' }} />
                  </IconButton>
                </Stack>
              ),
            });
          }
        }
      }

      if (widthTakenByLoads > cellWidth + epsilon && !cell.disabled) {
        cellAnomalies.push({
          type: RackAnomalyIds.cellNotEnoughSpace,
          state: 2,
          description: (
            <Stack direction="row" justifyContent="space-between">
              <p>
                The load '{load.name}' is too large for the column.
                <br />
                Minimum width: {widthTakenByLoads.toFixed(2)} m.
              </p>
              <IconButton
                title={`Autofix: set the column width to ${widthTakenByLoads.toFixed(2)} m`}
                onClick={(e) => {
                  e.stopPropagation();
                  (e.target as HTMLButtonElement).style.animation = 'shake 0.5s ease';
                  setTimeout(() => {
                    actions.updateColumns([
                      {
                        ...column,
                        width: widthTakenByLoads,
                        linkedProperties: { ...column.linkedProperties, width: false },
                      },
                    ]);

                    if (propagateFixToSelection) {
                      actions.applyColumnValueToAllSelectedColumns({ width: widthTakenByLoads }, { width: false });
                    }
                  }, 500);
                }}
                size="small"
              >
                <AutoFixHighIcon sx={{ color: 'white' }} />
              </IconButton>
            </Stack>
          ),
        });
      }

      if (
        (!load.center && load.a1 < minDistanceLoadUpright) ||
        (load.a2 !== undefined ? load.a2 < minDistanceLoadUpright : false)
      ) {
        cellAnomalies.push({
          type: RackAnomalyIds.cellNotEnoughMarginLoadUpright,
          state: 1,
          description: (
            <>
              {!cellAnomalies.find((anomaly) => anomaly.type === RackAnomalyIds.cellNotEnoughMarginLoadUpright) && (
                <Box component="div" sx={{ paddingRight: 2 }}>
                  <Divider variant="middle" sx={{ backgroundColor: 'white', marginBottom: 1 }} />
                </Box>
              )}
              {load.a1 < minDistanceLoadUpright && (
                <Stack direction="row" justifyContent="space-between">
                  The distance between the left upright and the load is too small ({(load.a1 * 1000).toFixed(0)} mm,
                  minimum: {(minDistanceLoadUpright * 1000).toFixed(0)} mm)
                  <IconButton
                    title={`Autofix: distance between the left upright and the load (a1) to ${(
                      minDistanceLoadUpright * 1000
                    ).toFixed(0)} m`}
                    onClick={(e) => {
                      e.stopPropagation();
                      (e.target as HTMLButtonElement).style.animation = 'shake 0.5s ease';
                      setTimeout(() => {
                        dispatch(
                          saveCellTemplateAction({
                            ...cellTemplate,
                            loads: cellTemplate.loads.map((l, index) => {
                              if (index === i) {
                                return { ...l, a1: minDistanceLoadUpright };
                              }

                              return l;
                            }),
                          })
                        );
                      }, 500);
                    }}
                    size="small"
                  >
                    <AutoFixHighIcon sx={{ color: 'white' }} />
                  </IconButton>
                </Stack>
              )}

              {load.a2 !== undefined && load.a2 < minDistanceLoadUpright && (
                <Stack direction="row" justifyContent="space-between">
                  The distance between the right upright and the load is too small ({(load.a2 * 1000).toFixed(0)} mm,
                  minimum: {(minDistanceLoadUpright * 1000).toFixed(0)} mm)
                  <IconButton
                    title={`Autofix: distance between the right upright and the load (a2) to ${(
                      minDistanceLoadUpright * 1000
                    ).toFixed(0)} mm`}
                    onClick={(e) => {
                      e.stopPropagation();
                      (e.target as HTMLButtonElement).style.animation = 'shake 0.5s ease';
                      setTimeout(() => {
                        dispatch(
                          saveCellTemplateAction({
                            ...cellTemplate,
                            loads: cellTemplate.loads.map((l, index) => {
                              if (index === i) {
                                return { ...l, a2: minDistanceLoadUpright };
                              }

                              return l;
                            }),
                          })
                        );
                      }, 500);
                    }}
                    size="small"
                  >
                    <AutoFixHighIcon sx={{ color: 'white' }} />
                  </IconButton>
                </Stack>
              )}
            </>
          ),
        });
      }
    }

    if (cell.beamThickness < minRecommendedBeamThickness && !cell.disabled && y > 0) {
      cellAnomalies.push({
        type: RackAnomalyIds.beamThicknessNotEnough,
        state: 1,
        description: (
          <Stack direction="row" justifyContent="space-between">
            <p>
              The beam thickness is too small.
              <br />
              Minimum thickness: {minRecommendedBeamThickness} m.
            </p>
            <IconButton
              title={`Autofix: set the beam thickness to ${minRecommendedBeamThickness} m`}
              onClick={(e) => {
                e.stopPropagation();
                (e.target as HTMLButtonElement).style.animation = 'shake 0.5s ease';
                setTimeout(() => {
                  actions.updateColumns([
                    {
                      ...column,
                      cells: column.cells.map((c, cellIndex) => {
                        const newCell = {
                          ...c,
                          beamThickness: minRecommendedBeamThickness,
                        };

                        if (cellIndex === 0) {
                          newCell.beamThickness = 0;
                          newCell.linkedBeamThickness = false;
                        }

                        return newCell;
                      }),
                    },
                  ]);

                  if (propagateFixToSelection) {
                    actions.applyCellValueToAllSelectedCells({ beamThickness: minRecommendedBeamThickness });
                  }
                }, 500);
              }}
              size="small"
            >
              <AutoFixHighIcon sx={{ color: 'white' }} />
            </IconButton>
          </Stack>
        ),
      });
    }

    if (y > 0 && cellTemplate.palletOverflow < minRecommendedPalletOverflow) {
      cellAnomalies.push({
        type: RackAnomalyIds.palletOverflowNotEnough,
        state: 1,
        description: (
          <Stack direction="row" justifyContent="space-between">
            <p>
              The pallet overflow is too small.
              <br />
              Minimum overflow: {minRecommendedPalletOverflow} m.
            </p>
          </Stack>
        ),
      });
    } else if (y > 0 && cellTemplate.palletOverflow > maxRecommendedPalletOverflow) {
      cellAnomalies.push({
        type: RackAnomalyIds.palletOverflowTooMuch,
        state: 1,
        description: (
          <Stack direction="row" justifyContent="space-between">
            <p>
              The pallet overflow is too big.
              <br />
              Maximum overflow: {maxRecommendedPalletOverflow} m.
            </p>
          </Stack>
        ),
      });
    }

    const uprights = rack.properties.uprights;

    const uprightsToCheck = [
      { index: x, upright: uprights[x] },
      { index: x, upright: uprights[x + 1] },
    ]
      .filter(({ upright }) => isDefined(upright))
      .filter(({ upright }) => !!upright.enabled);

    uprightsToCheck.forEach(({ index, upright }) => {
      if (upright.width < minRecommendedUprightWidth) {
        cellAnomalies.push({
          type: RackAnomalyIds.uprightWidthNotEnough,
          state: 1,
          description: (
            <Stack direction="row" justifyContent="space-between">
              <p>
                The upright width is too small.
                <br />
                Minimum width: {minRecommendedUprightWidth} m.
              </p>
              <IconButton
                title={`Autofix: set the upright width to ${minRecommendedUprightWidth} m`}
                onClick={(e) => {
                  e.stopPropagation();
                  (e.target as HTMLButtonElement).style.animation = 'shake 0.5s ease';
                  setTimeout(() => {
                    actions.updateUprights([
                      {
                        uprightIndex: index,
                        newUpright: {
                          ...upright,
                          width: minRecommendedUprightWidth,
                          linkedWidth: false,
                        },
                      },
                    ]);
                  }, 500);
                }}
                size="small"
              >
                <AutoFixHighIcon sx={{ color: 'white' }} />
              </IconButton>
            </Stack>
          ),
        });
      }
    });

    return cellAnomalies;
  }, [actions, cell, cellTemplate, column, dispatch, propagateFixToSelection, rack.properties.uprights, x, y]);

  const disableLoads = !!anomalies.length && !!anomalies.find((anomaly) => anomaly.state === 2);

  const [textEl, setTextEl] = useState<SVGElement | null>(null);
  const [openedName, setOpenedName] = useState<string | null>(null);
  const [openedNamePosition, setOpenedNamePosition] = useState<number | null>(null);

  const handleClickPositionName = useCallback(
    (e: React.MouseEvent<SVGElement, MouseEvent>, newName: string, positionIndex: number) => {
      e.stopPropagation();

      setTextEl(e.currentTarget);
      setOpenedName(newName);
      setOpenedNamePosition(positionIndex);
    },
    []
  );

  return (
    <g
      type="CELL-RACK-EDITION"
      id={cell.id}
      onClick={(e) => actions.cellClick(cell, column, e as unknown as MouseEvent)}
      className={selected ? 'selected-cell' : undefined}
    >
      <>
        {/* the beam */}
        {/* cell at floor level don't have a beam */}
        {y > 0 && (
          <>
            <rect
              x={posX * 100}
              y={(-posY - cell.beamThickness) * 100}
              width={column.width * 100}
              height={cell.beamThickness * 100}
              fill="orange"
              type="beam"
            />
            <foreignObject
              x={posX * 100}
              y={(-posY - (y > 0 ? cell.beamThickness : 0)) * 100}
              className="overflow-visible"
            >
              <BeamThicknessMenu
                cell={cell}
                column={column}
                defaultBeamThickness={defaultBeamThickness}
                actions={actions}
                nbSelectedCells={nbSelectedCells}
              />
            </foreignObject>
          </>
        )}

        {/* the cell */}
        {/* the background */}
        <rect
          x={posX * 100}
          y={(-posY - cell.height) * 100}
          width={column.width * 100}
          height={(cell.height - (y > 0 ? cell.beamThickness : 0)) * 100}
          fill={cellTemplate ? cellTemplate.color : theme.palette.grey[500]}
          style={{ opacity: 0.3, strokeWidth: '4px' }}
        />

        {xPosLoads &&
          xPosLoads.length &&
          load &&
          cellTemplate &&
          xPosLoads.map((xPosLoad, index) => (
            <PalletRackPreview
              key={xPosLoad}
              x={posX + xPosLoad}
              y={-posY - (y > 0 ? cell.beamThickness : 0)}
              cellTemplate={cellTemplate}
              load={load}
              displayShadow={false}
              disabled={cell.names?.[cellTemplate.selectedLoad]?.[index]?.disabled || disableLoads}
            />
          ))}

        {cell.disabled && (
          <rect
            x={posX * 100}
            y={(-posY - cell.height) * 100}
            width={column.width * 100}
            height={cell.height * 100}
            fill="url(#disabledCell)"
          />
        )}

        {/** the selection rectangle */}
        {selected && (
          <rect
            x={posX * 100 + strokeWidthSelectedCell / 2}
            y={(-posY - cell.height) * 100 + strokeWidthSelectedCell / 2}
            width={column.width * 100 - strokeWidthSelectedCell}
            height={cell.height * 100 - strokeWidthSelectedCell}
            fill={'transparent'}
            stroke={theme.palette.common.black}
            strokeWidth={strokeWidthSelectedCell}
            className="pointer-events-none"
          />
        )}

        {/** the names of the positions */}
        {displayPositionNames &&
          xPosLoads &&
          load &&
          xPosLoads.length &&
          cellTemplate &&
          cellTemplate.selectedLoad !== undefined &&
          names &&
          names.length === xPosLoads.length &&
          xPosLoads.map((xPosLoad, index) => {
            const name = names[index];
            const loadX = (posX + xPosLoad + load.W / 2) * 100;
            const loadY =
              (-posY -
                (y > 0 ? cell.beamThickness : 0) -
                (cellTemplate.maximumLoadHeight && cellTemplate.maximumLoadHeight > 0.24
                  ? cellTemplate.maximumLoadHeight
                  : load.W) /
                  2) *
              100;

            if (!name) return undefined;

            const warningSpaceStartOrEnd = name.value.startsWith(' ') || name.value.endsWith(' ');
            const valueToDisplay = name.value.replaceAll(' ', '\u00A0');

            return (
              <React.Fragment key={name.value}>
                <text
                  x={loadX}
                  y={loadY}
                  className="rack-position-name"
                  filter="url(#bg-name-positions)"
                  textAnchor="middle"
                >
                  {valueToDisplay}
                </text>
                <text
                  x={loadX}
                  y={loadY}
                  className="rack-position-name"
                  textAnchor="middle"
                  onClick={(e) => handleClickPositionName(e, name.value, index)}
                >
                  {valueToDisplay}
                </text>
                {warningSpaceStartOrEnd && (
                  <Tooltip title="Names cannot start or end with spaces" arrow>
                    <text
                      x={loadX}
                      y={loadY + 12}
                      textAnchor="middle"
                      style={{
                        fontSize: '10px',
                      }}
                    >
                      ⚠
                    </text>
                  </Tooltip>
                )}
              </React.Fragment>
            );
          })}

        {displayPositionNames && textEl && openedName && cellTemplate && openedNamePosition !== null && (
          <CellNameEditionMenu
            anchorEl={textEl}
            cellId={cell.id}
            cellName={openedName}
            setOpenedName={setOpenedName}
            changeCellName={actions.changeCellName}
            toggleCellDisabled={actions.toggleCellDisabled}
            disabled={!!cell.names[cellTemplate.selectedLoad]?.[openedNamePosition]?.disabled}
            cellNames={names}
            selectedLoad={cellTemplate.selectedLoad}
            positionIndex={openedNamePosition}
          />
        )}

        {/* the cell coordinates for debug */}
        <text x={posX * 100 + 6} y={(-posY - cell.height) * 100 + 14} style={{ fontSize: '10px' }}>
          ({x}, {y})
        </text>

        <foreignObject
          x={(posX + column.width) * 100 - 22}
          y={(-posY - cell.height) * 100 - 5}
          className="overflow-visible"
        >
          <CellMenu
            cellTemplate={cellTemplate}
            actions={actions}
            enabled={!cell.disabled}
            cellId={cell.id}
            changeCellState={changeCellState}
            nbSelectedCells={nbSelectedCells}
          />
          <CellHeightMenu
            cell={cell}
            column={column}
            defaultCellHeight={defaultCellHeight}
            actions={actions}
            nbSelectedCells={nbSelectedCells}
            isConveyor={isConveyor}
          />
        </foreignObject>

        {/** the anomalies if there are anomalies */}
        {anomalies.length && (
          <foreignObject
            x={(posX + column.width) * 100 - 38}
            y={(-posY - cell.height) * 100 + 12}
            className="overflow-visible"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Tooltip
              title={
                <ul style={{ paddingLeft: '20px' }}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={propagateFixToSelection}
                        onChange={() => {
                          setPropagateFixToSelection((bool) => !bool);
                        }}
                      />
                    }
                    label={<span style={{ fontSize: '0.9rem' }}>Propagate fix to selection</span>}
                  />
                  {anomalies.map((anomaly, index) => (
                    <li key={index}>{anomaly.description ?? anomaly.type}</li>
                  ))}
                </ul>
              }
              leaveDelay={200}
            >
              {disableLoads ? <ErrorIcon color="error" /> : <WarningIcon color="warning" />}
            </Tooltip>
          </foreignObject>
        )}
      </>
    </g>
  );
}

interface CellNameEditionMenuProps {
  cellId: string;
  cellName: string;
  setOpenedName: React.Dispatch<React.SetStateAction<string | null>>;
  changeCellName: (cellId: string, newNames: RackCell['names'][0], loadIndex: number) => boolean;
  toggleCellDisabled: (cellId: string, newNames: RackCell['names'][0], loadIndex: number) => boolean;
  disabled: boolean;
  anchorEl: HTMLElement | SVGElement;
  selectedLoad: number;
  positionIndex: number;
  cellNames: RackCell['names'][0];
}
function CellNameEditionMenu({
  cellId,
  cellName,
  changeCellName,
  toggleCellDisabled,
  disabled,
  anchorEl,
  setOpenedName,
  selectedLoad,
  positionIndex,
  cellNames,
}: CellNameEditionMenuProps): JSX.Element {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [error, setError] = useState(false);
  const deferredError = useDeferredValue(error);

  const handleClose = useCallback(() => {
    setOpenedName(null);
  }, [setOpenedName]);

  const handleValidate = useCallback(() => {
    if (!inputRef.current) return;

    const newNames = [...cellNames];
    newNames[positionIndex] = {
      ...newNames[positionIndex],
      value: inputRef.current.value,
      user: true,
      id: cellNames[positionIndex]?.id ?? generateShapeId(),
    };

    const changeOk = changeCellName(cellId, newNames, selectedLoad);
    if (changeOk) {
      handleClose();
    } else {
      setError(true);
    }
  }, [cellId, cellNames, changeCellName, handleClose, positionIndex, selectedLoad]);

  const handleToggle = useCallback(() => {
    if (!inputRef.current) return;

    const newNames = [...cellNames];
    newNames[positionIndex] = {
      ...newNames[positionIndex],
      disabled: !newNames[positionIndex].disabled,
    };

    const changeOk = toggleCellDisabled(cellId, newNames, selectedLoad);
    if (!changeOk) {
      setError(true);
    }
  }, [cellId, cellNames, toggleCellDisabled, positionIndex, selectedLoad]);

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (error) setError(false);

      if (e.key === 'Enter' && inputRef.current) {
        handleValidate();
      } else if (e.key === 'Escape') {
        handleClose();
      }
    },
    [handleClose, handleValidate, error]
  );

  return (
    <Popover
      open={true}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
      anchorEl={anchorEl}
      onClick={(e) => e.stopPropagation()}
      onClose={handleClose}
      sx={{ display: 'flex', flexDirection: 'column' }}
    >
      <Tooltip title={disabled ? 'Activate the slot' : 'Deactivate the slot'}>
        <Switch checked={!disabled} onChange={handleToggle} />
      </Tooltip>
      <TextField
        defaultValue={cellName}
        inputRef={inputRef}
        size="small"
        autoFocus
        onKeyDown={handleKeyDown}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleValidate} size="small">
                <CheckCircleOutlineIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        error={error}
        helperText={error ? <Collapse in={deferredError}>Name already used</Collapse> : undefined}
      />
    </Popover>
  );
}

interface CellMenuProps {
  /** the cell template of the cell */
  cellTemplate?: CellTemplate;
  /** is the cell enabled? */
  enabled?: boolean;
  /** all the actions that can be performed on the rack */
  actions: ActionsRack;
  /** the id of the cell */
  cellId: string;
  /** a fonction to call to change the state of the cell */
  changeCellState: (cellId: string, enabled: boolean) => void;
  /** number of selected cells */
  nbSelectedCells: number;
}
function CellMenu({
  cellTemplate,
  actions,
  enabled,
  cellId,
  changeCellState,
  nbSelectedCells,
}: CellMenuProps): JSX.Element {
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = !!anchorEl;

  const handleClickOpenMenu = useCallback((e: React.MouseEvent<HTMLButtonElement>): void => {
    e.stopPropagation();

    setAnchorEl(e.currentTarget);
  }, []);

  const handleClose = useCallback((): void => {
    setAnchorEl(null);
  }, []);

  const handleClickMenuItem = useCallback(
    (newSelectedLoad: number): void => {
      dispatch(
        saveCellTemplateAction({
          ...cellTemplate,
          selectedLoad: newSelectedLoad,
          userAction: true,
        })
      );

      handleClose();
    },
    [cellTemplate, dispatch, handleClose]
  );

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <IconButton onClick={handleClickOpenMenu}>
        <ExpandCircleDownIcon sx={{ fontSize: '10px' }} />
      </IconButton>
      {open && (
        <Menu
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          MenuListProps={{
            sx: {
              paddingTop: 0,
              paddingBottom: 0,
            },
          }}
        >
          <ListItem sx={{ minWidth: '200px' }} onClick={() => changeCellState(cellId, !enabled)} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <Switch checked={!!enabled} size="small" />
              </ListItemIcon>
              <ListItemText>Enabled</ListItemText>
            </ListItemButton>

            <Divider orientation="vertical" flexItem variant="middle" />

            <Tooltip
              title={`Apply this value to all the selected cells (${nbSelectedCells} cell${
                nbSelectedCells !== 1 ? 's' : ''
              })`}
            >
              <span>
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    actions.applyCellValueToAllSelectedCells({ disabled: !enabled });
                  }}
                  sx={{
                    marginRight: theme.spacing(1),
                    marginLeft: theme.spacing(1),
                  }}
                  size="small"
                >
                  <CallSplitIcon fontSize="small" />
                </IconButton>
              </span>
            </Tooltip>
            {/* <ListItemAvatar>
            <Switch checked={!!enabled} size="small"/>
            </ListItemAvatar>
            <ListItemText sx={{
              marginRight: theme.spacing(5),
            }}>Enabled</ListItemText>
            <ListItemSecondaryAction >
              
|
        {/* </ListItemSecondaryAction>
        <ListItemSecondaryAction */}
            {/* <Tooltip
                title={`Apply this value to all the selected cells (${nbSelectedCells} cell${
                  nbSelectedCells !== 1 ? 's' : ''
                })`}
              >
                <span>
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      actions.applyCellValueToAllSelectedCells({ disabled: !enabled });
                    }}
                    sx={{
                      // outline: '1px solid black'
                      marginRight: 0,
                      // backgroundColor: 'white'
                      // marginRight: theme.spacing(1),
                    }}
                    size="small"
                  >
                    <CallSplitIcon fontSize='small' />
                  </IconButton>
                </span>
              </Tooltip>
            </ListItemSecondaryAction> */}
          </ListItem>

          {cellTemplate
            ? [
                <Divider key="divider-0" />,

                <MenuItem
                  key="edit-cell-template-btn"
                  onClick={() => actions.changeRackEditionMenu(cellTemplate.id)}
                  sx={{
                    marginTop: '8px',
                  }}
                >
                  <ListItemIcon>
                    <ModeEditIcon />
                  </ListItemIcon>
                  <ListItemText>Edit {cellTemplate.name}</ListItemText>
                </MenuItem>,

                <Divider key="divider-1" />,

                cellTemplate.loads.map((load, index) => (
                  <Tooltip
                    key={load.name}
                    title={
                      <>
                        N: {load.N} - a<sub style={{ marginTop: '8px' }}>1</sub>: {load.a1} mm - W: {load.W} mm
                      </>
                    }
                    arrow
                    disableInteractive
                  >
                    <MenuItem onClick={() => handleClickMenuItem(index)} selected={cellTemplate.selectedLoad === index}>
                      <>{load.name}</>
                    </MenuItem>
                  </Tooltip>
                )),
              ]
            : undefined}
        </Menu>
      )}
    </div>
  );
}

interface CellHeightMenuProps {
  cell: RackCell;
  column: RackColumn;
  defaultCellHeight: number;
  actions: ActionsRack;
  nbSelectedCells: number;
  isConveyor: boolean;
}
function CellHeightMenu({
  cell,
  actions,
  column,
  defaultCellHeight,
  nbSelectedCells,
  isConveyor,
}: CellHeightMenuProps): JSX.Element {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = !!anchorEl;

  const [height, setHeight] = useState((cell.height * 1000).toFixed(0));
  const [isCellLinkedHeight, setIsCellLinkedHeight] = useState(!!cell.linkedHeight);

  const inputContainerRef = useRef<HTMLDivElement | null>(null);

  const handleClickOpenMenu = useCallback((e: React.MouseEvent<HTMLButtonElement>): void => {
    e.stopPropagation();

    setAnchorEl(e.currentTarget);
  }, []);

  const handleClose = useCallback((): void => {
    setAnchorEl(null);
  }, []);

  const handleClickLink = useCallback((): void => {
    const newColumn = {
      ...column,
      cells: column.cells.map((c) => {
        if (c.id === cell.id) {
          return { ...c, linkedHeight: !c.linkedHeight };
        }

        return c;
      }),
    };

    actions.updateColumns([newColumn]);
  }, [actions, cell.id, column]);

  const handleUpdateCellHeight = useCallback(
    (newHeight: string): void => {
      let h = parseFloat(newHeight) / 1000;
      if (isNaN(h)) {
        setHeight((cell.height * 1000).toFixed(0));

        return;
      }

      const min = parseInt(inputContainerRef.current?.querySelector('input')?.min ?? '0', 10) / 1000;
      if (h < min) h = min;

      const newColumn = {
        ...column,
        cells: column.cells.map((c) => {
          if (c.id === cell.id) {
            return { ...c, height: h };
          }

          return c;
        }),
      };

      setHeight((h * 1000).toFixed(0));

      actions.updateColumns([newColumn]);
    },
    [actions, cell.height, cell.id, column]
  );

  const handleOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'Enter') {
      handleUpdateCellHeight(height);
    } else if (e.key === 'Escape') {
      setHeight((cell.height * 1000).toFixed(0));
    }
  };

  useEffect(() => {
    const newState = !!cell.linkedHeight;
    setIsCellLinkedHeight(newState);

    if (newState && cell.height !== defaultCellHeight) handleUpdateCellHeight((defaultCellHeight * 1000).toFixed(0));
  }, [cell.height, cell.linkedHeight, defaultCellHeight, handleUpdateCellHeight]);
  useEffect(() => {
    setHeight((cell.height * 1000).toFixed(0));
  }, [cell.height]);

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <IconButton onClick={handleClickOpenMenu} title="Cell height">
        <StraightenIcon
          sx={{
            fontSize: '10px',
            transform: 'rotate(-90deg)',
            '&:hover': {
              color: theme.palette.primary.light,
            },
          }}
          color={open || !isCellLinkedHeight ? 'primary' : undefined}
        />
      </IconButton>
      {open && (
        <Menu open={open} anchorEl={anchorEl} onClose={handleClose}>
          <MenuItem>
            <Tooltip title="The beam height is included in the cell height" disableHoverListener placement="top" arrow>
              <TextField
                type="number"
                variant="standard"
                value={height}
                inputProps={{
                  step: 10,
                  min: cellHeightMinHeight * 1000,
                  max: cellHeightMaxHeight * 1000,
                }}
                InputProps={{
                  startAdornment: !isConveyor && (
                    <InputAdornment position="start">
                      <Tooltip title={'To link or unlink this value with the master value'}>
                        <span>
                          <IconButton onClick={handleClickLink}>
                            {isCellLinkedHeight ? <LinkIcon /> : <LinkOffIcon />}
                          </IconButton>
                        </span>
                      </Tooltip>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      mm
                      <Tooltip
                        title={`Apply this value to all the selected cells (${nbSelectedCells} cell${
                          nbSelectedCells !== 1 ? 's' : ''
                        })`}
                      >
                        <span>
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();

                              actions.applyCellValueToAllSelectedCells({
                                height: parseFloat(height) / 1000,
                                linkedHeight: !!cell.linkedHeight,
                              });
                            }}
                          >
                            <CallSplitIcon />
                          </IconButton>
                        </span>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
                sx={{}}
                label="Cell height"
                onChange={(e) => setHeight(e.target.value)}
                onBlur={(e) => handleUpdateCellHeight(e.currentTarget.value)}
                onKeyDown={handleOnKeyDown}
                disabled={isCellLinkedHeight}
                ref={inputContainerRef}
              />
            </Tooltip>
          </MenuItem>
        </Menu>
      )}
    </div>
  );
}

interface BeamThicknessMenuProps {
  cell: RackCell;
  column: RackColumn;
  defaultBeamThickness: number;
  actions: ActionsRack;
  nbSelectedCells: number;
}
function BeamThicknessMenu({
  cell,
  actions,
  column,
  defaultBeamThickness,
  nbSelectedCells,
}: BeamThicknessMenuProps): JSX.Element {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = !!anchorEl;

  const [beamThickness, setBeamThickness] = useState((cell.beamThickness * 1000).toFixed(0));
  const [isCellLinked, setIsCellLinked] = useState(cell.linkedBeamThickness);

  const inputContainerRef = useRef<HTMLDivElement | null>(null);

  const handleClickOpenMenu = useCallback((e: React.MouseEvent<HTMLButtonElement>): void => {
    e.stopPropagation();

    setAnchorEl(e.currentTarget);
  }, []);

  const handleClose = useCallback((): void => {
    setAnchorEl(null);
  }, []);

  const handleClickLink = useCallback((): void => {
    const newColumn = {
      ...column,
      cells: column.cells.map((c, cellIndex) => {
        if (cellIndex === 0) {
          // first cell has no beam thickness
          return { ...c, beamThickness: 0, linkedBeamThickness: false };
        }

        if (c.id === cell.id) {
          return { ...c, linkedBeamThickness: !c.linkedBeamThickness };
        }

        return c;
      }),
    };

    actions.updateColumns([newColumn]);
  }, [actions, cell.id, column]);

  const handleUpdateCellBeamThickness = useCallback(
    (newBeamThickness: string): void => {
      let bt = parseFloat(newBeamThickness) / 1000;
      if (isNaN(bt)) {
        setBeamThickness((cell.beamThickness * 1000).toFixed(0));

        return;
      }

      const min = parseInt(inputContainerRef.current?.querySelector('input')?.min ?? '0', 10) / 1000;
      if (bt < min) bt = min;

      const newColumn = {
        ...column,
        cells: column.cells.map((c, cellIndex) => {
          if (cellIndex === 0) {
            // first cell has no beam thickness
            return { ...c, beamThickness: 0, linkedBeamThickness: false };
          }

          if (c.id === cell.id) {
            return { ...c, beamThickness: bt };
          }

          return c;
        }),
      };

      setBeamThickness((bt * 1000).toFixed(0));

      actions.updateColumns([newColumn]);
    },
    [actions, cell.beamThickness, cell.id, column]
  );

  const handleOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'Enter') {
      handleUpdateCellBeamThickness(beamThickness);
    } else if (e.key === 'Escape') {
      setBeamThickness((cell.beamThickness * 1000).toFixed(0));
    }
  };

  useEffect(() => {
    const newState = !!cell.linkedBeamThickness;
    setIsCellLinked(newState);

    if (newState && cell.beamThickness !== defaultBeamThickness)
      handleUpdateCellBeamThickness((defaultBeamThickness * 1000).toFixed(0));
  }, [cell.beamThickness, cell.linkedBeamThickness, defaultBeamThickness, handleUpdateCellBeamThickness]);
  useEffect(() => {
    setBeamThickness((cell.beamThickness * 1000).toFixed(0));
  }, [cell.beamThickness]);

  const iconHeight = 10;

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <IconButton
        onClick={handleClickOpenMenu}
        title="Beam thickness"
        sx={{
          padding: 0,
          height: `${iconHeight}px`,
          verticalAlign: 'baseline',
          position: 'relative',
          top: cell.beamThickness * 100 < 100 ? `-8px` : undefined,
        }}
      >
        <HeightIcon
          sx={{
            fontSize: `${iconHeight}px`,
            '&:hover': {
              color: theme.palette.primary.light,
            },
          }}
          color={open || !isCellLinked ? 'primary' : undefined}
        />
      </IconButton>
      {open && (
        <Menu open={open} anchorEl={anchorEl} onClose={handleClose}>
          <MenuItem>
            <TextField
              type="number"
              variant="standard"
              value={beamThickness}
              inputProps={{
                step: 10,
                min: beamThicknessMin * 1000,
                max: beamThicknessMax * 1000,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Tooltip title={'To link or unlink this value with the master value'}>
                      <span>
                        <IconButton onClick={handleClickLink}>
                          {isCellLinked ? <LinkIcon /> : <LinkOffIcon />}
                        </IconButton>
                      </span>
                    </Tooltip>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    mm
                    <Tooltip
                      title={`Apply this value to all the selected cells (${nbSelectedCells} cell${
                        nbSelectedCells !== 1 ? 's' : ''
                      })`}
                    >
                      <span>
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation();

                            actions.applyCellValueToAllSelectedCells({
                              beamThickness: parseFloat(beamThickness) / 1000,
                              linkedBeamThickness: !!cell.linkedBeamThickness,
                            });
                          }}
                        >
                          <CallSplitIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
              sx={{}}
              label="Beam Thickness"
              onChange={(e) => setBeamThickness(e.target.value)}
              onBlur={(e) => handleUpdateCellBeamThickness(e.currentTarget.value)}
              onKeyDown={handleOnKeyDown}
              disabled={isCellLinked}
              ref={inputContainerRef}
            />
          </MenuItem>
        </Menu>
      )}
    </div>
  );
}

interface EditModeButtonProps {
  editMode: boolean;
  setEditMode: React.Dispatch<React.SetStateAction<boolean>>;
}
function EditModeButton({ editMode, setEditMode }: EditModeButtonProps): JSX.Element {
  const [state, setState] = useState(editMode);
  const [isPending, startTransition] = useTransition();

  useEffect(() => {
    setState(editMode);
  }, [editMode]);

  function onClick(): void {
    const newState = !state;

    setState(newState);

    startTransition(() => {
      setEditMode(newState);
    });
  }

  return (
    <Badge
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      badgeContent={isPending ? <CircularProgress size={15} /> : undefined}
      overlap="circular"
    >
      <IconButton onClick={onClick}>
        <ModeEditIcon
          color={state ? 'secondary' : undefined}
          sx={{
            transform: state ? 'rotate(360deg)' : 'rotate(0deg)',
            transition: 'transform 0.2s ease-in-out',
          }}
        />
      </IconButton>
    </Badge>
  );
}
