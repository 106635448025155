import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { HelpIconTooltip } from 'components/utils/tooltips';
import type { Flow, Station } from 'flows/flows';
import { removeFlow, setSelectedFlowId } from 'flows/flows';
import { useConfirm } from 'material-ui-confirm';
import { useCallback, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { theme } from 'utils/mui-theme';
import { DisplayFlowData } from './display-flow-data';
import { ListStationsOfFlow } from './list-stations-flow';

interface FlowListAccordionProps {
  flows: Flow[];
  setIsFlowCreation: (value: boolean) => void;
  setMode: (mode: 'creation' | 'edition') => void;
  setSelectedTab: (tabToSelect: string) => void;
}
export function FlowListAccordion(props: FlowListAccordionProps): JSX.Element {
  const { flows, setIsFlowCreation, setMode, setSelectedTab } = props;
  const stations: (Station | undefined)[] = useAppSelector((state) => state.flows.stations);
  const selectedFlowId = useAppSelector((state) => state.flows.selectedFlowId);
  const confirm = useConfirm();

  const selectedFlow = useMemo(() => {
    return flows.find((flow) => flow.id === selectedFlowId);
  }, [flows, selectedFlowId]);

  const stationsOfSelectedFlow = useMemo(() => {
    const selectedStations: Station[] = [];

    if (selectedFlow) {
      for (let i = 0; i < selectedFlow.stations.length; i++) {
        stations.forEach((st) => {
          if (st && selectedFlow.stations[i].id.includes(st.id)) {
            selectedStations.push(st);
          }
        });
      }
    }

    return [...new Set(selectedStations)];
  }, [selectedFlow, stations]);

  const dispatch = useAppDispatch();

  const handleAccordionChange = useCallback((flowId: string): void => dispatch(setSelectedFlowId(flowId)), [dispatch]);

  const handleDeleteFlow = useCallback(
    (flow: Flow): void => {
      confirm({ title: `Are you sure you want to remove "${flow.name}"?` })
        .then(() => {
          dispatch(removeFlow(flow.id));
        })
        .catch(() => undefined);
    },
    [confirm, dispatch]
  );

  const handleUnselectFlow = useCallback((): void => {
    dispatch(setSelectedFlowId(undefined));
  }, [dispatch]);

  const handleEditFlow = useCallback(() => {
    setMode('edition');
    setIsFlowCreation(true);
  }, [setIsFlowCreation, setMode]);

  return (
    <div>
      {flows.map((flow) => (
        <Accordion
          key={flow.id}
          expanded={selectedFlowId === flow.id}
          slotProps={{ transition: { unmountOnExit: true } }}
          onChange={(event, isExpanded) => {
            if (!isExpanded) return;

            handleAccordionChange(flow.id);
          }}
        >
          <AccordionSummary
            expandIcon={selectedFlowId !== flow.id && <ExpandMoreIcon />}
            aria-controls="panel1-content"
            id={`${flow.id}-header`}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              cursor: selectedFlowId === flow.id ? 'default' : 'pointer',
            }}
          >
            <Typography variant="subtitle2" sx={{ fontWeight: 600, color: theme.palette.grey[800], flexGrow: 1 }}>
              {flow.name}
            </Typography>

            {selectedFlowId === flow.id && (
              <Stack direction="row" spacing={1}>
                <Tooltip title="Edit flow">
                  <IconButton aria-label="edit" size="small" onClick={handleEditFlow}>
                    <EditIcon fontSize="small" sx={{ cursor: 'pointer' }} />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete flow">
                  <IconButton aria-label="delete" size="small" onClick={() => handleDeleteFlow(flow)}>
                    <DeleteIcon fontSize="small" sx={{ cursor: 'pointer', color: '#D70040' }} />
                  </IconButton>
                </Tooltip>
                <IconButton aria-label="close" size="small" onClick={handleUnselectFlow}>
                  <ExpandLessIcon />
                </IconButton>
              </Stack>
            )}
          </AccordionSummary>
          <AccordionDetails>
            <Stack
              direction={'row'}
              spacing={1}
              justifyContent="center"
              alignItems="center"
              sx={{ textAlign: 'center' }}
            >
              <DisplayFlowData
                valueToDisplay={flow?.objective}
                title={
                  <>
                    <span>Objective</span>
                    <HelpIconTooltip sx={{ width: 12 }} title="Objective for the flows in task per hour (optional)" />
                  </>
                }
                unit={'tasks/hr'}
              />
              <Divider orientation="vertical" variant="middle" flexItem />
              <DisplayFlowData
                valueToDisplay={flow?.maximumTaskTime}
                title={
                  <>
                    <span>Maximum Task Time</span>
                    <HelpIconTooltip sx={{ width: 12 }} title="Maximum task time for the flows in seconds (optional)" />
                  </>
                }
                unit={flow.maximumTaskTime && flow.maximumTaskTime > 1 ? 'seconds' : 'second'}
              />
              <Divider orientation="vertical" variant="middle" flexItem />
              <DisplayFlowData
                valueToDisplay={flow?.palletsPerTask}
                title={
                  <>
                    <span>Pallets Per Task</span>
                    <HelpIconTooltip
                      sx={{ width: 12 }}
                      title="Number of pallets moved during one task. Warning ! This value can affect the throughput when expressed in pallets per hour."
                    />
                  </>
                }
                unit={'pal/task'}
              />
            </Stack>
            {selectedFlow && (
              <>
                <Divider variant="middle" sx={{ my: 2 }} />
                <ListStationsOfFlow
                  canEditStation={false}
                  key={flow.id}
                  stations={stationsOfSelectedFlow ?? []}
                  flow={flow}
                  setSelectedTab={setSelectedTab}
                />
              </>
            )}
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}
