import { segmentLineStrokeWidthToStrokeWidth } from 'drawings/editor-elements';
import { Tools } from 'models/tools';
import { useMemo } from 'react';
import type { Itinerary } from 'routes/routes';
import { useAppSelector } from 'store';
import { theme } from 'utils/mui-theme';

export const highlightStrokeWidthFactor = 3;

export function ItinerariesComponent(): JSX.Element {
  const keepDisplayItineraryOnLeave = useAppSelector((state) => state.routes.keepDisplayItineraryOnLeave);
  const isRouteTool = useAppSelector((state) => state.tool.activeTool === Tools.Route);
  const isSimulation = useAppSelector((state) => state.editor.editorMode === 'flow');
  const hoveredRobotIndex = useAppSelector((state) => state.robots.hoveredRobotIndex);
  const performanceModeEnabled = useAppSelector((state) => state.editor.isPerformanceModeEnabled);

  const itineraries = useAppSelector((state) => state.routes.itineraries);
  const strokeSize = useAppSelector((state) => state.tool.lineStrokeSize);

  const deletedTurns = itineraries.filter((itinerary) => itinerary.isDeletedTurn === true);

  const strokeWidth = useMemo(() => {
    return 2 * segmentLineStrokeWidthToStrokeWidth(strokeSize);
  }, [strokeSize]);

  if (!isRouteTool && !keepDisplayItineraryOnLeave && !isSimulation && !deletedTurns.length) return <></>;

  return (
    <g className="itineraries">
      {itineraries.map((itinerary) => (
        <ItineraryComponent
          key={`${itinerary.id}`}
          itinerary={itinerary}
          strokeWidth={strokeWidth}
          highlight={
            !performanceModeEnabled && hoveredRobotIndex !== undefined && itinerary.robotIndex === hoveredRobotIndex
          }
        />
      ))}

      <PointItineraries strokeWidth={strokeWidth} />
    </g>
  );
}

interface ItineraryProps {
  itinerary: Itinerary;

  strokeWidth?: number;

  highlight?: boolean;
}

export function ItineraryComponent({ itinerary, strokeWidth, highlight = false }: ItineraryProps): JSX.Element {
  const positions = itinerary.positions;

  const polylineLength = useMemo(() => {
    let length = 0;

    for (let i = 1; i < positions.length; i++) {
      const p1 = positions[i - 1];
      const p2 = positions[i];

      length += Math.sqrt((p2.x - p1.x) ** 2 + (p2.y - p1.y) ** 2);
    }

    return length;
  }, [positions]);

  const color = theme.palette.primary.main;
  const colorDeletedTurn = theme.palette.warning.main;

  return (
    <polyline
      className="itinerary"
      points={positions.map((point) => `${point.x},${point.y}`).join(' ')}
      stroke={itinerary.isDeletedTurn ? colorDeletedTurn : color}
      strokeWidth={(strokeWidth ?? 5) * (highlight ? highlightStrokeWidthFactor : 1)}
      fill="none"
      style={{
        strokeDasharray: `${polylineLength}`,
        strokeDashoffset: polylineLength,
        animation: `itinerary-animation 0.5s ease-in-out 1 forwards`,
        filter: highlight ? `drop-shadow(0 0 5px ${color})` : undefined,
        transition: 'stroke-width 0.5s',
      }}
    />
  );
}

interface PointItinerariesProps {
  strokeWidth?: number;
}
export function PointItineraries({ strokeWidth }: PointItinerariesProps): JSX.Element {
  const nextItineraryFrom = useAppSelector((state) => state.routes.nextItineraryFrom);
  const nextItineraryTo = useAppSelector((state) => state.routes.nextItineraryTo);

  return (
    <>
      {nextItineraryFrom && 'x' in nextItineraryFrom && (
        <circle cx={nextItineraryFrom.x} cy={-nextItineraryFrom.y} r={(strokeWidth ?? 5) * 2} fill="green" />
      )}

      {nextItineraryTo && 'x' in nextItineraryTo && (
        <circle cx={nextItineraryTo.x} cy={-nextItineraryTo.y} r={(strokeWidth ?? 5) * 2} fill="red" />
      )}
    </>
  );
}
