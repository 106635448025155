import { Tools } from 'models/tools';
import { connect } from 'react-redux';
import type { AppState } from 'reducers/state';
import { getTool } from 'reducers/tools/reducer';
import { CustomStepsEditor } from './custom-steps/custom-steps-editor';
import { FlowsToolbox } from './flow-configuration/flow-configuration';
import { RobotsSimulationConfigurationToolbox } from './robots-simulation-configuration';
import { RouteToolbox } from './routes';
import { ConfigureSimulationToolbox } from './simulation';
import { FlowAssignationToolbox } from './simulation/flow-assignation';
import { SchedulerConfigToolbox } from './simulation/scheduler-config';
import { TrafficTools } from './traffic-tools';

interface StateProps {
  activeTool?: Tools;
}

function mapStateToProps(state: AppState): StateProps {
  const activeTool = getTool(state.tool);

  return {
    activeTool,
  };
}

export const Toolboxes = connect(mapStateToProps)(ToolboxesComponent);

function ToolboxesComponent({ activeTool }): JSX.Element | null {
  switch (activeTool) {
    case Tools.Route: {
      return <RouteToolbox />;
    }

    case Tools.DisplayTraffic: {
      return <TrafficTools trafficOption="displayTraffic" />;
    }

    case Tools.TrafficCheck: {
      return <TrafficTools trafficOption="trafficCheck" />;
    }

    case Tools.SimulationConfiguration: {
      return <ConfigureSimulationToolbox />;
    }

    case Tools.RobotsSimulationConfiguration: {
      return <RobotsSimulationConfigurationToolbox />;
    }

    case Tools.FlowConfiguration: {
      return <FlowsToolbox />;
    }

    case Tools.StationsConfiguration: {
      return <FlowsToolbox selectedTab="Station" />;
    }

    case Tools.TriggersConfiguration: {
      return <FlowsToolbox selectedTab="Trigger" />;
    }

    case Tools.FlowAssignation: {
      return <FlowAssignationToolbox />;
    }

    case Tools.SchedulerConfiguration: {
      return <SchedulerConfigToolbox />;
    }

    case Tools.CustomStepsEditor: {
      return <CustomStepsEditor />;
    }

    default: {
      return null;
    }
  }
}
