import { Bolt, Checklist } from '@mui/icons-material';
import ClearIcon from '@mui/icons-material/Clear';
import { Button, ButtonGroup, LinearProgress, Tooltip } from '@mui/material';
import { Box } from '@mui/system';
import { useCallback, useState } from 'react';
import { CircuitService } from 'services/circuit.service';
import { SnackbarUtils } from 'services/snackbar.service';
import {
  computeFlowItineraries,
  getComputingProgressFlowItineraries,
  resetFlowItinerariesProgress,
  stopComputingFlowItineraries,
} from 'simulation/check-flows';
import { generateXML } from 'utils/export/generate-xml';
import { theme } from 'utils/mui-theme';
import { type FlowCheckResult } from '../flow-configuration';

interface CheckFlowButtonProps {
  flowIds: string[];
  onCheckResult: (results: Record<string, FlowCheckResult>) => void;
}

export function CheckFlowButton({ flowIds, onCheckResult }: CheckFlowButtonProps): JSX.Element {
  const [checkingFlows, setCheckingFlows] = useState(false);
  const [checkingFlowsProgress, setCheckingFlowsProgress] = useState<Record<string, number>>({});

  const updateCheckingFlowsProgress = useCallback(() => {
    const progress: Record<string, number> = {};

    flowIds.forEach((flowId) => {
      const flowProgress = getComputingProgressFlowItineraries(flowId);

      progress[flowId] = flowProgress ?? 0;
    });

    setCheckingFlowsProgress(progress);
  }, [flowIds]);

  // Calculate overall progress as average of all flows
  const overallProgress =
    Object.values(checkingFlowsProgress).length > 0
      ? Object.values(checkingFlowsProgress).reduce((sum, curr) => sum + curr, 0) /
        Object.values(checkingFlowsProgress).length
      : undefined;

  const checkFlows = useCallback(
    async (displayItineraries: boolean) => {
      if (flowIds.length === 0) return;

      setCheckingFlows(true);
      onCheckResult({});

      const checkProgressInterval = setInterval(updateCheckingFlowsProgress, 1000);
      const results: Record<string, FlowCheckResult> = {};

      const simulationServiceModule = await import('../../../../services/simulation.service.ts');
      await simulationServiceModule.waitForSimulationService;

      const simulationService = simulationServiceModule.simulationService;
      if (!simulationService) {
        throw new Error('Simulation service not available');
      }

      // eslint-disable-next-line no-console
      console.log('Generating the xml file...');

      const [xml] = await generateXML({
        zones: CircuitService.zones,
        segments: CircuitService.segments,
        stockZones: CircuitService.stockZones,
        measurers: CircuitService.measurers,
        turns: CircuitService.turns,
        racks: CircuitService.racks,
        points: CircuitService.points,
      });

      // eslint-disable-next-line no-console
      console.log('generated');

      // eslint-disable-next-line no-console
      console.log('Loading the circuit in the libtrack...');
      const circuitPtr = await simulationService.allocateUTF8(xml);
      await simulationService._TRACK_WasmWrapper_loadCircuit(circuitPtr);

      try {
        for (const flowId of flowIds) {
          try {
            const res = await computeFlowItineraries({
              flowId,
              displayItineraries,
            });

            const itineraries = res?.itineraries?.flat() ?? [];
            const nbItineraries = itineraries.length;
            const issues = itineraries.filter((itinerary) => !itinerary.ok);

            if (res) {
              results[flowId] = {
                nbItineraries,
                issues,
              };
            }
          } catch (e) {
            // eslint-disable-next-line no-console
            console.error(`Error checking flow ${flowId}:`, e);
            SnackbarUtils.error(`An error occurred while checking flow ${flowId}`);
          }
        }

        onCheckResult(results);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error('Error checking flows:', e);
        SnackbarUtils.error('An error occurred while checking the flows');
      }

      clearInterval(checkProgressInterval);
      resetFlowItinerariesProgress();
      setCheckingFlows(false);
      setCheckingFlowsProgress({});

      // free the memory
      await simulationService._OS_WasmWrapper_free(circuitPtr);
    },
    [flowIds, updateCheckingFlowsProgress, onCheckResult]
  );

  const stopCheckingFlows = useCallback(() => {
    stopComputingFlowItineraries();
  }, []);

  const disabled = flowIds.length === 0 || checkingFlows;

  return (
    <>
      <ButtonGroup
        sx={{
          width: '100%',
          marginTop: theme.spacing(1),
        }}
      >
        <Button
          fullWidth
          onClick={() => checkFlows(true)}
          disabled={disabled}
          startIcon={<Checklist />}
          variant="outlined"
        >
          Check flow{flowIds.length > 1 ? 's' : ''}
        </Button>
        {!checkingFlows && (
          <Tooltip
            title={`Check flow${
              flowIds.length > 1 ? 's' : ''
            } without displaying itineraries (much faster on large projects)`}
            placement="top"
          >
            <Box component="span">
              <Button
                onClick={() => checkFlows(false)}
                sx={{
                  height: '100%',
                }}
                disabled={disabled}
              >
                <Bolt />
              </Button>
            </Box>
          </Tooltip>
        )}
        {checkingFlows && (
          <Tooltip title="Abort the computation">
            <Box component="span">
              <Button onClick={stopCheckingFlows} disabled={!overallProgress}>
                <ClearIcon />
              </Button>
            </Box>
          </Tooltip>
        )}
      </ButtonGroup>
      {checkingFlows && (
        <LinearProgress
          value={checkingFlows && overallProgress !== undefined ? overallProgress * 100 : undefined}
          variant={overallProgress !== undefined ? 'determinate' : 'indeterminate'}
          sx={{
            marginTop: '-4px',
          }}
          color={'inherit'}
        />
      )}
    </>
  );
}
