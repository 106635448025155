import type { Position } from '@turf/helpers';
import { savePointAction } from 'actions/points';
import { rotatePoint } from 'drawings/helpers';
import type {
  CircuitDevice,
  CircuitMeasurer,
  CircuitNote,
  CircuitPoint,
  CircuitRack,
  CircuitSegment,
  CircuitStockZone,
  CircuitTurn,
  CircuitZone,
} from 'models/circuit';

/**
 * Processes a selected point, applies rotation, and updates its properties.
 *
 * @param point - The selected point shape to process.
 * @param selectedShapes - The list of currently selected shapes.
 * @param center - The central position for rotation.
 * @param rotateValue - The value by which to rotate the point.
 * @param actions - The array of actions to which the updated point will be pushed.
 */
export function processRotatePoint(
  point: CircuitPoint,
  selectedShapes: (
    | CircuitTurn
    | CircuitSegment
    | CircuitStockZone
    | CircuitZone
    | CircuitPoint
    | CircuitMeasurer
    | CircuitRack
    | CircuitDevice
    | CircuitNote
  )[],
  centerSelectedShapes: Position,
  rotateValue: number,
  actions: any[]
): void {
  if (!point) return;

  const isPointOnSegment = point.properties.segment;

  if (isPointOnSegment) {
    const segmentId = point.properties.segment?.id;

    const isSegmentSelected = selectedShapes.some((shape) => shape.id === segmentId);

    if (isSegmentSelected) {
      return;
    }
  }

  if (point.properties.locked) return;

  const newOrientation = point.properties.orientation + rotateValue;

  const newCoords = rotatePoint(
    centerSelectedShapes,
    point.geometry.coordinates.map((c) => c),
    -rotateValue
  );

  actions.push(
    savePointAction({
      ...point,
      geometry: {
        ...point.geometry,
        coordinates: newCoords,
      },
      properties: {
        ...point.properties,
        orientation: newOrientation,
      },
    })
  );
}
