import { ArrowDropDown as ArrowDropDownIcon } from '@mui/icons-material';
import BugReportIcon from '@mui/icons-material/BugReport';
import DownloadIcon from '@mui/icons-material/Download';
import ModelTrainingIcon from '@mui/icons-material/ModelTraining';
import RemoveRoadIcon from '@mui/icons-material/RemoveRoad';
import RouteIcon from '@mui/icons-material/Route';
import SaveIcon from '@mui/icons-material/Save';
import {
  Badge,
  Button,
  ButtonGroup,
  ClickAwayListener,
  Divider,
  Grid,
  Grow,
  LinearProgress,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Tooltip,
} from '@mui/material';
import { Box } from '@mui/system';
import { AddMultipleSegmentsAction } from 'actions/circuit';
import { ImportCircuit } from 'components/import/circuit';
import { syncYJSLocalToRemote } from 'components/presence/utils/syncYjsDoc';
import * as d3 from 'd3';
import type {
  CircuitMeasurer,
  CircuitPoint,
  CircuitRack,
  CircuitSegment,
  CircuitStockZone,
  CircuitTurn,
  CircuitZone,
} from 'models/circuit';
import { localDoc } from 'multiplayer/globals';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { importRobotPath } from 'robots/import-robot-path';
import { clearAllRobotPaths } from 'robots/robots';
import { checkPermission } from 'services/check-permission';
import { CircuitService } from 'services/circuit.service';
import { SnackbarUtils } from 'services/snackbar.service';
import store, { useAppDispatch, useAppSelector } from 'store';
import { useAsyncMemo } from 'use-async-memo';
import { setImmediateTimeoutPromise } from 'utils/browser';
import { generateXML } from 'utils/export/generate-xml.tsx';
import { downloadVda5050Json } from 'utils/export/vda5050/generate-vda5050-json';
import { importCircuitXML } from 'utils/import/import-xml';
import { PreferencesService, blobToBase64, getPreferenceValue } from 'utils/preferences';
import { canBeUndefined } from 'utils/ts/is-defined';
import { MissingCellTemplateError } from '../../utils/export/generate-xml-utils.ts';
import { downloadGeojsonCircuit } from './download-circuit';
import { manageMissingCellTemplateError } from './manage-missing-cell-template-error';
import {
  resetSnackbarLoadingKey,
  saveProject,
  setIsSavingProject,
  snackbarLoadingKey,
} from './save-project/save-project';

const exportImagesEnabled = {
  mapPng: true,
  mapAndCircuitPng: false,
  mapAndCircuitSvg: true,
};
const nbExportImagesEnabled = Object.values(exportImagesEnabled).filter((v) => v).length;

export function ExportCircuitComponent(): JSX.Element {
  const dispatch = useAppDispatch();

  const multiplayer = useAppSelector((state) => state.multiplayer.multiplayer);

  // filename by default
  let filename = `circuit.geojson`;

  // if a project is loaded, use the project name
  if (PreferencesService.arePreferencesFullyLoaded()) {
    filename = PreferencesService.getCircuitName();
  }

  const saveHandler = useCallback(
    (pretty = false) => {
      const features = {
        zones: CircuitService.zones,
        stockZones: CircuitService.stockZones,
        points: CircuitService.points,
        segments: CircuitService.segments,
        measurers: CircuitService.measurers,
        turns: CircuitService.turns,
        racks: CircuitService.racks,
        devices: CircuitService.devices,
        notes: CircuitService.notes,
      };

      const projectName = PreferencesService.arePreferencesFullyLoaded()
        ? (PreferencesService.getPreferenceValue('general/projectName') as string)
        : 'unknown';

      downloadGeojsonCircuit(filename, features, pretty, {
        projectName,
      });
    },
    [filename]
  );

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);

  const [exportingPng, setExportingPng] = useState<false | 0 | 1 | 2>(false);
  const isRender2Enabled = useAppSelector((state) => state.editor.isRender2Enabled);

  const handleToggle = useCallback((): void => {
    setOpen((prevOpen) => !prevOpen);
  }, []);

  const handleClose = useCallback((event): void => {
    const { current } = anchorRef;
    if (!!current) {
      if (current.contains(event.target)) {
        return;
      }
    }

    setOpen(false);
  }, []);

  const saveXML = useCallback(
    (pretty = true) => {
      const zones = CircuitService.zones;
      const stockZones = CircuitService.stockZones;
      const points = CircuitService.points;
      const segments = CircuitService.segments;
      const measurers = CircuitService.measurers;
      const turns = CircuitService.turns;
      const racks = CircuitService.racks;

      if (zones || points || segments || measurers || turns || racks || stockZones) {
        let filename = 'circuit.xml';
        if (PreferencesService.arePreferencesFullyLoaded()) {
          filename = PreferencesService.getCircuitName().replace('.geojson', '.xml');
        }

        downloadXML({ filename, zones, points, segments, stockZones, measurers, turns, racks, pretty });
      }
    },

    // would be better to read the store instead of reloading the component everytime a component is changed, wouldn't be?
    []
  );

  // eslint-disable-next-line @typescript-eslint/require-await
  const handlePerformanceTest = useCallback(async (nbElements = 100): Promise<void> => {
    const startTime = performance.now();
    // eslint-disable-next-line no-console
    console.log('[PERFORMANCE TEST] Start');

    const lineLength = Math.floor(Math.sqrt(nbElements));
    const spaceX = 100,
      spaceY = 100;
    const segmentLength = 50;

    // let actions: AddSegment[] = [];

    const coords: number[][][] = [];

    for (let i = 0; i < nbElements; i++) {
      const row = i % lineLength;
      const line = Math.floor(i / lineLength);

      const coord = [
        [spaceX * row, spaceY * line],
        [spaceX * row, spaceY * line + segmentLength],
      ];

      // actions.push(
      //   addSegmentAction({
      //     coord,
      //   })
      // );

      coords.push(coord);

      // if (i % Math.floor(nbElements / 10) === 0) {
      //   // eslint-disable-next-line no-console
      //   console.log(
      //     '[PERFORMANCE TEST]',
      //     Math.round((i / nbElements) * 100),
      //     '% - elapsed time:',
      //     performance.now() - startTime,
      //     'ms.'
      //   );
      // }

      // if (i % 50 === 0) {
      // eslint-disable-next-line no-loop-func

      // coords  = [];

      // await new Promise((resolve) => setImmediate(resolve));
      // }
    }

    store.dispatch(AddMultipleSegmentsAction(coords.map((c) => ({ coord: c }))));
    // actions = [];

    const endTime = performance.now();
    // eslint-disable-next-line no-console
    console.log('[PERFORMANCE TEST] Finished in', endTime - startTime, 'ms.');
  }, []);

  useEffect(() => {
    (window as any).performanceTest = handlePerformanceTest;
  }, [handlePerformanceTest]);

  const handleSaveProject = useCallback((): void => {
    setOpen(false);

    setIsSavingProject();
    if (snackbarLoadingKey) {
      SnackbarUtils.closeSnackbar(snackbarLoadingKey);
      resetSnackbarLoadingKey();
    }

    saveProject();
  }, []);

  const exportMap = useCallback(async (): Promise<void> => {
    if (exportingPng !== false) return;

    if (isRender2Enabled) {
      setExportingPng(0);
      await setImmediateTimeoutPromise();
      const event = new CustomEvent('export-png');
      window.dispatchEvent(event);

      setExportingPng(false);

      return;
    }

    setExportingPng(0);

    // const format: 'png' | 'webp' | 'avif' = webpSupport() ? 'webp' : 'png';
    const format: 'png' | 'webp' | 'avif' = 'png'; // always export in png because it is more convenient for users

    const canvas = canBeUndefined(document.querySelector('[layer="background-lidar"] canvas') as HTMLCanvasElement);
    const pngMap = canvas?.toDataURL(`image/${format}`);

    await setImmediateTimeoutPromise();

    const projectName = PreferencesService.arePreferencesFullyLoaded()
      ? getPreferenceValue('general/projectName')
      : 'unknown';

    if (pngMap && exportImagesEnabled.mapPng && store.getState().local.filters.map) {
      const a = document.createElement('a');
      a.href = pngMap;
      a.download = `${projectName}-map.${format}`;
      a.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

      window.URL.revokeObjectURL(pngMap);
    }

    setExportingPng(1);
    await setImmediateTimeoutPromise();

    if (exportImagesEnabled.mapAndCircuitSvg) {
      const axisElem = d3.select('#root-node-svg [axis]');
      const rootNodeElem = d3.select('#root-node-svg');
      const mousePointerElem = d3.select('#mouseInfoContainer');
      axisElem.style('display', 'none');
      rootNodeElem.style('overflow', 'visible');
      mousePointerElem.style('display', 'none');

      const svgNode = (rootNodeElem.node() as HTMLElement)?.cloneNode(true) as HTMLElement;
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
      const svgEls = Array.from((rootNodeElem.node() as HTMLElement)?.querySelectorAll('*')) as HTMLElement[];
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
      const svgElsShadow = Array.from(svgNode.querySelectorAll('*')) as HTMLElement[];
      svgElsShadow.forEach((el, index) => {
        const computedStyle = getComputedStyle(svgEls[index]);
        for (const rule of computedStyle) {
          el.style[rule] = computedStyle.getPropertyValue(rule);
        }

        return el;
      });

      const imagesSelection = d3.select(svgNode).selectAll('image').nodes();

      if (store.getState().local.filters.mapImage) {
        for await (const el of imagesSelection) {
          if (!el || !(el instanceof SVGImageElement)) {
            // eslint-disable-next-line no-console
            console.error('el is not an SVGImageElement', el);
            if (el && 'remove' in el) el.remove();

            continue;
          }

          const elName = el.getAttribute('name');
          const elDisplayed = store.getState().local.filters.mapImageArray.find((f) => f.name === elName)?.toggle;
          if (!elDisplayed) {
            if (el && 'remove' in el) el.remove();

            continue;
          }

          const res = await fetch(el.href.baseVal);

          const blob = await res.blob();

          const base64String = await blobToBase64(blob);

          d3.select(el).attr('href', base64String);
        }
      }

      if (store.getState().local.filters.map && pngMap) {
        const clonedRoot = d3.select(svgNode); // should be replaced by a shadow dom
        const canvasElem = clonedRoot.select('[layer=background-lidar] canvas');
        const w = canvasElem.attr('width'),
          h = canvasElem.attr('height');
        const top = canvasElem.style('top');
        const canvasStyleWidth = canvasElem.style('width');
        const canvasStyleHeight = canvasElem.style('height');
        canvasElem.remove();
        const x = parseFloat(clonedRoot.select('[layer=background-lidar] foreignObject').attr('x'));
        const y = parseFloat(clonedRoot.select('[layer=background-lidar] foreignObject').attr('y')) + parseFloat(top);
        clonedRoot
          .select('[layer=background-lidar]')
          .append('svg:image')
          .attr('width', w)
          .attr('height', h)
          .attr('x', x)
          .attr('y', y)
          .style('width', canvasStyleWidth)
          .style('height', canvasStyleHeight)
          .attr('href', pngMap);
      }

      await setImmediateTimeoutPromise();

      const svg = new XMLSerializer().serializeToString(svgNode);
      const doctype =
        '<?xml version="1.0" standalone="no"?>' +
        '<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">';
      const svgBlob = new Blob([[doctype, svg].join('')], { type: 'image/svg+xml;charset=utf-8' });
      const svgUrl = window.URL.createObjectURL(svgBlob);

      await setImmediateTimeoutPromise();

      const a2 = document.createElement('a');
      a2.href = svgUrl;
      a2.download = `${projectName}-circuit.svg`;
      a2.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

      window.URL.revokeObjectURL(svgUrl);

      axisElem.style('display', '');
      rootNodeElem.style('overflow', '');
      mousePointerElem.style('display', '');
      rootNodeElem.attr('width', '100%');
      rootNodeElem.attr('height', '100%');
    }

    setExportingPng(2);
    await setImmediateTimeoutPromise();

    if (exportImagesEnabled.mapAndCircuitPng) {
      const axisElem = d3.select('#root-node-svg [axis]');
      const rootNodeElem = d3.select('#root-node-svg');
      const mousePointerElem = d3.select('#mouseInfoContainer');
      axisElem.style('display', '');
      rootNodeElem.style('overflow', '');
      mousePointerElem.style('display', '');
      rootNodeElem.attr('width', '100%');
      rootNodeElem.attr('height', '100%');

      if (canvas) {
        const canvas2 = document.createElement('canvas');
        canvas2.width = canvas.width;
        canvas2.height = canvas.height;
        const ctx2 = canvas2.getContext('2d') as CanvasRenderingContext2D;

        const imgCir = new Image();
        imgCir.addEventListener('load', (): void => {
          ctx2.fillStyle = 'white';
          ctx2.fillRect(0, 0, canvas2.width, canvas2.height);

          const nodeClonedRoot = (rootNodeElem.node() as HTMLElement)?.cloneNode(true) as HTMLElement;

          if (!nodeClonedRoot) {
            // eslint-disable-next-line no-console
            console.error('Cannot find node of the cloned root');

            return;
          }

          const dim = nodeClonedRoot.getBoundingClientRect();
          // const ratio = dim.width / dim.height;
          const scale = Math.min(canvas.width / dim.width, canvas.height / dim.height);
          ctx2.scale(scale, scale);

          imgCir.width = Math.round(dim.width * scale);
          imgCir.height = Math.round(dim.height * scale);

          ctx2.drawImage(
            imgCir,
            Math.floor((canvas2.width - imgCir.width) / 2) / scale,
            (canvas2.height - imgCir.height) / 2 / scale
          );

          const canvas2Url = canvas2.toDataURL('image/png');

          const a3 = document.createElement('a');
          a3.href = canvas2Url;
          a3.download = `${projectName}-map+circuit.png`;
          a3.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

          window.URL.revokeObjectURL(canvas2Url);
        });

        imgCir.setAttribute('crossorigin', 'Anonymous');
        imgCir.src = `data:image/svg+xml;base64,${window.btoa(
          unescape(
            encodeURIComponent(
              new XMLSerializer().serializeToString(
                (rootNodeElem.node() as HTMLElement)?.cloneNode(true) as HTMLElement
              )
            )
          )
        )}`;
      }
    }

    setExportingPng(false);
  }, [exportingPng, isRender2Enabled]);

  const handleSendReport = useCallback(() => {
    setOpen(false);

    throw new Error('Manual Report');
  }, []);

  const propagateCircuitIfInMultiplayer = useCallback(() => {
    if (!multiplayer) return;

    const localCircuitMap = localDoc.getMap('circuit');
    localCircuitMap.set('circuit', store.getState().circuit.present);
    syncYJSLocalToRemote();
  }, [multiplayer]);

  const handleImportXml = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const { files } = event.target;

      if (files && files[0]) {
        const file = files[0];
        const xmlStr = await file.text();

        importCircuitXML(xmlStr).then(() => propagateCircuitIfInMultiplayer());

        setOpen(false);
      } else {
        // eslint-disable-next-line no-console
        console.log('No file selected.');
      }
    },
    [propagateCircuitIfInMultiplayer]
  );
  const inputXmlEl = useRef<HTMLInputElement | null>(null);
  const inputRobotPathEl = useRef<HTMLInputElement | null>(null);

  const handleImportRobotPath = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;

    if (files && files[0]) {
      const file = files[0];
      const reader = new FileReader();

      reader.onload = (e) => {
        const text = e.target?.result as string;

        try {
          importRobotPath(text);

          SnackbarUtils.success('Robot path imported successfully');
        } catch (e) {
          // eslint-disable-next-line no-console
          console.warn(e); // a warning and not an error because it is due to the user input

          let errorMsg: string;
          if (e instanceof Error) {
            errorMsg = `Error importing the robot path - ${e.message}`;
          } else {
            errorMsg = 'Error importing the robot path';

            // eslint-disable-next-line no-console
            console.error('Errow thrown is not an error?');
          }

          SnackbarUtils.error(errorMsg);
        }
      };

      reader.readAsText(file);

      setOpen(false);
    } else {
      // eslint-disable-next-line no-console
      console.log('No file selected.');
    }
  }, []);

  const robotsPaths = useAppSelector((state) => state.robots.robotPaths);

  const handleRemoveRobotsPaths = useCallback(() => {
    dispatch(clearAllRobotPaths());

    SnackbarUtils.success('All robot paths removed');
  }, [dispatch]);

  const handleExportVDA5050 = useCallback(() => {
    downloadVda5050Json();
  }, []);

  const editCircuitPerm = useAsyncMemo(async () => {
    return await checkPermission('edit:circuit');
  }, []);

  return (
    <div>
      <Grid container direction="column" alignItems="center">
        <Grid item xs={12}>
          <ButtonGroup variant="text" color="primary" ref={anchorRef} aria-label="export-button-wrapper">
            <Button
              color="primary"
              size="small"
              variant="text"
              aria-controls={open ? 'split-button-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-label="select export option"
              aria-haspopup="menu"
              onClick={handleToggle}
            >
              <ArrowDropDownIcon />
            </Button>
          </ButtonGroup>
          <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                }}
              >
                <Paper>
                  {exportingPng !== false && (
                    <LinearProgress
                      variant="buffer"
                      value={(exportingPng + 1) * 33}
                      valueBuffer={(exportingPng + 2) * 33}
                      style={{ position: 'absolute', top: '0', left: '0', width: '100%' }}
                    />
                  )}
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList id="split-button-menu">
                      <Tooltip title={!editCircuitPerm ? 'You do not have the permission' : ''}>
                        <Box component="span">
                          <MenuItem
                            onClick={() => handleSaveProject()}
                            disabled={!PreferencesService.arePreferencesFullyLoaded() || !editCircuitPerm}
                          >
                            <ListItemIcon>
                              <SaveIcon />
                            </ListItemIcon>
                            <ListItemText>
                              Save Project <kbd>CTRL+S</kbd>
                            </ListItemText>
                          </MenuItem>
                        </Box>
                      </Tooltip>
                      <Divider />
                      <Tooltip
                        title={
                          isRender2Enabled ? 'Render 2 is enabled, only PNG export functionality is available.' : ''
                        }
                      >
                        <Box component="span">
                          <MenuItem onClick={exportMap} disabled={exportingPng !== false}>
                            <ListItemIcon>
                              <Badge
                                badgeContent={isRender2Enabled ? 1 : nbExportImagesEnabled}
                                anchorOrigin={{
                                  vertical: 'bottom',
                                  horizontal: 'right',
                                }}
                              >
                                <DownloadIcon />
                              </Badge>
                            </ListItemIcon>
                            <ListItemText>Export Circuit (PNG{!isRender2Enabled && '+SVG'})</ListItemText>
                          </MenuItem>
                        </Box>
                      </Tooltip>
                      <Tooltip title={!editCircuitPerm ? 'You do not have the permission' : ''}>
                        <Box component="span">
                          <MenuItem onClick={() => saveHandler(true)} disabled={!editCircuitPerm}>
                            <ListItemIcon>
                              <DownloadIcon />
                            </ListItemIcon>
                            <ListItemText>Export Circuit (GeoJSON)</ListItemText>
                          </MenuItem>
                        </Box>
                      </Tooltip>
                      {import.meta.env.VITE_FEATURE_FLAG_VDA5050 === 'true' && (
                        <Tooltip title={!editCircuitPerm ? 'You do not have the permission' : ''}>
                          <Box component="span">
                            <MenuItem onClick={handleExportVDA5050} disabled={!editCircuitPerm}>
                              <ListItemIcon>
                                <DownloadIcon />
                              </ListItemIcon>
                              <ListItemText>Export VDA5050 Circuit (JSON)</ListItemText>
                            </MenuItem>
                          </Box>
                        </Tooltip>
                      )}
                      <Tooltip title={!editCircuitPerm ? 'You do not have the permission' : ''}>
                        <Box component="span">
                          <MenuItem
                            onClick={() => saveXML()}
                            title="All features of the former XML circuit are not supported by Road Editor (such as the stock zones as well as the racks"
                            disabled={!editCircuitPerm}
                          >
                            <ListItemIcon>
                              <DownloadIcon />
                            </ListItemIcon>
                            <ListItemText>Export Circuit (XML)</ListItemText>
                          </MenuItem>
                        </Box>
                      </Tooltip>

                      <Divider />
                      <ImportCircuit />
                      <Tooltip
                        title={
                          !editCircuitPerm
                            ? 'You do not have the permission'
                            : 'This feature is partially implemented. Only the segments, the zones, the points, stock zones, and some turns will be imported'
                        }
                      >
                        <Box component={'span'}>
                          <MenuItem onClick={() => inputXmlEl.current?.click()} disabled={!editCircuitPerm}>
                            <input
                              accept=".xml"
                              type="file"
                              onChange={handleImportXml}
                              // className={classes.inputLidar}
                              id="import-xml"
                              style={{ display: 'none' }}
                              ref={inputXmlEl}
                            />
                            <ListItemIcon>
                              <ModelTrainingIcon />
                            </ListItemIcon>
                            <ListItemText>Import Standard Circuit (XML)</ListItemText>
                          </MenuItem>
                        </Box>
                      </Tooltip>

                      <Tooltip
                        title={
                          !editCircuitPerm
                            ? 'You do not have the permission'
                            : 'Import a Robot Path of the CSV file format to display it on the playground and help you to draw your circuit'
                        }
                      >
                        <Box component={'span'}>
                          <MenuItem onClick={() => inputRobotPathEl.current?.click()} disabled={!editCircuitPerm}>
                            <input
                              accept=".csv"
                              type="file"
                              onChange={handleImportRobotPath}
                              id="import-csv-robot-path"
                              style={{ display: 'none' }}
                              ref={inputRobotPathEl}
                            />
                            <ListItemIcon>
                              <RouteIcon />
                            </ListItemIcon>
                            <ListItemText>Import Robot Path (CSV)</ListItemText>
                          </MenuItem>
                        </Box>
                      </Tooltip>
                      {robotsPaths?.length ? (
                        <MenuItem onClick={handleRemoveRobotsPaths}>
                          <ListItemIcon>
                            <RemoveRoadIcon />
                          </ListItemIcon>
                          <ListItemText>Remove Robots Paths</ListItemText>
                        </MenuItem>
                      ) : null}
                      <Divider />
                      <Tooltip title="This will send a report to the developers. Please include a description of the issue and the steps to reproduce it.">
                        <MenuItem onClick={() => handleSendReport()}>
                          <ListItemIcon>
                            <BugReportIcon />
                          </ListItemIcon>
                          <ListItemText>Report a bug</ListItemText>
                        </MenuItem>
                      </Tooltip>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Grid>
      </Grid>
    </div>
  );
}

/**
 * Generates a Kiwi/Circuit Editor compatible XML circuit file a trigger a file download in the browser
 * @param zones The zones to include in the XML string
 * @param points The points to include in the XML string
 * @param segments The segments to include in the XML string
 * @param measurers The measurers to include in the XML string
 * @param turns The turns to include in the XML string
 * @param racks The racks to include in the XML string
 * @param pretty Minify the XML if false (P.-S. it looks like Circuit Editor cannot open a minified XML)
 */
async function downloadXML({
  filename,
  zones,
  points,
  segments,
  stockZones,
  measurers,
  turns,
  racks,
  pretty = false,
}: {
  filename: string;
  zones?: CircuitZone[];
  points?: CircuitPoint[];
  segments?: CircuitSegment[];
  stockZones?: CircuitStockZone[];
  measurers?: CircuitMeasurer[];
  turns?: CircuitTurn[];
  racks?: CircuitRack[];
  pretty?: boolean;
}): Promise<void> {
  let xmlStr = '';
  try {
    [xmlStr] = await generateXML({
      zones,
      points,
      segments,
      stockZones,
      measurers,
      turns,
      racks,
      pretty,
      useWorker: false,
    });
  } catch (e) {
    if (e instanceof MissingCellTemplateError) {
      manageMissingCellTemplateError(e);
    } else {
      // eslint-disable-next-line no-console
      console.error(e);

      SnackbarUtils.error(`The generation of the XML file failed. Please contact the support to get help.`);
    }
  }

  if (!xmlStr) return;

  const blob = new Blob([xmlStr], { type: 'application/xml' });

  const a = document.createElement('a');
  const url = window.URL.createObjectURL(blob);
  a.href = url;
  a.download = filename;
  a.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

  window.URL.revokeObjectURL(url);
}
