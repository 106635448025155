import type { Position } from '@turf/helpers';
import { saveMeasurerAction } from 'actions/measurers';
import { rotatePoint } from 'drawings/helpers';
import type { CircuitMeasurer } from 'models/circuit';

/**
 * Processes a selected measurer, applies rotation to its geometry, and dispatches the update action.
 *
 * @param selectedShape - The selected shape (measurer) to process.
 * @param rotateValue - The rotation value to apply to the measurer.
 * @param center - The center position for rotation.
 * @param actions - The array to collect actions to dispatch.
 */
export function processRotateMeasurer(
  selectedShape: CircuitMeasurer,
  rotateValue: number,
  center: Position,
  actions: any[]
): void {
  if (selectedShape.properties.locked) return;

  const newCoords = selectedShape.geometry.coordinates.map((coord) =>
    rotatePoint(center, [coord[0], coord[1]], -rotateValue)
  );

  actions.push(
    saveMeasurerAction({
      ...selectedShape,
      geometry: {
        ...selectedShape.geometry,
        coordinates: newCoords,
      },
    })
  );
}
