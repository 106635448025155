import { DeveloperMode, SystemUpdate } from '@mui/icons-material';
import type { SelectChangeEvent } from '@mui/material';
import { CircularProgress, ListItem, ListItemIcon, ListItemText, MenuItem, Select, Tooltip } from '@mui/material';
import { isDevVersion } from 'index';
import { setBalyoSimulationVersion } from 'project/project';
import { useEffect, useMemo, useState } from 'react';
import { loadProject } from 'services/project';
import { availableBalyoSimulationVersions } from 'simulation/BalyoSimulation/available-balyo-simulation-versions';
import store, { useAppDispatch, useAppSelector } from 'store';
import { theme } from 'utils/mui-theme';
import { isDefined } from 'utils/ts/is-defined';

type BalyoSimulationVersion = (typeof availableBalyoSimulationVersions)[0] & {
  balyoSimulationVersion?: string;
};

export function SelectBalyoSimulation(): JSX.Element {
  const dispatch = useAppDispatch();
  const isDevMode = isDevVersion();
  const availableBalyoSimulationVersionsWithoutDevVersions = useMemo(
    () => availableBalyoSimulationVersions.filter((version) => !version.devOnly || isDevMode),
    [isDevMode]
  );

  const [availableVersions, setAvailableVersions] = useState<BalyoSimulationVersion[]>(
    availableBalyoSimulationVersionsWithoutDevVersions
  );

  const version = useAppSelector((state) => state.project.balyoSimulationVersion);

  const [loadingNewVersion, setLoadingNewVersion] = useState(false);

  const handleChangeVersion = (event: SelectChangeEvent<string>): void => {
    const newVersion = event.target.value;
    if (typeof newVersion !== 'string') {
      // eslint-disable-next-line no-console
      console.error(`Invalid version: ${newVersion}`);

      return;
    }

    setLoadingNewVersion(true);

    dispatch(setBalyoSimulationVersion(newVersion));

    const currentCircuitName = store.getState().project.circuitName;

    setTimeout(async () => {
      await loadProject({
        simulationVersion: newVersion,
        circuitName: currentCircuitName,
      });

      setLoadingNewVersion(false);
    }, 1000);
  };

  useEffect(() => {
    (async () => {
      const versions: (BalyoSimulationVersion | null)[] = structuredClone(
        availableBalyoSimulationVersionsWithoutDevVersions
      );
      const projectName = store.getState().project.projectName;

      for (let i = 0; i < versions.length; i++) {
        const version = versions[i];
        if (!version) continue;

        if (projectName && version.projectNames?.length && !version.projectNames.includes(projectName)) {
          versions[i] = null;
          continue;
        }

        const balyoSimulationVersionPath = (
          await import(`../../../simulation/BalyoSimulation/${version.version}/balyo_simulation_version.txt`)
        ).default as string;

        const balyoSimulationVersion = (await fetch(balyoSimulationVersionPath).then((res) => res.text())).trim();

        version.balyoSimulationVersion = balyoSimulationVersion;
      }

      const authorizedVersion = versions.filter(isDefined);

      setAvailableVersions(authorizedVersion);
    })();
  }, [availableBalyoSimulationVersionsWithoutDevVersions]);

  return (
    <ListItem>
      <ListItemIcon>
        {loadingNewVersion ? <CircularProgress color="inherit" size={20} /> : <SystemUpdate />}
      </ListItemIcon>
      <ListItemText
        primary={
          <Select
            value={version}
            onChange={handleChangeVersion}
            size="small"
            disabled={loadingNewVersion}
            sx={{
              marginBottom: '0.5rem',
            }}
          >
            {availableVersions.map((availableVersion) => (
              <MenuItem key={availableVersion.version} value={availableVersion.version}>
                {availableVersion.version} ({availableVersion.balyoSimulationVersion ?? 'Loading...'}){' '}
                {availableVersion.devOnly && (
                  <Tooltip title="This version is only available in dev mode">
                    <DeveloperMode
                      sx={{
                        marginLeft: theme.spacing(1),
                        fontSize: 'small',
                      }}
                    />
                  </Tooltip>
                )}
              </MenuItem>
            ))}
          </Select>
        }
        secondary={'Select the Balyo simulation version'}
      />
    </ListItem>
  );
}
