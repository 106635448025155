import { Button } from '@mui/material';
import { Stack } from '@mui/system';
import { Border } from 'components/utils/border';
import { useCallback, useState } from 'react';
import { TrafficTools } from './traffic-tools';

interface DisplayTrafficCollapseProps {
  getSimulationData: (force?: boolean) => Promise<void>;
}

export function DisplayTrafficCollapse(props: DisplayTrafficCollapseProps): JSX.Element {
  const [loadingData, setLoadingData] = useState(false);

  const handleClickUpdate = useCallback(async () => {
    setLoadingData(true);

    await props.getSimulationData(true);

    setLoadingData(false);
  }, [props]);

  return (
    <Border>
      <TrafficTools
        loadCircuit={false}
        positioning="element"
        displayCard={false}
        displayLayerGroupSelect={false}
        displayKeepDisplaySwitch={false}
        displayNoStopCantons={false}
        handleMouseForRobotPosition={false}
        trafficOption="displayTraffic"
      />
      <Stack alignItems="center" justifyContent="center">
        <Button onClick={handleClickUpdate} disabled={loadingData} variant="contained">
          Update traffic
        </Button>
      </Stack>
    </Border>
  );
}
