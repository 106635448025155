import AutorenewIcon from '@mui/icons-material/Autorenew';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import type { SelectChangeEvent } from '@mui/material';
import {
  Alert,
  Button,
  ButtonGroup,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Tooltip,
} from '@mui/material';
import { Box } from '@mui/system';
import {
  changeReturnToMoveTool,
  closeDialogAction,
  updateLineStrokeSizeAction,
  updatePointsSizeAction,
  updateSelectStrokeWidthAction,
} from 'actions';
import { setShowPerformances } from 'editor/editor';
import { isSentryReplaysModeEnabled } from 'index';
import { useConfirm } from 'material-ui-confirm';
import type { lineStrokeSize } from 'models/drawings';
import { isReturnToMoveToolType } from 'models/drawings.guard';
import type { SnackbarKey } from 'notistack';
import type { ChangeEvent } from 'react';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ChangeCircuitStandardMode } from 'reducers/local/circuit-standard-mode';
import { ToggleShowDynamicShortcuts } from 'reducers/local/show-dynamic-shortcuts';
import { checkPermission } from 'services/check-permission';
import { SnackbarUtils } from 'services/snackbar.service';
import store, { useAppDispatch, useAppSelector } from 'store';
import { useAsyncMemo } from 'use-async-memo';
import { TRAINING_URL, getConfig, getSettings } from 'utils/config';
import { theme } from 'utils/mui-theme';
import { PreferencesService } from 'utils/preferences';
import { useDetectSentryBlocked } from 'utils/sentry';
import packageJson from '../../../package.json';
import { AnalyticsConsent } from './analytics-consent';
import { PerformanceModeSwitch } from './performance-mode';
import { RenderingEngine } from './rendering-engine';

interface SettingsDialogProps {
  closeDialog?: () => void;
}

export default function SettingsDialog({ closeDialog }: SettingsDialogProps): JSX.Element {
  const dispatch = useAppDispatch();

  const [autoSaveCircuitChange, setAutoSaveCircuitChange] = useState(getSettings('autoSaveCircuit'));
  const [autoSaveCircuitChangeEvery, setAutoSaveCircuitChangeEvery] = useState(getSettings('autoSaveCircuitInterval'));
  const showDynamicShortcuts = useAppSelector((state) => state.local.showDynamicShortcuts);

  const handleClose = useCallback((): void => {
    dispatch(closeDialogAction());
  }, [dispatch]);

  const handleAutoSaveCircuitChange = useCallback((e: React.ChangeEvent<HTMLInputElement>): void => {
    const checked = e.target.checked;
    setAutoSaveCircuitChange(checked);
    localStorage['settings.autoSaveCircuit'] = checked;

    PreferencesService.startAutoSave();
  }, []);

  const handleAutoSaveCircuitChangeEveryChange = useCallback((e): void => {
    const val = parseFloat(e.target.value);
    if (isNaN(val)) return;

    setAutoSaveCircuitChangeEvery(val);
    localStorage['settings.autoSaveCircuitInterval'] = val;

    PreferencesService.startAutoSave();
  }, []);

  const handleShowDynamicShortcutsChange = useCallback((e: React.ChangeEvent<HTMLInputElement>): void => {
    const checked = e.target.checked;

    localStorage['settings.showDynamicShortcuts'] = checked;
    store.dispatch(ToggleShowDynamicShortcuts({ show: checked }));
  }, []);

  const [updateAvailable, setUpdateAvailable] = useState(!!window.updateAvailable && !!window.registrationWorker);
  const [updating, setUpdating] = useState(false);
  const isSentryBlocked = useDetectSentryBlocked();

  const handleUpdateNow = useCallback(() => {
    if (!!window.registrationWorker?.waiting && !!window.registrationWorker) {
      const registration = window.registrationWorker;

      if (registration && registration.waiting) {
        registration.waiting.postMessage({ type: 'SKIP_WAITING' });

        setUpdating(true);
        setTimeout(window.location.reload.bind(window.location), 3000);
      }
    }
  }, []);

  useEffect(() => {
    let intervalId: number | NodeJS.Timeout | undefined;
    if (!updateAvailable && !!window.registrationWorker) {
      window.registrationWorker.update();

      intervalId = setInterval(() => {
        if (!updateAvailable && !!window.registrationWorker?.waiting) {
          setUpdateAvailable(true);
        }
      }, 1000);
    }

    return () => {
      intervalId && clearInterval(intervalId as number);
    };
  }, [updateAvailable]);

  useEffect(() => {
    let intervalId: number | NodeJS.Timeout | undefined;
    if (window.registrationWorker) {
      intervalId = setInterval(() => {
        window?.registrationWorker?.update();
      }, 10000);
    }

    return () => {
      intervalId && clearInterval(intervalId as number);
    };
  }, []);

  const roadEditorVersion = useMemo(() => getConfig('roadEditorVersion') || packageJson.version || undefined, []);

  const editCircuitPerm = useAsyncMemo(async () => {
    return await checkPermission('edit:circuit');
  }, []);

  return (
    <Dialog
      open={true}
      fullWidth={true}
      onClose={handleClose}
      aria-labelledby="settings-dialog-title"
      slotProps={{
        backdrop: {
          'aria-label': 'modal-backdrop',
        },
      }}
    >
      <DialogTitle id="settings-dialog-title">
        Settings
        <Button
          color="primary"
          variant="contained"
          href={TRAINING_URL}
          target="_blank"
          endIcon={<OpenInNewIcon />}
          rel="noopener"
          sx={{
            float: 'right',
            marginLeft: theme.spacing(2),
          }}
        >
          Training
        </Button>
        <Button
          variant="contained"
          href="https://forms.gle/mLqUuMcLB3x2cgXk8"
          target="_blank"
          endIcon={<OpenInNewIcon />}
          rel="noopener"
          color="inherit"
          sx={{
            float: 'right',
            marginLeft: theme.spacing(2),
          }}
        >
          Feedback
        </Button>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>This is where you can change the settings of the Road Editor</DialogContentText>
        <DialogContentText>
          Road Editor Version: {roadEditorVersion}
          {updateAvailable ? (
            <>
              <span style={{ marginLeft: '20px' }}>
                <span style={{ verticalAlign: 'middle' }}>
                  <NotificationImportantIcon />
                </span>
                An update is available
              </span>
              <br />
              <Button
                color="primary"
                startIcon={!updating ? <AutorenewIcon /> : <CircularProgress color="secondary" />}
                onClick={handleUpdateNow}
              >
                Update now
              </Button>
            </>
          ) : undefined}
        </DialogContentText>

        {isSentryBlocked && navigator.onLine && (
          <Alert
            severity="info"
            sx={{
              marginTop: theme.spacing(1),
              marginBottom: theme.spacing(1),
            }}
          >
            It appears that Sentry is is being blocked by your browser. It is a tool that submit us bugs and help us
            identify and fix them. This is likely caused by your ad blocker. Please consider to disabling it on Road
            Editor.
          </Alert>
        )}

        <form style={{ display: 'flex', flexDirection: 'column', rowGap: '12px' }}>
          <FormControl hiddenLabel={true} size="small" fullWidth={true} disabled={!editCircuitPerm}>
            <FormLabel>Save</FormLabel>
            <FormGroup row={true}>
              <FormControlLabel
                labelPlacement={'start'}
                control={
                  <Switch
                    checked={autoSaveCircuitChange}
                    onChange={handleAutoSaveCircuitChange}
                    name="Auto-backup circuit"
                  />
                }
                label="Auto-backup circuit"
              ></FormControlLabel>
              <FormControlLabel
                disabled={!autoSaveCircuitChange}
                style={{ marginLeft: '30px' }}
                control={
                  <TextField
                    type="number"
                    value={autoSaveCircuitChangeEvery}
                    onChange={handleAutoSaveCircuitChangeEveryChange}
                    inputProps={{
                      step: 1,
                      min: 1,
                      max: 24 * 60,
                    }}
                    variant="standard"
                  />
                }
                label="minutes"
              ></FormControlLabel>
              <FormHelperText>
                A snapshot of the circuit is automatically saved. Better to enable this feature.
              </FormHelperText>
            </FormGroup>
          </FormControl>
          <FormControl hiddenLabel={true} size="small" fullWidth={true}>
            <FormLabel>Shortcuts UI</FormLabel>
            <FormGroup row={true}>
              <FormControlLabel
                labelPlacement={'start'}
                control={
                  <Switch
                    checked={showDynamicShortcuts}
                    onChange={handleShowDynamicShortcutsChange}
                    name="Show dynamic shortcuts"
                  />
                }
                label="Show dynamic shortcuts"
              ></FormControlLabel>
              <FormHelperText>The dynamic shortcuts are shown right besides the "Layers" panel.</FormHelperText>
            </FormGroup>
          </FormControl>
          <DrawingSettingsGroup />

          <CircuitModeStandardSelect />

          <RenderingEngine />

          <PerformanceModeSwitch />

          <DebugSection />

          <AnalyticsConsent />
        </form>
      </DialogContent>
    </Dialog>
  );
}

export function DrawingSettingsGroup(): JSX.Element {
  return (
    <FormControl hiddenLabel={true} size="small" fullWidth={true}>
      <FormLabel>Drawing</FormLabel>
      <SizeSelectionInput />
      <LineStrokeSelect />
      <PointsSizeSelect />
      <ReturnToMoveToolSelect />
    </FormControl>
  );
}

function SizeSelectionInput(): JSX.Element {
  const dispatch = useAppDispatch();
  const selectStrokeWidth = useAppSelector((state) => state.tool.selectStrokeWidth);
  const [enabled, setEnabled] = useState(selectStrokeWidth !== -1);

  const [strokeWidthTmp, setStrokeWidthTmp] = useState(selectStrokeWidth.toFixed(0));
  const inputRef = useRef<HTMLInputElement | null>(null);

  const selectStrokeWidthDefault = 20;

  useEffect(() => {
    setStrokeWidthTmp(selectStrokeWidth.toFixed(0));

    setEnabled(selectStrokeWidth !== -1);
  }, [selectStrokeWidth]);

  const handleUpdate = useCallback(() => {
    if (!inputRef.current) {
      // eslint-disable-next-line no-console
      console.warn('No input ref in StrokeWidthInput component');

      return;
    }

    const newVal = parseInt(strokeWidthTmp, 10);
    const input = inputRef.current.querySelector('input');
    const min = input && input.min ? parseInt(input.min, 10) : -1 / 0;

    if (!isNaN(newVal) && (newVal >= min || newVal === -1)) {
      dispatch(updateSelectStrokeWidthAction({ selectStrokeWidth: newVal }));
    } else {
      setStrokeWidthTmp(selectStrokeWidth.toFixed(0));
    }
  }, [dispatch, selectStrokeWidth, strokeWidthTmp]);

  const handleChangeSwitch = useCallback(() => {
    const newEnabled = !enabled;

    setEnabled(newEnabled);

    dispatch(updateSelectStrokeWidthAction({ selectStrokeWidth: newEnabled ? selectStrokeWidthDefault : -1 }));
  }, [dispatch, enabled]);

  return (
    <FormGroup row={true}>
      <FormControlLabel
        labelPlacement={'start'}
        control={<Switch checked={enabled} onChange={handleChangeSwitch} name="Selection Helper" />}
        label="Selection Helper"
      ></FormControlLabel>
      <FormControlLabel
        disabled={!enabled}
        style={{ marginLeft: '30px' }}
        control={
          <TextField
            type="number"
            value={enabled ? strokeWidthTmp : selectStrokeWidthDefault}
            onChange={(e) => {
              setStrokeWidthTmp(e.target.value);
            }}
            onBlur={() => handleUpdate()}
            inputProps={{
              step: 1,
              min: 0,
            }}
            variant="standard"
            ref={inputRef}
          />
        }
        label="px"
      ></FormControlLabel>
      <FormHelperText>Size of the invisible shape around a shape to help you to click on an element.</FormHelperText>
    </FormGroup>
  );
}

function LineStrokeSelect(): JSX.Element {
  const dispatch = useAppDispatch();
  const lineStrokeSize = useAppSelector((state) => state.tool.lineStrokeSize);

  const handleChange = useCallback(
    (e: SelectChangeEvent<lineStrokeSize>) => {
      const newVal = e.target.value;

      dispatch(updateLineStrokeSizeAction({ lineStrokeSize: newVal }));
    },
    [dispatch]
  );

  return (
    <FormControl sx={{ marginTop: '20px' }}>
      <InputLabel id="lines-stroke-settings-label">Lines stroke width</InputLabel>
      <Select
        labelId="lines-stroke-settings-label"
        id="lines-stroke-settings"
        value={lineStrokeSize}
        label="Lines stroke width"
        onChange={handleChange}
      >
        <MenuItem value={'s'}>Small</MenuItem>
        <MenuItem value={'m'}>Medium</MenuItem>
        <MenuItem value={'l'}>Large</MenuItem>
        <MenuItem value={'xl'}>Extra Large</MenuItem>
      </Select>
      <FormHelperText>Width of the lines (segments and turns) on the circuit</FormHelperText>
    </FormControl>
  );
}

function PointsSizeSelect(): JSX.Element {
  const dispatch = useAppDispatch();
  const pointsSize = useAppSelector((state) => state.tool.pointsSize);

  const handleChange = useCallback(
    (e: SelectChangeEvent) => {
      const newVal = e.target.value;

      dispatch(updatePointsSizeAction({ pointsSize: newVal }));
    },
    [dispatch]
  );

  return (
    <FormControl sx={{ marginTop: '20px' }}>
      <InputLabel id="points-size-settings-label">Points Size</InputLabel>
      <Select
        labelId="points-size-settings-label"
        id="points-size-settings"
        value={pointsSize}
        label="Points Size"
        onChange={handleChange}
      >
        <MenuItem value={'small'}>Small</MenuItem>
        <MenuItem value={'medium'}>Medium</MenuItem>
        <MenuItem value={'large'}>Large</MenuItem>
      </Select>
      <FormHelperText>Size of the points displayed on the circuit</FormHelperText>
    </FormControl>
  );
}

function ReturnToMoveToolSelect(): JSX.Element {
  const dispatch = useAppDispatch();
  const returnToMoveTool = useAppSelector((state) => state.tool.returnToMoveTool);

  const handleChange = useCallback(
    (e: SelectChangeEvent) => {
      const newVal = e.target.value;
      if (isReturnToMoveToolType(newVal)) {
        dispatch(changeReturnToMoveTool({ returnToMoveTool: newVal }));
      } else {
        // eslint-disable-next-line no-console
        console.error(`Unknown returnToMoveTool type: ${newVal}`);
      }
    },
    [dispatch]
  );

  return (
    <FormControl sx={{ marginTop: '20px' }}>
      <InputLabel id="return-move-tool-settings-label">Go back to move tool</InputLabel>
      <Select
        labelId="return-move-tool-settings-label"
        id="return-move-tool-settings"
        value={returnToMoveTool}
        label="Go back to move tool"
        onChange={handleChange}
      >
        <MenuItem value={'always'}>Always</MenuItem>
        <MenuItem value={'never'}>Never</MenuItem>
        <MenuItem value={'sometimes'}>Sometimes (after drawing a rack, a stock zone, or a shape)</MenuItem>
      </Select>
      <FormHelperText>
        Choose if the <em>Move Tool</em> has to be automatically selected after drawing a new shape
      </FormHelperText>
    </FormControl>
  );
}

function CircuitModeStandardSelect(): JSX.Element {
  const dispatch = useAppDispatch();
  const confirm = useConfirm();
  const standardMode = useAppSelector((state) => state.local.standardMode);

  const handleClick = useCallback(
    async (newState: boolean) => {
      if (!newState) {
        // switching to preview mode may cause issues, we need to acknowledge the user
        try {
          await confirm({
            title: 'Switching to preview mode / Risk of not being compatible with the current project code ',
            description:
              'Enabling the preview mode may cause the lost of circuit compatibility with the project code. Moreover, it may not be possible to go back to a working version of the standard mode noly by switching the settings mode. The designer of the circuit take the responsability to modify manually the circuit until a compatible circuit is reached.',
            allowClose: false,
          });
        } catch (e) {
          return;
        }
      }

      dispatch(ChangeCircuitStandardMode({ newMode: newState }));
    },
    [confirm, dispatch]
  );

  return (
    <FormControl hiddenLabel={true} size="small" fullWidth={true}>
      <FormLabel>Circuit standard mode</FormLabel>

      <Box sx={{ textAlign: 'center' }} component="div">
        <ButtonGroup>
          <Tooltip title="Restricted functionnalities of Road Editor only that are compatible with the current project code. Use the XML circuit.">
            <Button variant={standardMode ? 'contained' : 'outlined'} onClick={() => handleClick(true)}>
              Standard
            </Button>
          </Tooltip>
          <Tooltip title="Use all the functionnalities available in Road Editor">
            <Button variant={standardMode ? 'outlined' : 'contained'} onClick={() => handleClick(false)}>
              Preview
            </Button>
          </Tooltip>
        </ButtonGroup>
      </Box>

      <FormHelperText>
        The standard mode exports the circuit in a former format (XML) in addition to the new Road Editor format. Unless
        advised, always use the standard mode
      </FormHelperText>
    </FormControl>
  );
}

function DebugSection(): JSX.Element {
  const displayShowPerformancesSwitch = import.meta.env.VITE_SHOW_PERFORMANCE === 'true';

  const showPerformances = useAppSelector((state) => state.editor.isShowPerformancesEnabled);
  const dispatch = useAppDispatch();

  const handleChangeShowPerformances = useCallback(
    (e: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      dispatch(
        setShowPerformances({
          showPerformances: checked,
        })
      );
    },
    [dispatch]
  );

  const [sentryReplaysModeEnabled, setSentryReplaysModeEnabled] = useState(isSentryReplaysModeEnabled());
  const snackBarRef = useRef<SnackbarKey | undefined>(undefined);

  const handleChangeReplaysMode = useCallback((e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    localStorage.setItem('sentryReplays', checked ? 'enabled' : 'disabled');

    const newState = isSentryReplaysModeEnabled();
    setSentryReplaysModeEnabled(newState);

    if (newState === checked) {
      if (snackBarRef.current) {
        SnackbarUtils.closeSnackbar(snackBarRef.current);
      }

      snackBarRef.current = SnackbarUtils.toast(
        <Box component="span">
          The replays mode is now <strong>{newState ? 'enabled' : 'disabled'}</strong>.<br />
          You need to reload Road Editor to apply the changes.
        </Box>,
        {
          variant: newState ? 'success' : undefined,
          autoHideDuration: 5000,
        }
      );
    } else {
      const msg = 'The replays mode has not been updated.';
      SnackbarUtils.error(msg);

      // eslint-disable-next-line no-console
      console.error(msg);
    }
  }, []);

  const isForcedEnable = sentryReplaysModeEnabled && localStorage.sentryReplays !== 'enabled';

  return (
    <FormControl hiddenLabel={true} size="small" fullWidth={true} sx={{ marginTop: theme.spacing(1) }}>
      <FormLabel>Debug</FormLabel>

      {displayShowPerformancesSwitch && (
        <Box sx={{ paddingBottom: 1 }} component="div">
          <FormControlLabel
            labelPlacement={'start'}
            control={
              <Switch checked={showPerformances} onChange={handleChangeShowPerformances} name="Show performances" />
            }
            label="Show performances"
            sx={{ flexDirection: 'row' }}
          ></FormControlLabel>

          <FormHelperText>
            This mode will show performances at the bottom right of the screen but may cause lag, enable it for testing
            purpose only. (Frame per seconds, Delay in ms and Memory usage in mb)
          </FormHelperText>
        </Box>
      )}

      <FormControlLabel
        labelPlacement={'start'}
        control={<Switch checked={sentryReplaysModeEnabled} onChange={handleChangeReplaysMode} name="Replays Mode" />}
        label="Replays Mode"
        disabled={isForcedEnable}
        sx={{ flexDirection: 'row' }}
      ></FormControlLabel>

      <FormHelperText>
        Enable this mode to allow developers to see your screen before a crash. This will help developers to fix bugs.
        This mode is disabled by default.
      </FormHelperText>

      {isForcedEnable && (
        <Alert severity="info">
          The replays mode is forced to be enabled by the developer in your current configuration.
        </Alert>
      )}
    </FormControl>
  );
}
