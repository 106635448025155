import * as Comlink from 'comlink';
import { generateXMLWorker } from './generate-xml-utils.ts';

let worker: Worker | null = null;
let proxy: Comlink.Remote<{ generateXMLWorker: typeof generateXMLWorker }> | null = null;

if (!window.__TEST__) {
  const importWorker = async (): Promise<void> => {
    const workerModule = await import('./generate-xml-utils.ts?worker');
    worker = new workerModule.default({
      name: `generate-xml-worker-${Date.now()}`,
    });
    proxy = Comlink.wrap<{ generateXMLWorker: typeof generateXMLWorker }>(worker);
  };

  void importWorker();
}

/**
 * Safely gets the XML worker proxy instance
 * @throws Error if worker proxy is not available
 */
export const getGenerateXmlWorkerProxy = (): Comlink.Remote<{ generateXMLWorker: typeof generateXMLWorker }> => {
  if (!proxy) {
    throw new Error('XML worker proxy not initialized');
  }

  return proxy;
};

// Export the worker proxy
export const generateXmlWorkerProxy = proxy;
export const terminateGenerateXmlWorker = (): void => worker?.terminate();

// Export the worker object for Comlink
Comlink.expose({ generateXMLWorker });
