import { useDebouncedValue } from '@mantine/hooks';
import type { SelectChangeEvent } from '@mui/material';
import { Collapse, FormControl, InputLabel, ListItemIcon, ListItemText, MenuItem, Select } from '@mui/material';
import { Stack } from '@mui/system';
import { collapseTransitionDuration } from 'components/utils/constants';
import { capitalize } from 'lodash';
import type { GeneralTrigger, Trigger, TriggerType } from 'models/simulation';
import { useCallback } from 'react';
import { defaultIntervalTriggerInterval, defaultSizeTriggerBuffer, setTrigger } from 'simulation/triggers';
import store, { useAppDispatch, useAppSelector } from 'store';
import { theme } from 'utils/mui-theme';
import { getTriggerTypeIcon } from '../../simulation/triggers-list';
import { BufferTriggerConfiguration } from './buffer-triggers';
import { GeneralTriggerConfiguration } from './general-triggers';
import { IntervalTriggerConfiguration } from './interval-triggers';
import { ReplayTriggerConfiguration } from './replay-triggers';

const availableTriggers: TriggerType[] = ['interval', 'buffer', 'replay'] as const;

export function TriggerConfiguration(): JSX.Element {
  const selectedTriggerId = useAppSelector((state) => state.triggers.selectedTriggerId);
  const [selectedTriggerIdDelayed] = useDebouncedValue(selectedTriggerId, collapseTransitionDuration);

  const triggers = useAppSelector((state) => state.triggers.triggers);

  const dispatch = useAppDispatch();

  const handleChangeTriggerType = useCallback(
    (e: SelectChangeEvent<string>) => {
      const triggerType = e.target.value as TriggerType;
      const trigger = triggers.find((tr) => tr.id === selectedTriggerId);
      if (!trigger) {
        return;
      }

      const defaultFlowId = store.getState().flows.flows[0]?.id;

      const newTriggerGeneral: GeneralTrigger = {
        type: triggerType,
        enabled: trigger.enabled,
        id: trigger.id,
        name: trigger.name,
      };
      let newTrigger: Trigger | undefined = undefined;
      if (newTriggerGeneral.type === 'interval') {
        newTrigger = {
          ...newTriggerGeneral,
          type: 'interval',
          flowId: 'flowId' in trigger ? trigger.flowId : defaultFlowId,
          interval: defaultIntervalTriggerInterval,
        };
      } else if (newTriggerGeneral.type === 'buffer') {
        const flowId =
          'flowId' in trigger
            ? typeof trigger.flowId === 'string'
              ? trigger.flowId
              : trigger.flowId[0]
            : defaultFlowId;

        newTrigger = {
          ...newTriggerGeneral,
          type: 'buffer',
          flowId,
          size: defaultSizeTriggerBuffer,
          bufferForFlowOnly: true,
        };
      } else if (newTriggerGeneral.type === 'replay') {
        newTrigger = {
          ...newTriggerGeneral,
          type: 'replay',
          tasks: [],
        };
      }

      if (!newTrigger) {
        // eslint-disable-next-line no-console
        console.error(`Unknown trigger type "${newTriggerGeneral.type}"`);

        return;
      }

      dispatch(setTrigger(newTrigger));
    },
    [dispatch, selectedTriggerId, triggers]
  );

  const trigger = (selectedTriggerId ? triggers.find((tr) => tr.id === selectedTriggerId) : undefined) ?? undefined;
  const triggerDelayed =
    (selectedTriggerIdDelayed ? triggers.find((tr) => tr.id === selectedTriggerIdDelayed) : undefined) ?? undefined;

  if (!trigger) {
    return <></>;
  }

  const displayTriggerData = selectedTriggerId === selectedTriggerIdDelayed;

  const triggerToUse = !displayTriggerData && triggerDelayed ? triggerDelayed : trigger;
  const triggerType = triggerToUse.type;

  return (
    <Collapse
      in={displayTriggerData}
      timeout={selectedTriggerIdDelayed ? collapseTransitionDuration : 0}
      sx={{
        marginTop: theme.spacing(2),
      }}
    >
      <Stack direction={'column'} spacing={1}>
        <FormControl fullWidth size="small" sx={{ marginTop: theme.spacing(1) }}>
          <InputLabel id="select-trigger-type">Trigger Type</InputLabel>
          <Select
            labelId="select-trigger-type"
            label="Trigger Type"
            value={triggerType}
            size="small"
            renderValue={(triggerType) => {
              return capitalize(triggerType);
            }}
            onChange={handleChangeTriggerType}
            disabled={trigger.linked}
          >
            {availableTriggers.map((triggerType) => {
              const triggerTypeIcon = getTriggerTypeIcon(triggerType);

              return (
                <MenuItem key={triggerType} value={triggerType}>
                  <ListItemIcon>{triggerTypeIcon}</ListItemIcon>
                  <ListItemText>{capitalize(triggerType)}</ListItemText>
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        {triggerType === 'interval' && <IntervalTriggerConfiguration key={triggerToUse.id} trigger={triggerToUse} />}
        {triggerType === 'buffer' && <BufferTriggerConfiguration key={triggerToUse.id} trigger={triggerToUse} />}
        {triggerType === 'replay' && <ReplayTriggerConfiguration key={triggerToUse.id} trigger={triggerToUse} />}

        <GeneralTriggerConfiguration trigger={triggerToUse} />
      </Stack>
    </Collapse>
  );
}
