import type { Position } from '@turf/helpers';
import { saveDeviceAction } from 'actions/devices';
import { rotatePoint } from 'drawings/helpers';
import type { CircuitDevice } from 'models/circuit';

/**
 * Processes a selected device, applies rotation, and updates its properties.
 *
 * @param device - The selected device shape to process.
 * @param center - The central position for rotation.
 * @param rotateValue - The value by which to rotate the device.
 * @param actions - The array of actions to which the updated device will be pushed.
 */
export function processRotateDevice(
  device: CircuitDevice,
  centerSelectedShapes: Position,
  rotateValue: number,
  actions: any[]
): void {
  if (!device) return;

  if (device.properties.locked) return;

  const newCoords = rotatePoint(
    centerSelectedShapes,
    device.geometry.coordinates.map((c) => c),
    -rotateValue
  );

  actions.push(
    saveDeviceAction({
      ...device,
      geometry: {
        ...device.geometry,
        coordinates: newCoords,
      },
    })
  );
}
