import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import HideImageIcon from '@mui/icons-material/HideImage';
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import type { TooltipProps } from '@mui/material';
import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Input,
  InputAdornment,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
  styled,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  tooltipClasses,
} from '@mui/material';
import { Box } from '@mui/system';
import { saveCircuitToHistoryAction } from 'actions/circuit';
import { saveNoteAction } from 'actions/notes';
import { generateCircuitImageData } from 'components/utils/import-image';
import { ShapeTypes } from 'models/circuit';
import { isNoteDisplayMode } from 'models/circuit.guard';
import { useCallback, useEffect, useRef, useState } from 'react';
import type { LoadedNote } from 'reducers/circuit/state';
import { removeCircuitImage } from 'reducers/images/images';
import { useAppDispatch, useAppSelector } from 'store';
import { useDebouncedCallback } from 'use-debounce';
import { convertNoteSizeToPx } from 'utils/circuit/note';
import { theme } from 'utils/mui-theme';
import { ACCEPTED_IMAGE_FORMATS } from '../../utils/image';
import { PropertiesComponent } from './properties-component';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const NoMaxWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 'none',
    backgroundColor: 'transparent',
  },
});

interface NotePropertiesProps {
  noteId: string;
}

export const NoteProperties = ({ noteId }: NotePropertiesProps): JSX.Element => {
  const note = useAppSelector((state) => state.circuit.present.notes.entities[noteId]) as LoadedNote | undefined;
  const dispatch = useAppDispatch();

  const [noteName, setNoteName] = useState(note?.properties.name || '');

  const updatePropertiesDebounced = useDebouncedCallback(() => {
    if (!note) return;

    const name = noteName;
    dispatch(saveCircuitToHistoryAction());
    dispatch(
      saveNoteAction({
        id: note.id,
        properties: {
          ...note.properties,
          name,
        },
      })
    );
  }, 400);

  const onNameChangeHandler = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newName = e.target.value;
      if (newName?.length < 1000) {
        setNoteName(newName);
      }

      updatePropertiesDebounced();
    },
    [updatePropertiesDebounced]
  );

  const locked = !!note?.properties?.locked;

  useEffect(() => {
    if (note?.properties.name) setNoteName(note.properties.name);
  }, [note?.properties.name]);

  const displayMode = note?.properties.displayMode;

  const handleChange = useCallback(
    (event: React.MouseEvent<HTMLElement>, newDisplay: string) => {
      if (!note || !note.id) {
        throw new Error('note must be defined');
      }

      if (!isNoteDisplayMode(newDisplay)) {
        // eslint-disable-next-line no-console
        console.error(`Wrong type for the note display mode: ${newDisplay}`);

        return;
      }

      const size = 20;

      dispatch(
        saveNoteAction({
          id: note.id,
          properties: { ...note.properties, size: size, displayMode: newDisplay },
        })
      );
    },
    [dispatch, note]
  );

  const textSize = note?.properties.size;

  const handleNoteSize = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!note || !note.id) {
        throw new Error('note must be defined');
      }

      const newSizeInPx = parseInt((event.target as HTMLInputElement).value, 10);

      if (isNaN(newSizeInPx)) {
        throw new Error(`Wrong value for the new size in px: ${newSizeInPx}`);
      }

      dispatch(
        saveNoteAction({
          id: note.id,
          properties: { ...note.properties, size: newSizeInPx },
        })
      );
    },
    [dispatch, note]
  );

  return !noteId || !note ? (
    <></>
  ) : (
    <PropertiesComponent
      shape={note}
      shapeId={noteId}
      shapeType={ShapeTypes.NoteShape}
      sx={{
        maxWidth: 'min-content',
      }}
    >
      <ToggleButtonGroup
        sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', my: 2 }}
        color="primary"
        exclusive
        value={displayMode}
        onChange={handleChange}
        aria-label="Platform"
      >
        <ToggleButton sx={{ textTransform: 'capitalize' }} value={'icon'}>
          Icon
        </ToggleButton>
        <ToggleButton sx={{ textTransform: 'capitalize' }} value={'text'}>
          Text
        </ToggleButton>
        <ToggleButton sx={{ textTransform: 'capitalize' }} disabled={!note.properties.imageId} value={'image'}>
          Image
        </ToggleButton>
      </ToggleButtonGroup>
      <Box component="div" sx={{ flexDirection: 'column' }}>
        <Collapse in={displayMode === 'text'} timeout="auto">
          <FormControl>
            <Divider sx={{ marginBottom: '4px' }}>Text size</Divider>
            <RadioGroup
              value={textSize}
              onChange={handleNoteSize}
              sx={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)' }}
            >
              <FormControlLabel value={convertNoteSizeToPx('S')} control={<Radio size="small" />} label="S" />
              <FormControlLabel value={convertNoteSizeToPx('M')} control={<Radio size="small" />} label="M" />
              <FormControlLabel value={convertNoteSizeToPx('L')} control={<Radio size="small" />} label="L" />
              <FormControlLabel value={convertNoteSizeToPx('XL')} control={<Radio size="small" />} label="XL" />
            </RadioGroup>
          </FormControl>
        </Collapse>
        <Collapse in={displayMode === 'image'} timeout="auto">
          <Divider variant="middle">Image size</Divider>
          <FormControl variant="standard" sx={{ m: 1, width: '25ch' }}>
            <Input
              id="standard-adornment"
              type="number"
              endAdornment={<InputAdornment position="end">cm</InputAdornment>}
              value={textSize}
              onChange={handleNoteSize}
              inputProps={{
                'aria-label': 'cm',
              }}
            />
            <FormHelperText id="standard-helper-text">Size</FormHelperText>
          </FormControl>
        </Collapse>
      </Box>
      <TextField
        value={noteName}
        onChange={onNameChangeHandler}
        fullWidth
        margin="dense"
        multiline
        rows={4}
        label={<>Note</>}
        disabled={locked}
        size="small"
        sx={{
          color: locked ? theme.palette.grey[300] : undefined,
          pointerEvents: locked ? 'none' : undefined,
        }}
      />
      {note.properties.imageId && <NoteImage note={note} />}
      <AddImage note={note} />
    </PropertiesComponent>
  );
};

interface ChooseImageDialogProps {
  open: boolean;
  images: { id: string; url: string; name: string }[];
  onClose: () => void;
  onSelectImage: (imageUrl: string) => void;
}

const ChooseImageDialog: React.FC<ChooseImageDialogProps> = ({ open, images, onClose, onSelectImage }) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md">
      <DialogTitle>Select an Image</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          {images.map((image) => (
            <Grid item xs={6} sm={4} md={3} key={image.id}>
              <Card onClick={() => onSelectImage(image.id)}>
                <CardMedia component="img" image={image.url} alt={image.name} height="140" sx={{ cursor: 'pointer' }} />
                <CardContent>
                  <ListItemText primary={image.name.split('_')[0]} />
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

interface AddImageProps {
  note: LoadedNote;
}
function AddImage(props: AddImageProps): JSX.Element {
  const { note } = props;
  const notes = useAppSelector((state) => state.circuit.present.notes.entities);
  const dispatch = useAppDispatch();
  const locked = !!note?.properties?.locked;

  // Image note
  const circuitImages = useAppSelector((state) => state.images.circuitImages);
  const attachedImage = circuitImages.find((image) => image.id === note.properties.imageId);
  const fileInputRef = useRef<HTMLInputElement>(null);

  // choose image dialog
  const [dialogOpen, setDialogOpen] = useState(false);

  //Menu add image
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleAddImage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!note) return;

      const file = event.target.files?.[0];
      if (file) {
        const imageId = generateCircuitImageData(file);

        dispatch(
          saveNoteAction({
            id: note.id,
            properties: {
              ...note.properties,
              imageId: imageId,
            },
          })
        );
      }

      handleClose();
    },
    [dispatch, handleClose, note]
  );

  const handleDeleteImage = useCallback(() => {
    if (!note) return;

    if (attachedImage) {
      if (
        !Object.values(notes).some(({ id, properties }) => id !== note.id && properties.imageId === attachedImage.id)
      ) {
        dispatch(removeCircuitImage({ imageId: attachedImage.id }));
      }

      dispatch(
        saveNoteAction({
          id: note.id,
          properties: {
            ...note.properties,
            displayMode: 'icon',
            imageId: undefined,
          },
        })
      );
    }
  }, [attachedImage, dispatch, note, notes]);

  const openFileDialog = useCallback(() => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  }, [fileInputRef]);

  const handleOpenDialog = useCallback(() => {
    setDialogOpen(true);
    setAnchorEl(null);
  }, []);

  const handleCloseDialog = useCallback(() => {
    setDialogOpen(false);
  }, []);

  const handleImageSelect = useCallback(
    (imageId: string) => {
      setDialogOpen(false);
      dispatch(
        saveNoteAction({
          id: note.id,
          properties: {
            ...note.properties,
            imageId: imageId,
          },
        })
      );
    },
    [dispatch, note.id, note.properties]
  );

  return (
    <Box component="div">
      {attachedImage ? (
        <Button
          size="small"
          startIcon={<HideImageIcon />}
          onClick={() => {
            handleDeleteImage();
          }}
          color="secondary"
          disabled={locked}
        >
          Delete image
        </Button>
      ) : (
        <div>
          <Button
            id="basic-button"
            onClick={handleClick}
            startIcon={<AddPhotoAlternateIcon />}
            size="small"
            disabled={locked}
          >
            Attach an image
          </Button>
          <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
            <MenuItem onClick={handleOpenDialog} disabled={circuitImages.length === 0}>
              <ListItemIcon>
                <ImageSearchIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Choose existing image</ListItemText>
            </MenuItem>
            <MenuItem onClick={openFileDialog}>
              <ListItemIcon>
                <AddPhotoAlternateIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Import image</ListItemText>
              <VisuallyHiddenInput
                type="file"
                accept={ACCEPTED_IMAGE_FORMATS}
                onChange={handleAddImage}
                style={{ display: 'none' }}
                ref={fileInputRef}
              />
            </MenuItem>
          </Menu>

          <ChooseImageDialog
            open={dialogOpen}
            images={circuitImages}
            onClose={handleCloseDialog}
            onSelectImage={handleImageSelect}
          />
        </div>
      )}
    </Box>
  );
}

interface NoteImageProps {
  note: LoadedNote;
}
function NoteImage(props: NoteImageProps): JSX.Element {
  const [showPreview, setShowPreview] = useState(false);

  // Image note
  const images = useAppSelector((state) => state.images.circuitImages);
  const attachedImage = images.find((image) => image.id === props.note.properties.imageId);

  const handleMouseEnter = useCallback(() => {
    setShowPreview(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setShowPreview(false);
  }, []);

  const handleImageClick = useCallback(() => {
    if (attachedImage?.url) {
      window.open(attachedImage.url, '_blank');
    }
  }, [attachedImage]);

  if (!attachedImage?.url) {
    return <></>;
  }

  return (
    <div style={{ position: 'relative' }}>
      <NoMaxWidthTooltip
        title={
          <img src={attachedImage?.url} alt={attachedImage?.name} style={{ borderRadius: '8px', maxWidth: '1500px' }} />
        }
        open={showPreview}
        onOpen={handleMouseEnter}
        onClose={handleMouseLeave}
        followCursor
        placement="left"
        arrow
      >
        <img
          src={attachedImage?.url}
          alt={attachedImage?.name}
          onClick={handleImageClick}
          style={{ maxWidth: '400px', height: 'auto', borderRadius: '4px' }}
        />
      </NoMaxWidthTooltip>
    </div>
  );
}
