/* eslint-disable react-hooks/exhaustive-deps */
import { Alert, AlertTitle, Button, Dialog, DialogActions, DialogContent, Stack } from '@mui/material';
import { closeDialogAction } from 'actions';
import { createCellTemplateSuccessAction, deleteCellTemplateSuccessAction } from 'actions/cell-templates';
import { askUpdateRackAction } from 'actions/circuit';
import { saveRackAction } from 'actions/racks';
import * as d3 from 'd3';
import { addPositionToStations, removePositionFromStations } from 'flows/flows';
import { useConfirm } from 'material-ui-confirm';
import type { CellAnomaly, Pallet, RackCell, RackColumn, RackUpright, ShapeTypes, SlotTypes } from 'models/circuit';
import type { SnackbarKey } from 'notistack';
import { useCallback, useEffect, useMemo, useRef, useState, useTransition } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import type { LoadedRack } from 'reducers/circuit/state';
import { SnackbarUtils } from 'services/snackbar.service';
import store, { useAppDispatch, useAppSelector } from 'store';
import useUndoable from 'use-undoable';
import { areAllShapeNamesUnique } from 'utils/circuit/are-shape-names-unique';
import { getDefaultConveyorCellTemplate, getDefaultRackCellTemplate } from 'utils/circuit/default-circuit-shapes';
import { generateShapeId } from 'utils/circuit/next-free-id';
import { checkUnicityRackPositionsName, computeRackCoordinates } from 'utils/circuit/racks';
import type { ConvertCellPositionToNameOptions } from 'utils/circuit/racks-naming';
import {
  convertCellPositionToName,
  generatePositionNames,
  isPositionNameNotAlreadyUsed,
  isValidPositionCellName,
} from 'utils/circuit/racks-naming';
import type { BlockDataPositionName } from 'utils/circuit/racks-naming.model';
import { isDefined } from 'utils/ts/is-defined';
import { RackEditionTopBar, zoomRackEdition } from '.';
import { RackEditionCellTemplate } from './rack-edition-celltemplate';
import { RackEditionRackView } from './rack-edition-rackview';

const epsilon = 1e-5;
const nbMaxIter = 1000;

interface SettingsDialogProps {
  zoomToCellId?: string;
}

/**
 * actions to update the state of the racks (NOT Redux actions)
 * it is used to update the preview and the state of the rack inside the RackEditionDialog
 * A Redux action has to be dispatched to actually update the Redux store
 */
export interface ActionsRack {
  /** function to call to update several columns at once */
  updateColumns: (newColumns: RackColumn[]) => void;
  /** function to call to update several uprights at once */
  updateUprights: (uprightsToUpdate: { newUpright: RackUpright; uprightIndex: number }[]) => void;
  /** function to call to update a cell template */
  changeRackEditionMenu: (newEditCellTemplate: null | string) => void;
  /** function to call to delete a certain column in the rack */
  deleteColumn: (columnIndex: number) => void;
  /** function to call to add a column in the rack
   * the column will be copied from the column columnIndex and will be inserted at the columnIndex postion
   * it also add an upright in the same manner
   */
  addColumn: (columnIndex: number) => void;
  /** function to call to change which cell template we will assign next */
  selectCellTemplateToAssign: (cellTemplateId: string | null) => void;
  /** function to call when a cell has been clicked */
  cellClick: (cell: RackCell, column: RackColumn, e?: MouseEvent) => void;
  /** function to call to update a cell template */
  cellTemplateChange: (newValue: boolean) => void;
  /** function to change the number of columns */
  updateNbColumns: (newNbColumns: number) => void;

  /** function to call to update the rack */
  updateRack: (newRack: LoadedRack) => void;
  /** function to compute the position of the columns */
  computeColumnsPosition: (newColumns: RackColumn[], newUprights?: RackUpright[]) => RackColumn[];
  /** function to toggle if we display the name of the positions or not */
  toggleDisplayPositionNames: () => void;
  /** apply a value to all the columns that have at least one selected cell */
  applyColumnValueToAllSelectedColumns: (
    columnValue: Partial<RackColumn>,
    linkedPropertiesToChange: Partial<RackColumn['linkedProperties']>
  ) => void;
  /** apply a value to all the selected cells */
  applyCellValueToAllSelectedCells: (rackCellNewValues: Partial<RackCell>) => void;
  /** change the name of a cell */
  changeCellName: (cellId: string, newNames: RackCell['names'][0], loadIndex: number) => boolean;
  /* toggle disabled property of a cell */
  toggleCellDisabled: (cellId: string, newNames: RackCell['names'][0], loadIndex: number) => boolean;
  /** generate and assign a name (string) to cells from a rule */
  assignNameToCells: (
    assignTo: 'selection' | 'all',
    rules: BlockDataPositionName[] | null,
    applyToAllLoads?: boolean
  ) => void;

  /**
   * Alter the rack to be a mirror of itself
   * The first column will be the last one, the second one will be the second to last one, etc.
   */
  mirrorRack: () => void;

  validateRack: (close?: boolean) => void;
}

export interface CellAnomaliesDict {
  [k: string]: CellAnomaly[];
}

export type StationPositions = {
  selectedStation: string;
  id: string;
  type: ShapeTypes.PointShape | ShapeTypes.StockZoneShape | ShapeTypes.RackShape | SlotTypes;
};

export type SlotName = {
  id: string;
  value: string;
  user: boolean;
  disabled?: boolean | undefined;
};

export default function RackEditionDialog({ zoomToCellId }: SettingsDialogProps): JSX.Element {
  const dispatch = useAppDispatch();
  const confirm = useConfirm();

  const rackId = useAppSelector((state) => state.local.selectedShapesData)[0].id;
  const rackFromStore = useAppSelector((state) => state.circuit.present.racks.entities[rackId]);

  const [rack, setRack, { undo: undoRack, redo: redoRack, canRedo, canUndo, reset: resetRackHistory, past: rackPast }] =
    useUndoable(rackFromStore, { behavior: 'destroyFuture', historyLimit: 50 });

  // https://developer.mozilla.org/fr/docs/Web/API/Performance/memory
  const preventOutOfMemory = (): void => {
    if (!('memory' in performance)) return;

    const memory = performance.memory as { totalJSHeapSize: number; jsHeapSizeLimit: number };
    const usedTotal = memory.totalJSHeapSize / 1048576; // 1MB = 1048576 bytes
    const limit = memory.jsHeapSizeLimit / 1048576; // 1MB = 1048576 bytes
    const percentage = (usedTotal / limit) * 100;

    if (percentage > 80) {
      // eslint-disable-next-line no-console
      console.warn('Memory usage is high', usedTotal, limit, percentage);
      SnackbarUtils.warning('Memory usage is high, the undo/redo history will remove the earliest state');

      rackPast.splice(0, 1);
    }
  };

  useEffect(() => {
    const interval = setInterval(preventOutOfMemory, 5000);

    return () => clearInterval(interval); // Cleanup on unmount
  }, []);

  const cellTemplatesIds = useAppSelector((state) => state.circuit.present.cellTemplates.ids);
  const cellTemplates = useAppSelector((state) => state.circuit.present.cellTemplates.entities);
  const [editCellTemplate, setEditCellTemplate] = useState<null | string>(null); // null = rack edition, template id = cell template edition of the related cell template
  const [selectedCellTemplate, setSelectedCellTemplate] = useState<null | string>(null); // null = no cell template selected, template id = cell template selected
  const columns = rack.properties.columns;
  const uprights = rack.properties.uprights;
  const racks = useAppSelector((state) => state.circuit.present.racks.entities);

  const isConveyor = !!rack.properties.conveyor;

  const [reactRackPending, startTransition] = useTransition();

  const [cellTemplateChanged, setCellTemplateChanged] = useState(false);

  /**
   * Array of coordinates of the selected cell ([x, y])[]
   */
  const [selectedCells, setSelectedCells] = useState<number[][]>([]);
  const [displayPositionNames, setDisplayPositionNames] = useState(true);

  /** the names of all the cells in this rack */
  const cellsNames = useRef<Record<string, string[]>>({});

  const autoZoomToForm = useRef(!zoomToCellId);

  const undoSnackBarKey = useRef<SnackbarKey | undefined>(undefined);

  const stations = useAppSelector((state) => state.flows.stations);

  const [stationPositions, setStationPositions] = useState<StationPositions[]>([]);

  const [userRemovedStationPositions, setUserRemovedStationPositions] = useState(false);

  // Reset the rack history when opening the dialog
  useEffect(() => {
    resetRackHistory(rack);
  }, []);

  useHotkeys(
    'Ctrl+z',
    (event, hotkeysEvent): void => {
      event.preventDefault();
      // github link to the issue https://github.com/JohannesKlauss/react-hotkeys-hook/issues/1040
      if (event.key.toLowerCase() !== 'z' || editCellTemplate) return;
      if (undoSnackBarKey?.current) SnackbarUtils.closeSnackbar(undoSnackBarKey.current);

      if (!canUndo) {
        undoSnackBarKey.current = SnackbarUtils.toast('Rack undo history is empty', {
          variant: 'info',
          autoHideDuration: 1000,
        });

        return;
      }

      undoRack();
      undoSnackBarKey.current = SnackbarUtils.toast('Undo changes in rack', {
        variant: 'success',
        autoHideDuration: 1000,
      });
    },
    [editCellTemplate, canUndo]
  );

  useHotkeys(
    'Ctrl+y',
    (event, hotkeysEvent): void => {
      event.preventDefault();
      // github link to the issue https://github.com/JohannesKlauss/react-hotkeys-hook/issues/1040
      if (event.key.toLowerCase() !== 'y' || editCellTemplate) return;
      if (undoSnackBarKey?.current) SnackbarUtils.closeSnackbar(undoSnackBarKey.current);

      if (!canRedo) {
        undoSnackBarKey.current = SnackbarUtils.toast('Rack redo history is empty', {
          variant: 'info',
          autoHideDuration: 1000,
        });

        return;
      }

      redoRack();
      undoSnackBarKey.current = SnackbarUtils.toast('Redo changes in rack', {
        variant: 'success',
        autoHideDuration: 1000,
      });
    },
    [editCellTemplate, canRedo]
  );

  const handleCellTemplateChanged = useCallback(
    (newValue: boolean) => {
      if (cellTemplateChanged !== newValue) {
        setCellTemplateChanged(newValue);
      }
    },
    [cellTemplateChanged]
  );

  const handleClose = useCallback(
    (e: unknown, reason: string): void => {
      const isRackUnchanged = JSON.stringify(rack) === JSON.stringify(rackFromStore);
      if (isRackUnchanged) {
        dispatch(closeDialogAction());

        return;
      }

      if (reason === 'backdropClick') return;

      confirm({
        title: 'Do you want to discard your changes?',
        description: 'If you close the dialog, your changes will be lost.',
        allowClose: false,
        cancellationText: 'Continue editing',
        confirmationText: 'Close without saving',
        confirmationButtonProps: {
          color: 'error',
          sx: {
            textTransform: 'none',
          },
        },
        cancellationButtonProps: {
          sx: {
            textTransform: 'none',
          },
        },
      })
        .then(() => {
          dispatch(closeDialogAction());
        })
        .catch(() => undefined);
    },

    [confirm, dispatch, rack, rackFromStore]
  );

  const [currentName, setCurrentName] = useState<string>(rack.properties.name);
  const [newRackNameOk, setNewRackNameOk] = useState(true);

  const checkRackName = useCallback(
    (newName: string) => {
      if (!rack) return false;
      const allSlotsNamesOfThisRack = Object.values(cellsNames.current).flatMap((cellNames) => cellNames);
      const newRackNameAndSlotNames = [...allSlotsNamesOfThisRack, newName];
      const isRackNameOk = areAllShapeNamesUnique(newRackNameAndSlotNames, [rack.id as string], {
        ignoreDuplicatesBefore: true,
      });

      return isRackNameOk;
    },
    [rack]
  );

  useEffect(() => {
    setNewRackNameOk(true);

    startTransition(() => {
      const isRackNameOk = checkRackName(currentName);
      if (!isRackNameOk) {
        setNewRackNameOk(false);
      }
    });
  }, [checkRackName, currentName]);

  /** mutate the array passed in argument */
  const computeColumnsPosition = useCallback(
    (newColumns: RackColumn[], newUprights: RackUpright[] = uprights): RackColumn[] => {
      newColumns[0] = {
        ...newColumns[0],
        x: newUprights[0].enabled ? newUprights[0].width : 0,
      };

      for (let i = 1; i < newColumns.length; i++) {
        newColumns[i] = {
          ...newColumns[i],
          x: newColumns[i - 1].x + (newUprights[i].enabled ? newUprights[i].width : 0) + newColumns[i - 1].width,
        };
      }

      return newColumns;
    },
    [uprights]
  );

  const updateColumns = useCallback(
    (newColumns: RackColumn[]) => {
      startTransition(() => {
        setRack((state) => {
          let updatedColumns = [...state.properties.columns];

          newColumns.forEach((newState) => {
            const columnIndex = updatedColumns.findIndex((column) => column.id === newState.id);

            if (columnIndex === -1) {
              // eslint-disable-next-line no-console
              console.error(`Column ${newState.id} not found in rack ${rackId}`);

              return;
            }

            const formerWidth = updatedColumns[columnIndex].width;
            const formerExtendedLength = updatedColumns[columnIndex].extendedLength;
            const formerStartHeight = updatedColumns[columnIndex].startHeight;

            // Update column with new state
            updatedColumns[columnIndex] = newState;

            // Apply linked properties logic
            if (
              newState.linkedProperties.width &&
              updatedColumns[columnIndex].width !== rack.properties.defaultColumnWidth
            ) {
              updatedColumns[columnIndex].width = rack.properties.defaultColumnWidth;
              updatedColumns = computeColumnsPosition(updatedColumns);
            }

            if (
              newState.linkedProperties.nbLevels &&
              updatedColumns[columnIndex].nbLevels !== rack.properties.defaultNbLevels
            ) {
              updatedColumns[columnIndex].nbLevels = rack.properties.defaultNbLevels;
              updatedColumns[columnIndex].cells = updateCellsAfterChangeNbLevels(
                updatedColumns[columnIndex].nbLevels,
                updatedColumns[columnIndex].cells
              );
            }

            if (newState.linkedProperties.extendedLength) {
              updatedColumns[columnIndex].extendedLength = state.properties.defaultExtendedLength;
            }

            if (newState.linkedProperties.startHeight) {
              updatedColumns[columnIndex].startHeight = 0;
            }

            // Apply changes based on value differences
            if (Math.abs(newState.width - formerWidth) > epsilon) {
              updatedColumns = computeColumnsPosition(updatedColumns);
            }

            if (newState.nbLevels !== updatedColumns[columnIndex].cells.length) {
              updatedColumns[columnIndex].cells = updateCellsAfterChangeNbLevels(
                newState.nbLevels,
                updatedColumns[columnIndex].cells
              );
            }

            if (Math.abs(newState.extendedLength - formerExtendedLength) > epsilon) {
              updatedColumns[columnIndex].extendedLength = newState.extendedLength;
            }

            if (Math.abs(newState.startHeight - formerStartHeight) > epsilon) {
              updatedColumns[columnIndex].startHeight = newState.startHeight;
            }
          });

          return {
            ...state,
            properties: {
              ...state.properties,
              columns: updatedColumns,
            },
          };
        });
      });
    },
    [computeColumnsPosition, rack, rackId]
  );

  const updateUprights = useCallback(
    (uprightsToUpdate: { newUpright: RackUpright; uprightIndex: number }[]) => {
      startTransition(() => {
        setRack((state) => {
          const newUprights = [...state.properties.uprights];

          // Update each upright based on the incoming array
          uprightsToUpdate.forEach(({ newUpright, uprightIndex }) => {
            newUprights[uprightIndex] = newUpright;
          });

          // Recompute columns based on updated uprights
          let newColumns = [...state.properties.columns];
          newColumns = computeColumnsPosition(newColumns, newUprights);

          return {
            ...state,
            properties: {
              ...state.properties,
              uprights: newUprights,
              columns: newColumns,
            },
          };
        });
      });
    },
    [computeColumnsPosition]
  );

  const changeRackEditionMenu = useCallback((newEditCellTemplate: null | string) => {
    setEditCellTemplate(newEditCellTemplate);
  }, []);

  const deleteColumn = useCallback(
    (columnIndex: number, nbToDelete = 1) => {
      startTransition(() => {
        setRack((state) => {
          let newColumns = [...state.properties.columns];
          const newUprights = [...state.properties.uprights];

          // Deleting the columns and uprights
          newColumns.splice(columnIndex, nbToDelete);
          newUprights.splice(columnIndex, nbToDelete);

          // Integrity check between columns and uprights
          if (newUprights.length !== newColumns.length + 1) {
            // eslint-disable-next-line no-console
            console.error(
              `Incorrect number of uprights in rack ${state.id}; nb of columns: ${newColumns.length}, nb of uprights: ${newUprights.length}`
            );
          }

          // Recompute positions for the columns
          newColumns = computeColumnsPosition(newColumns, newUprights);

          return {
            ...state,
            properties: {
              ...state.properties,
              columns: newColumns,
              uprights: newUprights,
            },
          };
        });
      });
    },
    [computeColumnsPosition]
  );

  const addColumn = useCallback(
    (columnIndex: number, nbToAdd = 1) => {
      startTransition(() => {
        setRack((state) => {
          const newColumns = [...state.properties.columns];
          const newUprights = [...state.properties.uprights];
          const columnsToAdd: RackColumn[] = [];
          const uprightsToAdd: RackUpright[] = [];

          // Add new columns
          for (let i = 0; i < nbToAdd; i++) {
            const referenceColumn = newColumns[columnIndex !== 0 ? columnIndex - 1 : 0];
            const newColumn = {
              ...referenceColumn,
              id: generateShapeId(),
              cells: referenceColumn.cells.map((cell) => ({
                ...cell,
                id: generateShapeId(),
                cellTemplate: undefined,
                names: [],
              })),
              extendedLengthSegments: [],
            };
            columnsToAdd.push(newColumn);

            // Add new upright
            const newUpright = {
              ...newUprights[columnIndex],
              enabled: true, // new uprights are always enabled
            };
            uprightsToAdd.push(newUpright);
          }

          // Insert columns and uprights in a single step
          newColumns.splice(columnIndex, 0, ...columnsToAdd);
          newUprights.splice(columnIndex, 0, ...uprightsToAdd);

          // Recompute positions for the columns
          const updatedColumns = computeColumnsPosition(newColumns, newUprights);

          return {
            ...state,
            properties: {
              ...state.properties,
              columns: updatedColumns,
              uprights: newUprights,
            },
          };
        });
      });
    },
    [computeColumnsPosition]
  );

  const selectCellTemplateToAssign = useCallback((cellTemplateId: string | null) => {
    setSelectedCellTemplate(cellTemplateId);
  }, []);

  const cellClick = useCallback(
    (cell: RackCell, column: RackColumn, e?: MouseEvent) => {
      const cellIndex = column.cells.findIndex((c) => c.id === cell.id);
      const columnIndex = columns.findIndex((c) => c.id === column.id);

      // Handle cell template assignment
      if (selectedCellTemplate) {
        const newCell: RackCell = {
          ...cell,
          cellTemplate: cell.cellTemplate === selectedCellTemplate ? undefined : selectedCellTemplate,
          names: [],
        };

        const newCells = [...column.cells];
        newCells[cellIndex] = newCell;
        const newColumn = { ...column, cells: newCells };

        updateColumns([newColumn]);
      }

      // Handle shift key selection (range selection)
      if (e?.shiftKey && selectedCells.length) {
        const firstSelected = selectedCells[0];
        const startColumn = Math.min(firstSelected[0], columnIndex);
        const endColumn = Math.max(firstSelected[0], columnIndex);
        const startCell = Math.min(firstSelected[1], cellIndex);
        const endCell = Math.max(firstSelected[1], cellIndex);

        const newSelectedCells: [number, number][] = [];
        for (let col = startColumn; col <= endColumn; col++) {
          for (let cellIdx = startCell; cellIdx <= endCell; cellIdx++) {
            newSelectedCells.push([col, cellIdx]);
          }
        }

        setSelectedCells(newSelectedCells);

        // Apply the cell template to all selected cells
        if (selectedCellTemplate) {
          const newColumns = [...columns];
          newSelectedCells.forEach(([colIdx, cellIdx]) => {
            const targetColumn = newColumns[colIdx];
            const targetCell = targetColumn?.cells[cellIdx];
            if (targetCell) {
              const updatedCell: RackCell = { ...targetCell, cellTemplate: selectedCellTemplate };
              newColumns[colIdx] = {
                ...targetColumn,
                cells: [...targetColumn.cells.slice(0, cellIdx), updatedCell, ...targetColumn.cells.slice(cellIdx + 1)],
              };
            }
          });

          startTransition(() => {
            setRack((prevRack) => ({
              ...prevRack,
              properties: {
                ...prevRack.properties,
                columns: newColumns,
              },
            }));
          });
        }
      }
      // Handle ctrl key selection (individual selection toggle)
      else if (e?.ctrlKey) {
        const alreadySelected = selectedCells.some((coords) => coords[0] === columnIndex && coords[1] === cellIndex);

        const updatedSelection = alreadySelected
          ? selectedCells.filter((coords) => !(coords[0] === columnIndex && coords[1] === cellIndex))
          : [...selectedCells, [columnIndex, cellIndex]];

        setSelectedCells(updatedSelection);
      }
      // Handle single cell selection
      else {
        setSelectedCells([[columnIndex, cellIndex]]);
      }
    },
    [columns, rack, selectedCellTemplate, selectedCells, updateColumns]
  );

  const assignToWholeRack = useCallback(() => {
    startTransition(() => {
      setRack((state) => {
        const newColumns: RackColumn[] = state.properties.columns.map((column) => ({
          ...column,
          cells: column.cells.map((cell) => {
            return {
              ...cell,
              names: [],
              cellTemplate: selectedCellTemplate,
            } as RackCell;
          }),
        }));

        return {
          ...state,
          properties: {
            ...state.properties,
            columns: newColumns,
          },
        };
      });

      setSelectedCellTemplate(null);
    });
  }, [selectedCellTemplate]);

  const updateNbColumns = useCallback(
    (newNbColumns: number) => {
      const n = Math.abs(newNbColumns - columns.length);

      if (newNbColumns > columns.length) {
        // then we need to add the missing columns
        addColumn(columns.length - 1, n);
      } else if (newNbColumns < columns.length) {
        // then we need to delete the extra columns
        deleteColumn(columns.length - n, n);
      }
    },
    [addColumn, columns.length, deleteColumn]
  );

  const updateRack = useCallback((newRack: LoadedRack) => {
    startTransition(() => {
      setRack(newRack);
    });
  }, []);

  const toggleDisplayPositionNames = useCallback(() => {
    startTransition(() => setDisplayPositionNames((s) => !s));
  }, []);

  const applyColumnValueToAllSelectedColumns = useCallback(
    (columnValue: Partial<RackColumn>, linkedPropertiesToChange: Partial<RackColumn['linkedProperties']>) => {
      if (!selectedCells.length) return;

      // Convert selectedCells to a Set for fast lookup
      const selectedColumnsIdx = new Set(selectedCells.map((s) => s[0]));

      const columnsToEdit = columns.map((column, index) => {
        if (!selectedColumnsIdx.has(index)) {
          return column; // If the column is not selected, return it as-is
        }

        // Merge linked properties
        const updatedLinkedProperties = {
          ...column.linkedProperties,
          ...linkedPropertiesToChange,
        };

        // Return the updated column with the new value and updated linked properties
        return {
          ...column,
          ...columnValue,
          linkedProperties: updatedLinkedProperties,
        };
      });

      // Only update columns that were modified
      const modifiedColumns = columnsToEdit.filter((col, i) => col !== columns[i]);

      if (modifiedColumns.length) {
        updateColumns(modifiedColumns);
      }
    },
    [columns, selectedCells, updateColumns]
  );

  const applyCellValueToAllSelectedCells = useCallback(
    (newRackCellValues: Partial<RackCell>) => {
      if (!selectedCells.length) return;

      // Convert selectedCells into a Set for efficient lookup
      const selectedCellsSet = new Set(selectedCells.map((c) => `${c[0]}-${c[1]}`));

      const updatedColumns = columns.map((column, indexColumn) => {
        let isColumnUpdated = false;

        const updatedCells = column.cells.map((cell, indexCell) => {
          // Check if the current cell is in the selectedCells set
          if (selectedCellsSet.has(`${indexColumn}-${indexCell}`)) {
            isColumnUpdated = true;

            return {
              ...cell,
              ...newRackCellValues, // Apply new values to the selected cells
            };
          }

          return cell;
        });

        // Only update columns that have modified cells
        return isColumnUpdated ? { ...column, cells: updatedCells } : column;
      });

      // Filter out columns that were not updated
      const columnsToEdit = updatedColumns.filter((col, i) => col !== columns[i]);

      if (columnsToEdit.length) {
        updateColumns(columnsToEdit);
      }
    },
    [columns, selectedCells, updateColumns]
  );

  const computeAutoRackCellNames = useCallback(
    (r: LoadedRack, newRackName?: string): LoadedRack => {
      const newRack = {
        ...r,
        properties: {
          ...r.properties,
          name: newRackName ?? currentName,
          columns: r.properties.columns.map((column, columnIndex) => ({
            ...column,
            cells: column.cells.map((cell, cellIndex) => {
              if (!cell.cellTemplate) return cell;

              const formerNames = cell.names.map((name) => name.map((n) => (n.user ? n.value : undefined)));
              const cellTemplate = cellTemplates[cell.cellTemplate];

              return {
                ...cell,
                cellTemplate: cellTemplate ? cell.cellTemplate : undefined,
                names: cellTemplate
                  ? generatePositionNames(
                      { ...r, properties: { ...r.properties, name: currentName } },
                      {
                        column: columnIndex,
                        level: cellIndex,
                      },
                      cellTemplate,
                      formerNames
                    ).map((name, i) =>
                      name.map((n, j) => {
                        const formerName = formerNames?.[i]?.[j];
                        const disabled = cell.names?.[i]?.[j]?.disabled || undefined;
                        const id = cell.names?.[i]?.[j]?.id ? cell.names[i][j].id : generateShapeId();

                        return {
                          value: formerName ?? n,
                          user: !!formerName,
                          disabled,
                          id,
                        };
                      })
                    )
                  : [],
              };
            }),
          })),
        },
      };

      return newRack;
    },
    [cellTemplates, currentName]
  );

  useEffect(() => {
    if (!currentName || currentName === rack.properties.name) return;

    startTransition(() => {
      setRack((r) => computeAutoRackCellNames(r, currentName));
    });
  }, [currentName, cellTemplates]);

  const changeCellName = useCallback(
    (cellId: string, newNames: RackCell['names'][0], loadIndex: number): boolean => {
      const newNamesValues = newNames.map((name) => name.value);
      const racksArr = Object.values(racks);

      const upToDateRack = computeAutoRackCellNames(rack, currentName);

      // Check if the new name is unique
      const isUnique = isPositionNameNotAlreadyUsed(newNamesValues, upToDateRack, racksArr, cellId, cellsNames.current);

      if (!isUnique) return false; // Exit early if not unique

      // Proceed with updating only the relevant cell and column
      startTransition(() => {
        setRack((r) => {
          const newColumns = r.properties.columns.map((column) => {
            const updatedCells = column.cells.map((cell) => {
              if (cell.id !== cellId) return cell;

              // Update the names for the target cell
              const newNamesCell = [...cell.names];
              newNamesCell[loadIndex] = newNames;

              return {
                ...cell,
                names: newNamesCell,
              };
            });

            // Return the updated column with new cells
            return { ...column, cells: updatedCells };
          });

          return {
            ...r,
            properties: {
              ...r.properties,
              columns: newColumns,
            },
          };
        });
      });

      return true;
    },
    [computeAutoRackCellNames, currentName, rack, racks]
  );

  const toggleCellDisabled = useCallback(
    (cellId: string, newNames: RackCell['names'][0], loadIndex: number): boolean => {
      startTransition(() => {
        setRack((r) => {
          const newColumns = r.properties.columns.map((column) => {
            // Find the column that contains the cell with the matching cellId
            const updatedCells = column.cells.map((cell) => {
              if (cell.id !== cellId) return cell; // Only update the target cell

              // Update the names for the target cell
              const newNamesCell = [...cell.names];
              newNamesCell[loadIndex] = newNames;

              return {
                ...cell,
                names: newNamesCell,
              };
            });

            return { ...column, cells: updatedCells };
          });

          return {
            ...r,
            properties: {
              ...r.properties,
              columns: newColumns,
            },
          };
        });
      });

      return true;
    },
    []
  );

  const selectedCellsForStation = useMemo(
    () =>
      selectedCells
        .map(([columnIndex, cellIndex]) => {
          const cell = columns[columnIndex]?.cells[cellIndex];

          if (!cell?.id) {
            return null;
          }

          return [cell.id, columnIndex, cellIndex] as [string, number, number];
        })
        .filter(isDefined),
    [selectedCells]
  );

  const allCellsId = useMemo(() => columns.flatMap((column) => column.cells.flatMap((cell) => cell.id)), [columns]);

  const assignNameToCells = useCallback(
    (assignTo: 'selection' | 'all', rules: BlockDataPositionName[] | null, selectedLoadOnly = true): void => {
      const cellsToName =
        assignTo === 'selection' ? selectedCellsForStation.map((selectedCell) => selectedCell[0]) : allCellsId;

      const cellsToNameSet = new Set(cellsToName);
      let nbNoUniqueNames = 0;
      let nbTooLongNames = 0;
      const newGeneratedNames = new Set<string>();
      const racksArr = Object.values(racks);

      const newRack: LoadedRack = {
        ...rack,
        properties: {
          ...rack.properties,
          columns: rack.properties.columns.map((column, columnIndex) => {
            const newCells = column.cells.map((cell, level) => {
              if (!cellsToNameSet.has(cell.id) || !cell.cellTemplate) return cell;

              const cellTemplate = cellTemplates[cell.cellTemplate];
              if (!cellTemplate) return cell;

              const selectedLoad = cellTemplate.selectedLoad;
              const loads = cellTemplate.loads;

              const names = loads.map((load, loadIndex) => {
                if (selectedLoadOnly && loadIndex !== selectedLoad) {
                  return cell.names[loadIndex];
                }

                const positionNames: Pallet[] = new Array(load.N) as Pallet[];

                for (let palletIndex = 0; palletIndex < load.N; palletIndex++) {
                  const props: ConvertCellPositionToNameOptions = {
                    rack,
                    level,
                    cell,
                    palletIndex,
                    columnIndex,
                    load: loads[loadIndex],
                    cellTemplates,
                    cellTemplate,
                  };

                  let newNameOriginal = 'error';
                  let isUnique = false;
                  let i = 0;
                  let newName: string;

                  do {
                    newName =
                      i === 0
                        ? rules
                          ? convertCellPositionToName(rules, props)
                          : generatePositionNames(rack, { column: columnIndex, level }, cellTemplate)[selectedLoad][
                              palletIndex
                            ]
                        : `${newNameOriginal}_${i}`;

                    isUnique = isPositionNameNotAlreadyUsed(
                      [newName],
                      rack,
                      racksArr,
                      cell.id,
                      cellsNames.current,
                      Array.from(newGeneratedNames),
                      { displaySnackbar: false }
                    );

                    if (!isUnique && i++ === 0) {
                      newNameOriginal = newName;
                      nbNoUniqueNames++;
                    }
                  } while (!isUnique && i < nbMaxIter);

                  if (!isUnique) {
                    // eslint-disable-next-line no-console
                    console.error(`Could not find a unique name for ${newName} after ${nbMaxIter} iterations`);
                    SnackbarUtils.error(`A unique name for ${newName} has not been found.`);
                  }

                  const isValid = isValidPositionCellName(newName);
                  if (!isValid) {
                    nbTooLongNames++;
                    positionNames[palletIndex] = cell.names?.[loadIndex]?.[palletIndex]; // Return previous name
                    continue; // Skip to the next iteration
                  }

                  newGeneratedNames.add(newName);
                  const previousId = cell.names?.[loadIndex]?.[palletIndex]?.id;

                  positionNames[palletIndex] = {
                    user: !!rules,
                    value: newName,
                    id: previousId ?? generateShapeId(),
                  };
                }

                return positionNames;
              });

              return {
                ...cell,
                names,
              };
            });

            return {
              ...column,
              cells: newCells,
            };
          }),
        },
      };

      startTransition(() => {
        setRack(newRack);
      });

      if (nbNoUniqueNames) {
        // eslint-disable-next-line no-console
        console.warn(`There were ${nbNoUniqueNames} names that were not unique.`);
        SnackbarUtils.warning(`${nbNoUniqueNames} names were not unique and were modified`);
      }

      if (nbTooLongNames) {
        // eslint-disable-next-line no-console
        console.warn(`There were ${nbTooLongNames} names that were too long.`);
        SnackbarUtils.warning(`${nbTooLongNames} names were too long and the new rule has not been applied to them`);
      }
    },
    [cellTemplates, rack, racks, selectedCellsForStation]
  );

  const mirrorRack = useCallback(() => {
    startTransition(() => {
      setRack((r) => {
        let x = 0;

        const newUprights = structuredClone(r.properties.uprights).reverse();

        const newColumns = structuredClone(r.properties.columns)
          .sort((columnA, columnB) => columnA.x - columnB.x)
          .reverse()
          .map((column, columnIndex) => {
            x += newUprights[columnIndex].enabled ? newUprights[columnIndex].width : 0;
            column.x = x;
            x += column.width;

            column.cells = column.cells.map((cell) => {
              cell.names = cell.names.map((name) => {
                name.reverse();

                return name;
              });

              return cell;
            });

            return column;
          });

        // const newColumns = r.properties.columns.map((column, columnIndex) => {
        //   const mirrorIndex = r.properties.columns.length - columnIndex - 1;
        //   const newColumn = structuredClone(r.properties.columns[mirrorIndex]);
        //   const newUpright = r.properties.uprights[mirrorIndex];

        //   x += newUpright.enabled ? newUpright.width : 0;
        //   newColumn.x = x;
        //   x += newColumn.width;

        //   const newCells = newColumn.cells.map((cell) => {
        //     return {
        //       ...cell,
        //       names: cell.names.map((name, slotInCellIndex) => {
        //         // const mirrorSlotInCellIndex = name.length - slotInCellIndex - 1;
        //         // const newName = structuredClone(name[mirrorSlotInCellIndex]);

        //         // return newName;
        //         return structuredClone(name).reverse();
        //       }),
        //     };
        //   });
        //   column.cells = newCells;

        //   return newColumn;
        // });

        // const newUprights = r.properties.uprights.map((upright, uprightIndex) => {
        //   const mirrorIndex = r.properties.uprights.length - uprightIndex - 1;
        //   const newUpright = structuredClone(r.properties.uprights[mirrorIndex]);

        //   return newUpright;
        // });

        return {
          ...r,
          properties: {
            ...r.properties,
            columns: newColumns,

            uprights: newUprights,
          },
        };
      });
    });
  }, []);

  const validateRack = useCallback(
    (close = true) => {
      const isRackUnchanged = JSON.stringify(rack) === JSON.stringify(rackFromStore);

      // Early exit if the rack is unchanged and there are no station positions
      if (isRackUnchanged && stationPositions.length < 1) {
        if (close) dispatch(closeDialogAction());

        return;
      }

      // Validate rack name
      if (!checkRackName(currentName)) {
        const warningMessage = `Rack name ${currentName} is already used in the project`;
        SnackbarUtils.warning(warningMessage);
        // eslint-disable-next-line no-console
        console.warn(warningMessage);

        return;
      }

      const coordinates = computeRackCoordinates(rack, columns, uprights);

      // Create new rack with updated geometry and properties
      const newRack: LoadedRack = {
        ...rack,
        geometry: {
          ...rack.geometry,
          coordinates: [coordinates],
        },
        properties: {
          ...rack.properties,
          name: currentName,
          columns: columns.map((column) => ({
            ...column,
            cells: column.cells.map((cell) => {
              // Remove cell template if it's not used
              return cell && (!cell.cellTemplate || cellTemplates[cell.cellTemplate])
                ? cell
                : { ...cell, cellTemplate: undefined };
            }),
          })),
          uprights,
        },
      };

      // Dispatch actions for saving the rack
      startTransition(() => {
        dispatch(saveRackAction(newRack));

        // Update rack and check positions uniqueness
        dispatch(askUpdateRackAction({ id: newRack.id as string, type: 'saveSuccess' }));

        const allRacks = Object.values(store.getState().circuit.present.racks.entities);
        const positionRackNamesUnicity = checkUnicityRackPositionsName(allRacks);
        if (!positionRackNamesUnicity) {
          SnackbarUtils.error('Rack positions names are not unique');
        }
      });

      // Update latest unfiltered state
      const currentState = store.getState();
      currentState.circuit._latestUnfiltered = currentState.circuit.present;

      // Handle station positions
      // Initialize arrays to hold positions to add and remove
      const positionsToRemove: { stationId: string; positionId: string }[] = [];
      const positionsToAdd: {
        stationId: string;
        position: {
          id: string;
          type: StationPositions['type'];
        };
      }[] = [];

      stationPositions.forEach((position) => {
        if (userRemovedStationPositions) {
          // Collect positions to remove
          positionsToRemove.push({
            stationId: position.selectedStation,
            positionId: position.id,
          });
        } else {
          const actualStationPositionsMap = new Map(stations.map((station) => [station.id, station.positions]));
          const actualStationPositions = actualStationPositionsMap.get(position.selectedStation) || [];

          // Check if position already exists
          const isPositionAlreadyInStation = actualStationPositions.some(
            (actualPosition) => actualPosition.id === position.id
          );

          // Collect positions to add if they don't exist
          if (!isPositionAlreadyInStation) {
            positionsToAdd.push({
              stationId: position.selectedStation,
              position: { id: position.id, type: position.type },
            });
          }
        }
      });

      if (positionsToRemove.length > 0) {
        dispatch(removePositionFromStations(positionsToRemove));
      }

      if (positionsToAdd.length > 0) {
        dispatch(addPositionToStations(positionsToAdd));
      }

      if (close) dispatch(closeDialogAction());
    },
    [
      cellTemplates,
      checkRackName,
      columns,
      currentName,
      dispatch,
      rack,
      uprights,
      rackFromStore,
      stationPositions,
      userRemovedStationPositions,
    ]
  );

  const actions: ActionsRack = useMemo(() => {
    return {
      updateUprights,
      changeRackEditionMenu,
      deleteColumn,
      addColumn,
      selectCellTemplateToAssign,
      cellClick,
      cellTemplateChange: handleCellTemplateChanged,
      updateNbColumns,
      updateColumns,
      updateRack,
      computeColumnsPosition,
      toggleDisplayPositionNames,
      applyColumnValueToAllSelectedColumns,
      applyCellValueToAllSelectedCells,
      changeCellName,
      toggleCellDisabled,
      assignNameToCells,
      mirrorRack,
      validateRack,
    };
  }, [
    updateUprights,
    changeRackEditionMenu,
    deleteColumn,
    addColumn,
    selectCellTemplateToAssign,
    cellClick,
    handleCellTemplateChanged,
    updateNbColumns,
    updateColumns,
    updateRack,
    computeColumnsPosition,
    toggleDisplayPositionNames,
    applyColumnValueToAllSelectedColumns,
    applyCellValueToAllSelectedCells,
    changeCellName,
    toggleCellDisabled,
    assignNameToCells,
    mirrorRack,
  ]);

  useEffect(() => {
    if (currentName !== rack.properties.name) setCurrentName(rack.properties.name);
  }, [rack]);

  const resetCellTemplateRef = useRef<undefined | (() => void)>(undefined);
  const saveCellTemplateRef = useRef<undefined | (() => void)>(undefined);
  const addCellTemplateRef = useRef<undefined | ((cellTemplateType?: 'rack' | 'conveyor') => void)>(undefined);

  const addCellTemplate = useCallback(
    (cellTemplateType: 'rack' | 'conveyor' = 'rack') => {
      const defaultCellTemplate =
        cellTemplateType === 'conveyor' ? getDefaultConveyorCellTemplate() : getDefaultRackCellTemplate();
      defaultCellTemplate.id = generateShapeId();
      defaultCellTemplate.name = `CellTemplate${Math.floor(Math.random() * 1000)}`;

      dispatch(createCellTemplateSuccessAction(defaultCellTemplate));
    },
    [dispatch]
  );
  addCellTemplateRef.current = addCellTemplate;

  const deleteCellTemplate = useCallback(
    (id: string | null = editCellTemplate) => {
      if (!id) return;

      if (id === editCellTemplate) setEditCellTemplate(null);
      if (id === selectedCellTemplate) setSelectedCellTemplate(null);

      // we dispatch the action to update the store
      dispatch(
        deleteCellTemplateSuccessAction({
          id,
        })
      );
    },
    [dispatch, editCellTemplate, selectedCellTemplate]
  );

  useEffect(() => {
    if (!zoomToCellId) return;

    zoomToCell(zoomToCellId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zoomRackEdition]);

  return (
    <Dialog
      open={true}
      fullWidth={true}
      maxWidth="xl"
      onClose={handleClose}
      aria-labelledby="rack-edition-dialog-title"
      disableEscapeKeyDown
      sx={{
        cursor: reactRackPending ? 'progress !important' : undefined,
        '& *': { cursor: reactRackPending ? 'progress !important' : undefined },
      }}
    >
      <RackEditionTopBar
        currentName={currentName}
        setCurrentName={setCurrentName}
        name={rack.properties.name}
        locked={rack.properties.locked}
        cellTemplates={cellTemplates}
        actions={actions}
        editCellTemplate={editCellTemplate}
        resetCellTemplate={resetCellTemplateRef}
        saveCellTemplate={saveCellTemplateRef}
        addCellTemplate={addCellTemplateRef}
        cellTemplateChanged={cellTemplateChanged}
        displayPositionNames={displayPositionNames}
        isConveyor={isConveyor}
        selectedCellTemplate={selectedCellTemplate}
        rackNameOk={newRackNameOk}
        deleteCellTemplate={deleteCellTemplate}
        cellTemplate={cellTemplates[editCellTemplate ?? '']}
        rack={rack}
        selectedCellsForStation={selectedCellsForStation}
        allCellsId={allCellsId}
        stationPositions={stationPositions}
        setStationPositions={setStationPositions}
        setUserRemovedStationPositions={setUserRemovedStationPositions}
      />
      <DialogContent
        id="rack-edition-dialog-content"
        onScroll={(e) => {
          if (editCellTemplate) {
            const scrollTop = (e.target as HTMLElement).scrollTop;

            const svgCellTemplateViewFront = document.querySelector<HTMLElement>('#cell-template-view-front');
            if (svgCellTemplateViewFront) {
              svgCellTemplateViewFront.style.transform = `translateY(${scrollTop}px)`;
            }

            const svgCellTemplateViewTop = document.querySelector<HTMLElement>('#cell-template-view-top');
            if (svgCellTemplateViewTop) {
              svgCellTemplateViewTop.style.transform = `translateY(${scrollTop}px)`;
            }
          }
        }}
        className={selectedCellTemplate ? 'assignCellTemplate' : ''}
        sx={{ padding: '0 24px' }}
      >
        {/** if editCellTemplate is empty, we edit the rack, otherwise we edit a cellTemplate */}
        {!editCellTemplate ? (
          <RackEditionRackView
            rack={rack}
            cellTemplatesIds={cellTemplatesIds}
            cellTemplates={cellTemplates}
            actions={actions}
            selectedCells={selectedCells}
            autoZoomToForm={autoZoomToForm}
            displayPositionNames={displayPositionNames}
            cellsNames={cellsNames}
          />
        ) : (
          <RackEditionCellTemplate
            key={editCellTemplate}
            cellTemplateId={editCellTemplate}
            cellTemplates={cellTemplates}
            resetCellTemplateRef={resetCellTemplateRef}
            saveCellTemplateRef={saveCellTemplateRef}
            addCellTemplateRef={addCellTemplateRef}
            actions={actions}
          />
        )}

        {selectedCellTemplate && !editCellTemplate && (
          <Alert
            severity="info"
            sx={{ position: 'absolute', bottom: 0, left: 0 }}
            action={
              <Button sx={{ textTransform: 'none' }} onClick={assignToWholeRack} variant="outlined">
                Assign it to the whole rack
              </Button>
            }
          >
            <AlertTitle>Click on a cell to assign {cellTemplates[selectedCellTemplate].name}</AlertTitle>
          </Alert>
        )}
      </DialogContent>
      {!editCellTemplate && (
        <DialogActions>
          <Stack direction="row" justifyContent="flex-end" gap={1}>
            <Button variant="text" id="rack-editor-mouse-coordinates" color="inherit">
              (0.000, 0.000)
            </Button>
            <Button onClick={(e) => handleClose(e, 'cancel-button')} color="secondary">
              Cancel
            </Button>
            <Button color="primary" variant="contained" onClick={() => validateRack()}>
              Validate
            </Button>
          </Stack>
        </DialogActions>
      )}
    </Dialog>
  );
}

/**
 * Update the cells of a column after a change of the number of levels of the column
 * @param newNbLevels the new number of levels of the column
 * @param cells the previous cells array of the column
 * @returns the new cells array of the column
 */
export function updateCellsAfterChangeNbLevels(newNbLevels: number, cells: RackCell[]): RackCell[] {
  const newCells = [...cells];

  if (newNbLevels > cells.length) {
    // we increased the number of levels
    const highestCell = cells[cells.length - 1];
    for (let i = 0; i < newNbLevels - cells.length; i++) {
      newCells.push({
        ...highestCell,
        id: generateShapeId(),
        cellTemplate: undefined,
        names: highestCell.names.map((name) =>
          name.map((n) => ({
            value: `${n.value}_${Math.floor(Math.random() * 1000)}`,
            user: false,
            id: generateShapeId(),
          }))
        ),
      });
    }
  } else if (newNbLevels < cells.length) {
    // we decreased the number of levels
    newCells.splice(newNbLevels, cells.length - newNbLevels);
  }

  return newCells;
}

/**
 * Calculate the d3 translate and scale for the bbox to be centered in the parent
 * @param parent the parent svg element to center the bbox in
 * @param Bbox the bounding box of the element to center
 * @returns {translate: number[], scale: number} the translate and scale to apply to the parent
 */
export function calculateD3TransformFromBBox(
  parent: SVGSVGElement,
  Bbox: DOMRect
): { translate: number[]; scale: number } {
  const paddingPercent = 1;
  const fullWidth = parent.viewBox.baseVal.width,
    fullHeight = parent.viewBox.baseVal.height;
  const width = Bbox.width,
    height = Bbox.height;
  const midX = Bbox.x + width / 2,
    midY = Bbox.y + height / 2;

  const scale = (paddingPercent || 0.75) / Math.max(width / fullWidth, height / fullHeight);
  const translate = [
    parent.viewBox.baseVal.x + fullWidth / 2 - scale * midX,
    parent.viewBox.baseVal.y + fullHeight / 2 - scale * midY,
  ];

  return {
    translate,
    scale,
  };
}

/**
 * Zoom the user view to fit the rack
 * @returns void
 */
export function zoomToRack(): void {
  if (!zoomRackEdition) return;

  const root = d3.select('#rack-edition-svg-zoom');
  const rootNode = root.node() as SVGSVGElement;

  if (!rootNode) return;

  const bounds = rootNode.getBBox();
  const parent = rootNode.parentElement as unknown as SVGSVGElement;

  const { translate, scale } = calculateD3TransformFromBBox(parent, bounds);

  d3.select(parent)
    .transition()
    .duration(500)
    .call(zoomRackEdition.transform as any, d3.zoomIdentity.translate(translate[0], translate[1]).scale(scale));
}

/**
 * Zoom the user view to fit the form
 * @param transition {optional} transition duration in ms, by default 0, 0 equals no transition
 * @returns void
 */
export function zoomToForm(transition = 0): void {
  if (!zoomRackEdition) return;

  const root = d3.select('#rack-edition-svg-zoom');
  const rootNode = root.node() as SVGElement;

  if (!rootNode) return;

  const parent = rootNode.parentElement as unknown as SVGSVGElement;

  const scale = 1;
  const translate = [8 * 100, 0 * 100];

  if (transition) {
    d3.select(parent)
      .transition()
      .duration(transition)
      .call(zoomRackEdition.transform as any, d3.zoomIdentity.translate(translate[0], translate[1]).scale(scale));
  } else {
    d3.select(parent).call(
      zoomRackEdition.transform as any,
      d3.zoomIdentity.translate(translate[0], translate[1]).scale(scale)
    );
  }
}

/**
 * Zoom the user view to fit the specified cell
 * @param cellId the id of the cell to zoom to
 * @returns void
 */
export function zoomToCell(cellId: string): void {
  if (!zoomRackEdition) return;

  const root = d3.select('#rack-edition-svg-zoom');
  const rootNode = root.node() as SVGElement;

  if (!rootNode) return;

  const parent = rootNode.parentElement as unknown as SVGSVGElement;

  const cell = d3.select(`[id="${cellId}"]`);
  if (!cell) return;

  const cellNode = cell.node() as SVGSVGElement;
  if (!cellNode) return;

  const cellBBox = cellNode.getBBox();

  const { translate, scale } = calculateD3TransformFromBBox(parent, cellBBox);

  d3.select(parent).call(
    zoomRackEdition.transform as any,
    d3.zoomIdentity.translate(translate[0], translate[1]).scale(scale)
  );
}
