import { Box } from '@mui/system';
import { DisplayActiveModes } from 'components/editor/displayActiveModes';
import { RightClickMenu } from 'components/editor/right-click-menu';
import { MapImagePropertiesComponent as MapImageProperties } from 'components/import/map-image/import-map-image.properties';
import { LayersBottomLeft } from 'components/layers/layers-bottom-left';
import { FullPageLayout } from 'components/layouts';
import PerformanceMonitor from 'components/layouts/performance-monitor';
import { Properties as ShapesProperties } from 'components/properties/properties';
import { Toolboxes } from 'components/toolboxes';
import { Tools } from 'models/tools';
import { useEffect } from 'react';
import { DisplaySimulationTablesView } from 'simulation/displaySimulationTablesView';
import { useAppSelector } from 'store';
import { createProjectsObjectStore, createRackAnalysisObjectStores } from 'utils/indexed-db';
import { EditorEngine } from './editor-engine';

interface EditorPageProps {}

export function EditorPage(props: EditorPageProps): JSX.Element {
  const showPerformances = useAppSelector((state) => state.editor.isShowPerformancesEnabled);

  const activeTool = useAppSelector((state) => state.tool.activeTool);

  useEffect(() => {
    const projectsDb = indexedDB.open('projects');

    projectsDb.onupgradeneeded = (event) => {
      createProjectsObjectStore(projectsDb);
    };

    const rackAnalysisDb = indexedDB.open('rackAnalysis');

    rackAnalysisDb.onupgradeneeded = (event) => {
      createRackAnalysisObjectStores(rackAnalysisDb);
    };

    rackAnalysisDb.onsuccess = (event) => {
      const db = (event.target as IDBOpenDBRequest).result;

      const transaction = db.transaction('_unsaved', 'readwrite');
      const objectStore = transaction.objectStore('_unsaved');
      objectStore.clear();
      db.close();
    };
  }, []);

  return (
    <FullPageLayout>
      <EditorEngine />
      <DisplayActiveModes />
      {activeTool !== Tools.SimulationConfiguration && <LayersBottomLeft />}
      {activeTool === Tools.SimulationConfiguration && <DisplaySimulationTablesView />}
      <RightClickMenu />
      <ShapesProperties />
      <MapImageProperties />
      <Box
        component="span"
        sx={{
          overflowX: 'hidden',
        }}
      >
        <Toolboxes />
      </Box>
      {showPerformances && <PerformanceMonitor />}
    </FullPageLayout>
  );
}
