import { Elevator, Upgrade } from '@mui/icons-material';
import { Box, ListItem, ListItemIcon, ListItemText, Switch, Tooltip } from '@mui/material';
import { HelpIconTooltip } from 'components/utils/tooltips';
import { useSdkVersionOk } from 'components/utils/use-sdk-version-ok';
import { useCallback, useEffect, useState } from 'react';
import { checkPermission } from 'services/check-permission';
import { SnackbarUtils } from 'services/snackbar.service';
import { useAsyncMemo } from 'use-async-memo';
import { PreferencesService } from 'utils/preferences';

export const useElevatorPref = 'general/useElevator';

interface EnableElevatorSwitchProps {
  projectOpened: boolean;
}

export function EnableElevatorSwitch(props: EnableElevatorSwitchProps): JSX.Element {
  const { projectOpened } = props;
  const [elevatorEnabled, setElevatorEnabled] = useState(false);
  const isVersionOk = useSdkVersionOk('4.15.0');

  const handleChange = useCallback(async (newState: boolean) => {
    const [ok] = await PreferencesService.setPreferenceValue(useElevatorPref, newState ? '1' : '0', true);
    if (ok) {
      setElevatorEnabled(newState);
    } else {
      // eslint-disable-next-line no-console
      console.error(`Could not ${newState ? 'enable' : 'disable'} elevator usage`);
      SnackbarUtils.error(`Could not ${newState ? 'enable' : 'disable'} elevator usage`);
    }
  }, []);

  const editProjectPerm = useAsyncMemo(async () => {
    return await checkPermission('edit:project_configuration');
  }, []);

  useEffect(() => {
    const enabled = (() => {
      try {
        return projectOpened && PreferencesService.getPreferenceValue(useElevatorPref) === '1';
      } catch (e) {
        return false;
      }
    })();

    setElevatorEnabled(enabled);
  }, [projectOpened]);

  if (!projectOpened) return <></>;

  const tooltipTitle = !isVersionOk
    ? 'This feature requires SDK version 4.15.0 or higher'
    : !editProjectPerm
      ? 'You do not have the authorization to edit this value'
      : '';

  return (
    <ListItem>
      <ListItemIcon>
        <Elevator />
      </ListItemIcon>
      <ListItemText
        primary={
          <Box component="div" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Tooltip title={tooltipTitle}>
              <Box component="span">
                <Switch
                  checked={elevatorEnabled}
                  onChange={(e, checked) => handleChange(checked)}
                  disabled={!editProjectPerm || !isVersionOk}
                />
              </Box>
            </Tooltip>
            {!isVersionOk && (
              <Tooltip title="This feature requires SDK version 4.15.0 or higher">
                <Upgrade fontSize="small" />
              </Tooltip>
            )}
          </Box>
        }
        secondary={
          <>
            Enable Elevator Usage
            <HelpIconTooltip
              title="Enable this option if you want to use elevators in your project."
              sx={{
                fontSize: '1rem',
              }}
            />
          </>
        }
      />
    </ListItem>
  );
}
