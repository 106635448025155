import type { SelectChangeEvent } from '@mui/material';
import { Collapse, FormControlLabel, Step, StepIcon, StepLabel, Stepper, Switch, TextField } from '@mui/material';
import { Border } from 'components/utils/border';
import { CollapseMore } from 'components/utils/collapse-more';
import { HelpIconTooltip } from 'components/utils/tooltips';
import { getSteps } from 'flows/get-steps-from-flow';
import { defaultTaskStepsToConsider, type BufferTrigger } from 'models/simulation';
import { useCallback, useMemo, useRef } from 'react';
import { setTrigger } from 'simulation/triggers';
import { useAppDispatch, useAppSelector } from 'store';
import { theme } from 'utils/mui-theme';
import { ListFlowsInTriggers } from './list-flows-in-triggers';
import { TriggerPriorityTextfield } from './priority-textfield';

interface BufferTriggerConfigurationProps {
  trigger: BufferTrigger;
}
export function BufferTriggerConfiguration(props: BufferTriggerConfigurationProps): JSX.Element {
  const { trigger } = props;

  const inputRef = useRef<HTMLInputElement>(null);
  const inputRefPriority = useRef<HTMLInputElement>(null);
  const dispatch = useAppDispatch();
  const flows = useAppSelector((state) => state.flows.flows);

  const flowIds = useMemo(() => (Array.isArray(trigger.flowId) ? trigger.flowId : [trigger.flowId]), [trigger.flowId]);

  const flow = useMemo(() => flows.find((flow) => flow.id === flowIds[0]), [flowIds, flows]);

  const flowSteps = useMemo(() => flow && getSteps(flow), [flow]);

  const handleChangeFlow = useCallback(
    (e: SelectChangeEvent<string>, index: number) => {
      const selectFlowId = e.target.value;

      const newFlowIds = flowIds.map((flowId, i) => (i === index ? selectFlowId : flowId));

      dispatch(
        setTrigger({
          ...trigger,
          flowId: newFlowIds,
        })
      );
    },
    [dispatch, flowIds, trigger]
  );

  const handleAddFlow = useCallback(() => {
    dispatch(
      setTrigger({
        ...trigger,
        flowId: [...flowIds, flows[0].id],
      })
    );
  }, [dispatch, flowIds, flows, trigger]);

  const handleRemoveFlow = useCallback(
    (index: number) => {
      const newFlowIds = flowIds.filter((_, i) => i !== index);

      dispatch(
        setTrigger({
          ...trigger,
          flowId: newFlowIds,
        })
      );
    },
    [dispatch, flowIds, trigger]
  );

  const handleUpdateTrigger = useCallback(() => {
    const inputEl = inputRef.current;
    const rawSize = inputEl?.valueAsNumber;
    if (!rawSize || isNaN(rawSize)) {
      return;
    }

    let size = Math.floor(rawSize);
    const min = parseInt(inputEl.min, 10) ?? 0;
    if (size < min) size = min;

    let priority = inputRefPriority.current?.valueAsNumber;
    if (priority === undefined || isNaN(priority)) {
      priority = 1;
    }

    dispatch(
      setTrigger({
        ...trigger,
        size,
        priority: Math.round(priority),
      })
    );

    inputEl.value = size.toString();
  }, [dispatch, trigger]);

  return (
    <Border
      sx={{
        marginTop: theme.spacing(1),
      }}
    >
      <ListFlowsInTriggers
        flowIds={flowIds}
        flows={flows}
        handleAddFlow={handleAddFlow}
        handleChangeFlow={handleChangeFlow}
        handleRemoveFlow={handleRemoveFlow}
        disabled={trigger.linked}
      />

      <TextField
        key={`${trigger.id}-size`}
        label={
          <>
            Size{' '}
            <HelpIconTooltip title="Tasks will be created to keep at least the buffer size in the remaining tasks to be done by the robots." />
          </>
        }
        type="number"
        defaultValue={trigger.size}
        fullWidth
        size="small"
        variant="outlined"
        sx={{
          marginTop: theme.spacing(1),
        }}
        InputProps={{
          endAdornment: 'tasks',
        }}
        inputRef={inputRef}
        onBlur={handleUpdateTrigger}
        inputProps={{
          min: 1,
          step: 1,
        }}
        disabled={trigger.linked}
      />

      <FormControlLabel
        control={
          <Switch
            key={`${trigger.id}-bufferForFlowOnly`}
            checked={trigger.bufferForFlowOnly}
            disabled={trigger.linked}
            onChange={(e) => {
              dispatch(
                setTrigger({
                  ...trigger,
                  bufferForFlowOnly: e.target.checked,
                })
              );
            }}
          />
        }
        label={
          <>
            Buffer for flow only{' '}
            <HelpIconTooltip title="When enabled, the buffer size is considered for the remaining tasks of this flow only." />
          </>
        }
        sx={{
          marginTop: theme.spacing(1),
        }}
      />

      <Collapse in={trigger.bufferForFlowOnly} unmountOnExit>
        <Border>
          <CollapseMore
            title={
              <>
                Steps to consider{' '}
                <HelpIconTooltip title="Tasks after the last selected step will be considered as done by the trigger that will ignore them." />
              </>
            }
            defaultExpended={trigger.taskStepsToConsider !== undefined}
            unmountOnExit
            onChange={(newState) => {
              dispatch(
                setTrigger({
                  ...trigger,
                  taskStepsToConsider: newState ? defaultTaskStepsToConsider : undefined,
                })
              );
            }}
          >
            <Stepper
              key={`${trigger.id}-taskStepsToConsider`}
              activeStep={trigger.taskStepsToConsider ?? defaultTaskStepsToConsider}
              orientation="vertical"
              sx={{
                marginTop: theme.spacing(1),
                marginBottom: theme.spacing(2),
              }}
            >
              {flowSteps?.map((step, index) => (
                <Step key={`${trigger.id}-taskStepsToConsider-${index}`}>
                  <StepLabel
                    onClick={() => {
                      dispatch(
                        setTrigger({
                          ...trigger,
                          taskStepsToConsider: index,
                        })
                      );
                    }}
                    sx={{
                      cursor: 'pointer',
                      textTransform: 'capitalize',
                    }}
                    StepIconComponent={(props) => (
                      <StepIcon
                        active={props.active || props.completed}
                        icon={index + 1}
                        sx={{
                          cursor: 'pointer',
                        }}
                      />
                    )}
                  >
                    {step.missionType}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </CollapseMore>
        </Border>
      </Collapse>

      <TriggerPriorityTextfield
        key={`priority-${trigger.id}`}
        trigger={trigger}
        handleUpdateTrigger={handleUpdateTrigger}
        inputRef={inputRefPriority}
      />
    </Border>
  );
}
