import ExploreIcon from '@mui/icons-material/Explore';
import PushPinIcon from '@mui/icons-material/PushPin';
import { IconButton, Stack, Tooltip } from '@mui/material';
import { zoomToRobot } from '../zoom-to-robot';

interface RobotActionsProps {
  robotId: number;
  isPinned: boolean;
  onPinClick: (robotId: number) => void;
}

export function RobotActions({ robotId, isPinned, onPinClick }: RobotActionsProps): JSX.Element {
  return (
    <Stack direction="row" spacing={1}>
      <Tooltip title="Pin the robot data" arrow disableInteractive>
        <IconButton onClick={() => onPinClick(robotId)}>
          <PushPinIcon color={isPinned ? 'primary' : 'inherit'} />
        </IconButton>
      </Tooltip>
      <Tooltip title="Zoom-in to the robot" arrow disableInteractive>
        <IconButton onClick={() => zoomToRobot(robotId)}>
          <ExploreIcon />
        </IconButton>
      </Tooltip>
    </Stack>
  );
}
