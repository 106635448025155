import { createListenerMiddleware } from '@reduxjs/toolkit';
import { getSimulationDataHandle, waitForGetSimulationDataHandle } from 'components/toolboxes/simulation';
import { startTransition } from 'react';
import { setRobots } from 'robots/robots';
import { clearAllItineraries } from 'routes/routes';
import { SnackbarUtils } from 'services/snackbar.service.tsx';
import { robemuSetBattResponseSchema } from 'simulation/simulation.model.ts';
import store from 'store';
import { clearAllCantons } from 'traffic/traffic';
import {
  setEnableAdvancedDisplayTraffic,
  setEnableCharging,
  setEnableDisplayTraffic,
  setEnableLibPerfo,
  setSimulationDisplayState,
  setSpeedFactor,
} from '../simulation';

const listenerSimulationMiddleware = createListenerMiddleware();

// Add one or more listener entries that look for specific actions.
// They may contain any sync or async logic, similar to thunks.
listenerSimulationMiddleware.startListening({
  actionCreator: setEnableCharging,
  effect: async (action, listenerApi) => {
    const simulationService = (await import('../../services/simulation.service.ts')).simulationService;
    if (!simulationService) return;

    const isSimulationPaused = store.getState().simulation.isSimulationPaused;
    const currentSpeed = store.getState().simulation.speedFactor;

    if (!isSimulationPaused) {
      await simulationService._WEBSIMU_WasmWrapper_setSpeed(0);
    }

    // eslint-disable-next-line no-console
    console.log(`${action.payload ? 'Enabling the battery charging mode' : 'Disabling the battery charging mode'}`);

    const resStrPtr = await simulationService._ROBEMU_WasmWrapper_setBatt(action.payload ? 1 : 0);
    const resStr = await simulationService.UTF8ToString(resStrPtr);
    try {
      const res = robemuSetBattResponseSchema.parse(JSON.parse(resStr));
      if (res.errorID !== 0) {
        // eslint-disable-next-line no-console
        console.error(`Failed to set the battery: ${res.error}`);
        SnackbarUtils.error(`Failed to set the battery: ${res.error}`);
      } else {
        // eslint-disable-next-line no-console
        console.log(action.payload ? 'Battery charging mode enabled' : 'Battery charging mode disabled');
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Failed to parse battery response:', error);
      SnackbarUtils.error(`Failed to parse battery response: ${error}`);
    }

    if (!isSimulationPaused) {
      await simulationService._WEBSIMU_WasmWrapper_setSpeed(currentSpeed);
    }
  },
});

listenerSimulationMiddleware.startListening({
  actionCreator: setSpeedFactor,
  effect: async (action, listenerApi) => {
    const simulationService = (await import('../../services/simulation.service.ts')).simulationService;
    if (!simulationService) return;

    const speedFactor = action.payload;
    const isSimulationPaused = store.getState().simulation.isSimulationPaused;
    const isSimulationRunning = store.getState().simulation.isSimulationRunning;
    const fastRefreshEnabled = store.getState().simulation.enableFastRefresh;
    const waitForGetSimulationData = waitForGetSimulationDataHandle;
    const getSimulationData = getSimulationDataHandle;

    if (waitForGetSimulationData) await waitForGetSimulationData.current;
    if (!isSimulationPaused && isSimulationRunning) {
      await simulationService._WEBSIMU_WasmWrapper_setSpeed(speedFactor);
    }

    if (!fastRefreshEnabled && !isSimulationPaused) {
      if (waitForGetSimulationData) await waitForGetSimulationData.current;
      if (getSimulationData) await getSimulationData();
    }

    if (speedFactor !== 0) {
      // we do not remove the itineraries when the simulation is paused
      listenerApi.dispatch(clearAllItineraries());
      listenerApi.dispatch(clearAllCantons());
    }
  },
});

listenerSimulationMiddleware.startListening({
  actionCreator: setEnableDisplayTraffic,
  effect: (action, listenerApi) => {
    startTransition(() => {
      listenerApi.dispatch(clearAllCantons());
      listenerApi.dispatch(clearAllItineraries());
    });
  },
});

listenerSimulationMiddleware.startListening({
  actionCreator: setEnableAdvancedDisplayTraffic,
  effect: (action, listenerApi) => {
    startTransition(() => {
      listenerApi.dispatch(clearAllCantons());
      listenerApi.dispatch(clearAllItineraries());
    });
  },
});

listenerSimulationMiddleware.startListening({
  actionCreator: setSimulationDisplayState,
  effect: async (action, listenerApi) => {
    const enableDisplay = action.payload;

    const getSimulationData = getSimulationDataHandle;

    if (!enableDisplay) {
      startTransition(() => {
        listenerApi.dispatch(setRobots([]));
        listenerApi.dispatch(clearAllCantons());
        listenerApi.dispatch(clearAllItineraries());
      });
    } else {
      if (getSimulationData) await getSimulationData(true);
    }
  },
});

listenerSimulationMiddleware.startListening({
  actionCreator: setEnableLibPerfo,
  effect: async (action, listenerApi) => {
    const simulationService = (await import('../../services/simulation.service.ts')).simulationService;
    if (!simulationService) return;

    if (action.payload) {
      await simulationService._ROBEMU_WasmWrapper_enablePerfo();
    } else {
      await simulationService._ROBEMU_WasmWrapper_disablePerfo();
    }
  },
});

export default listenerSimulationMiddleware;
