import { useDebouncedValue } from '@mantine/hooks';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import { Card, CardContent, CardHeader, Collapse, IconButton } from '@mui/material';
import { Box } from '@mui/system';
import { type GridColDef } from '@mui/x-data-grid';
import { positionIdToPositionName } from 'flows/position-id-to-position-name';
import { useCallback, useEffect, useState } from 'react';
import { robotTaskStateNbToState } from 'robots/states';
import { useAppDispatch, useAppSelector } from 'store';
import { theme } from 'utils/mui-theme';
import { RobotActions } from './components/robot-actions';
import { RobotInfoIndicators } from './components/robot-info-indicators';
import { StripedDataGrid, maxLinesFoldedArr, type RobotSummaryInfo } from './scheduler/task-and-robots-scheduler-info';
import { setPinnedRobots } from './simulation';
import { taskStrToNumber } from './states';

interface RobotsTableProps {
  rows: RobotSummaryInfo[];
  handleEditPin: (rowId: number) => void;
}

export default function RobotsSummaryTable({ rows, handleEditPin }: RobotsTableProps): JSX.Element {
  const columns: GridColDef[] = [
    { field: 'name', headerName: 'Name', type: 'string', minWidth: 150 },
    {
      field: 'status',
      headerName: 'Status',
      type: 'singleSelect',
      minWidth: 150,
    },
    { field: 'destination', headerName: 'Destination', minWidth: 200 },
    { field: 'taskName', headerName: 'Task Name', type: 'string', minWidth: 200 },
    {
      field: 'batteryLevel',
      headerName: 'Info',
      type: 'number',
      minWidth: 100,
      renderCell: ({ row }: { row: RobotSummaryInfo }) => (
        <RobotInfoIndicators
          batteryLevel={row.batteryLevel}
          isCharging={row.isCharging}
          robotState={row.state}
          trafficData={row.trafficData}
        />
      ),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      minWidth: 100,
      renderCell: ({ row }: { row: RobotSummaryInfo }) => (
        <RobotActions robotId={row.id} isPinned={row.pin} onPinClick={handleEditPin} />
      ),
    },
  ];

  return (
    <StripedDataGrid
      columns={columns}
      rows={rows}
      disableRowSelectionOnClick
      getCellClassName={(params) => {
        if (params.field === 'status' && params.value) return `task-status ${params.value.toString()}`;

        return '';
      }}
      sx={{ maxHeight: 'inherit' }}
      initialState={{
        pagination: { paginationModel: { pageSize: 25 } },
      }}
    ></StripedDataGrid>
  );
}

interface RobotsSummaryViewProps {
  foldedRobots: boolean;
  setFoldedRobots: React.Dispatch<React.SetStateAction<boolean>>;
}

export const RobotsSummaryView = ({ foldedRobots, setFoldedRobots }: RobotsSummaryViewProps): JSX.Element => {
  const tasks = useAppSelector((state) => state.simulation.tasks);
  const robots = useAppSelector((state) => state.robots.robots);
  const pinnedRobots = useAppSelector((state) => state.simulation.pinnedRobots);
  const dispatch = useAppDispatch();

  const collapseTime = theme.transitions.duration.short;

  const [rows, setRows] = useState<RobotSummaryInfo[] | undefined>(undefined);

  useEffect(() => {
    const robotsArr = robots.map((robot) => {
      const state = robot.state;

      const taskTxt = state?.task !== undefined ? robotTaskStateNbToState(state.task) : 'unknown';

      const runningTaskStateCode = taskStrToNumber('running');
      const preRunningTaskStateCode = taskStrToNumber('prerunning');
      const currentTask = Object.values(tasks).find(
        (task) => [runningTaskStateCode, preRunningTaskStateCode].includes(task.state) && task.robotID === robot.id
      );

      const destination = currentTask?.dst;

      const destinationName = destination ? positionIdToPositionName(destination.toString(), 'all') : undefined;

      const taskName = currentTask?.taskName;

      const isPinnedRobot = !!pinnedRobots.find((id) => id === robot.id);

      const isCharging = !!robot?.battery?.isCharging;

      return {
        id: robot.id,
        name: robot.name,
        status: taskTxt,
        destination: destinationName,
        taskName: taskName,
        pin: isPinnedRobot,
        batteryLevel: robot.battery?.level,
        isCharging,
        state: {
          task: robot.state?.task,
          navigation: robot.state?.navigation,
          traffic: robot.state?.traffic,
          action: robot.action,
        },
        trafficData: robot.trafficData,
      } as RobotSummaryInfo;
    });

    setRows(robotsArr);
  }, [pinnedRobots, robots, tasks]);

  const [showSummary, setShowSummary] = useState(false);
  const [showSummaryDebounced] = useDebouncedValue(showSummary, collapseTime);

  const handleClickShowSummary = useCallback((): void => {
    setShowSummary(!showSummary);
  }, [showSummary]);

  const handleEditPin = (rowId: number): void => {
    if (!rows) return;
    const updatedRows = rows.map((row) => {
      if (row.id === rowId) {
        return { ...row, pin: !row.pin };
      }

      return row;
    });

    setRows(updatedRows);

    dispatch(setPinnedRobots(rowId));
  };

  useEffect(() => {
    if (!showSummary && !foldedRobots) {
      setFoldedRobots(!foldedRobots);
    }
  }, [foldedRobots, setFoldedRobots, showSummary]);

  return (
    <div style={{ pointerEvents: 'none', marginTop: theme.spacing(1) }}>
      <Card
        sx={{
          pointerEvents: 'initial',
          margin: 0,
        }}
      >
        <CardHeader
          sx={{
            userSelect: 'none',
            paddingBottom: theme.spacing(0.5),
            paddingTop: theme.spacing(0.5),
            marginTop: 0,
          }}
          avatar={<SmartToyIcon></SmartToyIcon>}
          title="Robots summary view"
          action={
            <>
              {robots.length > maxLinesFoldedArr && showSummary && (
                <IconButton
                  title={foldedRobots ? 'Expand' : 'Fold'}
                  onClick={() => setFoldedRobots(!foldedRobots)}
                  size="large"
                >
                  {foldedRobots ? <UnfoldMoreIcon /> : <UnfoldLessIcon />}
                </IconButton>
              )}

              <IconButton
                title={showSummary ? 'Hide' : 'Show'}
                aria-label="Show layers"
                onClick={handleClickShowSummary}
                size="large"
              >
                {!showSummary ? <ExpandLessIcon></ExpandLessIcon> : <ExpandMoreIcon></ExpandMoreIcon>}
              </IconButton>
            </>
          }
        ></CardHeader>

        <Collapse in={showSummary} timeout={collapseTime}>
          <CardContent
            sx={{
              padding: theme.spacing(1),
              marginTop: 0,
              paddingTop: 0,
              paddingBottom: `${theme.spacing(1)} !important`,
              textAlign: 'left',
            }}
          >
            <Box
              component="div"
              sx={{ padding: 0, maxHeight: foldedRobots ? '250px' : '76vh', maxWidth: '60vw', width: '60vw' }}
            >
              {(showSummary || showSummaryDebounced) && rows ? (
                <RobotsSummaryTable rows={rows} handleEditPin={handleEditPin}></RobotsSummaryTable>
              ) : null}
            </Box>
          </CardContent>
        </Collapse>
      </Card>
    </div>
  );
};
