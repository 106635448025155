import DesktopWindowsOutlinedIcon from '@mui/icons-material/DesktopWindowsOutlined';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import GpsOffIcon from '@mui/icons-material/GpsOff';
import HelpIcon from '@mui/icons-material/Help';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import WarningIcon from '@mui/icons-material/Warning';
import type { SelectChangeEvent } from '@mui/material';
import {
  Alert,
  Button,
  Collapse,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Select,
  Switch,
  TextField,
  Tooltip,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Box } from '@mui/system';
import { booleanPointInPolygon } from '@turf/turf';
import { clearShapesSelectionAction, selectCircuitShapeAction } from 'actions/circuit';
import { saveZoneAction } from 'actions/zones';
import { Border } from 'components/utils/border';
import { HelpIconTooltip, WarningIconTooltip } from 'components/utils/tooltips';
import { useSdkVersionOk } from 'components/utils/use-sdk-version-ok';
import type { Broadcast, CircuitZone, DeviceInDoor } from 'models/circuit';
import { Intersection, ShapeTypes, broadcastDefaultValue } from 'models/circuit';
import React, { startTransition, useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { CircuitService } from 'services/circuit.service';
import store, { useAppDispatch, useAppSelector } from 'store';
import { areAllShapeNamesUnique } from 'utils/circuit/are-shape-names-unique';
import { getDefaultdAsk, getDefaultdStop } from 'utils/circuit/default-circuit-shapes';
import { MIN_VERSION_NEW_ROOF_HEIGHT } from 'utils/config';
import { theme } from 'utils/mui-theme';
import { PreferencesService } from 'utils/preferences';
import { ElevatorOptions } from '../components/elevator-options';
import { DoorSettings } from '../doors-settings';
import { PropertiesComponent } from '../properties-component';
import { AllowByIdSwitch } from './allow-by-id-switch';
import { BroadcastSelect } from './broadcast-select';
import { SelectZoneEvents } from './select-zone-events';
import { UpdateAisleBtn } from './update-aisle-btn';

const stopDurationMin = 0; // s
const stopDurationMax = 60; // s

const componentWidth = 400;

const formElementStyle = {
  margin: theme.spacing(1),
  width: componentWidth,
  textAlign: 'left',
};

const useStyles = makeStyles((theme) =>
  createStyles({
    suggestedName: {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
    helperText: {
      maxWidth: 'fit-content',
      textAlign: 'center',
    },

    helpTooltip: {
      verticalAlign: 'middle',
      cursor: 'help',
      opacity: 0.7,
      maxHeight: '20px',
      color: 'rgba(0, 0, 0, 0.54)',
    },
  })
);

export interface ZonePropertiesProps {
  zone?: CircuitZone;
  onSubmit: (payload: Partial<CircuitZone>) => void;
  onDelete: (payload: string) => void;
}

export function ZonePropertiesComponent({ zone, onSubmit, onDelete }: ZonePropertiesProps): JSX.Element | null {
  const zoneId = zone?.id;

  if (!zone || !zoneId) {
    return null;
  }

  return <ZonePropertiesForm key={zoneId} zone={zone} zoneId={zoneId} onSubmit={onSubmit} onDelete={onDelete} />;
}

interface ZonePropertiesFormProps {
  zone: CircuitZone;
  zoneId: string | number;
  onSubmit: (payload: Partial<CircuitZone>) => void;
  onDelete: (payload: string) => void;
}

export interface ZoneFormValues {
  name: string;

  maxSpeedEnabled: boolean;
  maxSpeed?: number | string;

  door: boolean;

  roofHeightEnabled: boolean;
  roofHeight?: number | string;

  noParking: boolean;
  noBeep: boolean;

  hornEnteringEnabled: boolean;
  hornLeavingEnabled: boolean;
  hornInsideEnabled: boolean;

  stopEnteringEnabled: boolean;
  stopEntering?: number | string;

  stopLeavingEnabled: boolean;
  stopLeaving?: number | string;

  intersectionType: Intersection;

  blackHoleLocalisation: boolean;

  curtainInhibitionEnabled: boolean;
  curtainInhibition?: number | string;

  safetyManualAck?: number | string;

  batteryCharger: boolean;

  specificBatteryManagement: boolean;

  limitRobotCountEnabled: boolean;
  limitRobotCount?: number | string; // int > 0

  doordAsk: number;
  doordStop: number;
  doorDevices: DeviceInDoor[];

  broadcast: Broadcast;

  trafficById: boolean;
  floor?: boolean;
  floorLevel?: string | number;
  elevator?: boolean;
  elevatorName?: string | number;
}

const zoneDefaultMaxSpeed = 2.0; // m/s
const zoneDefaultRoofHeight = 5.0; // m
const zoneDefaultStopDuration = 2.0; // s
const zoneDefaultCurtainInhibition = 0.05; // m
const zoneDefaultMaxLimitRobotCount = 1; // [1]

const dAskDefaultValue = getDefaultdAsk(); // m
const dStopDefaultValue = getDefaultdStop(); // m

function ZonePropertiesForm({ zone, zoneId, onSubmit, onDelete }: ZonePropertiesFormProps): JSX.Element | null {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const roadEditorStandardMode = useAppSelector((state) => state.local.standardMode);

  const [displayElevatorOptions, setDisplayElevatorOptions] = useState(false);

  useEffect(() => {
    let isUseElevatorEnabled: boolean | undefined;
    try {
      isUseElevatorEnabled = !!Number(PreferencesService.getPreferenceValue('general/useElevator'));
    } catch (e) {}

    if (!isUseElevatorEnabled) return;

    setDisplayElevatorOptions(true);
  }, []);

  const initialState: ZoneFormValues = useMemo(() => {
    const maxSpeedIndex = zone.properties.rules.findIndex((rule) => rule[0] === 'LimitSpeed');
    const doorIndex = zone.properties.rules.findIndex((rule) => rule[0] === 'Door');
    const roofHeightIndex = zone.properties.rules.findIndex((rule) => rule[0] === 'RoofHeight');
    const noParkingIndex = zone.properties.rules.findIndex((rule) => rule[0] === 'NoParking');
    const noBeepIndex = zone.properties.rules.findIndex((rule) => rule[0] === 'NoBeepInside');
    const hornEnteringIndex = zone.properties.rules.findIndex((rule) => rule[0] === 'HornEntering');
    const hornLeavingIndex = zone.properties.rules.findIndex((rule) => rule[0] === 'HornLeaving');
    const hornInsideIndex = zone.properties.rules.findIndex((rule) => rule[0] === 'HornInside');
    const stopEnteringIndex = zone.properties.rules.findIndex((rule) => rule[0] === 'StopEntering');
    const stopLeavingIndex = zone.properties.rules.findIndex((rule) => rule[0] === 'StopLeaving');
    const blackHoleLocalisationIndex = zone.properties.rules.findIndex((rule) => rule[0] === 'BlackHole');
    const curtainInhibitionIndex = zone.properties.rules.findIndex((rule) => rule[0] === 'CurtainInhibitedArea');
    const batteryChargerIndex = zone.properties.rules.findIndex((rule) => rule[0] === 'BatteryCharger');
    const specificBatteryManagementIndex = zone.properties.rules.findIndex((rule) => rule[0] === 'NoHardSafetyInside');
    const limitRobotCountIndex = zone.properties.rules.findIndex((rule) => rule[0] === 'LimitAgvCount');
    const safetyManualAckIndex = zone.properties.rules.findIndex((rule) => rule[0] === 'SafetyManualAck');
    const trafficByIdIndex = zone.properties.rules.findIndex((rule) => rule[0] === 'TrafficByID');
    const elevatorNameIndex = zone.properties.rules.findIndex((rule) => rule[0] === 'ElevatorName');
    const floorNameIndex = zone.properties.rules.findIndex((rule) => rule[0] === 'FloorName');

    const isFloor = floorNameIndex !== -1;
    const floorLevel = isFloor ? zone.properties.rules[floorNameIndex][1] : undefined;
    const isElevator = elevatorNameIndex !== -1;
    const elevatorName = isElevator ? zone.properties.rules[elevatorNameIndex][1] : undefined;

    const doorEnabled = doorIndex !== -1;

    return {
      name: zone.properties.name,
      displayHMI: zone.properties.displayHMI,
      maxSpeedEnabled: maxSpeedIndex !== -1,
      maxSpeed: maxSpeedIndex !== -1 ? zone.properties.rules[maxSpeedIndex][1] : zoneDefaultMaxSpeed,
      door: doorEnabled,
      roofHeightEnabled: roofHeightIndex !== -1,
      roofHeight: roofHeightIndex !== -1 ? zone.properties.rules[roofHeightIndex][1] : zoneDefaultRoofHeight,
      noParking: noParkingIndex !== -1,
      noBeep: noBeepIndex !== -1,
      hornEnteringEnabled: hornEnteringIndex !== -1,
      hornLeavingEnabled: hornLeavingIndex !== -1,
      hornInsideEnabled: hornInsideIndex !== -1,
      stopEnteringEnabled: stopEnteringIndex !== -1,
      stopEntering: stopEnteringIndex !== -1 ? zone.properties.rules[stopEnteringIndex][1] : zoneDefaultStopDuration,
      stopLeavingEnabled: stopLeavingIndex !== -1,
      stopLeaving: stopLeavingIndex !== -1 ? zone.properties.rules[stopLeavingIndex][1] : zoneDefaultStopDuration,
      blackHoleLocalisation: blackHoleLocalisationIndex !== -1,
      intersectionType: zone.properties.intersectionType,
      curtainInhibitionEnabled: curtainInhibitionIndex !== -1,
      curtainInhibition:
        curtainInhibitionIndex !== -1 ? zone.properties.rules[curtainInhibitionIndex][1] : zoneDefaultCurtainInhibition,
      batteryCharger: batteryChargerIndex !== -1,
      specificBatteryManagement: specificBatteryManagementIndex !== -1,
      limitRobotCountEnabled: limitRobotCountIndex !== -1,
      limitRobotCount:
        limitRobotCountIndex !== -1 ? zone.properties.rules[limitRobotCountIndex][1] : zoneDefaultMaxLimitRobotCount,
      safetyManualAck: safetyManualAckIndex !== -1 ? zone.properties.rules[safetyManualAckIndex][1] : 0,

      doordAsk: doorEnabled && zone.properties.door?.dAsk !== undefined ? zone.properties.door.dAsk : dAskDefaultValue,
      doordStop:
        doorEnabled && zone.properties.door?.dStop !== undefined ? zone.properties.door.dStop : dStopDefaultValue,
      doorDevices: doorEnabled && zone.properties.door?.devices !== undefined ? zone.properties.door.devices : [],

      broadcast: zone.properties.broadcast ?? broadcastDefaultValue,
      trafficById: trafficByIdIndex !== -1,
      floor: isFloor,
      floorLevel: floorLevel,
      elevator: isElevator,
      elevatorName,
    };
  }, [zone.properties]);
  const [formValues, setFormValues] = useState<ZoneFormValues>(initialState);

  const [errorName, setErrorName] = useState(false);
  const [suggestedName, setSuggestedName] = useState('');
  const onNameChangeHandler = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      event.persist();
      const checkNameSpaceInput = event.target.value;
      const newName = checkNameSpaceInput;
      setFormValues((state) => ({ ...state, name: newName }));

      if (
        !newName ||
        !areAllShapeNamesUnique([newName], [zoneId as string], {
          ignoreDuplicatesBefore: true,
        })
      ) {
        setErrorName(true);
        setSuggestedName(
          CircuitService.generateDifferentName(newName, {
            shapeIdsToIgnore: [zoneId as string],
          })
        );
      } else {
        setErrorName(false);
      }
    },
    [zoneId]
  );
  const setSuggestedNameHandler = useCallback(() => {
    setFormValues((state) => ({ ...state, name: suggestedName }));
    setErrorName(false);
  }, [suggestedName]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useMemo(() => setErrorName(false), [zoneId]);

  const projectOpened = PreferencesService.arePreferencesFullyLoaded();

  const [comments, setComments] = useState(
    zone && zone.properties && zone.properties.comments && zone.properties.comments.length
      ? zone.properties.comments
      : ['']
  );

  const handleVisible = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, newValue: boolean) => {
      dispatch(
        saveZoneAction({
          ...zone,
          properties: {
            ...zone.properties,
            displayHMI: newValue,
          },
          userAction: true,
        })
      );
    },
    [dispatch, zone]
  );

  const handleCommentsChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      const el = e.target;
      const val = el.value;
      const commentId = el.dataset.commentid;
      if (!commentId) return;

      const id = parseInt(commentId, 10);

      const newVals = [...comments];
      newVals[id] = val;

      setComments(newVals);
    },
    [comments]
  );

  const handleSaveComments = useCallback(
    (e): void => {
      let newVals = [...comments];
      newVals = newVals.filter((com) => !!com.length);
      newVals.push('');

      setComments(newVals);

      dispatch(
        saveZoneAction({
          ...zone,
          properties: {
            ...zone.properties,
            comments: newVals,
          },
          userAction: true,
        })
      );
    },
    [comments, dispatch, zone]
  );

  const updateZone = useCallback(() => {
    setFormValues((state) => ({ ...state, name: formValues.name.trim() }));
    const newState: CircuitZone = {
      ...zone,
      properties: {
        ...zone.properties,
        name: formValues.name.trim(),
        intersectionType: formValues.intersectionType,

        door: formValues.door
          ? { enabled: true, dAsk: formValues.doordAsk, dStop: formValues.doordStop, devices: formValues.doorDevices }
          : undefined,

        broadcast: formValues.broadcast,
      },
    };

    if (newState.properties.broadcast === broadcastDefaultValue) {
      delete newState.properties.broadcast;
    }

    newState.properties.rules = [];
    if (formValues.door) {
      newState.properties.rules.push(['Door']);
    }

    if (formValues.elevatorName) {
      newState.properties.rules.push(['ElevatorName', formValues.elevatorName]);
    }

    if (formValues.floorLevel) {
      newState.properties.rules.push(['FloorName', formValues.floorLevel]);
    }

    if (formValues.maxSpeedEnabled) {
      newState.properties.rules.push([
        'LimitSpeed',
        typeof formValues.maxSpeed === 'string' ? parseFloat(formValues.maxSpeed) : formValues.maxSpeed,
      ]);
    }

    if (formValues.roofHeightEnabled) {
      newState.properties.rules.push([
        'RoofHeight',
        typeof formValues.roofHeight === 'string' ? parseFloat(formValues.roofHeight) : formValues.roofHeight,
      ]);
    }

    if (formValues.noParking) {
      newState.properties.rules.push(['NoParking']);
    }

    if (formValues.noBeep) {
      newState.properties.rules.push(['NoBeepInside']);
    }

    if (formValues.hornEnteringEnabled) {
      newState.properties.rules.push(['HornEntering']);
    }

    if (formValues.hornLeavingEnabled) {
      newState.properties.rules.push(['HornLeaving']);
    }

    if (formValues.hornInsideEnabled) {
      newState.properties.rules.push(['HornInside']);
    }

    if (formValues.stopEnteringEnabled) {
      newState.properties.rules.push([
        'StopEntering',
        typeof formValues.stopEntering === 'string' ? parseFloat(formValues.stopEntering) : formValues.stopEntering,
      ]);
    }

    if (formValues.stopLeavingEnabled) {
      newState.properties.rules.push([
        'StopLeaving',
        typeof formValues.stopLeaving === 'string' ? parseFloat(formValues.stopLeaving) : formValues.stopLeaving,
      ]);
    }

    if (formValues.blackHoleLocalisation) {
      newState.properties.rules.push(['BlackHole']);
    }

    if (formValues.curtainInhibitionEnabled) {
      newState.properties.rules.push([
        'CurtainInhibitedArea',
        typeof formValues.curtainInhibition === 'string'
          ? parseFloat(formValues.curtainInhibition)
          : formValues.curtainInhibition,
      ]);
    }

    if (formValues.safetyManualAck) {
      newState.properties.rules.push([
        'SafetyManualAck',
        typeof formValues.safetyManualAck === 'string'
          ? parseInt(formValues.safetyManualAck, 10)
          : formValues.safetyManualAck,
      ]);
    }

    if (formValues.batteryCharger) {
      newState.properties.rules.push(['BatteryCharger']);
    }

    if (formValues.specificBatteryManagement) {
      newState.properties.rules.push(['NoHardSafetyInside']);
    }

    if (formValues.limitRobotCountEnabled) {
      newState.properties.rules.push(['LimitAgvCount', +(formValues.limitRobotCount ?? -1)]);
    }

    if (formValues.trafficById) {
      newState.properties.rules.push(['TrafficByID']);
    }

    if (errorName) {
      newState.properties.name = suggestedName;
    }

    startTransition(() => dispatch(saveZoneAction(newState)));
  }, [
    dispatch,
    errorName,
    formValues.batteryCharger,
    formValues.blackHoleLocalisation,
    formValues.broadcast,
    formValues.curtainInhibition,
    formValues.curtainInhibitionEnabled,
    formValues.door,
    formValues.doorDevices,
    formValues.doordAsk,
    formValues.doordStop,
    formValues.elevatorName,
    formValues.floorLevel,
    formValues.hornEnteringEnabled,
    formValues.hornInsideEnabled,
    formValues.hornLeavingEnabled,
    formValues.intersectionType,
    formValues.limitRobotCount,
    formValues.limitRobotCountEnabled,
    formValues.maxSpeed,
    formValues.maxSpeedEnabled,
    formValues.name,
    formValues.noBeep,
    formValues.noParking,
    formValues.roofHeight,
    formValues.roofHeightEnabled,
    formValues.safetyManualAck,
    formValues.specificBatteryManagement,
    formValues.stopEntering,
    formValues.stopEnteringEnabled,
    formValues.stopLeaving,
    formValues.stopLeavingEnabled,
    formValues.trafficById,
    suggestedName,
    zone,
  ]);

  const [askUpdateZone, setAskUpdateZone] = useState(false);

  useLayoutEffect(() => {
    if (askUpdateZone) {
      setAskUpdateZone(false);

      updateZone();
    }
  }, [askUpdateZone, updateZone]);

  const handleChangeBlackhole = useCallback((e): void => {
    setFormValues((state) => {
      const newState: ZoneFormValues = {
        ...state,
        blackHoleLocalisation: !state.blackHoleLocalisation,
      };

      return newState;
    });

    setAskUpdateZone(true);
  }, []);

  const handleChangeFormValue = useCallback(
    (
      e:
        | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        | SelectChangeEvent<Intersection>
        | SelectChangeEvent<number | string>,
      fieldName: keyof ZoneFormValues,
      roundValue = false
    ) => {
      const input = e.target;
      let newValue = input.value;

      if (roundValue && newValue !== '') {
        newValue = parseInt(input.value.toString(), 10).toFixed(0);
      }

      const min = parseFloat('min' in input ? input.min : '');
      const max = parseFloat('max' in input ? input.max : '');

      if (!isNaN(min) && parseFloat(newValue.toString()) < min) {
        newValue = min.toString();
      } else if (!isNaN(max) && parseFloat(newValue.toString()) > max) {
        newValue = max.toString();
      }

      setFormValues((state) => ({
        ...state,
        [fieldName]: newValue,
      }));

      setAskUpdateZone(true);
    },
    []
  );
  const handleBlurFormValue = useCallback((e: React.FocusEvent) => {
    setAskUpdateZone(true);
  }, []);
  const handleKeyPressFormValue = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === 'Enter') {
        setAskUpdateZone(true);
      } else if (e.key === 'Escape') {
        setFormValues(initialState);
      }
    },
    [initialState]
  );

  const handleNoParkingChange = useCallback((): void => {
    setFormValues((state) => {
      const newNoParkingState = !state.noParking;

      return { ...state, noParking: newNoParkingState };
    });

    setAskUpdateZone(true);
  }, []);

  const handleBatteryChargerChange = useCallback((): void => {
    setFormValues((state) => {
      const newBatteryChargerState = !state.batteryCharger;

      return { ...state, batteryCharger: newBatteryChargerState };
    });

    setAskUpdateZone(true);
  }, []);

  const handleSpecificBatteryManagementChange = useCallback((): void => {
    setFormValues((state) => {
      const newSpecificBatteryManagementState = !state.specificBatteryManagement;

      return { ...state, specificBatteryManagement: newSpecificBatteryManagementState };
    });

    setAskUpdateZone(true);
  }, []);

  const handleNoBeepChange = useCallback((): void => {
    setFormValues((state) => {
      const newNoBeepState = !state.noBeep;

      return { ...state, noBeep: newNoBeepState };
    });

    setAskUpdateZone(true);
  }, []);

  const handleHornEnteringChange = useCallback((): void => {
    setFormValues((state) => {
      const newHornEnteringState = !state.hornEnteringEnabled;

      return { ...state, hornEnteringEnabled: newHornEnteringState };
    });

    setAskUpdateZone(true);
  }, []);

  const handleHornLeavingChange = useCallback((): void => {
    setFormValues((state) => {
      const newHornLeavingState = !state.hornLeavingEnabled;

      return { ...state, hornLeavingEnabled: newHornLeavingState };
    });

    setAskUpdateZone(true);
  }, []);

  const handleHornInsideChange = useCallback((): void => {
    setFormValues((state) => {
      const newHornInsideState = !state.hornInsideEnabled;

      return { ...state, hornInsideEnabled: newHornInsideState };
    });

    setAskUpdateZone(true);
  }, []);

  const locked = !!zone?.properties?.locked;
  const isConveyorZone = !!zone?.properties?.conveyor;
  const conveyorName = useMemo(() => {
    if (isConveyorZone) {
      const rackId = zone?.properties?.conveyor;
      const rack = rackId ? store.getState().circuit.present.racks.entities[rackId] : undefined;

      return rack?.properties?.name;
    }

    return undefined;
  }, [isConveyorZone, zone?.properties?.conveyor]);
  const isConveyorIncludedInZone = useMemo(() => {
    if (!isConveyorZone) return undefined; // the zone is not linked to a conveyor, this property is not relevant in this case

    const rackId = zone?.properties?.conveyor;
    const rack = rackId ? store.getState().circuit.present.racks.entities[rackId] : undefined;

    if (!rack) throw new Error('Rack associated with the zone for the conveyor not found');

    const rackCoordinates = rack.geometry.coordinates[0];

    for (let i = 0; i < rackCoordinates.length; i++) {
      const point = rackCoordinates[i];

      // we test every point of the geometry of the rack to test if they are all inside the zone
      if (!booleanPointInPolygon(point, zone)) {
        return false;
      }
    }

    return true;
  }, [isConveyorZone, zone]);

  const [moreActionsExpanded, setMoreActionsExpanded] = useState(false);

  const NEW4XVersion = PreferencesService.getNEW4XCoreVersion();

  const minimumSDKVersionSafetyManualAck = '4.14.0';
  const isSdkVersionOkSafetyManualAck = useSdkVersionOk(minimumSDKVersionSafetyManualAck);

  const minimumSDKVersionTrafficById = '4.15.1';
  const isSdkVersionOkTrafficById = useSdkVersionOk(minimumSDKVersionTrafficById);

  const safetyManualAckLabel = (
    <>
      Safety Manual Acknowledgement{' '}
      <HelpIconTooltip title="Requires a manual acknowledgement from the operator when the robot safety is triggered" />
      {!isSdkVersionOkSafetyManualAck && (
        <WarningIconTooltip title={`Requires SDK version ${minimumSDKVersionSafetyManualAck} or higher`} />
      )}
    </>
  );

  const handleDoorChange = useCallback((e): void => {
    setFormValues((state) => {
      const newState: ZoneFormValues = {
        ...state,
        door: !state.door,
        intersectionType: !state.door ? Intersection.GabaritIntersection : Intersection.PointIntersection,
      };

      return newState;
    });

    setAskUpdateZone(true);
  }, []);

  return (
    <PropertiesComponent
      shape={zone}
      shapeId={zoneId as string}
      shapeType={ShapeTypes.ZoneShape}
      disableDelete={isConveyorZone}
      disableCopy={isConveyorZone}
    >
      {isConveyorZone && (
        <Alert severity="info">
          This zone is linked to the conveyor <strong>{conveyorName}</strong>
          <br />
          <Button
            onClick={() => {
              const rackId = zone?.properties?.conveyor;
              if (!rackId) throw new Error('Conveyor not found');
              dispatch(clearShapesSelectionAction());
              dispatch(
                selectCircuitShapeAction({
                  selectedShapeId: rackId,
                  selectedShapeType: ShapeTypes.RackShape,
                })
              );
            }}
            variant="contained"
            sx={{
              marginTop: theme.spacing(1),
              textTransform: 'none',
            }}
          >
            Open the conveyor properties
          </Button>
        </Alert>
      )}
      {isConveyorIncludedInZone === false && (
        <Alert
          severity="warning"
          sx={{
            marginTop: theme.spacing(1),
          }}
        >
          The conveyor is not fully included in the zone.
        </Alert>
      )}
      <FormControl>
        <TextField
          value={formValues.name}
          onChange={onNameChangeHandler}
          onBlur={(e) => updateZone()}
          onKeyPress={(e) => handleKeyPressFormValue(e)}
          fullWidth
          margin="normal"
          label="Name"
          disabled={locked}
          error={errorName}
          variant="standard"
          sx={{
            ...formElementStyle,
            textDecorations: errorName ? 'line-through' : 'none',
          }}
        />
        <FormHelperText className={classes.helperText} error id="suggested-name-zone">
          {errorName ? (
            <>
              Name already used, suggested name:{' '}
              <span onClick={setSuggestedNameHandler} className={classes.suggestedName}>
                {suggestedName}
              </span>
            </>
          ) : (
            <></>
          )}
        </FormHelperText>
      </FormControl>

      <FormControl>
        <TextField
          value={formValues.maxSpeedEnabled ? formValues.maxSpeed : ''}
          onChange={(e) => handleChangeFormValue(e, 'maxSpeed')}
          onBlur={(e) => handleBlurFormValue(e)}
          onKeyPress={(e) => handleKeyPressFormValue(e)}
          fullWidth
          margin="normal"
          type="number"
          label={
            <>
              Maximum speed{' '}
              <Tooltip className={classes.helpTooltip} title="Add a speed limit for all robots in this area.">
                <HelpIcon />
              </Tooltip>
            </>
          }
          inputProps={{ step: 0.1, lang: 'en', 'aria-label': 'Maximum speed', min: 0 }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Switch
                  checked={formValues.maxSpeedEnabled}
                  onChange={(e) => {
                    setFormValues((state) => ({ ...state, maxSpeedEnabled: !state.maxSpeedEnabled }));
                    setAskUpdateZone(true);
                  }}
                  color="primary"
                  disabled={locked}
                />
              </InputAdornment>
            ),
            endAdornment: <InputAdornment position="end">m/s</InputAdornment>,
          }}
          disabled={locked || !formValues.maxSpeedEnabled}
          variant="standard"
          sx={formElementStyle}
        />
      </FormControl>

      <List
        component="nav"
        sx={{
          color: locked ? 'rgba(0, 0, 0, 0.38)' : undefined,
        }}
      >
        <ListItemButton onClick={() => setMoreActionsExpanded((prevState) => !prevState)}>
          <ListItemIcon>
            <NotificationsActiveIcon />
          </ListItemIcon>
          <ListItemText primary="Actions" />
          {moreActionsExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ListItemButton>
        <Collapse in={moreActionsExpanded} timeout="auto" unmountOnExit>
          <Border>
            <TextField
              value={formValues.roofHeightEnabled ? formValues.roofHeight : ''}
              onChange={(e) => handleChangeFormValue(e, 'roofHeight')}
              onBlur={(e) => handleBlurFormValue(e)}
              onKeyPress={(e) => handleKeyPressFormValue(e)}
              fullWidth
              margin="normal"
              label={
                <>
                  Roof height{' '}
                  <Tooltip
                    className={classes.helpTooltip}
                    title="Prevent the robot from lifting too much its forks and hit the roof."
                  >
                    <HelpIcon />
                  </Tooltip>
                  {typeof NEW4XVersion === 'number' && NEW4XVersion < MIN_VERSION_NEW_ROOF_HEIGHT && (
                    <Tooltip
                      className={classes.helpTooltip}
                      title={`This feature may not be available in your project. We detected that you use NEW4X version ${NEW4XVersion} and the minimum version required is ${MIN_VERSION_NEW_ROOF_HEIGHT}.`}
                    >
                      <WarningIcon
                        color="warning"
                        sx={{
                          color: `${theme.palette.warning.main} !important`,
                        }}
                      />
                    </Tooltip>
                  )}
                </>
              }
              type="number"
              inputProps={{ step: 0.5, lang: 'en', min: 0 }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Switch
                      disabled={locked}
                      checked={formValues.roofHeightEnabled}
                      onChange={(e) => {
                        setFormValues((state) => ({ ...state, roofHeightEnabled: !state.roofHeightEnabled }));
                        setAskUpdateZone(true);
                      }}
                      color="primary"
                    />
                  </InputAdornment>
                ),
                endAdornment: <InputAdornment position="end">m</InputAdornment>,
              }}
              disabled={locked || !formValues.roofHeightEnabled}
              variant="standard"
              sx={formElementStyle}
            />

            <ListItem disabled={locked} button onClick={handleNoParkingChange}>
              <ListItemText
                id="switch-list-label-noParking"
                primary={
                  <>
                    No Parking{' '}
                    <Tooltip
                      className={classes.helpTooltip}
                      title="The robot will try to anticipate and not stop in this zone. It will still stop if an obstacle is detected."
                    >
                      <HelpIcon />
                    </Tooltip>
                  </>
                }
              />
              <ListItemSecondaryAction>
                <Switch
                  disabled={locked}
                  edge="end"
                  onChange={handleNoParkingChange}
                  checked={formValues.noParking}
                  inputProps={{ 'aria-labelledby': 'switch-list-label-noParking' }}
                  color="primary"
                />
              </ListItemSecondaryAction>
            </ListItem>

            <ListItem disabled={locked} button onClick={handleNoBeepChange}>
              <ListItemText
                id="switch-list-label-noBeep"
                primary={
                  <>
                    No Beep{' '}
                    <Tooltip
                      className={classes.helpTooltip}
                      title="The robot will not beep in this zone. It will still beep if an obstacle is detected. Please note that the normal beeping behavior helps people to mind the robot."
                    >
                      <HelpIcon />
                    </Tooltip>
                  </>
                }
              />
              <ListItemSecondaryAction>
                <Switch
                  edge="end"
                  onChange={handleNoBeepChange}
                  checked={formValues.noBeep}
                  inputProps={{ 'aria-labelledby': 'switch-list-label-noBeep' }}
                  disabled={locked}
                  color="primary"
                />
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem disabled={locked} button onClick={handleHornInsideChange}>
              <ListItemText
                id="switch-list-label-hornInside"
                primary={
                  <>
                    Horn when Inside{' '}
                    <Tooltip
                      className={classes.helpTooltip}
                      title="The robot will emit a loud continuous beep when inside"
                    >
                      <HelpIcon />
                    </Tooltip>
                  </>
                }
              />
              <ListItemSecondaryAction>
                <Switch
                  edge="end"
                  onChange={handleHornInsideChange}
                  checked={formValues.hornInsideEnabled}
                  inputProps={{ 'aria-labelledby': 'switch-list-label-hornInside' }}
                  disabled={locked}
                  color="primary"
                />
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem disabled={locked} button onClick={handleHornEnteringChange}>
              <ListItemText
                id="switch-list-label-hornEntering"
                primary={
                  <>
                    Horn when Entering{' '}
                    <Tooltip
                      className={classes.helpTooltip}
                      title="The robot will emit a loud beep when entering the zone"
                    >
                      <HelpIcon />
                    </Tooltip>
                  </>
                }
              />
              <ListItemSecondaryAction>
                <Switch
                  edge="end"
                  onChange={handleHornEnteringChange}
                  checked={formValues.hornEnteringEnabled}
                  inputProps={{ 'aria-labelledby': 'switch-list-label-hornEntering' }}
                  disabled={locked}
                  color="primary"
                />
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem disabled={locked} button onClick={handleHornLeavingChange}>
              <ListItemText
                id="switch-list-label-hornExiting"
                primary={
                  <>
                    Horn when Leaving{' '}
                    <Tooltip
                      className={classes.helpTooltip}
                      title="The robot will emit a loud beep when exiting the zone"
                    >
                      <HelpIcon />
                    </Tooltip>
                  </>
                }
              />
              <ListItemSecondaryAction>
                <Switch
                  edge="end"
                  onChange={handleHornLeavingChange}
                  checked={formValues.hornLeavingEnabled}
                  inputProps={{ 'aria-labelledby': 'switch-list-label-hornExiting' }}
                  disabled={locked}
                  color="primary"
                />
              </ListItemSecondaryAction>
            </ListItem>

            {!isConveyorZone && (
              <ListItem disabled={locked} button onClick={handleBatteryChargerChange}>
                <ListItemText
                  id="switch-list-label-batteryCharger"
                  primary={
                    <>
                      Battery Charger{' '}
                      <Tooltip className={classes.helpTooltip} title="Battery charger area.">
                        <HelpIcon />
                      </Tooltip>
                    </>
                  }
                />
                <ListItemSecondaryAction>
                  <Switch
                    edge="end"
                    onChange={handleBatteryChargerChange}
                    checked={formValues.batteryCharger}
                    inputProps={{ 'aria-labelledby': 'switch-list-label-batteryCharger' }}
                    disabled={locked}
                    color="primary"
                  />
                </ListItemSecondaryAction>
              </ListItem>
            )}

            <ListItem disabled={locked} button onClick={handleSpecificBatteryManagementChange}>
              <ListItemText
                id="switch-list-label-specificSafetyManagement"
                primary={
                  <>
                    Specific Safety Management{' '}
                    <Tooltip
                      className={classes.helpTooltip}
                      title="Some robots safety rules are changed in this zone. Depending of robot model, the robot speed may be limited. It can be useful for VNAs inside aisles."
                    >
                      <HelpIcon />
                    </Tooltip>
                  </>
                }
              />
              <ListItemSecondaryAction>
                <Switch
                  edge="end"
                  onChange={handleSpecificBatteryManagementChange}
                  checked={formValues.specificBatteryManagement}
                  inputProps={{ 'aria-labelledby': 'switch-list-label-specificSafetyManagement' }}
                  disabled={locked}
                  color="primary"
                />
              </ListItemSecondaryAction>
            </ListItem>

            {isSdkVersionOkTrafficById && (
              <AllowByIdSwitch
                locked={locked}
                setFormValues={setFormValues}
                setAskUpdateZone={setAskUpdateZone}
                zoneFormValues={formValues}
              />
            )}

            <TextField
              value={formValues.stopEnteringEnabled ? formValues.stopEntering : ''}
              onChange={(e) => handleChangeFormValue(e, 'stopEntering')}
              onBlur={(e) => handleBlurFormValue(e)}
              onKeyPress={(e) => handleKeyPressFormValue(e)}
              fullWidth
              margin="normal"
              type="number"
              label={
                <>
                  Stop when Entering{' '}
                  <Tooltip className={classes.helpTooltip} title="The robot will mark a stop when entering the zone.">
                    <HelpIcon />
                  </Tooltip>
                </>
              }
              inputProps={{
                step: 0.1,
                lang: 'en',
                'aria-label': 'Duration of the stop',
                min: stopDurationMin,
                max: stopDurationMax,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Switch
                      disabled={locked}
                      checked={formValues.stopEnteringEnabled}
                      onChange={(e) => {
                        setFormValues((state) => ({ ...state, stopEnteringEnabled: !state.stopEnteringEnabled }));
                        setAskUpdateZone(true);
                      }}
                      color="primary"
                    />
                  </InputAdornment>
                ),
                endAdornment: <InputAdornment position="end">s</InputAdornment>,
              }}
              disabled={locked || !formValues.stopEnteringEnabled}
              variant="standard"
              sx={formElementStyle}
            />
            <br />
            <TextField
              value={formValues.stopLeavingEnabled ? formValues.stopLeaving : ''}
              onChange={(e) => handleChangeFormValue(e, 'stopLeaving')}
              onBlur={(e) => handleBlurFormValue(e)}
              onKeyPress={(e) => handleKeyPressFormValue(e)}
              fullWidth
              margin="normal"
              type="number"
              label={
                <>
                  Stop when Leaving{' '}
                  <Tooltip className={classes.helpTooltip} title="The robot will mark a stop when leaving the zone.">
                    <HelpIcon />
                  </Tooltip>
                </>
              }
              inputProps={{
                step: 0.1,
                lang: 'en',
                'aria-label': 'Duration of the stop',
                min: stopDurationMin,
                max: stopDurationMax,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Switch
                      disabled={locked}
                      checked={formValues.stopLeavingEnabled}
                      onChange={(e) => {
                        setFormValues((state) => ({ ...state, stopLeavingEnabled: !state.stopLeavingEnabled }));
                        setAskUpdateZone(true);
                      }}
                      color="primary"
                    />
                  </InputAdornment>
                ),
                endAdornment: <InputAdornment position="end">s</InputAdornment>,
              }}
              disabled={locked || !formValues.stopLeavingEnabled}
              variant="standard"
              sx={formElementStyle}
            />
            <br />
            <TextField
              value={formValues.limitRobotCountEnabled ? formValues.limitRobotCount : ''}
              onChange={(e) => handleChangeFormValue(e, 'limitRobotCount', true)}
              onBlur={(e) => handleBlurFormValue(e)}
              onKeyPress={(e) => handleKeyPressFormValue(e)}
              fullWidth
              margin="normal"
              label={
                <>
                  Limit Robot Count{' '}
                  <Tooltip className={classes.helpTooltip} title="Limit the maximum number of robots in a zone.">
                    <HelpIcon />
                  </Tooltip>
                </>
              }
              type="number"
              inputProps={{ step: 1, lang: 'en', min: 1 }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Switch
                      disabled={locked}
                      checked={formValues.limitRobotCountEnabled}
                      onChange={(e) => {
                        setFormValues((state) => ({
                          ...state,
                          limitRobotCountEnabled: !state.limitRobotCountEnabled,
                        }));
                        setAskUpdateZone(true);
                      }}
                      color="primary"
                    />
                  </InputAdornment>
                ),
                // endAdornment: <InputAdornment position="end">m</InputAdornment>,
              }}
              disabled={locked || !formValues.limitRobotCountEnabled}
              variant="standard"
              sx={formElementStyle}
            />
            <br />
            <TextField
              value={formValues.curtainInhibitionEnabled ? formValues.curtainInhibition : ''}
              onChange={(e) => handleChangeFormValue(e, 'curtainInhibition')}
              onBlur={(e) => handleBlurFormValue(e)}
              onKeyPress={(e) => handleKeyPressFormValue(e)}
              fullWidth
              margin="normal"
              type="number"
              label={
                <>
                  Curtain laser inhibition{' '}
                  <Tooltip
                    className={classes.helpTooltip}
                    title="Inhibit the curtain laser between the floor and the entered value"
                  >
                    <HelpIcon />
                  </Tooltip>
                </>
              }
              inputProps={{ step: 0.01, lang: 'en', 'aria-label': 'Curtain laser inhibition', min: 0 }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Switch
                      disabled={locked}
                      checked={formValues.curtainInhibitionEnabled}
                      onChange={(e) => {
                        setFormValues((state) => ({
                          ...state,
                          curtainInhibitionEnabled: !state.curtainInhibitionEnabled,
                        }));
                        setAskUpdateZone(true);
                      }}
                      color="primary"
                    />
                  </InputAdornment>
                ),
                endAdornment: <InputAdornment position="end">m</InputAdornment>,
              }}
              disabled={locked || !formValues.curtainInhibitionEnabled}
              variant="standard"
              sx={formElementStyle}
            />
            <br />

            <FormControl sx={formElementStyle}>
              <InputLabel id="safety-manual-ack-label" sx={{ transform: 'scale(0.75)' }}>
                {safetyManualAckLabel}
              </InputLabel>
              <Select
                labelId="safety-manual-ack-label"
                id="safety-manual-ack-input"
                value={formValues.safetyManualAck || 0}
                label={safetyManualAckLabel}
                onChange={(e) => handleChangeFormValue(e, 'safetyManualAck')}
                variant="standard"
              >
                <MenuItem value={0}>Disabled</MenuItem>
                <MenuItem value={1}>Emergency safety only (hard)</MenuItem>
                <MenuItem value={2}>Emergency and normal safeties (hard and soft)</MenuItem>
              </Select>
            </FormControl>

            <BroadcastSelect
              zone={zone}
              setAskUpdateZone={setAskUpdateZone}
              setFormValues={setFormValues}
              zoneFormValues={formValues}
              formElementStyle={formElementStyle}
            />

            <ListItem disabled={locked} button onClick={handleChangeBlackhole}>
              <ListItemIcon>
                <GpsOffIcon />
              </ListItemIcon>
              <ListItemText
                id="switch-list-label-blackhole"
                primary={
                  <>
                    Black Hole{' '}
                    <Tooltip
                      className={classes.helpTooltip}
                      title={
                        <>
                          For advanced users only.
                          <br />
                          The geolocalisation system will ignore the map points in it.
                        </>
                      }
                    >
                      <WarningIcon />
                    </Tooltip>
                  </>
                }
              />
              <ListItemSecondaryAction>
                <Switch
                  edge="end"
                  onChange={handleChangeBlackhole}
                  checked={formValues.blackHoleLocalisation}
                  inputProps={{ 'aria-labelledby': 'switch-list-label-blackhole' }}
                  disabled={locked}
                  color="primary"
                />
              </ListItemSecondaryAction>
            </ListItem>

            {projectOpened && <SelectZoneEvents zone={zone} />}
          </Border>
        </Collapse>

        <ListItemButton disabled={locked} onClick={handleDoorChange}>
          <ListItemIcon>
            <MeetingRoomIcon />
          </ListItemIcon>
          <ListItemText
            id="switch-list-label-door"
            primary={
              <>
                Door{' '}
                <HelpIconTooltip title="The zone will be associated to a door. Doors are opened or closed and their statuses can be linked to other devices (conveyors, fire alarms, etc.), often with a Balyo Combox." />
              </>
            }
          />
          <ListItemSecondaryAction>
            <Switch
              edge="end"
              checked={formValues.door}
              inputProps={{ 'aria-labelledby': 'switch-list-label-door' }}
              disabled={locked}
              tabIndex={-1}
            />
          </ListItemSecondaryAction>
        </ListItemButton>
        {!roadEditorStandardMode && (
          <Collapse in={formValues.door} timeout="auto" unmountOnExit>
            <DoorSettings
              classes={classes}
              formValues={formValues}
              setFormValues={setFormValues}
              handleBlurFormValue={handleBlurFormValue}
              handleChangeFormValue={handleChangeFormValue}
              handleKeyPressFormValue={handleKeyPressFormValue}
              locked={locked}
              setAskSaveShape={setAskUpdateZone}
              componentWidth={componentWidth}
              doorSettingsFor={'zone'}
            />
          </Collapse>
        )}
        {displayElevatorOptions && (
          <ElevatorOptions
            setFormValues={setFormValues}
            setAskUpdateZone={setAskUpdateZone}
            handleChangeFormValue={handleChangeFormValue}
            handleBlurFormValue={handleBlurFormValue}
            handleKeyPressFormValue={handleKeyPressFormValue}
            formValues={formValues}
            locked={locked}
          />
        )}
      </List>
      <FormControl fullWidth>
        <InputLabel sx={{ transform: 'scale(0.75)', marginLeft: theme.spacing(1) }} disabled={locked}>
          Intersection type{' '}
          <HelpIconTooltip
            title={
              <>
                Define how the robot will know if it is in the zone or not.
                <ul>
                  <li>Point: it will be in the zone if the center of its rollers are in the zone</li>
                  <li>Gabarit: if any point of the robot is in the zone, the robot will be in the zone</li>
                </ul>
                For a better computation resources utilization, prefer the <em>Point</em> intersection type.
              </>
            }
          />
          {formValues.door && formValues.intersectionType !== Intersection.GabaritIntersection && (
            <Tooltip
              className={classes.helpTooltip}
              title="It is recommended to have a gabarit intersection type for doors."
            >
              <WarningIcon />
            </Tooltip>
          )}
        </InputLabel>
        <Select
          id="intersection-type"
          value={formValues.intersectionType}
          onChange={(e) => handleChangeFormValue(e, 'intersectionType')}
          variant="standard"
          sx={formElementStyle}
          disabled={locked}
        >
          <MenuItem value={Intersection.PointIntersection}>Point</MenuItem>
          <MenuItem value={Intersection.GabaritIntersection}>Gabarit</MenuItem>
        </Select>
      </FormControl>

      {zone.properties.aisle?.isAisle ? <UpdateAisleBtn zone={zone} /> : null}

      <Box
        component="div"
        sx={{
          display: 'grid',
          gap: 2,
          gridTemplateRows: 'auto',
          gridTemplateAreas: `
  "label label . switch"
  `,
        }}
      >
        <Box component="div" sx={{ gridArea: 'label' }}>
          <MenuItem>
            <DesktopWindowsOutlinedIcon />
            <ListItemText
              primary={
                <>
                  Display on Interface{' '}
                  <HelpIconTooltip title="If activated, the zone will appear in the circuit displayed on the Fleet Manager Interface" />
                </>
              }
            />
          </MenuItem>
        </Box>
        <Box component="div" sx={{ gridArea: 'switch' }}>
          <Switch edge="end" checked={zone.properties.displayHMI} onChange={handleVisible} color="primary" />
        </Box>
      </Box>

      {comments.map((com, index) => {
        return (
          <TextField
            key={index}
            label="Comment"
            multiline
            minRows={2}
            maxRows={4}
            autoComplete="off"
            value={com}
            onChange={handleCommentsChange}
            onBlur={handleSaveComments}
            inputProps={{
              'data-commentid': index,
            }}
            disabled={locked}
            sx={formElementStyle}
            fullWidth
          ></TextField>
        );
      })}
    </PropertiesComponent>
  );
}
