import type { Position } from '@turf/helpers';
import { saveNoteAction } from 'actions/notes';
import { rotatePoint } from 'drawings/helpers';
import type { CircuitNote } from 'models/circuit';

/**
 * Processes a selected note, applies rotation, and updates its properties.
 *
 * @param note - The selected note shape to process.
 * @param center - The central position for rotation.
 * @param rotateValue - The value by which to rotate the note.
 * @param actions - The array of actions to which the updated note will be pushed.
 */
export function processRotateNote(
  note: CircuitNote,
  centerSelectedShapes: Position,
  rotateValue: number,
  actions: any[]
): void {
  if (!note) return;

  if (note.properties.locked) return;

  const newCoords = rotatePoint(
    centerSelectedShapes,
    note.geometry.coordinates.map((c) => c),
    -rotateValue
  );

  actions.push(
    saveNoteAction({
      ...note,
      geometry: {
        ...note.geometry,
        coordinates: newCoords,
      },
    })
  );
}
