import { Avatar, Tooltip } from '@mantine/core';
import { Badge } from '@mui/material';
import { Box, styled } from '@mui/system';
import CryptoJS from 'crypto-js';
import { awareness, localDoc, remoteDoc } from 'multiplayer/globals';
import { setFollowUser } from 'multiplayer/multiplayer';
import { useCallback, useEffect, useState } from 'react';
import type { UserProfileData } from 'shared';
import { useAppDispatch, useAppSelector } from 'store';
import { useDebouncedCallback } from 'use-debounce';
import { theme } from 'utils/mui-theme';
import { useUsers } from 'y-presence';
import type { UserPresence } from '../../multiplayer/types';

const SyncBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#ff1744',
    color: '#ff1744',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}));

const SyncedBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#4caf50',
    color: '#4caf50',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
  },
}));

export default function Users(): JSX.Element {
  if (!awareness) throw new Error('Missing awareness');

  const users = useUsers(awareness) as Map<number, UserPresence>;
  const dispatch = useAppDispatch();
  const [tooltipOpened, setTooltipOpened] = useState(false);
  const [syncedUsers, setSyncedUsers] = useState<Set<number>>(new Set());
  const [debouncedSyncedUsers, setDebouncedSyncedUsers] = useState<Set<number>>(new Set());
  const followClientId = useAppSelector((state) => state.multiplayer.followClientId);
  const render2Enabled = useAppSelector((state) => state.editor.isRender2Enabled);

  useEffect(() => {
    if (!awareness) return;

    const handleRemoteChange = (update: Uint8Array, origin: 'local' | 'remote' | undefined): void => {
      if (!awareness || !remoteDoc) return;

      const hash = CryptoJS.SHA1(update.toString()).toString(CryptoJS.enc.Hex);

      awareness.setLocalStateField('lastChange', {
        id: hash,
        timestamp: Date.now(),
        origin: origin === 'local' ? 'local' : 'remote',
      });
    };

    const handleAwareness = (): void => {
      if (!awareness) return;

      const states = awareness.getStates();
      const newSyncedUsers = new Set<number>();

      const maxTimestampState = Array.from(states.values()).reduce(
        (maxState, currentState) => {
          return (currentState.lastChange?.timestamp || 0) > (maxState.lastChange?.timestamp || 0)
            ? currentState
            : maxState;
        },
        { lastChange: { timestamp: 0 } }
      );

      states.forEach((state: any, clientId: number) => {
        if (maxTimestampState.lastChange.id === state.lastChange?.id) {
          newSyncedUsers.add(clientId);
        }
      });

      setSyncedUsers(newSyncedUsers);
    };

    remoteDoc?.on('update', handleRemoteChange);
    awareness.on('change', handleAwareness);

    return () => {
      remoteDoc?.off('update', handleRemoteChange);
      awareness?.off('change', handleAwareness);
    };
  }, []);

  // When the user clicks on an avatar, follow the user
  const handleAvatarClick = useCallback(
    (clientId: number): void => {
      if (!awareness || followClientId === clientId) return;

      dispatch(setFollowUser({ clientId }));

      if (render2Enabled) {
        const canvas = document.getElementsByTagName('canvas')[0];
        if (!canvas) return;

        canvas.style.outline = `8px solid ${users.get(clientId)?.c}`;
        canvas.style.outlineOffset = '-8px';
      } else {
        const editorView = document.getElementById('editorView');
        if (!editorView) return;

        editorView.style.outline = `8px solid ${users.get(clientId)?.c}`;
      }
    },
    [users, followClientId, render2Enabled, dispatch]
  );

  const debouncedSetSyncedUsers = useDebouncedCallback((syncedUsers) => setDebouncedSyncedUsers(syncedUsers), 300);

  useEffect(() => {
    debouncedSetSyncedUsers(syncedUsers);
  }, [debouncedSetSyncedUsers, syncedUsers]);

  const maxInlineAvatarIndex = 4;

  const profilesMap = localDoc.getMap<UserProfileData>('profiles');
  const inactiveMap = localDoc.getMap('inactive');

  return (
    <Tooltip.Group closeDelay={100}>
      <Avatar.Group spacing="sm">
        {Array.from(users.entries()).map(([key, value], index) => {
          if (index > maxInlineAvatarIndex) return null;

          if (index === maxInlineAvatarIndex && users.size > maxInlineAvatarIndex + 1) {
            return (
              <Tooltip
                key={`tooltip-${index}`}
                opened={tooltipOpened}
                withArrow
                style={{
                  pointerEvents: 'unset',
                  cursor: 'pointer',
                  padding: '4px',
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: '4px',
                }}
                label={
                  <>
                    {Array.from(users.entries())
                      .slice(4)
                      .map(([key, value]) => {
                        const textColor = theme.palette.getContrastText(value.c);
                        const profile = profilesMap.get(key.toString());
                        const inactive = inactiveMap.has(key.toString());
                        const isSynced = syncedUsers.has(key);

                        if (!profile) return null;

                        return (
                          <Box
                            component="div"
                            key={key}
                            sx={{
                              color: textColor,
                              backgroundColor: value.c,
                              padding: '5px 10px',
                              borderRadius: '4px',
                              ...(inactive && {
                                opacity: 0.5,
                              }),
                            }}
                            onClick={() => handleAvatarClick(key)}
                          >
                            {profile.name} {isSynced ? '✓' : '...'}
                          </Box>
                        );
                      })}
                  </>
                }
                zIndex={1100}
              >
                <Avatar
                  key={`avatar-${index}`}
                  radius="xl"
                  onClick={() => setTooltipOpened((prevState) => !prevState)}
                  onBlur={() => setTooltipOpened(false)}
                  tabIndex={index}
                  style={{
                    ...(followClientId &&
                      Array.from(users.keys()).slice(maxInlineAvatarIndex).includes(followClientId) && {
                        borderWidth: '3px',
                        borderColor: users.get(followClientId)?.c,
                      }),
                  }}
                >
                  +{users.size - 4}
                </Avatar>
              </Tooltip>
            );
          }

          /* Change text color for it to contrast with the background */
          const textColor = theme.palette.getContrastText(value.c);
          const profile = profilesMap.get(key.toString());
          const inactive = inactiveMap.has(key.toString());
          const isSynced = debouncedSyncedUsers.has(key);

          const BadgeComponent = isSynced ? SyncedBadge : SyncBadge;

          return (
            <Tooltip
              key={`tooltip-${index}`}
              label={`${index === 0 ? `${profile?.name} (you)` : profile?.name} ${
                isSynced ? '(synced)' : '(syncing...)'
              }`}
              color={value.c}
              withArrow
              style={{ color: textColor }}
              zIndex={1100}
            >
              <BadgeComponent
                overlap="circular"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                variant="dot"
                invisible={inactive}
              >
                <Avatar
                  alt={profile?.name}
                  src={profile?.picture}
                  tabIndex={index}
                  style={{
                    ...(inactive && {
                      opacity: 0.5,
                    }),
                    ...(key === followClientId && {
                      borderColor: users.get(followClientId)?.c,
                      borderWidth: '3px',
                    }),
                  }}
                  imageProps={{
                    /**
                     * referrerPolicy added because otherwise sometimes Google sends a 403 instead of the image
                     * link: https://stackoverflow.com/questions/56242788/http-403-on-images-loaded-from-googleusercontent-com
                     */
                    referrerPolicy: 'no-referrer',
                    /**
                     * Required by the cross-origin-embedder-policy: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Cross-Origin-Embedder-Policy
                     */
                    crossOrigin: 'anonymous',
                  }}
                  radius="xl"
                  onClick={key === awareness?.clientID ? undefined : () => handleAvatarClick(key)}
                />
              </BadgeComponent>
            </Tooltip>
          );
        })}
      </Avatar.Group>
    </Tooltip.Group>
  );
}
