import { z } from 'zod';

/** Schema for _ROBEMU_WasmWrapper_setBatt response */
export const robemuSetBattResponseSchema = z.object({
  errorID: z.number(),
  error: z.string(),
});

export type RobemuSetBattResponse = z.infer<typeof robemuSetBattResponseSchema>;

export interface Simulation {
  /** Read the memory from a pointer and returns a string */
  UTF8ToString: (ptr: number) => string;
  /** Write a string to a pointer */
  stringToUTF8: (str: string, ptr: number, maxBytesToWrite?: number) => void;
  /** allocate a string to the wasm memory, return the pointer address */
  allocateUTF8: (str: string) => number;
  /** allocate a string to the wasm memory,  return the pointer address */
  stringToNewUTF8: (str: string) => number;

  /** ask for memory and return the pointer address */
  _malloc: (size: number) => number;
  /** get a variable value */
  getValue: (ptr: number, type: string) => number;

  /**
   * Load a circuit from a xml string to the libTrack
   */
  _TRACK_WasmWrapper_loadCircuit: (xmlDataPtr: number) => number;
  /** free the memory allocated by the libTrack after loading a circuit */
  _TRACK_WasmWrapper_destroy: () => void;
  /**
   * Find the itinerary between two points (x, y, cap (in rad))
   * @param layerNamePtr the pointer to the layer name string
   * @param startX the x coordinate of the start point
   * @param startY the y coordinate of the start point
   * @param startCap the cap of the start point (in radians)
   * @param endX the x coordinate of the end point
   * @param endY the y coordinate of the end point
   * @param endCap the cap of the end point (in radians)
   * @param layerFlag the layer flag
   */
  _TRACK_WasmWrapper_findItinerary: (
    layerNamePtr: number,
    startX: number,
    startY: number,
    startCap: number,
    endX: number,
    endY: number,
    endCap: number,
    layerFlag: number
  ) => number;

  /**
   * Check wether we can create a trackless portion (turn) from a point (x, y, heading) on a portion of a slot (rack)
   * @param layerNamePtr the pointer to the layer name string
   * @param x the x coordinate of the point
   * @param y the y coordinate of the point
   * @param heading the cap of the point (in radians)
   * @returns a pointer to a string containing a json with the response
   */
  _TRACK_WasmWrapper_checkTrackless: (layerNamePtr: number, x: number, y: number, heading: number) => number;

  /** call a function in the wasm module */
  ccall: (...args: any) => any;

  /**
   * Get the memory slice from a pointer
   * @param start the start pointer
   * @param length tjhe length of the slice
   * @returns the memory slice in bytes
   */
  getWasmMemorySlice: (startPtr: number, length: number) => Uint8Array;

  /**
   * The memory of the wasm module
   */
  HEAP8: Int8Array;

  /**
   * Initialize the preferences in the libtrack
   * @returns the pointer to the error string
   **/
  _PREF_WasmWrapper_Initialize: () => number;
  /**
   * Deinitialize the preference library
   * @returns the pointer to the error string
   */
  _PREF_WasmWrapper_Deinitialize: () => number;
  /**
   * Define a value to the undefined preferences
   * To be called after the preference initialization
   */
  _PREF_WasmWrapper_checkUndefined: () => void;

  /**
   * Retrieve a preference value from the preference library
   * @param pathPtr the pointer to the path string
   * @returns the pointer to the value string
   */
  _PREF_WasmWrapper_get: (pathPtr: number) => number;

  /**
   * Load an install project dictionary (xml file in string format) to the libTrack
   * @param xmlFileStrPtr the pointer to the xml string
   * @returns the pointer to the error string
   */
  _PREF_WasmWrapper_loadInstallDictionary: (xmlFileStrPtr: number) => number;
  /**
   * Load a truck project dictionary (xml file in string format) to the libTrack
   * @param xmlFileStrPtr the pointer to the xml string
   * @returns the pointer to the error string
   */
  _PREF_WasmWrapper_loadTruckDictionary: (xmlFileStrPtr: number) => number;
  /**
   * Load a model project dictionary (xml file in string format) to the libTrack
   * @param xmlFileStrPtr the pointer to the xml string
   * @returns the pointer to the error string
   */
  _PREF_WasmWrapper_loadModelDictionary: (xmlFileStrPtr: number) => number;
  /**
   * Load a project preferences (xml file in string format) to the libTrack
   * @param xmlFileStrPtr the pointer to the xml string
   * @returns the pointer to the error string
   */
  _PREF_WasmWrapper_loadInstallPref: (xmlFileStrPtr: number) => number;
  /**
   * Load a truck (project) preferences (xml file in string format) to the libTrack
   * @param xmlFileStrPtr the pointer to the xml string
   * @param serialPtr the pointer to the serial string
   * @returns the pointer to the error string
   */
  _PREF_WasmWrapper_loadTruckPref: (xmlFileStrPtr: number, serialPtr: number) => number;
  /**
   * Load a model (project) preferences (xml file in string format) to the libTrack
   * @param xmlFileStrPtr the pointer to the xml string
   * @param modelPtr the pointer to the model string
   * @returns the pointer to the error string
   */
  _PREF_WasmWrapper_loadModelPref: (xmlFileStrPtr: number, modelPtr: number) => number;

  /**
   * Initialize the SDK  preference dictionnaries
   */
  _WEBSIMU_WasmWrapper_initialize: () => number;

  /**
   * Initialize the simulation
   * @param configuration the custom configuration (optional) (pointer to the json string)
   * @returns void
   */
  _WEBSIMU_WasmWrapper_initializeSimulation: (configuration?: number) => void;

  /** Deinitialize the simulation */
  _WEBSIMU_WasmWrapper_deinitializeSimulation: () => void;

  /** Set the simulation time to 0 */
  _WEBSIMU_WasmWrapper_resetCounter: () => void;

  /** Set the simulation time to a certain number of seconds (uint) */
  _WEBSIMU_WasmWrapper_setSimulationTime(seconds: number): void;

  /**
   * Get data about the simulation that is currently running
   * @returns a pointer to a string containing a json with the response
   */
  _WEBSIMU_WasmWrapper_getThreadData: () => number;

  /**
   * Change the speed of the simulation
   * @param speedFactor /!\ UINT /!\ - the speed factor to apply (1 = normal speed, 2 = 2x speed, 0 = stop, etc.)
   * @returns void
   */
  _WEBSIMU_WasmWrapper_setSpeed: (speedFactor: number) => void;

  /**
   * Retrieve the duration spent by a robot in the different states
   * @param robotID the id of the robot (start from 1)
   * @returns a pointer to a string containing a json with the response
   */
  _WEBSIMU_WasmWrapper_getRobotTimers: (robotID: number) => number;

  /**
   * Trigger a robot error for debug purposes
   * @param robotID the robot to trigger the error on (start from 1)
   * @returns void
   */
  _WEBSIMU_WasmWrapper_triggerRobotError: (robotID: number) => void;

  /**
   * Function to call to give the user answer to the prompt to the simulation module
   * @param promptCategory the prompt category id (uint)
   * @param replyIndex the index of the reply (uint), if actions was ['A', 'B', 'C'] and the user chose 'B', replyIndex = 1
   * @param parameter a parameter to pass back to the simulation module (uint)
   * @returns void
   */
  _WEBSIMU_WasmWrapper_promptReply: (promptCategory: number, replyIndex: number, parameter: number) => void;

  /**
   * Set the start epoch of the simulation
   * @param seconds the start epoch in seconds since the 1st jan 1970 (uint32_t)
   */
  _WEBSIMU_WasmWrapper_setStartEpoch: (seconds: number) => void;

  /**
   * Get the start epoch of the simulation
   * @returns the start epoch of the simulation in seconds since the 1st jan 1970 (uint32_t)
   */
  _WEBSIMU_WasmWrapper_getStartEpoch: () => number;

  /** Intialize the RTE module */
  _RTE_WasmWrapper_initialize: () => void;

  /** Deinitialize the RTE module */
  _RTE_WasmWrapper_Deinitialize: () => void;

  /**
   * Get the robots data
   * @returns a pointer to a string containing a json with the response
   */
  _RTE_WasmWrapper_getRobotsData: () => number;

  /**
   * Initilize the traffic management of the SDK (including the cantons)
   * It can takes a while if the cantons have not been computed yet
   * To provide a cache, see _OS_WasmWrapper_writeFile
   * */
  _TRAFFIC_WasmWrapper_initialize: () => void;

  /** Deinitiallize the traffic module buffers */
  _TRAFFIC_WasmWrapper_destroy: () => void;

  /**
   * Compute the no stop cantons for a given circuit
   * @returns a pointer to a string containing a json with the response
   *
   * @see https://redmine.balyo.com/issues/42333
   **/
  _TRAFFIC_WasmWrapper_getAllCantons: () => number;

  /**
   * Get the traffic data for a specific canton at given position.
   * @param layerNamePtr the pointer to the layer name string
   * @param x the x coordinate of the point (meters)
   * @param y the y coordinate of the point (meters)
   * @param heading the cap of the point (in radians)
   * @returns a pointer to a string containing a json with the response
   */
  _TRAFFIC_WasmWrapper_getCanton: (layerNamePtr: number, x: number, y: number, heading: number) => number;

  /**
   * Get the traffic related to deadends data for a specific canton at given position.
   * @param layerNamePtr the pointer to the layer name string
   * @param x the x coordinate of the point (meters)
   * @param y the y coordinate of the point (meters)
   * @param heading the cap of the point (in radians)
   * @returns a pointer to a string containing a json with the response
   */
  _TRAFFIC_WasmWrapper_getDeadend: (layerNamePtr: number, x: number, y: number, heading: number) => number;

  /**
   * Get the traffic data for a specified canton at a given position.
   * @param robotId
   */
  _TRAFFIC_WasmWrapper_getRobotOccupation: (robotId: number) => number;

  /**
   * Set logging level
   * @param debugLevel the debug level (uint)
   * @returns void
   */
  _TRAFFIC_WasmWrapper_setDebug: (debugLevel: number) => void;

  /**
   * Provide a file for the traffic module
   * example: to provide a cache file for the traffic moduke: _OS_WasmWrapper_writeFile("traffic.SWAL", fileContent)
   */
  _OS_WasmWrapper_writeFile: (fileNamePtr: number, fileContentPtr: any, fileSize: number) => void;

  /**
   * Read a file for the traffic module
   * example: to read a cache file for the traffic moduke: _OS_WasmWrapper_readFile("traffic.SWAL")
   */
  _OS_WasmWrapper_readFile: (fileNamePtr: number, fileSizePtr: number) => number;

  /**
   * Free a pointer
   */
  _OS_WasmWrapper_free: (ptr: number) => void;

  /**
   * Allocate memory from the wasm heap
   * @param size the size of the memory to allocate
   * @returns the pointer to the allocated memory
   */
  _OS_WasmWrapper_calloc: (size: number) => number;

  /** Enable or disable the log printed by the simulation module */
  _OS_WasmWrapper_enableLog: (enable: boolean) => void;

  /**
   * Initialize the dispatcher module
   * @param configurationPtr the pointer to the configuration string, if 0 is provided, the default configuration will be used
   */
  _DISPATCHER_WasmWrapper_initialize: (configurationPtr: number) => void;

  /** Deinitialize the dispatcher module */
  _DISPATCHER_WasmWrapper_deinitialize: () => void;

  /**
   * Set the debug level for the dispatcher
   * Debug level is integer, 0 = no logs, 5 = all logs
   */
  _DISPATCHER_WasmWrapper_setDebug: (debugLevel: number) => void;

  /**
   * Create a task in the dispatcher module
   * @param taskPtr the pointer to the json task string
   */
  _DISPATCHER_WasmWrapper_newTask: (taskPtr: number) => void;

  /**
   * Create a task in the dispatcher module in the future
   * @param taskPtr the pointer to the json task string
   * @param seconds the simulation time when to create the task (int32_t)
   */
  _DISPATCHER_WasmWrapper_newTaskInFuture(taskPtr: number, seconds: number): void;

  /**
   * Create a (or several) task(s) from and to random points
   * @param numberOfTasks the number of tasks to create (uint32)
   */
  _DISPATCHER_WasmWrapper_createRandomTasks: (numberOfTasks: number) => void;

  /**
   * Get the last changes (since the last call) in the tasks data
   * @returns a pointer to a string containing a json with the response
   */
  _DISPATCHER_WasmWrapper_getTaskEvents: () => number;

  /**
   * Assign a robot to a task
   * @param task ID
   * @param robot ID
   */
  _DISPATCHER_WasmWrapper_setNextTask: (taskID: number, robotID: number) => void;

  /**
   * Initialize the scheduler module
   * @param confPtr the pointer to the configuration string, if 0 is provided, the default configuration will be used
   * @returns the pointer to the error string
   */
  _SCHEDULER_WasmWrapper_initialize: (confPtr?: number) => number;

  /**
   * Deinitialize the scheduler module
   */
  _SCHEDULER_WasmWrapper_deinitialize: () => void;

  /**
   * Set robot position, robot position is snapped to a portion if close enough
   * @param robotID the id of the robot (start from 1) (uint32)
   * @param x the x coordinate of the point (meters) (float64)
   * @param y the y coordinate of the point (meters) (float64)
   * @param heading the cap of the point (in radians) (float64)
   * @returns pointer JSON string containing the error code. { "errorID":*int*, "error":*string* }
   */
  _ROBEMU_WasmWrapper_setRobotPosition: (robotID: number, x: number, y: number, heading: number) => number;

  /**
   * Reset the robot states and position to its taxi point and relaunch its current mission
   * @param robotID the id of the robot (start from 1) (uint32)
   * @returns pointer JSON string containing the error code. { "errorID":*int*, "error":*string* }
   */
  _ROBEMU_WasmWrapper_resetRobot: (robotID: number) => number;

  /**
   * Change robot state
   * @param robotID the id of the robot (start from 1) (uint32)
   * @param stateCategory (1:com 2:safety 3:position 4:navigation 5:task)
   * @param stateValue long Com : 0:OK 1:OFF 2:CRC 3:DST 4:CMD 5:unk Safety: -5:curtainRear -4:curtainFront -3:rear -2:front -1:BA 0:OK 1:AU 2:SAU 3:FC 4:GPIO 5:Loc 6:EScharge 7:unk Position: 0:OK 1:OffTrack 2:NearTrack 3:unInit 4:noData 5:unk Nav: 0:OK 1:Traffic 2:StopPgm 3:NoDest 4:Manual 5:badTraffic 6:ESTraffic 7:Door 8:unk Task: 0:Available 3:runTaxi 5:Calib 7:Run 16:Manual 32:ErrMiss 48:ErrNav 64:ErrBatt 255:unk
   * @returns pointer JSON string containing the error code. { "errorID":*int*, "error":*string* }
   */
  _ROBEMU_WasmWrapper_setRobotState(robotID: number, stateCategory: number, stateValue: number): number;

  /**
   * Set robot to auto and relaunch its current mission
   * @param robotID the id of the robot (start from 1) (uint32)
   * @returns pointer JSON string containing the error code. { "errorID":*int*, "error":*string* }
   */
  _ROBEMU_WasmWrapper_toAuto(robotID: number): number;

  /**
   * Set robot to manual
   * @param robotID the id of the robot (start from 1) (uint32)
   * @returns pointer JSON string containing the error code. { "errorID":*int*, "error":*string* }
   */
  _ROBEMU_WasmWrapper_toManu(robotID: number): number;

  /**
   * Enable or disable the battery thread
   * @param enable
   * @returns pointer JSON string containing the error code. { "errorID":*int*, "error":*string* }
   */
  _ROBEMU_WasmWrapper_setBatt(enable: 0 | 1): number;

  /**
   * Set custom battery level for a robot
   * @param robotId the id of the robot (start from 1) (uint32)
   * @param battLevel the battery level (uint32) (between 0 and 100)
   */
  _ROBEMU_WasmWrapper_setBattLevel(robotId: number, battLevel: number): number;

  /**
   * Get the robot battery data
   * Either static (= inherent of the robot)
   * Or dynamic (= current state of the robot)
   * @param robotId
   * @param dynamicData
   */
  _ROBEMU_WasmWrapper_getBatteryData(robotId: number, dynamicData: boolean): number;

  /**
   * Enable the performance module
   * @returns pointer JSON string containing the error code. { "errorID":*int*, "error":*string* }
   */
  _ROBEMU_WasmWrapper_enablePerfo(): number;

  /**
   * Disable the performance module
   * @returns pointer JSON string containing the error code. { "errorID":*int*, "error":*string* }
   */
  _ROBEMU_WasmWrapper_disablePerfo(): number;

  /**
   * Initialize the OPC (Opportunity Charging) module
   * @param configuration the configuration string of the OPC, if ommitted a default configuration will be used
   * @returns the pointer to the error string
   */
  _OPC_WasmWrapper_initialize: (configuration?: number) => number;

  /**
   * Deinitialize the OPC module
   * @returns void
   */
  _OPC_WasmWrapper_deinitialize: () => void;

  /**
   * Get all the performance records stored in a buffer
   * Every time the records are fetched, the buffer is cleared
   * @returns the pointer to the json string
   */
  _PERFO_WasmWrapper_getData: () => number;
}

export type LoadSimulationModule = (params: LoadSimulationModuleParams) => Promise<Simulation>;

export interface LoadSimulationModuleParams {
  balyoSimulationVersion?: string;
}
