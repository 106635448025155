import store from 'store';

/**
 * Finds the IDs of all turns connected to a given stock zone or rack based on the selected shape's ID.
 *
 * @param circuitState - The current circuit state from the Redux store.
 * @param selectedShapeId - The unique ID of the selected shape (either a stock zone or rack).
 * @param type - The type of the selected shape, either "stockZone" or "rack".
 * @returns An array of connected turn IDs.
 */
export function getConnectedTurnIds(selectedShapeId: string | number, type: 'stockZone' | 'rack'): string[] {
  const circuitState = store.getState().circuit.present;
  const connectedTurnIds = new Set<string>();

  if (type === 'stockZone') {
    // Gather stock line IDs from the selected stock zone
    const stockLinesIdsSet = new Set<string>();
    const stockZone = circuitState.stockZones.entities[selectedShapeId];
    if (stockZone) {
      stockZone.properties.slots.forEach((slots) => {
        stockLinesIdsSet.add(slots.id);
      });
    }

    // Find segments connected to the stock lines
    const connectedSegmentIds = Object.values(circuitState.segments.entities)
      .filter(
        (segment) =>
          typeof segment.properties.stockLine === 'string' && stockLinesIdsSet.has(segment.properties.stockLine)
      )
      .map((segment) => segment.id as string);

    // Find turns connected to the segments
    Object.values(circuitState.turns.entities).forEach((turn) => {
      if (
        connectedSegmentIds.includes(turn.properties.originId as string) ||
        connectedSegmentIds.includes(turn.properties.destinationId as string)
      ) {
        connectedTurnIds.add(turn.id as string);
      }
    });
  } else if (type === 'rack') {
    // Gather extended segment IDs from the selected rack
    const rackExtendedSegmentSet = new Set<string>();
    const rack = circuitState.racks.entities[selectedShapeId];
    if (rack) {
      rack.properties.columns.forEach((column) => {
        column.extendedLengthSegments?.forEach((extendedLengthSegment) => {
          rackExtendedSegmentSet.add(extendedLengthSegment[0]);
        });
      });
    }

    // Find turns connected to the extended segments
    Object.values(circuitState.turns.entities).forEach((turn) => {
      if (
        rackExtendedSegmentSet.has(turn.properties.originId as string) ||
        rackExtendedSegmentSet.has(turn.properties.destinationId as string)
      ) {
        connectedTurnIds.add(turn.id as string);
      }
    });
  }

  return Array.from(connectedTurnIds);
}
