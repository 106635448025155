import type { SimulationService } from 'components/toolboxes/simulation';
import { getRobotsEmulation } from 'components/toolboxes/simulation/use-set-robots';
import type { StationPositionWithName } from 'flows/flows';
import type { Trigger } from 'models/simulation';
import type { SnackbarKey } from 'notistack';
import { SnackbarUtils } from 'services/snackbar.service';
import { getNbRemainingTasks, getNbRemainingTasksPerFlow } from 'simulation/scheduler/tasks-utils';
import store from 'store';
import { isDefined } from 'utils/ts/is-defined';
import { convertTask } from './utils';

const executeTriggerIntervalDefault = 60; // s
const minimumDurationBetweenBufferTriggerExec = 5; // s

interface CheckTriggersProps {
  simulationTime: number;
  simulationServiceRef: React.MutableRefObject<SimulationService | null>;
  checkingTriggers: React.MutableRefObject<boolean>;
  triggersExecution: React.MutableRefObject<Record<string, number | undefined>>;
  isSimulationPausedInstant: React.MutableRefObject<boolean>;
  createTask: (
    flowId?: string | string[],
    time?: number,
    options?: {
      robAuth?: number[];
      steps?: StationPositionWithName[];
      priority?: number;
    }
  ) => Promise<void>;
  tasksWaitingForCreation: React.MutableRefObject<Record<string, number | undefined>>;
}

export const isTriggerWithoutPosition = (trigger: Trigger): boolean => {
  if (trigger.type === 'interval' || trigger.type === 'buffer') {
    const flows = store.getState().flows.flows;
    const stations = store.getState().flows.stations;
    const triggerFlowId = Array.isArray(trigger.flowId) ? trigger.flowId[0] : trigger.flowId;
    const flow = flows.find((f) => f.id === triggerFlowId);

    if (flow) {
      const stationIds = new Set(flow.stations.flatMap((s) => s.id));

      return stations.some((station) => stationIds.has(station.id) && station.positions?.length === 0);
    }
  }

  return false;
};

/**
 * Check whether a trigger should be executed and execute it if necessary.
 * @param params the parameters of the function
 * @returns
 */
export async function checkTriggers(params: CheckTriggersProps): Promise<void> {
  const {
    simulationTime,
    simulationServiceRef,
    checkingTriggers,
    triggersExecution,
    isSimulationPausedInstant,
    createTask,
    tasksWaitingForCreation,
  } = params;

  if (checkingTriggers.current) return;

  const simulationService = simulationServiceRef.current;
  if (!simulationService) {
    // eslint-disable-next-line no-console
    console.error('[checkTriggers] Simulation service not initialized');

    return;
  }

  checkingTriggers.current = true;

  const triggers = store.getState().triggers.triggers.filter((trigger) => !isTriggerWithoutPosition(trigger));
  const flows = store.getState().flows.flows;

  const nbRemainingTasksPerFlow = getNbRemainingTasksPerFlow();
  const nbRemainingTasks = getNbRemainingTasks(nbRemainingTasksPerFlow);

  const executeTriggerInterval = Math.max(store.getState().simulation.currentSpeed * 5, executeTriggerIntervalDefault);

  const triggersToExecute = triggers.filter((trigger) => {
    // we reset the trigger if it has been disabled
    if (!trigger.enabled && triggersExecution.current[trigger.id] !== undefined)
      delete triggersExecution.current[trigger.id];

    if (!trigger.enabled) return false;

    if (trigger.type === 'interval') {
      const lastExecutionTime = triggersExecution.current[trigger.id];

      const startAt = trigger.startAt ?? 0;

      const stopAt = trigger.stopAt ?? Infinity;

      if (simulationTime > stopAt) {
        return false;
      } else if (
        (lastExecutionTime === undefined || lastExecutionTime < simulationTime + executeTriggerInterval) &&
        simulationTime >= startAt
      ) {
        return true;
      }
    } else if (trigger.type === 'buffer') {
      const lastExecutionTime = triggersExecution.current[trigger.id];
      const bufferForFlowOnly = !!trigger.bufferForFlowOnly;
      const taskStepsToConsider = trigger.taskStepsToConsider ?? Infinity;

      const triggerFlowId = Array.isArray(trigger.flowId) ? trigger.flowId[0] : trigger.flowId;

      const nbRemainingTasksToConsider = bufferForFlowOnly
        ? nbRemainingTasksPerFlow
            .find((f) => f.flowId === triggerFlowId)
            ?.countPerStep.reduce((acc, count, index) => acc + (index <= taskStepsToConsider ? count : 0), 0) ?? 0
        : nbRemainingTasks;

      return (
        nbRemainingTasksToConsider < trigger.size &&
        (lastExecutionTime === undefined ||
          lastExecutionTime + minimumDurationBetweenBufferTriggerExec < simulationTime)
      );
    } else if (trigger.type === 'replay') {
      const lastExecutionTime = triggersExecution.current[trigger.id];

      return lastExecutionTime === undefined;
    }

    return false;
  });

  if (!triggersToExecute.length) {
    checkingTriggers.current = false;

    return;
  }

  const wasPaused = isSimulationPausedInstant.current;
  await simulationServiceRef.current?._WEBSIMU_WasmWrapper_setSpeed(0);

  for (let i = 0; i < triggersToExecute.length; i++) {
    const trigger = triggersToExecute[i];
    if (trigger.type === 'interval') {
      const lastExecutionTime = triggersExecution.current[trigger.id];

      const stopAt = trigger.stopAt ?? Infinity;

      let creationTime = (lastExecutionTime ?? (trigger.startAt ?? 0) - trigger.interval) + trigger.interval;

      // we create the task for the next executeTriggerInterval seconds
      while (creationTime <= simulationTime + executeTriggerInterval && creationTime <= stopAt) {
        const nbTaskToCreate = trigger.count ?? 1;
        for (let j = 0; j < nbTaskToCreate; j++) {
          await createTask(trigger.flowId, creationTime, {
            priority: trigger.priority,
          });
        }

        triggersExecution.current[trigger.id] = creationTime;

        creationTime += trigger.interval;
      }
    } else if (trigger.type === 'buffer') {
      triggersExecution.current[trigger.id] = simulationTime;
      const bufferForFlowOnly = !!trigger.bufferForFlowOnly;
      const taskStepsToConsider = trigger.taskStepsToConsider ?? Infinity;

      const triggerFlowId = Array.isArray(trigger.flowId) ? trigger.flowId[0] : trigger.flowId;

      const nbRemainingTasksToConsider = bufferForFlowOnly
        ? nbRemainingTasksPerFlow
            .find((f) => f.flowId === triggerFlowId)
            ?.countPerStep.reduce((acc, count, index) => acc + (index <= taskStepsToConsider ? count : 0), 0) ?? 0
        : nbRemainingTasks;

      const flow = flows.find((f) => f.id === triggerFlowId);
      if (!flow) {
        // eslint-disable-next-line no-console
        console.warn(`Flow not found for trigger ${trigger.name}`);
        continue;
      }

      const nbTasksToCreate = trigger.size - nbRemainingTasksToConsider - (tasksWaitingForCreation[flow.name] ?? 0);

      if (nbTasksToCreate > 0) {
        tasksWaitingForCreation.current[flow.name] =
          (tasksWaitingForCreation.current[flow.name] ?? 0) + nbTasksToCreate;
      }

      for (let i = 0; i < nbTasksToCreate; i++) {
        await createTask(trigger.flowId, undefined, {
          priority: trigger.priority,
        });
      }
    } else if (trigger.type === 'replay') {
      triggersExecution.current[trigger.id] = simulationTime;

      const flowNamesNotFound = new Set<string>();

      const tasks = trigger.tasks;

      let snackbarKey: SnackbarKey | undefined = undefined;
      if (tasks.length > 50) {
        snackbarKey = SnackbarUtils.info(
          `Inserting the ${tasks.length} tasks of the trigger ${trigger.name} in the scheduler...`,
          {
            persist: true,
          }
        );
      }

      const robots = getRobotsEmulation();

      for (let i = 0; i < tasks.length; i++) {
        const groupOfTasks = tasks[i];
        const time = groupOfTasks.time;
        const flowsNameOfTasks =
          'tasks' in groupOfTasks ? groupOfTasks.tasks.map((t) => t.flowName) : [groupOfTasks.flowName];

        const flowsId = flowsNameOfTasks.map(
          (flowName) => store.getState().flows.flows.find((flow) => flow.name === flowName)?.id
        );

        const allFlowsIdOk = flowsId.length > 0 && flowsId.every(isDefined);
        if (allFlowsIdOk) {
          const newTasks = 'tasks' in groupOfTasks ? groupOfTasks.tasks : [groupOfTasks];
          const newTasksWithUpdatedRobAuth = newTasks.map((task) => {
            let newRobAuth = task.serials
              ?.map((serial) => {
                const robot = robots.find((r) => r.serial === serial);

                return robot?.ID;
              })
              .filter(isDefined);
            if (newRobAuth && newRobAuth.length === 0) newRobAuth = [-1];

            return {
              ...task,
              robAuth: newRobAuth,
              time,
            };
          });

          const newTasksFormated = convertTask(newTasksWithUpdatedRobAuth);

          // eslint-disable-next-line no-console
          console.log(`Adding task to scheduler with the replay trigger ${trigger.name}`, newTasksFormated);

          const newTaskStrPtr = await simulationService.allocateUTF8(
            JSON.stringify(newTasksFormated.tasks.length > 1 ? newTasksFormated.tasks : newTasksFormated.tasks[0])
          );

          await simulationService._DISPATCHER_WasmWrapper_newTaskInFuture(newTaskStrPtr, time);
          await simulationService._OS_WasmWrapper_free(newTaskStrPtr);
        } else {
          flowsId.forEach((flowId, index) => {
            if (!flowId) {
              flowNamesNotFound.add(flowsNameOfTasks[index]);
            }
          });
        }
      }

      if (flowNamesNotFound.size) {
        SnackbarUtils.error(
          `The following flows were not found: ${Array.from(flowNamesNotFound).join(
            ', '
          )} and therefore their associated tasks not created.`
        );
      }

      if (snackbarKey) {
        SnackbarUtils.closeSnackbar(snackbarKey);
      }
    }
  }

  checkingTriggers.current = false;

  const currentSpeedFactor = store.getState().simulation.speedFactor; // important to retrieve it just before, because the simulation can be paused/speed changed during the execution of the triggers
  if (!wasPaused) await simulationServiceRef.current?._WEBSIMU_WasmWrapper_setSpeed(currentSpeedFactor);
}
