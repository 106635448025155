import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';
import { closeDialogAction, selectToolAction } from 'actions';
import { setEditorMode } from 'editor/editor';
import { isEditorMode, type EditorMode } from 'editor/model';
import { Tools } from 'models/tools';
import { useCallback } from 'react';
import { useAppDispatch } from 'store';

interface ExitRunningSimulationProps {
  newToolOrMode: Tools | EditorMode;
}

export function ExitRunningSimulation({ newToolOrMode }: ExitRunningSimulationProps): JSX.Element {
  const dispatch = useAppDispatch();

  const handleClose = useCallback((): void => {
    dispatch(closeDialogAction());
  }, [dispatch]);

  const changeToolOrMode = (value: Tools | EditorMode): void => {
    if (isEditorMode(value)) {
      // We don't want to be able to select the previous tool of another editor mode hence the dispatch of the same action twice
      dispatch(
        selectToolAction({
          toolName: Tools.Move,
        })
      );

      dispatch(
        setEditorMode({
          editorMode: value,
        })
      );
    } else {
      dispatch(selectToolAction({ toolName: value }));
    }

    handleClose();
  };

  return (
    <Dialog open={true} fullWidth={true} maxWidth="xs" onClose={handleClose}>
      <DialogTitle sx={{ paddingBottom: 0 }}>Exit the simulation?</DialogTitle>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose} color="success">
          No, Stay
        </Button>
        <Button
          variant="outlined"
          onClick={() => {
            changeToolOrMode(newToolOrMode);
          }}
          color="error"
        >
          Yes, Exit
        </Button>
      </DialogActions>
    </Dialog>
  );
}
