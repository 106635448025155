import { centerOfMass, bbox as turfBbox } from '@turf/turf';
import { clearShapesSelectionAction, selectCircuitShapeAction } from 'actions/circuit';
import type { EditorDrawing } from 'drawings/editor.drawing';
import type { BBox } from 'geojson';
import { setCameraPosition } from 'reducers/local/camera-position.reducer';
import { CircuitService } from 'services/circuit.service';
import store from 'store';

export function zoomToShape(shapeId: string, skipAnim = false): void {
  const shape = CircuitService.getShape(shapeId);

  const isLibCirEnabled = store.getState().editor.isRender2Enabled;

  let drawing: EditorDrawing | undefined;

  if (!isLibCirEnabled) {
    drawing = CircuitService.getDrawingReference();
  }

  // it takes 0.5 of the playground
  const zoomFactor = 0.5; // defined arbitrarily

  if (shape) {
    let bbox: BBox;
    try {
      bbox = turfBbox(shape.geometry);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(`Computation of the BBox of the shape '${shapeId} failed'`, shape, e);

      return;
    }

    const center = centerOfMass(shape.geometry);

    const centerCoords = center.geometry.coordinates;

    let w: number | undefined;
    let h: number | undefined;

    if (!isLibCirEnabled) {
      if (!drawing) return;
      const svgEl = drawing.getRootNode().node();

      if (!svgEl) return;
      w = svgEl.clientWidth;
      h = svgEl.clientHeight;
    } else {
      const canvas = document.getElementsByTagName('canvas')[0];
      w = canvas.width;
      h = canvas.height;
    }

    const bboxWidth = bbox[2] - bbox[0];
    const bboxHeight = bbox[3] - bbox[1];

    // we compute the scale to fit the shape in the playground
    let scale = w && h ? Math.min(Math.abs(w / bboxWidth), Math.abs(h / bboxHeight)) * zoomFactor : 1;

    //if it's a turn between 2 segments colinear and on top of each other and one is a stockline extendedLength
    const isSingularTurn = !!(shape.geometry.coordinates[0] === shape.geometry.coordinates[1]);

    // for points, we define a zoom to an arbitrarily value given that the bbox has a width and height of 0
    if (typeof shape.geometry.coordinates[0] === 'number' || isSingularTurn) {
      scale = 2;
    }

    if (!isLibCirEnabled) {
      drawing?.zoomTo(centerCoords[0], centerCoords[1], scale, skipAnim, true);
    } else {
      const halfWidth = (bbox[2] - bbox[0]) / 2;
      const halfHeight = (bbox[3] - bbox[1]) / 2;

      store.dispatch(
        setCameraPosition({
          position: {
            minX: (centerCoords[0] - halfWidth) / 100,
            maxX: (centerCoords[0] + halfWidth) / 100,
            minY: (centerCoords[1] - halfHeight) / 100,
            maxY: (centerCoords[1] + halfHeight) / 100,
          },
        })
      );
    }

    const selectedShapesData = store.getState().local.selectedShapesData;
    const shapeAlreadySelectedAndOnlyThisOne = selectedShapesData.length === 1 && selectedShapesData[0].id === shape.id;
    if (!shapeAlreadySelectedAndOnlyThisOne) {
      // this is noop if the shape is already selected
      if (selectedShapesData.length) {
        // useless to clear the selection if the selection is empty
        store.dispatch(clearShapesSelectionAction());
      }

      setTimeout(() => {
        store.dispatch(
          selectCircuitShapeAction({
            selectedShapeId: shape.id as string,
            selectedShapeType: shape.properties.type,
            shape,
          })
        );
      }, 0);
    }
  }
}
