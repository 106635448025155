import { TextField } from '@mui/material';
import { HelpIconTooltip } from 'components/utils/tooltips';
import type { BufferTrigger, IntervalTrigger } from 'models/simulation';
import { theme } from 'utils/mui-theme';

interface PriorityTextfieldProps {
  trigger: IntervalTrigger | BufferTrigger;
  handleUpdateTrigger: () => void;
  inputRef: React.RefObject<HTMLInputElement>;
}
export function TriggerPriorityTextfield(props: PriorityTextfieldProps): JSX.Element {
  const { trigger, handleUpdateTrigger, inputRef } = props;

  const label = (
    <>
      Priority <HelpIconTooltip title="Set mission priority. Higher numbers mean higher priority." />
    </>
  );

  return (
    <TextField
      label={label}
      type="number"
      inputProps={{
        step: 1,
      }}
      InputLabelProps={{
        shrink: true,
      }}
      defaultValue={trigger.priority ?? 1}
      onBlur={handleUpdateTrigger}
      inputRef={inputRef}
      sx={{
        marginTop: theme.spacing(1),
      }}
      fullWidth
      size="small"
      variant="outlined"
      disabled={trigger.linked}
    />
  );
}
