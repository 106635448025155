import type { Position } from '@turf/helpers';
import { saveStockZoneAction } from 'actions/stock-zones';
import { rotatePoint } from 'drawings/helpers';
import type { CircuitStockZone } from 'models/circuit';
import { CircuitService } from 'services/circuit.service';
import { getConnectedTurnIds } from '../utils/findConnectedTurn';

/**
 * Processes a selected stock zone, applies rotation, updates its geometry and properties,
 * and tracks the associated turn IDs to be modified.
 *
 * @param selectedShape - The selected shape (stock zone) to process.
 * @param rotateValue - The rotation value to apply to the stock zone.
 * @param centerSelectedShapes - The center point for rotation.
 * @param actions - The array to collect actions to dispatch.
 * @param modifiedShapes - A set of modified shape IDs (including turns).
 */
export function processRotateStockZone(
  selectedShape: CircuitStockZone,
  rotateValue: number,
  centerSelectedShapes: Position,
  actions: any[],
  modifiedShapes: Set<string>
): void {
  const stockZone = selectedShape;
  if (!stockZone.id || stockZone.properties.locked) return;

  const { id, properties, geometry } = stockZone;
  const slotSample = properties.slots[0].slots[0];

  // Apply rotation
  const newCap = properties.cap - rotateValue;
  const newCoords = geometry.coordinates[0].map((coord) => rotatePoint(centerSelectedShapes, [...coord], -rotateValue));

  // Recompute slots
  let newSlots = CircuitService.generateStockZoneSlots(
    [...newCoords],
    properties.slotSize,
    properties.gap,
    properties.length,
    properties.width,
    properties.palletTypes,
    slotSample.palletPosition,
    properties.palletSize,
    slotSample.tolerancePosition,
    newCap,
    properties.slots,
    properties.customGapSlots,
    properties.customGapLines,
    properties.name,
    { lineNameOnlyIfNotUserGenerated: true }
  );

  newSlots = CircuitService.computeSlotsGeometry(newSlots, newCap);

  actions.push(
    saveStockZoneAction({
      ...stockZone,
      geometry: { ...geometry, coordinates: [newCoords] },
      properties: { ...properties, cap: newCap, slots: newSlots },
    })
  );

  // Add associated turns to modifiedShapes
  getConnectedTurnIds(id, 'stockZone').forEach((turnId) => modifiedShapes.add(turnId));
}
