import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import DirectionsOffIcon from '@mui/icons-material/DirectionsOff';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import HomeIcon from '@mui/icons-material/Home';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import ThreeSixtyIcon from '@mui/icons-material/ThreeSixty';
import TrafficIcon from '@mui/icons-material/Traffic';
import VisibilityIcon from '@mui/icons-material/Visibility';
import type { TooltipProps } from '@mui/material';
import { Alert, Tooltip } from '@mui/material';
import { useDrag } from '@use-gesture/react';
import { recordKeywordToColor } from 'components/toolboxes/simulation/perfo-chart-report';
import { getSvgFromIcon } from 'components/utils/generate-jsx';
import type { OpenContextMenuParams } from 'drawings/base.drawing';
import { findShapeOrientation, getClosestPointInSegment, pDistance } from 'drawings/helpers';
import { positionIdToPositionName } from 'flows/position-id-to-position-name';
import { useCallback, useMemo, useRef, useState } from 'react';
import { flushSync } from 'react-dom';
import type { Robot } from 'robots/robots';
import {
  selectRobotByName,
  setAutoRobotsSimulation,
  setHoveredRobotIndex,
  setManualRobotsSimulation,
  setRobotPositionSimulation,
  setRobots,
  updateRobot,
} from 'robots/robots';
import {
  robotActionStateNbToState,
  robotNavigationStateNbToState,
  robotTaskStateNbToState,
  robotTrafficStateNbToState,
} from 'robots/states';
import { isRobotTaskTaxi, isRobotTrafficStateOK, isRobotTrafficStateWait } from 'robots/states.guard';
import { clearAllItineraries, setItineraries, type Itinerary } from 'routes/routes';
import { REFRESH_SIMU_ROBOTS_INTERVAL, isRobotSmoothingEnabled } from 'simulation/config';
import { displayTraffic } from 'simulation/displayTraffic';
import { resetDeadLockRobots } from 'simulation/simulation';
import { taskStrToNumber } from 'simulation/states';
import store, { useAppDispatch, useAppSelector } from 'store';
import type { CantonsDict } from 'traffic/traffic';
import { clearAllCantons, setOccupiedCantons, setReservedCantons } from 'traffic/traffic';
import { useDebounce } from 'use-debounce';
import { epsilon } from 'utils/circuit/utils';
import { keepAngleProximity, toRad } from 'utils/helpers';
import { theme } from 'utils/mui-theme';

const directionsOffIconSvg = getSvgFromIcon(DirectionsOffIcon);
const trafficIconSvg = getSvgFromIcon(TrafficIcon);
const homeIconSvg = getSvgFromIcon(HomeIcon);
const emojiPeopleIconSvg = getSvgFromIcon(EmojiPeopleIcon);
const arrowCircleUpIconSvg = getSvgFromIcon(ArrowCircleUpIcon);
const arrowCircleDownIconSvg = getSvgFromIcon(ArrowCircleDownIcon);
const visibilityIconSvg = getSvgFromIcon(VisibilityIcon);
const threeSixtyIconSvg = getSvgFromIcon(ThreeSixtyIcon);
const closeFullscreenIconSvg = getSvgFromIcon(CloseFullscreenIcon);
const openInFullIconSvg = getSvgFromIcon(OpenInFullIcon);

const viewBoxIcon = directionsOffIconSvg?.querySelector('svg')?.getAttribute('viewBox') || '';

const directionsOffIconSvgPath = directionsOffIconSvg?.querySelector('path')?.getAttribute('d') || '';
const trafficIconSvgPath = trafficIconSvg?.querySelector('path')?.getAttribute('d') || '';
const homeIconSvgPath = homeIconSvg?.querySelector('path')?.getAttribute('d') || '';
const emojiPeopleSvgPath = emojiPeopleIconSvg?.querySelector('path')?.getAttribute('d') || '';
const arrowCircleUpIconSvgPath = arrowCircleUpIconSvg?.querySelector('path')?.getAttribute('d') || '';
const arrowCircleDownIconSvgPath = arrowCircleDownIconSvg?.querySelector('path')?.getAttribute('d') || '';
const visibilityIconSvgPath = visibilityIconSvg?.querySelector('path')?.getAttribute('d') || '';
const threeSixtyIconSvgPath = threeSixtyIconSvg?.querySelector('path')?.getAttribute('d') || '';
const closeFullscreenIconSvgPath = closeFullscreenIconSvg?.querySelector('path')?.getAttribute('d') || '';
const openInFullIconSvgPath = openInFullIconSvg?.querySelector('path')?.getAttribute('d') || '';

const robotStatusIconSize = 20; // px
const distanceIconsFromRobot = 0.2; // m
const robotStatusIconOffsetXPerIcon = robotStatusIconSize * 2;

const robotAnimationDuration = REFRESH_SIMU_ROBOTS_INTERVAL;

export function RobotsComponent(): JSX.Element {
  const robots = useAppSelector((state) => state.robots.robots);
  const selectedRobotsIndexes = useAppSelector((state) => state.robots.selectedRobotsIndexes);
  const hoveredRobotIndex = useAppSelector((state) => state.robots.hoveredRobotIndex);

  const robotsOpacity = useAppSelector((state) => state.simulation.robotsOpacity);

  const displayAllRobotsData = useAppSelector((state) => state.simulation.displayAllRobotsData);

  const robotIndexBlockingHoveredRobot = useMemo(() => {
    if (hoveredRobotIndex === undefined) return undefined;

    const hoveredRobot = robots[hoveredRobotIndex];
    if (!hoveredRobot) return undefined;

    const otherRobotBlockingName = hoveredRobot.trafficData?.otherRobotBlocking;
    if (!otherRobotBlockingName) return undefined;

    const robotIndex = robots.findIndex((robot) => robot.name === otherRobotBlockingName);

    return robotIndex !== -1 ? robotIndex : undefined;
  }, [hoveredRobotIndex, robots]);

  return (
    <g className="robots">
      {robots.map((robot, robotIndex) => (
        <RobotComponent
          key={robot.name}
          robot={robot}
          selected={selectedRobotsIndexes.includes(robotIndex)}
          opacity={robotsOpacity}
          robotIndex={robotIndex}
          robots={robots}
          openTooltip={displayAllRobotsData ? true : undefined}
          hovered={robotIndex === hoveredRobotIndex}
          blockingHoveredRobot={robotIndex === robotIndexBlockingHoveredRobot}
        />
      ))}
    </g>
  );
}

interface RobotComponentProps {
  robot: Robot;
  selected?: boolean;
  opacity?: number;
  enableSnapping?: boolean;
  robotIndex: number;
  robots: Robot[];
  openTooltip?: boolean;
  hovered?: boolean;
  blockingHoveredRobot?: boolean;
}

type RobotState = 'ok' | 'warn' | 'error' | 'deadlock';

const borderWidthSolidBorder = 5; // px

export function RobotComponent(props: RobotComponentProps): JSX.Element {
  const { robot, opacity, enableSnapping = true, robotIndex, robots, hovered = false, blockingHoveredRobot } = props;
  const { position, picturePath, distanceXWheelTop, distanceYWheelTop, robotLength, robotWidth, carry } = robot;
  const dispatch = useAppDispatch();
  const robotContainerRef = useRef<SVGGElement>(null);
  const robotRef = useRef<SVGGElement>(null);

  const setHovered = useCallback(
    (newState: boolean) => {
      dispatch(setHoveredRobotIndex({ indexRobotHovered: newState ? robotIndex : undefined }));
    },
    [dispatch, robotIndex]
  );

  const speedFactor = useAppSelector((state) => state.simulation.speedFactor);
  const robotTrafficTxt =
    robot.state?.traffic !== undefined ? robotTrafficStateNbToState(robot.state.traffic) : undefined;
  const robotTaskTxt = robot.state?.task !== undefined ? robotTaskStateNbToState(robot.state.task) : undefined;

  const deadlockRobots = useAppSelector((state) => state.simulation.deadLockRobots);
  const isRobotInDeadlock = !!deadlockRobots.find((id) => id === robot.id);

  const pinnedRobots = useAppSelector((state) => state.simulation.pinnedRobots);
  const isPinnedRobot = !!pinnedRobots.find((id) => id === robot.id);

  const fastRefreshEnabled = useAppSelector((state) => state.simulation.enableFastRefresh);

  const animateRobot =
    isRobotSmoothingEnabled(speedFactor, fastRefreshEnabled) && document.visibilityState !== 'hidden';
  const [delayedAnimateRobot] = useDebounce(animateRobot, robotAnimationDuration, {
    maxWait: robotAnimationDuration,
    trailing: true,
  });

  const [dragOffset, setDragOffset] = useState({ x: 0, y: 0, heading: 0 });

  const actualAnimateRobot = animateRobot && delayedAnimateRobot && dragOffset.x === 0 && dragOffset.y === 0;
  const formerHeading = useRef<number | undefined>(position?.heading);

  const [isDragging, setIsDragging] = useState(false);
  const wasInAutoBeforeDrag = useRef<boolean | undefined>(undefined);
  const bind = useDrag(
    async ({ event, movement: [mx, my], dragging, xy }) => {
      event?.stopPropagation();
      event?.preventDefault();

      if (isDragging !== dragging) {
        if (dragging) {
          const robotTaskStateStr =
            robot.state?.task !== undefined ? robotTaskStateNbToState(robot.state.task) : undefined;
          wasInAutoBeforeDrag.current = robotTaskStateStr !== 'Manual';

          await setManualRobotsSimulation([robot.id]);
        }

        setIsDragging(dragging ?? false);
      }

      let newDragOffsetX = dragOffset.x;
      let newDragOffsetY = dragOffset.y;
      let newDragOffsetHeading = dragOffset.heading;
      if (robotRef.current) {
        const rootNode = document.querySelector('.zoom-container');
        if (!rootNode) {
          // eslint-disable-next-line no-console
          console.error('rootNode not found');

          return;
        }

        if (!(rootNode instanceof SVGGElement)) {
          // eslint-disable-next-line no-console
          console.error('rootNode is not an SVG');

          return;
        }

        const transform = rootNode.getAttribute('transform') || '';
        const currentZoomStr = transform.match(/scale\((.*)\)/);
        const currentZoom = currentZoomStr ? parseFloat(currentZoomStr[1]) : 1;

        newDragOffsetX = mx / currentZoom;
        newDragOffsetY = my / currentZoom;
      }

      let newX = position.x + newDragOffsetX / 100;
      let newY = position.y - newDragOffsetY / 100;
      let heading = position.heading;

      if (enableSnapping) {
        const segmentsStore = store.getState().circuit.present.segments;
        const segments = segmentsStore.entities;
        const segmentsIds = segmentsStore.ids;

        const snapDistanceThreshold = 0.5; // m

        const ptCoords = [newX * 100, newY * 100];

        let minD = Infinity;
        let closestSegmentId: string | undefined = undefined;
        for (let i = 0; i < segmentsIds.length; i++) {
          const segment = segments[segmentsIds[i]];

          const segCoords = segment.geometry.coordinates;
          const start = segCoords[0];
          const end = segCoords[segCoords.length - 1];

          const d = pDistance(ptCoords[0], ptCoords[1], start[0], start[1], end[0], end[1]) / 100; // m
          if (d < snapDistanceThreshold && d < minD) {
            minD = d;
            closestSegmentId = segment.id as string;
          }
        }

        if (closestSegmentId) {
          const segment = segments[closestSegmentId];
          const segCoords = segment.geometry.coordinates;
          const start = segCoords[0];
          const end = segCoords[segCoords.length - 1];

          const [snappedCoords] = getClosestPointInSegment(ptCoords, start, end);
          const orientation = findShapeOrientation([start[0], start[1]], [end[0], end[1]]);

          heading = toRad(orientation);
          newX = snappedCoords[0] / 100;
          newY = snappedCoords[1] / 100;

          newDragOffsetX = (newX - position.x) * 100;
          newDragOffsetY = -(newY - position.y) * 100;
          newDragOffsetHeading = heading - position.heading;
        }
      }

      setDragOffset({ x: newDragOffsetX, y: newDragOffsetY, heading: newDragOffsetHeading });

      if (!dragging) {
        await setRobotPositionSimulation({
          robotId: robot.id,

          x: newX,
          y: newY,
          heading: heading,
        });

        const robots = store.getState().robots.robots;
        const newRobots = robots.map((r) => {
          if (r.id === robot.id) {
            if (isRobotInDeadlock) {
              store.dispatch(resetDeadLockRobots());
            }

            return {
              ...r,
              position: {
                ...r.position,
                x: newX,
                y: newY,
                heading: heading,
              },
            };
          }

          return r;
        });

        flushSync(() => {
          setDragOffset({ x: 0, y: 0, heading: 0 });
          dispatch(setRobots(newRobots));
        });

        if (wasInAutoBeforeDrag.current) {
          wasInAutoBeforeDrag.current = undefined;
          await setAutoRobotsSimulation([robot.id]);
        }

        return;
      }
    },
    {
      eventOptions: {
        capture: true,
        passive: false,
      },
    }
  );

  const handleClickRobot = useCallback(
    (e: React.MouseEvent<SVGImageElement, MouseEvent>) => {
      const ctrlPressed = e.ctrlKey;
      const isRightClick = e.button === 2;

      dispatch(
        selectRobotByName({
          nameRobotToSelect: robot.name,
          unselectOtherRobots: !ctrlPressed,
        })
      );

      if (isRightClick) {
        document.querySelector('#context-menu-editor')?.dispatchEvent(
          new CustomEvent('open-context-menu', {
            detail: {
              x: e.clientX,
              y: e.clientY,
            } as OpenContextMenuParams,
          })
        );
      }
    },
    [dispatch, robot.name]
  );

  const [validPicturePath, setValidPicturePath] = useState(true);
  const computedPicturePath = useMemo(() => {
    let picturePathToUse = picturePath;
    if (!validPicturePath) {
      picturePathToUse = 'otherManufacturer';
    }

    return `/img/trucks/${picturePathToUse}${carry ? 'Carry' : ''}.png`;
  }, [carry, picturePath, validPicturePath]);

  const transition = useMemo(() => {
    return [actualAnimateRobot ? `transform ${robotAnimationDuration}ms linear` : undefined, 'opacity 0.5s ease-in-out']
      .filter((t) => t)
      .join(', ');
  }, [actualAnimateRobot]);

  /** Function to display the traffic when the user over the robot with his mouse */
  const showTraffic = useCallback(async (): Promise<void> => {
    const simulationService = (await import('../../services/simulation.service.ts'))?.simulationService;
    const minimumDistanceBetweenPointsRobotItinerary = 0.025; // m

    if (!simulationService) {
      // eslint-disable-next-line no-console
      console.error('Simulation service not loaded');

      return;
    }

    const itineraries: Itinerary[] = [];

    let occupiedCantons: CantonsDict = {};
    let reservedCantons: CantonsDict = {};

    if (simulationService) {
      const [computedItinerary, computedOccupiedCantons, computedReservedCantons, modifiedRobot] = await displayTraffic(
        robot,
        {
          simulationService: simulationService,
          minimumDistanceBetweenPointsRobotItinerary: minimumDistanceBetweenPointsRobotItinerary,
          robots: robots,
        }
      );

      if (computedItinerary) {
        computedItinerary.robotIndex = robotIndex;

        itineraries.push(computedItinerary);
      }

      Object.keys(computedOccupiedCantons).forEach((key) => {
        const canton = computedOccupiedCantons[key];
        if (canton) {
          canton.robotIndex = robotIndex;
        }
      });

      Object.keys(computedReservedCantons).forEach((key) => {
        const canton = computedReservedCantons[key];
        if (canton) {
          canton.robotIndex = robotIndex;
        }
      });

      occupiedCantons = computedOccupiedCantons;
      reservedCantons = computedReservedCantons;

      dispatch(updateRobot({ indexRobotToUpdate: robotIndex, robot: modifiedRobot }));
    }

    // Dispatch the itinerary of the chosen robot
    if (itineraries.length > 0) {
      dispatch(setItineraries({ itineraries: itineraries }));
    }

    // Dispatch the occupied and reserved of the chosen robot
    if (occupiedCantons || reservedCantons) {
      const occupiedCantonsKeys = Object.keys(occupiedCantons ?? {});
      const reservedCantonsKeys = Object.keys(reservedCantons ?? {});

      if (occupiedCantonsKeys.length) dispatch(setOccupiedCantons(occupiedCantons ?? {}));
      if (reservedCantonsKeys.length) dispatch(setReservedCantons(reservedCantons ?? {}));
    }
  }, [dispatch, robot, robotIndex, robots]);

  /** Function to hide the traffic when the user leave the robot with his mouse */
  const hideTraffic = useCallback(() => {
    dispatch(clearAllItineraries());
    dispatch(clearAllCantons());
  }, [dispatch]);

  const robotState: RobotState = useMemo(() => {
    if (robotTaskTxt?.toLocaleLowerCase().includes('error') || robotTrafficTxt?.toLocaleLowerCase().includes('error')) {
      return 'error';
    }

    if (robotTrafficTxt === 'ManuThief') {
      return 'warn';
    }

    if (isRobotInDeadlock) {
      return 'deadlock';
    }

    return 'ok';
  }, [isRobotInDeadlock, robotTaskTxt, robotTrafficTxt]);

  const robotStateColor = (() => {
    switch (robotState) {
      case 'error':
        return theme.palette.error.main;
      case 'warn':
      case 'deadlock':
        return theme.palette.warning.main;
      default:
        return blockingHoveredRobot ? theme.palette.warning.light : undefined;
    }
  })();

  /** do not display the robot if the position is at the origin = not inititialized */
  if (position.x === 0 && position.y === 0) return <></>;

  const trafficStateOk = robotTrafficTxt ? isRobotTrafficStateOK(robotTrafficTxt) : true;
  const trafficStateWait = robotTrafficTxt ? isRobotTrafficStateWait(robotTrafficTxt) : false;
  const robotNoTasks = robotTaskTxt ? isRobotTaskTaxi(robotTaskTxt) : false;
  const actionTxt = robot.action !== undefined ? robotActionStateNbToState(robot.action) : 'unknown';

  const robotInManual = robot.state?.navigation
    ? robotNavigationStateNbToState(robot.state?.navigation) === 'Manual'
    : false;

  const isRobotMovingForksUp = actionTxt === 'fork_moving_up';
  const isRobogMovingForksDown = actionTxt === 'fork_moving_down';
  const isRobotDoingPerception = actionTxt === 'perception';
  const isRobotRotatingForksLeft = actionTxt === 'fork_rotation_left';
  const isRobotRotatingForksRight = actionTxt === 'fork_rotation_right';
  const isRobotMovingRetractIn = actionTxt === 'fork_retract_in';
  const isRobotMovingRetractOut = actionTxt === 'fork_retract_out';

  const robotStatusIconsStates = [
    !trafficStateOk,
    trafficStateWait,
    robotNoTasks,
    robotInManual,
    isRobotMovingForksUp || isRobogMovingForksDown,
    isRobotDoingPerception,
    isRobotRotatingForksLeft || isRobotRotatingForksRight,
    isRobotMovingRetractIn || isRobotMovingRetractOut,
  ];
  const nbStatusIcons = robotStatusIconsStates.filter((state) => state).length;

  const displayStatusIcons = nbStatusIcons > 0;

  if (
    picturePath &&
    distanceXWheelTop !== undefined &&
    distanceYWheelTop !== undefined &&
    robotLength !== undefined &&
    robotWidth !== undefined
  ) {
    const translateX = -robotLength + distanceXWheelTop;
    const translateY = robotWidth / 2 - distanceYWheelTop;

    const posStatusIconX =
      position.x * 100 +
      +Math.cos(position.heading) * robotWidth * 100 +
      -robotStatusIconSize / 2 -
      ((nbStatusIcons - 1) * robotStatusIconOffsetXPerIcon) / 2 +
      2 * robotStatusIconSize * Math.cos(position.heading);

    const posStatusIconY =
      -position.y * 100 +
      -Math.sin(position.heading) * (distanceXWheelTop + distanceIconsFromRobot) * 100 -
      robotStatusIconSize / 2;

    let indiceStatusIcon = 0;

    let heading = position.heading;
    if (animateRobot && formerHeading.current !== undefined) {
      heading = keepAngleProximity(formerHeading.current, heading);
    }

    formerHeading.current = heading;

    const openTooltip = isDragging ? false : !!(hovered || props.openTooltip || isPinnedRobot);

    const filter = isDragging
      ? 'drop-shadow(0 0 0.75rem #000)'
      : robotStateColor
        ? getBorderDropShadow(robotStateColor, borderWidthSolidBorder)
        : undefined;

    return (
      <g className="robot-container" ref={robotContainerRef}>
        <g
          {...bind()}
          ref={robotRef}
          x={0}
          y={0}
          style={{
            transition: actualAnimateRobot ? `transform ${robotAnimationDuration}ms linear` : undefined,
            transform: `translate(${(position.x + translateX) * 100 + dragOffset.x}px, ${
              (-position.y + translateY) * 100 + dragOffset.y
            }px)`,
          }}
        >
          <RobotTooltip robot={robot} open={openTooltip}>
            <image
              href={computedPicturePath}
              x={0}
              y={0}
              width={robotLength * 100}
              height={robotWidth * 100}
              style={{
                transition,

                transformOrigin: `${-translateX * 100}px ${-translateY * 100}px`,
                transform: `rotate(${-(heading + dragOffset.heading)}rad)`,

                cursor: 'pointer',

                outline: props.selected ? `2px solid ${theme.palette.primary.light}` : undefined,

                opacity,

                filter,
              }}
              onClick={handleClickRobot}
              onContextMenu={handleClickRobot}
              onPointerEnter={() => setHovered(true)}
              onPointerLeave={() => setHovered(false)}
              onError={() => setValidPicturePath(false)}
              onMouseEnter={showTraffic}
              onMouseLeave={hideTraffic}
            />
          </RobotTooltip>
        </g>

        {displayStatusIcons && (
          <g
            x={0}
            y={0}
            style={{
              transition: actualAnimateRobot ? `transform ${robotAnimationDuration}ms linear` : undefined,
              transform: `translate(${posStatusIconX}px, ${posStatusIconY}px)`,
            }}
            className="robot-status-icons"
          >
            {!trafficStateOk && (
              <Tooltip title={`Robot traffic state: ${robotTrafficTxt}`}>
                <svg
                  x={indiceStatusIcon++ * robotStatusIconOffsetXPerIcon}
                  viewBox={viewBoxIcon}
                  width={robotStatusIconSize}
                  height={robotStatusIconSize}
                  style={{
                    cursor: 'help',
                    pointerEvents: 'bounding-box' as 'fill',
                  }}
                >
                  <path d={directionsOffIconSvgPath} fill="red" />
                </svg>
              </Tooltip>
            )}
            {trafficStateWait && (
              <Tooltip
                title={`${
                  robot.name
                } is waiting for traffic control authorization. The route is currently used by other robots.${
                  robot.trafficData?.otherRobotBlocking
                    ? ` (blocked at least by ${robot.trafficData.otherRobotBlocking})`
                    : ''
                }`}
              >
                <svg
                  x={indiceStatusIcon++ * robotStatusIconOffsetXPerIcon}
                  viewBox={viewBoxIcon}
                  width={robotStatusIconSize}
                  height={robotStatusIconSize}
                  style={{
                    cursor: 'help',
                    pointerEvents: 'bounding-box' as 'fill',
                  }}
                >
                  <path d={trafficIconSvgPath} fill="orange" />
                </svg>
              </Tooltip>
            )}
            {robotNoTasks && (
              <Tooltip title={`Robot task state: ${robotTaskTxt?.toLowerCase()}`}>
                <svg
                  x={indiceStatusIcon++ * robotStatusIconOffsetXPerIcon}
                  viewBox={viewBoxIcon}
                  width={robotStatusIconSize}
                  height={robotStatusIconSize}
                  style={{
                    cursor: 'help',
                    pointerEvents: 'bounding-box' as 'fill',
                  }}
                >
                  <path d={homeIconSvgPath} fill="green" />
                </svg>
              </Tooltip>
            )}
            {robotInManual && (
              <Tooltip title={`Robot is in manual mode`}>
                <svg
                  x={indiceStatusIcon++ * robotStatusIconOffsetXPerIcon}
                  viewBox={viewBoxIcon}
                  width={robotStatusIconSize}
                  height={robotStatusIconSize}
                  style={{
                    cursor: 'help',
                    pointerEvents: 'bounding-box' as 'fill',
                  }}
                >
                  <path d={emojiPeopleSvgPath} fill="blue" />
                </svg>
              </Tooltip>
            )}
            {(isRobotMovingForksUp || isRobogMovingForksDown) && (
              <Tooltip title={`Robot is moving forks ${isRobotMovingForksUp ? 'up' : 'down'}`}>
                <svg
                  x={indiceStatusIcon++ * robotStatusIconOffsetXPerIcon}
                  viewBox={viewBoxIcon}
                  width={robotStatusIconSize}
                  height={robotStatusIconSize}
                  style={{
                    cursor: 'help',
                    pointerEvents: 'bounding-box' as 'fill',
                  }}
                >
                  <path d={isRobotMovingForksUp ? arrowCircleUpIconSvgPath : arrowCircleDownIconSvgPath} fill="black" />
                </svg>
              </Tooltip>
            )}
            {isRobotDoingPerception && (
              <Tooltip title={`Robot is doing perception with the 3D camera`}>
                <svg
                  x={indiceStatusIcon++ * robotStatusIconOffsetXPerIcon}
                  viewBox={viewBoxIcon}
                  width={robotStatusIconSize}
                  height={robotStatusIconSize}
                  style={{
                    cursor: 'help',
                    pointerEvents: 'bounding-box' as 'fill',
                  }}
                >
                  <path d={visibilityIconSvgPath} fill={recordKeywordToColor['3D']} />
                </svg>
              </Tooltip>
            )}
            {(isRobotRotatingForksLeft || isRobotRotatingForksRight) && (
              <Tooltip title={`Robot is rotating its forks ${isRobotRotatingForksLeft ? 'left' : 'right'}`}>
                <svg
                  x={indiceStatusIcon++ * robotStatusIconOffsetXPerIcon}
                  viewBox={viewBoxIcon}
                  width={robotStatusIconSize}
                  height={robotStatusIconSize}
                  style={{
                    cursor: 'help',
                    pointerEvents: 'bounding-box' as 'fill',
                  }}
                >
                  <path d={threeSixtyIconSvgPath} fill="black" />
                </svg>
              </Tooltip>
            )}
            {(isRobotMovingRetractIn || isRobotMovingRetractOut) && (
              <Tooltip title={`Robot is moving its ${isRobotMovingRetractIn ? 'retract in' : 'retract out'}`}>
                <svg
                  x={indiceStatusIcon++ * robotStatusIconOffsetXPerIcon}
                  viewBox={viewBoxIcon}
                  width={robotStatusIconSize}
                  height={robotStatusIconSize}
                  style={{
                    cursor: 'help',
                    pointerEvents: 'bounding-box' as 'fill',
                  }}
                >
                  <path d={isRobotMovingRetractIn ? closeFullscreenIconSvgPath : openInFullIconSvgPath} fill="black" />
                </svg>
              </Tooltip>
            )}
          </g>
        )}
      </g>
    );
  }

  return <circle cx={position.x * 100} cy={-position.y * 100} r={50} fill="purple" />;
}

interface RobotTooltipProps {
  robot: Robot;
  children: JSX.Element;
  open?: boolean;
}
function RobotTooltip(props: RobotTooltipProps): JSX.Element {
  const { robot, children } = props;

  const state = robot.state;

  const taskTxt = state?.task !== undefined ? robotTaskStateNbToState(state.task) : 'unknown';
  const trafficTxt = state?.traffic !== undefined ? robotTrafficStateNbToState(state.traffic) : 'unknown';

  const tasks = useAppSelector((state) => state.simulation.tasks);

  const currentTask = useMemo(() => {
    const runningTaskStateCode = taskStrToNumber('running');
    const preRunningTaskStateCode = taskStrToNumber('prerunning');
    const currentTask = Object.values(tasks).find(
      (task) => [runningTaskStateCode, preRunningTaskStateCode].includes(task.state) && task.robotID === robot.id
    );

    return currentTask;
  }, [robot.id, tasks]);

  const [src, dst] = useMemo(() => {
    if (!currentTask) {
      return [undefined, undefined] as const;
    }

    return [currentTask.src, currentTask.dst];
  }, [currentTask]);

  const [srcName, dstName] = useMemo(() => {
    return [
      src ? positionIdToPositionName(src.toString(), 'all') : undefined,
      dst ? positionIdToPositionName(dst.toString(), 'all') : undefined,
    ];
  }, [src, dst]);

  const taskName = currentTask?.taskName;

  const isItineraryError = useMemo(() => {
    if (taskTxt === 'ErrorMission') {
      const preRunningTaskStateCode = taskStrToNumber('prerunning');

      // error mission + prerunning task => error itinerary
      if (currentTask?.state === preRunningTaskStateCode) {
        return true;
      }
    }

    return false;
  }, [currentTask?.state, taskTxt]);

  const [openDelayed] = useDebounce(props.open, theme.transitions.duration.short, {
    maxWait: theme.transitions.duration.short,
    trailing: true,
  });

  const previousSpeedSign = useRef<1 | -1>(1);

  const tooltipPlacement: TooltipProps['placement'] = useMemo(() => {
    if (!props.open) return 'bottom';

    let speed = robot.speed ?? +0;
    const heading = robot.position.heading;

    if (Math.abs(speed) < epsilon) {
      speed = previousSpeedSign.current * epsilon;
    }

    previousSpeedSign.current = speed > 0 ? 1 : -1;

    const placements =
      speed < 0
        ? ({ first: 'right', second: 'top', third: 'left', fourth: 'bottom' } as const)
        : ({ first: 'left', second: 'bottom', third: 'right', fourth: 'top' } as const);

    return getRobotTooltipPlacement(heading, placements);
  }, [props.open, robot.position.heading, robot.speed]);

  return (
    <Tooltip
      title={
        props.open === false && openDelayed === false ? null : (
          <>
            <h3 style={{ marginTop: 0, textAlign: 'center' }}>{robot.name}</h3>
            <strong>Traffic:</strong> {trafficTxt}
            {robot.trafficData?.otherRobotBlocking ? ` (blocked by ${robot.trafficData.otherRobotBlocking})` : ''}
            {robot.trafficData?.info ? ` (${robot.trafficData.info})` : ''}
            <br />
            <strong>Task:</strong> {taskTxt}
            {taskName && (
              <>
                <br />
                <strong>Task name:</strong> {taskName}
              </>
            )}
            {robot.battery?.level !== undefined && (
              <>
                <br />
                <strong>Battery:</strong> {robot.battery?.level}%
              </>
            )}
            {srcName && (
              <>
                <br />
                <strong>Source:</strong> {srcName}
              </>
            )}
            {dstName && (
              <>
                <br />
                <strong>Destination:</strong> {dstName}
              </>
            )}
            {robot.speed !== undefined && (
              <>
                <br />
                <strong>Speed:</strong> {robot.speed.toFixed(2)} m/s
              </>
            )}
            {robot.forksHeight !== undefined && (
              <>
                <br />
                <strong>Forks height:</strong> {robot.forksHeight.toFixed(2)} m
              </>
            )}
            {isItineraryError && (
              <>
                <br />

                <Alert
                  severity="error"
                  sx={{
                    marginTop: theme.spacing(1),
                  }}
                >
                  Itinerary error
                </Alert>
              </>
            )}
          </>
        )
      }
      PopperProps={{ style: { zIndex: 0, opacity: 0.95 } }}
      placement={tooltipPlacement}
      disableInteractive
      arrow
      open={props.open}
      TransitionProps={{
        timeout: theme.transitions.duration.short,
      }}
    >
      {children}
    </Tooltip>
  );
}

/**
 * Generates a CSS drop-shadow filter string with the specified color and width.
 *
 * @param {string} color - The color of the drop shadow.
 * @param {number} width - The width of the drop shadow in pixels.
 * @returns {string} - The CSS drop-shadow filter string.
 */
function getBorderDropShadow(color: string, width: number): string {
  return `
    drop-shadow(${width}px 0px 0px ${color})
    drop-shadow(-${width}px 0px 0px ${color})
    drop-shadow(0px ${width}px 0px ${color})
    drop-shadow(0px -${width}px 0px ${color})
  `;
}

/**
 * Compute the tooltip placement based on the robot's heading to display it in the reverse direction of the speed vector.
 *
 * @param {number} heading - The heading angle of the robot in radians.
 * @param {Object} placements - An object mapping placement keys to Tooltip placement values.
 * @param {TooltipProps['placement']} placements.first - Placement when heading is between -π/4 and π/4.
 * @param {TooltipProps['placement']} placements.second - Placement when heading is between π/4 and 3π/4.
 * @param {TooltipProps['placement']} placements.third - Placement when heading is between 3π/4 and -3π/4.
 * @param {TooltipProps['placement']} placements.fourth - Placement when heading is between -3π/4 and -π/4.
 * @returns {TooltipProps['placement']} - The computed tooltip placement.
 */
function getRobotTooltipPlacement(
  heading: number,
  placements: { [key: string]: TooltipProps['placement'] }
): TooltipProps['placement'] {
  if (heading >= -Math.PI / 4 && heading <= Math.PI / 4) {
    return placements['first'];
  } else if (heading >= Math.PI / 4 && heading <= (3 * Math.PI) / 4) {
    return placements['second'];
  } else if (heading >= (3 * Math.PI) / 4 || heading <= -(3 * Math.PI) / 4) {
    return placements['third'];
  } else if (heading >= -(3 * Math.PI) / 4 && heading <= -Math.PI / 4) {
    return placements['fourth'];
  }
}
