import type {
  CircuitDevice,
  CircuitMeasurer,
  CircuitNote,
  CircuitPoint,
  CircuitRack,
  CircuitSegment,
  CircuitStockZone,
  CircuitTurn,
  CircuitZone,
} from 'models/circuit';
import { ShapeTypes } from 'models/circuit';
import { CircuitService } from 'services/circuit.service';
import store from 'store';
import { isSegmentAnExtendedLength } from 'utils/circuit/is-segment-an-extended-length';

/**
 * Processes a selected turn, determines if it should be updated based on connected segments, and updates a set of turn IDs.
 *
 * @param turn - The selected turn to process.
 * @param selectedShapes - The list of selected shapes to compare against.
 * @param turnsIdsToUpdate - The set of turn IDs to update.
 */
export function processRotateTurn(
  turn: CircuitTurn,
  turnsIdsToUpdate: Set<string>,
  selectedShapes: (
    | CircuitTurn
    | CircuitSegment
    | CircuitStockZone
    | CircuitZone
    | CircuitPoint
    | CircuitMeasurer
    | CircuitRack
    | CircuitDevice
    | CircuitNote
  )[]
): void {
  if (!turn.id) return;

  const { originId: ognId, destinationId: destId } = turn.properties;

  const isShapeMatchingRackOrStockLine = (shapeId: string, segment: CircuitSegment): boolean => {
    const stockZones = Object.values(store.getState().circuit.present.stockZones.entities);

    const isRackMatch = segment.properties.rack === shapeId;
    const isStockLineMatch = stockZones.some((stockZone) => {
      const hasMatchingSlot = stockZone.properties.slots.some((slot) => slot.id === segment.properties.stockLine);

      return hasMatchingSlot && stockZone.id === shapeId;
    });

    return isRackMatch || isStockLineMatch;
  };

  const isSelectedShapeMatchingSegment = (segmentId?: string): boolean => {
    if (!segmentId || !isSegmentAnExtendedLength(segmentId)) return false;

    const segment = CircuitService.getShape(segmentId, ShapeTypes.SegmentShape) as CircuitSegment;

    return selectedShapes.some((shape) => shape.id && isShapeMatchingRackOrStockLine(String(shape.id), segment));
  };

  const shouldUpdateTurn = isSelectedShapeMatchingSegment(ognId) || isSelectedShapeMatchingSegment(destId);

  if (!shouldUpdateTurn) {
    turnsIdsToUpdate.add(String(turn.id));
  }
}
