import type { SimulationService } from 'components/toolboxes/simulation';
import type { Robot } from 'robots/robots.ts';
import store from 'store/index.ts';
import { dynamicBatteryDataSchema } from './battery-data.ts';
import { taskStrToNumber } from './states.ts';

/**
 * Checks if the specified robot is currently charging based on its dynamic battery data.
 *
 * This function retrieves the robot's battery data from the simulation service and validates it
 * against a predefined schema. If the robot is running the battery task and the data indicates
 * that it is charging, the function returns true; otherwise, it returns false.
 *
 * @param {SimulationService} simulationService - The simulation service instance used to retrieve battery data.
 * @param {number} robotId - The unique identifier of the robot to check.
 * @param {boolean} [optimized=true] - A flag to determine if the check should be optimized by looking for the battery task.
 * @returns {Promise<boolean>} - A promise that resolves to true if the robot is charging, false otherwise.
 */
export async function isRobotCharging(
  simulationService: SimulationService,
  robotId: number,
  optimized = true
): Promise<boolean> {
  if (optimized) {
    const tasks = store.getState().simulation.tasks;
    const runningBatteryState = taskStrToNumber('running');
    const runningBatteryTask = Object.values(tasks).find(
      (t) => t.robotID === robotId && t.taskName === 'opcBattery' && t.state === runningBatteryState
    );

    if (!runningBatteryTask) {
      return false;
    }
  }

  const dynamicBatteryDataPtr = await simulationService._ROBEMU_WasmWrapper_getBatteryData(robotId, true);
  const dynamicBatteryDataStr = dynamicBatteryDataPtr
    ? await simulationService.UTF8ToString(dynamicBatteryDataPtr)
    : '';

  const dynamicBatteryDataToValidate = JSON.parse(dynamicBatteryDataStr) as unknown;
  const isDynamicBatteryData = dynamicBatteryDataSchema.safeParse(dynamicBatteryDataToValidate);

  if (isDynamicBatteryData.success) {
    const isCharging = isDynamicBatteryData.data.isCharging === 1;

    return isCharging;
  }

  // eslint-disable-next-line no-console
  console.error('[TMP] dynamicBatteryData is not a DynamicBatteryData', dynamicBatteryDataToValidate);

  return false;
}

/**
 * Enriches the provided list of robots with their battery charging status.
 *
 * @param {Robot[]} robots - An array of robots to enrich with charging status.
 * @param {SimulationService} simulationService - The simulation service instance used to check the charging status.
 * @returns {Promise<RobotWithBatteryChargingStatus[]>} - A promise that resolves to an array of robots enriched with their charging status.
 */
export async function enrichRobotsWithBatteryChargingStatus(
  robots: Robot[],
  simulationService: SimulationService
): Promise<Robot[]> {
  return Promise.all(
    robots.map(async (robot) => ({
      ...robot,
      battery: {
        ...robot.battery,
        isCharging: await isRobotCharging(simulationService, robot.id),
      },
    }))
  );
}
