import type { union } from '@turf/turf';
import * as Comlink from 'comlink';
import type {
  generateGabaritPolygon,
  generatePointGabarit,
  generateSegmentGabarit,
  generateTurnGabarit,
  mergeAllGabarits,
} from '../utils/circuit/gabarits';

type RemoteFunction<T extends (...args: any[]) => any> = (...args: Parameters<T>) => Promise<ReturnType<T>>;

export interface GabaritsWorker {
  generateGabaritPolygon: RemoteFunction<typeof generateGabaritPolygon>;
  generateSegmentGabarit: RemoteFunction<typeof generateSegmentGabarit>;
  generateTurnGabarit: RemoteFunction<typeof generateTurnGabarit>;
  generatePointGabarit: RemoteFunction<typeof generatePointGabarit>;
  union: RemoteFunction<typeof union>;
  mergeAllGabarits: RemoteFunction<typeof mergeAllGabarits>;
}

let worker: Worker | null = null;
let proxy: Comlink.Remote<GabaritsWorker> | null = null;
let initializationPromise: Promise<void> | null = null;

if (!window.__TEST__) {
  initializationPromise = (async (): Promise<void> => {
    const workerModule = await import('../utils/circuit/gabarits.ts?worker');
    worker = new workerModule.default({
      name: `gabarits-worker-${Date.now()}`,
    });
    proxy = Comlink.wrap<GabaritsWorker>(worker);
  })();
}

export const getGabaritsWorkerProxy = async (): Promise<Comlink.Remote<GabaritsWorker> | null> => {
  if (initializationPromise) {
    await initializationPromise;
  }

  return proxy;
};

export const terminateGabaritsWorker = (): void => worker?.terminate();
