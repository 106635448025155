import { memoize } from 'moderndash';
import store from 'store';
import { getAllRackPositions } from './racks';

export const getPositionHeightMemoized = memoize(getPositionHeight, {
  ttl: 20000,
});

/**
 * Get the height [m] of a position
 * @param positionName
 * @returns the height in meters or null if not found
 */
export function getPositionHeight(positionName: string): number | null {
  const position = getAllRackPositions().find((p) => p.value === positionName);

  if (!position) {
    // eslint-disable-next-line no-console
    console.warn(`Position ${positionName} not found`);

    return null;
  }

  const rackId = position.rackId;
  const rack = store.getState().circuit.present.racks.entities[rackId];

  if (!rack) {
    // eslint-disable-next-line no-console
    console.warn(`Rack ${rackId} not found`);

    return null;
  }

  const column = rack.properties.columns.find((c) =>
    c.cells.some((cell) => cell.names.flat().some((n) => n.value === position.value))
  );
  if (!column) {
    // eslint-disable-next-line no-console
    console.warn(`Column not found for position ${positionName}`);

    return null;
  }

  let height = column.startHeight;
  for (let i = 0; i < column.cells.length; i++) {
    const cell = column.cells[i];
    if (i !== 0) {
      height += cell.beamThickness;
    }

    if (cell.names.flat().some((n) => n.value === position.value)) {
      break;
    }

    height += cell.height;
  }

  return height;
}
