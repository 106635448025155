import styled from '@emotion/styled';
import InfoIcon from '@mui/icons-material/Info';
import { Box, Button, ButtonGroup, Dialog, DialogTitle, TableRow, Tooltip } from '@mui/material';
import { closeDialogAction, openDialogAction } from 'actions';
import RackAnalysisReferenceAxis from 'assets/perception/rack-analysis-reference-axis.png';
import { DialogTypes } from 'models';
import { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { SnackbarUtils } from 'services/snackbar.service';
import store, { useAppDispatch, useAppSelector } from 'store';
import { zoomToShape } from 'utils/circuit/zoom-to-shape';
import { toRad } from 'utils/helpers';
import { theme } from 'utils/mui-theme';
import { PreferencesService } from 'utils/preferences';
import {
  offsetOrAngleSchemaParseSuccess,
  type Csv,
  type OffsetOrAngle,
  type RackAnalysisSteps,
  type Recommendation,
  type RecommendationDataForAvgAllAxis,
  type RobotMeasures,
  type RobotsHealth,
  type RobotsMeasuresInfo,
} from './models';
import { StepImport } from './step-import';
import MemoizedStepMeasures from './step-measures';
import { StepRecommendation } from './step-recommendation';
import { StepRobotHealth } from './step-robot-health';
import {
  computeLinearRegressionFromMeasures,
  convertCsvDateToTimestampRackAnalysis,
  getRobotMeasuresMean,
  getRobotMeasuresStandardDeviation,
  getSlotsWeight,
  rackAnalysisMeasureSort,
} from './utils';

/**
 * Separator that is super unlikely to be present in a rack name or in the csv
 */
export const unlikelyToBeUsedSeparator = 'ù^$*ù^$*ù^$*ù^$*ù';

export type RobotMeasuresWithEnable = RobotMeasures[number] & { enable: boolean };

export default function RackAnalysisDialog(): JSX.Element {
  const dispatch = useAppDispatch();

  const [step, setStep] = useState<RackAnalysisSteps>('import');
  const [robotsMeasuresInfo, setRobotsMeasuresInfo] = useState<RobotsMeasuresInfo>({});
  const [robotsHealth, setRobotsHealth] = useState<RobotsHealth>({});
  const [dataList, setDataList] = useState<RobotMeasuresWithEnable[]>([]);
  const [recommendationList, setRecommendationList] = useState<Recommendation[]>([]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerpage] = useState(25);

  const handleChangePage = useCallback((event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  }, []);

  const handleChangeRowsPerPage = useCallback((event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerpage(parseInt(event.target.value, 10));
    setPage(0);
  }, []);

  const handleClose = useCallback((): void => {
    dispatch(closeDialogAction());
  }, [dispatch]);

  const trucks = useMemo(() => {
    if (!PreferencesService.arePreferencesFullyLoaded()) return [];

    return PreferencesService.getTrucks();
  }, []);

  const filteredDataList = useMemo(() => dataList.filter((data) => data.enable), [dataList]);

  const serialToRobotNames = useMemo(() => {
    const names: Record<string, string> = {};
    let serials: string[] | undefined;
    let trucksName: string[] | undefined;
    try {
      const trucksNameTmp = PreferencesService.getPreferenceValue('general/trucksName');
      const serialsTmp = PreferencesService.getPreferenceValue('general/trucksSerial');
      if (trucksNameTmp && Array.isArray(trucksNameTmp)) {
        trucksName = trucksNameTmp;
      } else {
        // eslint-disable-next-line no-console
        console.warn('Trucks name not found');
      }

      if (serialsTmp && Array.isArray(serialsTmp)) {
        serials = serialsTmp;
      } else {
        // eslint-disable-next-line no-console
        console.warn('Trucks serial not found');
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn('Trucks name or trucks serial not found');
    }

    trucks.forEach((truck) => {
      const serialIndex = serials?.findIndex((v) => v === truck.serial);
      if (serialIndex === -1 || serialIndex === undefined) return;

      const name = trucksName?.[serialIndex];
      if (!name) return;

      names[truck.serial] = name;
    });

    return names;
  }, [trucks]);

  const handleImportCsv = useCallback(
    (importedCsv: Csv[]): void => {
      const filteredCsv = importedCsv.filter((v) => v.data.length);
      sessionStorage.setItem(`rackAnalysisCsv-${PreferencesService.getProjectName()}`, JSON.stringify(filteredCsv));

      const toggledRobotsMeasureTemp: RobotsMeasuresInfo = {};
      filteredCsv.forEach((csv) => {
        csv.data.forEach((data) => {
          const serial = data['Serial'];
          const robotName = serialToRobotNames[serial] ?? serial;
          if (!toggledRobotsMeasureTemp[robotName])
            toggledRobotsMeasureTemp[robotName] = { disabled: false, measures: [], slotsCount: 0 };
          toggledRobotsMeasureTemp[robotName].measures.push(data);
        });
      });

      Object.values(toggledRobotsMeasureTemp).forEach((robot) => {
        const alreadyCountedSlot: Record<string, boolean> = {};
        robot.measures.forEach((measure) => {
          const slotName = measure?.['Slot Name'];

          if (alreadyCountedSlot[slotName]) return;

          alreadyCountedSlot[slotName] = true;

          if (!robot.slotsCount) {
            robot.slotsCount = 0;
          }

          robot.slotsCount += 1;
        });
      });

      setRobotsMeasuresInfo(toggledRobotsMeasureTemp);
      setStep('measures');
    },
    [serialToRobotNames]
  );

  const toggleRobotHealth = useCallback((robotName: string): void => {
    setRobotsMeasuresInfo((prev) => ({
      ...prev,
      [robotName]: {
        ...prev[robotName],
        disabled: !prev[robotName].disabled,
      },
    }));
  }, []);

  const racks = useAppSelector((state) => state.circuit.present.racks.entities);
  const racksArray = useMemo(() => Object.values(racks), [racks]);

  const slotNameMap = useMemo(() => {
    const slotNameToDataMap: Record<string, { cellId: string; rackId: string; levelLabel: string }> = {};

    racksArray.forEach((rack) => {
      rack.properties.columns.forEach((column) => {
        let prevHeight = 0;
        column.cells.forEach((cell, rowIndex) => {
          const rackId = rack.id as string;

          let height = 0;
          const columnStartHeight = column.startHeight;
          const cellHeight = cell.height;
          const cellBeamThickness = cell.beamThickness;

          if (rowIndex === 0) {
            height = columnStartHeight;
          } else {
            height = prevHeight;
          }

          prevHeight = height + cellHeight;

          if (rowIndex !== 0) {
            height += cellBeamThickness;
          }

          cell.names.forEach((name) => {
            name.forEach((n) => {
              slotNameToDataMap[n.value] = {
                cellId: cell.id,
                rackId,
                levelLabel: `${height.toFixed(3)} m`,
              };
            });
          });
        });
      });
    });

    return slotNameToDataMap;
  }, [racksArray]);

  const handleGoToCell = useCallback(
    (slotName: string): void => {
      const rackId = slotNameMap[slotName]?.rackId;
      const cellId = slotNameMap[slotName]?.cellId;

      if (!rackId || !cellId) {
        SnackbarUtils.error(`Couldn't find slot ${slotName} and its rack`);

        return;
      }

      zoomToShape(rackId, true);

      // Wait for the zoom to shape function to select the rack
      setTimeout(() => {
        dispatch(
          openDialogAction({
            type: DialogTypes.RackEdition,
            payload: {
              zoomToCellId: cellId,
            },
          })
        );
      }, 0);
    },
    [dispatch, slotNameMap]
  );

  useLayoutEffect(() => {
    if (!Object.keys(robotsMeasuresInfo).length) return;

    const dataListTemp: RobotMeasures = [];
    const robotsHealthTemp: RobotsHealth = {};

    const slotsWeight = getSlotsWeight(robotsMeasuresInfo);

    // Add the measures to all calculation if the robot is enabled
    Object.values(robotsMeasuresInfo).forEach((robot) => {
      if (robot.disabled) return;

      dataListTemp.push(...robot.measures);
    });

    // Get the mean of the measures in (x, y, z) of each robot
    Object.entries(robotsMeasuresInfo).forEach(([robotName, robot]) => {
      robotsHealthTemp[robotName] = {
        ...robotsHealthTemp[robotName],
        mean: getRobotMeasuresMean(robot.measures, slotsWeight),
      };
    });

    // Get the standard deviation of the measures in (x, y, z) of each robot
    Object.entries(robotsMeasuresInfo).forEach(([robotName, robot]) => {
      robotsHealthTemp[robotName] = {
        ...robotsHealthTemp[robotName],
        sd: getRobotMeasuresStandardDeviation(robot.measures, slotsWeight, robotsHealthTemp[robotName].mean),
      };
    });

    setRobotsHealth(robotsHealthTemp);

    dataListTemp.sort(rackAnalysisMeasureSort);
    setDataList(
      dataListTemp.map((item) => ({
        ...item,
        enable: true,
      }))
    );
  }, [robotsMeasuresInfo]);

  useEffect(() => {
    const dbPromise = indexedDB.open('rackAnalysis');

    dbPromise.onsuccess = (event) => {
      const db = (event.target as IDBOpenDBRequest).result;

      if (db.objectStoreNames.contains('saved')) {
        const transaction = db.transaction('saved', 'readonly');
        const objectStore = transaction.objectStore('saved');

        objectStore.get(PreferencesService.getProjectName()).onsuccess = (event) => {
          const savedRacks = (event.target as IDBRequest).result as Record<string, number>;

          const recommendationMap: Record<string, RecommendationDataForAvgAllAxis> = {};
          const authorizedTolerance: Recommendation['authorizedTolerance'] = {
            x: 1,
            y: 1,
            z: 1,
            theta: toRad(0.5),
          };
          filteredDataList.forEach((v) => {
            const rackName = v?.['Rack Name'];

            const date = convertCsvDateToTimestampRackAnalysis(v?.['Date']);

            if (date <= savedRacks?.[rackName]) return;

            const errorInX = +v?.['Offset on beam position (X)'];
            const errorInY = +v?.['Offset on Reference position (Y)'];
            const errorInZ = +v?.['Offset on beam height (Z)'];

            const toleranceInX = +v?.['Beam position tolerance'];
            const toleranceInY = +v?.['Reference position tolerance'];
            const toleranceInZ = +v?.['Beam height tolerance'];

            if (toleranceInX < authorizedTolerance.x) authorizedTolerance.x = toleranceInX;
            if (toleranceInY < authorizedTolerance.y) authorizedTolerance.y = toleranceInY;
            if (toleranceInZ < authorizedTolerance.z) authorizedTolerance.z = toleranceInZ;

            const errorInXIsNumber = errorInX !== 0 && !isNaN(errorInX);
            const errorInYIsNumber = errorInY !== 0 && !isNaN(errorInY);
            const errorInZIsNumber = errorInZ !== 0 && !isNaN(errorInZ);

            if (!errorInXIsNumber && !errorInZIsNumber) return;

            if (!recommendationMap[rackName]) {
              recommendationMap[rackName] = {
                x: { value: 0, count: 0 },
                y: { value: 0, count: 0 },
                z: {},
                measuresCount: 0,
              };
            }

            if (errorInXIsNumber) {
              recommendationMap[rackName].x.value += errorInX;
              recommendationMap[rackName].x.count += 1;
            }

            if (errorInYIsNumber) {
              recommendationMap[rackName].y.value += errorInY;
              recommendationMap[rackName].y.count += 1;
            }

            if (errorInZIsNumber) {
              const slotName = v?.['Slot Name'];

              if (slotName) {
                const levelLabel = slotNameMap[slotName]?.levelLabel;

                if (levelLabel) {
                  if (!recommendationMap[rackName].z[levelLabel]) {
                    recommendationMap[rackName].z[levelLabel] = { value: 0, count: 0 };
                  }

                  recommendationMap[rackName].z[levelLabel].value += errorInZ;
                  recommendationMap[rackName].z[levelLabel].count += 1;
                }
              }
            }

            recommendationMap[rackName].measuresCount += 1;
          });

          const recommendationListTemp: Recommendation[] = [];
          Object.keys(recommendationMap).forEach((key) => {
            const value = recommendationMap[key];
            let zlevelLabelRecommendation = {};

            const racksIds = store.getState().circuit.present.racks.ids;
            const racks = store.getState().circuit.present.racks.entities;
            const rackId = racksIds.find((id) => racks[id].properties.name === key);
            const rack = rackId ? racks[rackId] : null;

            if (!rack) {
              // eslint-disable-next-line no-console
              console.warn(`Rack ${key} not found in the circuit`);

              return;
            }

            Object.entries(value.z).forEach(([levelLabel, data]) => {
              zlevelLabelRecommendation = {
                ...zlevelLabelRecommendation,
                [levelLabel]: {
                  value: data.value && data.count ? data.value / data.count : 0,
                  count: data.count as number,
                },
              };
            });

            const { deltaRackAngleRad, deltaX } = computeLinearRegressionFromMeasures({
              dataList: filteredDataList as RobotMeasures,
              rackName: rack.properties.name,
            });

            const dTheta = deltaRackAngleRad;

            recommendationListTemp.push({
              rackName: key,
              recommendation: {
                dx: deltaX,
                dy: value.y.value && value.y.count ? value.y.value / value.y.count : 0,
                dz: zlevelLabelRecommendation,
                dTheta,
              },
              applied: false,
              authorizedTolerance,
              measuresCount: value.measuresCount,
            });
          });

          const transaction = db.transaction('_unsaved', 'readonly');
          const objectStore = transaction.objectStore('_unsaved');

          objectStore.getAllKeys().onsuccess = async (event) => {
            const allRecommendationKeys = (event.target as IDBRequest).result as string[];

            for (let i = 0; i < recommendationListTemp.length; i++) {
              const v = recommendationListTemp[i];
              const keyRecommendation = v.rackName;
              if (allRecommendationKeys.includes(keyRecommendation)) {
                await new Promise<void>((resolve, reject) => {
                  const request = objectStore.get(v.rackName);
                  request.onsuccess = (event) => {
                    const data = (event.target as IDBRequest).result as string;
                    const appliedValueStr = data.split('-')[0];
                    const formerRecommendationStr = data.split(unlikelyToBeUsedSeparator)[1];
                    let appliedValue: boolean | OffsetOrAngle;
                    if (appliedValueStr === 'true') appliedValue = true;
                    else if (appliedValueStr === 'false') appliedValue = false;
                    else if (offsetOrAngleSchemaParseSuccess(appliedValueStr)) appliedValue = appliedValueStr;
                    else {
                      // eslint-disable-next-line no-console
                      console.warn(`Failed to parse applied value: ${appliedValueStr}`);
                      appliedValue = false;
                    }

                    let formerDTheta: undefined | number;
                    try {
                      const parsedRecommendation: unknown = JSON.parse(formerRecommendationStr);
                      if (
                        parsedRecommendation &&
                        typeof parsedRecommendation === 'object' &&
                        'recommendation' in parsedRecommendation &&
                        typeof parsedRecommendation.recommendation === 'object' &&
                        parsedRecommendation.recommendation &&
                        'dTheta' in parsedRecommendation.recommendation &&
                        typeof parsedRecommendation.recommendation.dTheta === 'number'
                      ) {
                        formerDTheta = parsedRecommendation.recommendation.dTheta;
                      }
                    } catch (e) {}

                    v.applied = appliedValue;

                    if (formerDTheta !== undefined) {
                      v.recommendation.formerDTheta = formerDTheta;
                    }

                    resolve();
                  };

                  request.onerror = (event) => {
                    reject((event.target as IDBRequest).error);
                  };
                });
              }
            }

            setRecommendationList(recommendationListTemp);
            db.close();
          };
        };
      }
    };
  }, [dataList, filteredDataList, racks, slotNameMap]);

  const handleChangeDisableMeasure = useCallback((index: number) => {
    setDataList((prevDataList) =>
      prevDataList.map((item, i) => (i === index ? { ...item, enable: !item.enable } : item))
    );
  }, []);

  return (
    <Dialog
      open={true}
      aria-labelledby="rack-analysis-dialog-title"
      onClose={handleClose}
      fullWidth
      maxWidth={step !== 'import' ? 'lg' : undefined}
      sx={{ '& .MuiDialog-paper': { minHeight: step === 'recommendation' ? '95vh' : undefined } }}
    >
      <Box
        component="div"
        sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingBottom: 'unset' }}
      >
        <DialogTitle
          id="rack-analysis-dialog-title"
          sx={{
            fontStyle: 'bolder',
            display: 'flex',
            alignItems: 'center',
            columnGap: 1,
            paddingBottom: 0,
          }}
        >
          Rack analysis
          <Tooltip title="Analyze rack data to identify opportunities for improved success rates. Outdated data is excluded from analysis as the racks have already been moved.">
            <InfoIcon />
          </Tooltip>
          {step !== 'import' ? <img src={RackAnalysisReferenceAxis} alt="rack analysis reference axis" /> : null}
        </DialogTitle>
        {step !== 'import' ? (
          <Box component="div">
            <Button variant={step === 'robotHealth' ? 'contained' : 'outlined'} onClick={() => setStep('robotHealth')}>
              Robot health
            </Button>
            <ButtonGroup
              variant="outlined"
              color="primary"
              sx={{ padding: '16px 24px' }}
              disableElevation
              disabled={!dataList.length}
            >
              <Button variant={step === 'measures' ? 'contained' : 'outlined'} onClick={() => setStep('measures')}>
                Measures
              </Button>
              <Button
                variant={step === 'recommendation' ? 'contained' : 'outlined'}
                onClick={() => setStep('recommendation')}
              >
                Recommendations
              </Button>
            </ButtonGroup>
          </Box>
        ) : null}
      </Box>
      {step === 'robotHealth' && (
        <StepRobotHealth
          toggleRobotHealth={toggleRobotHealth}
          robotsHealth={robotsHealth}
          toggledRobotsMeasure={robotsMeasuresInfo}
        />
      )}
      {step === 'import' && <StepImport handleImportCsv={handleImportCsv} handleClose={handleClose} />}
      {step === 'measures' && (
        <MemoizedStepMeasures
          dataList={dataList}
          serialToRobotNames={serialToRobotNames}
          handleGoToCell={handleGoToCell}
          page={page}
          handleChangePage={handleChangePage}
          rowsPerPage={rowsPerPage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleChangeDisableMeasure={handleChangeDisableMeasure}
        />
      )}
      {step === 'recommendation' && (
        <StepRecommendation
          dataList={filteredDataList}
          recommendationList={recommendationList}
          setRecommendationList={setRecommendationList}
        />
      )}
    </Dialog>
  );
}

export const StyledTableRowRackAnalysis = styled(TableRow)(() => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.grey[100],
  },
}));
