import type { Position } from '@turf/helpers';
import { saveZoneAction } from 'actions/zones';
import { rotatePoint } from 'drawings/helpers';
import type { CircuitZone } from 'models/circuit';

/**
 * Processes a selected zone, applies rotation to its geometry, and dispatches the update action.
 *
 * @param selectedShape - The selected shape (zone) to process.
 * @param rotateValue - The rotation value to apply to the zone.
 * @param centerSelectedShapes - The center point for rotation.
 * @param actions - The array to collect actions to dispatch.
 */
export function processRotateZone(
  selectedShape: CircuitZone,
  rotateValue: number,
  centerSelectedShapes: Position,
  actions: any[]
): void {
  if (selectedShape.properties.locked) return;

  const { geometry } = selectedShape;
  const newCoords = geometry.coordinates[0].map((coord) => rotatePoint(centerSelectedShapes, [...coord], -rotateValue));

  actions.push(
    saveZoneAction({
      ...selectedShape,
      geometry: { ...geometry, coordinates: [newCoords] },
    })
  );
}
