import { moveSelectionAction } from 'actions/circuit';
import type { EditorDrawing } from 'drawings/editor.drawing';
import store from 'store';
import { Vector3 } from 'three';
import type { OrbitControls } from 'three-stdlib';
import { getConfig } from 'utils/config';

interface MovementParams {
  direction: 'left' | 'right' | 'up' | 'down';
  drawing?: EditorDrawing | null;
  isLibCirEnabled: boolean;
  controlsRef?: React.MutableRefObject<OrbitControls | null>;
}

/**
 * Handles camera and selection movement based on arrow key input
 */
export function handleMovement({ direction, drawing, isLibCirEnabled, controlsRef }: MovementParams): void {
  // Check if there are selected shapes - if so, move them instead of the camera
  if (store.getState().local.selectedShapesData.length > 0) {
    const d = 1; // cm
    const movement = {
      left: { dx: -d, dy: 0 },
      right: { dx: d, dy: 0 },
      up: { dx: 0, dy: d },
      down: { dx: 0, dy: -d },
    }[direction];

    store.dispatch(moveSelectionAction(movement));

    return;
  }

  const translation = getConfig('editor').zoom.translateArrowKeys;

  // Handle 2D movement
  if (!isLibCirEnabled) {
    const movement = {
      left: { x: translation, y: 0 },
      right: { x: -translation, y: 0 },
      up: { x: 0, y: translation },
      down: { x: 0, y: -translation },
    }[direction];

    drawing?.translateBy(movement.x, movement.y);

    return;
  }

  // Handle 3D movement
  if (controlsRef?.current) {
    const controls = controlsRef.current;
    const currPos = controls.object.position;

    const movement = {
      left: { x: -translation * 0.01, y: 0 },
      right: { x: translation * 0.01, y: 0 },
      up: { x: 0, y: translation * 0.01 },
      down: { x: 0, y: -translation * 0.01 },
    }[direction];

    const newTo = new Vector3(currPos.x + movement.x, currPos.y + movement.y, currPos.z);
    controls.object.position.set(newTo.x, newTo.y, newTo.z);
    controls.target.set(newTo.x, newTo.y, 0);
    controls.update();
  }
}
