import {
  Battery30,
  Battery50,
  Battery60,
  Battery80,
  Battery90,
  BatteryAlert,
  BatteryCharging20,
  BatteryCharging30,
  BatteryCharging50,
  BatteryCharging60,
  BatteryCharging80,
  BatteryCharging90,
  BatteryChargingFull,
  BatteryFull,
  BatteryUnknown,
} from '@mui/icons-material';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import DirectionsOffIcon from '@mui/icons-material/DirectionsOff';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import HomeIcon from '@mui/icons-material/Home';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import ThreeSixtyIcon from '@mui/icons-material/ThreeSixty';
import TrafficIcon from '@mui/icons-material/Traffic';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Box, Stack, Tooltip } from '@mui/material';
import { recordKeywordToColor } from 'components/toolboxes/simulation/perfo-chart-report';
import {
  robotActionStateNbToState,
  robotNavigationStateNbToState,
  robotTaskStateNbToState,
  robotTrafficStateNbToState,
} from 'robots/states';
import { isRobotTaskTaxi, isRobotTrafficStateOK, isRobotTrafficStateWait } from 'robots/states.guard';

interface BatteryIconProps {
  batteryLevel?: number;
  isCharging: boolean;
}

function BatteryIcon({ batteryLevel, isCharging }: BatteryIconProps): JSX.Element {
  if (batteryLevel === undefined) {
    return <BatteryUnknown color="error" />;
  }

  if (batteryLevel <= 20) {
    return isCharging ? <BatteryCharging20 /> : <BatteryAlert color="warning" />;
  } else if (batteryLevel <= 30) {
    return isCharging ? <BatteryCharging30 /> : <Battery30 />;
  } else if (batteryLevel <= 50) {
    return isCharging ? <BatteryCharging50 /> : <Battery50 />;
  } else if (batteryLevel <= 60) {
    return isCharging ? <BatteryCharging60 /> : <Battery60 />;
  } else if (batteryLevel <= 80) {
    return isCharging ? <BatteryCharging80 /> : <Battery80 />;
  } else if (batteryLevel <= 90) {
    return isCharging ? <BatteryCharging90 /> : <Battery90 />;
  }

  return isCharging ? <BatteryChargingFull /> : <BatteryFull />;
}

interface BatteryIndicatorProps {
  batteryLevel?: number;
  isCharging?: boolean;
}

export function BatteryIndicator({ batteryLevel, isCharging = false }: BatteryIndicatorProps): JSX.Element {
  return (
    <Tooltip
      title={`Battery: ${batteryLevel ?? 'unknown'}%${isCharging ? ' (Charging)' : ''}`}
      arrow
      disableInteractive
    >
      <Box component="span" sx={{ display: 'inline-flex' }}>
        <BatteryIcon batteryLevel={batteryLevel} isCharging={isCharging} />
      </Box>
    </Tooltip>
  );
}

interface RobotInfoIndicatorsProps {
  batteryLevel?: number;
  isCharging?: boolean;
  robotState?: {
    task?: number;
    navigation?: number;
    traffic?: number;
    action?: number;
  };
  trafficData?: {
    otherRobotBlocking?: string;
  };
}

export function RobotInfoIndicators({
  batteryLevel,
  isCharging,
  robotState,
  trafficData,
}: RobotInfoIndicatorsProps): JSX.Element {
  const robotTrafficTxt =
    robotState?.traffic !== undefined ? robotTrafficStateNbToState(robotState.traffic) : undefined;
  const robotTaskTxt = robotState?.task !== undefined ? robotTaskStateNbToState(robotState.task) : undefined;
  const actionTxt = robotState?.action !== undefined ? robotActionStateNbToState(robotState.action) : 'unknown';

  const trafficStateOk = robotTrafficTxt ? isRobotTrafficStateOK(robotTrafficTxt) : true;
  const trafficStateWait = robotTrafficTxt ? isRobotTrafficStateWait(robotTrafficTxt) : false;
  const robotNoTasks = robotTaskTxt ? isRobotTaskTaxi(robotTaskTxt) : false;
  const robotInManual = robotState?.navigation
    ? robotNavigationStateNbToState(robotState.navigation) === 'Manual'
    : false;

  const isRobotMovingForksUp = actionTxt === 'fork_moving_up';
  const isRobogMovingForksDown = actionTxt === 'fork_moving_down';
  const isRobotDoingPerception = actionTxt === 'perception';
  const isRobotRotatingForksLeft = actionTxt === 'fork_rotation_left';
  const isRobotRotatingForksRight = actionTxt === 'fork_rotation_right';
  const isRobotMovingRetractIn = actionTxt === 'fork_retract_in';
  const isRobotMovingRetractOut = actionTxt === 'fork_retract_out';

  const statusIcons = [
    {
      active: !trafficStateOk,
      icon: <DirectionsOffIcon sx={{ color: 'error.main' }} />,
      tooltip: `Robot traffic state: ${robotTrafficTxt}`,
    },
    {
      active: trafficStateWait,
      icon: <TrafficIcon sx={{ color: 'warning.main' }} />,
      tooltip: `Robot is waiting for traffic control authorization. The route is currently used by other robots.${
        trafficData?.otherRobotBlocking ? ` (blocked by ${trafficData.otherRobotBlocking})` : ''
      }`,
    },
    {
      active: robotNoTasks,
      icon: <HomeIcon sx={{ color: 'success.main' }} />,
      tooltip: `Robot task state: ${robotTaskTxt?.toLowerCase()}`,
    },
    {
      active: robotInManual,
      icon: <EmojiPeopleIcon sx={{ color: 'info.main' }} />,
      tooltip: 'Robot is in manual mode',
    },
    {
      active: isRobotMovingForksUp || isRobogMovingForksDown,
      icon: isRobotMovingForksUp ? <ArrowCircleUpIcon /> : <ArrowCircleDownIcon />,
      tooltip: `Robot is moving forks ${isRobotMovingForksUp ? 'up' : 'down'}`,
    },
    {
      active: isRobotDoingPerception,
      icon: <VisibilityIcon sx={{ color: recordKeywordToColor['3D'] }} />,
      tooltip: 'Robot is doing perception with the 3D camera',
    },
    {
      active: isRobotRotatingForksLeft || isRobotRotatingForksRight,
      icon: <ThreeSixtyIcon />,
      tooltip: `Robot is rotating its forks ${isRobotRotatingForksLeft ? 'left' : 'right'}`,
    },
    {
      active: isRobotMovingRetractIn || isRobotMovingRetractOut,
      icon: isRobotMovingRetractIn ? <CloseFullscreenIcon /> : <OpenInFullIcon />,
      tooltip: `Robot is moving its ${isRobotMovingRetractIn ? 'retract in' : 'retract out'}`,
    },
  ];

  const activeIcons = statusIcons.filter((icon) => icon.active);

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      {activeIcons.slice(2).map((icon, index) => (
        <Box key={`extra-${index}`} component="span" sx={{ display: 'inline-flex' }}>
          <Tooltip title={icon.tooltip} arrow disableInteractive>
            {icon.icon}
          </Tooltip>
        </Box>
      ))}
      {Array.from({ length: 2 }).map((_, i) => {
        const icon = activeIcons[i];

        return (
          <Box
            key={i}
            component="span"
            sx={{
              display: 'inline-flex',
              visibility: icon ? 'visible' : 'hidden',
            }}
          >
            <Tooltip title={icon?.tooltip ?? ''} arrow disableInteractive>
              {icon?.icon ?? <HomeIcon />}
            </Tooltip>
          </Box>
        );
      })}
      <BatteryIndicator batteryLevel={batteryLevel} isCharging={isCharging} />
    </Stack>
  );
}
