import type { Position } from '@turf/helpers';
import { updateMeasurerAction } from 'actions/circuit';
import { savePointAction } from 'actions/points';
import { saveSegmentAction } from 'actions/segment';
import { rotatePoint } from 'drawings/helpers';
import type {
  CircuitDevice,
  CircuitMeasurer,
  CircuitNote,
  CircuitPoint,
  CircuitRack,
  CircuitSegment,
  CircuitStockZone,
  CircuitTurn,
  CircuitZone,
} from 'models/circuit';
import store from 'store';
import { isCircuitSegment } from 'utils/circuit/shape-guards';
import { isDefined } from 'utils/ts/is-defined';

/**
 * Rotates all points in a segment's geometry and properties.
 */
function getRotatedSegment(
  segment: CircuitSegment,
  center: Position,
  rotateValue: number
): { newCoords: Position[]; newPortions: typeof segment.properties.portions } {
  const newCoords = segment.geometry.coordinates.map((coord) =>
    rotatePoint(center, [coord[0], coord[1]], -rotateValue)
  );

  const newPortions = segment.properties.portions.map((portion) => ({
    ...portion,
    points: portion.points.map((point) => rotatePoint(center, [point[0], point[1]], -rotateValue)),
  }));

  return { newCoords, newPortions };
}

/**
 * Finds turns connected to the segment that are not selected.
 */
function getTurnsToUpdate(
  segmentId: string,
  selectedShapes: (
    | CircuitTurn
    | CircuitSegment
    | CircuitStockZone
    | CircuitZone
    | CircuitPoint
    | CircuitMeasurer
    | CircuitRack
    | CircuitDevice
    | CircuitNote
  )[],
  modifiedShapes: Set<string>
): string[] {
  const { entities: turns, ids: turnsIds } = store.getState().circuit.present.turns;

  return turnsIds.filter((turnId) => {
    const turn = turns[turnId];
    const isTurnSelected = selectedShapes.some((shape) => shape.id === turnId);

    if (!isTurnSelected && (segmentId === turn.properties.originId || segmentId === turn.properties.destinationId)) {
      modifiedShapes.add(turnId); // Add the turn ID to modifiedShapes

      return true;
    }

    return false;
  });
}

/**
 * Finds measurers linked to the segment.
 */
function getMeasurersToUpdate(segmentId: string, modifiedShapes: Set<string>): string[] {
  const { entities: measurers, ids: measurersIds } = store.getState().circuit.present.measurers;

  return measurersIds.filter((measurerId) => {
    const measurer = measurers[measurerId];

    if (
      (measurer.properties.link0 && segmentId === measurer.properties.link0.id) ||
      (measurer.properties.link1 && segmentId === measurer.properties.link1.id)
    ) {
      modifiedShapes.add(measurerId); // Add the measurer ID to modifiedShapes

      return true;
    }

    return false;
  });
}

/**
 * Finds points linked to the segment.
 */
function getPointsToUpdate(segmentId: string, modifiedShapes: Set<string>): CircuitPoint[] {
  const { entities: points, ids: pointsIds } = store.getState().circuit.present.points;

  return pointsIds
    .map((pointId) => {
      const point = points[pointId];

      if (point.properties.segment?.id === segmentId) {
        modifiedShapes.add(pointId); // Add the point ID to modifiedShapes

        return point;
      }

      return undefined;
    })
    .filter(isDefined);
}

/**
 * Processes points on the segment, updating or unsnapping them as needed.
 */
function processPointsOnSegment(
  points: CircuitPoint[],
  newCoords: Position[],
  rotateValue: number,
  actions: any[],
  pointsUnsnapped: number
): number {
  points.forEach((point) => {
    const positionOnSegment = point.properties.segment?.position;

    if (point.properties.locked) {
      actions.push(
        savePointAction({
          ...point,
          properties: { ...point.properties, segment: undefined },
        })
      );
      pointsUnsnapped++;

      return;
    }

    if (positionOnSegment === undefined) {
      // eslint-disable-next-line no-console
      console.error(`Position on segment not found for point ${point.id}`);

      return;
    }

    const newCoordinates = [
      newCoords[0][0] + (newCoords[1][0] - newCoords[0][0]) * positionOnSegment,
      newCoords[0][1] + (newCoords[1][1] - newCoords[0][1]) * positionOnSegment,
    ];

    const newOrientation = point.properties.orientation + rotateValue;

    actions.push(
      savePointAction({
        ...point,
        geometry: { ...point.geometry, coordinates: newCoordinates },
        properties: { ...point.properties, orientation: newOrientation },
      })
    );
  });

  return pointsUnsnapped;
}

/**
 * Processes a circuit segment, rotating it, updating linked entities, and dispatching actions.
 */
export function processRotateSegment(
  selectedShape: CircuitSegment,
  selectedShapes: (
    | CircuitTurn
    | CircuitSegment
    | CircuitStockZone
    | CircuitZone
    | CircuitPoint
    | CircuitMeasurer
    | CircuitRack
    | CircuitDevice
    | CircuitNote
  )[],
  center: Position,
  rotateValue: number,
  actions: any[],
  turnsIdsToUpdate: Set<string>,
  modifiedShapes: Set<string>,
  pointsUnsnapped: number
): void {
  if (!isCircuitSegment(selectedShape)) return;

  const segment = selectedShape;
  if (!segment.id || segment.properties.locked || segment.properties.rack || segment.properties.stockLine) return;

  const { newCoords, newPortions } = getRotatedSegment(segment, center, rotateValue);
  const segmentId = String(segment.id);

  const turnsToUpdate = getTurnsToUpdate(segmentId, selectedShapes, modifiedShapes);
  turnsToUpdate.forEach((turnId) => turnsIdsToUpdate.add(turnId));

  const measurersToUpdate = getMeasurersToUpdate(segmentId, modifiedShapes);
  measurersToUpdate.forEach((measurerId) => {
    actions.push(updateMeasurerAction({ measurerId }));
  });

  const pointsToUpdate = getPointsToUpdate(segmentId, modifiedShapes);
  pointsUnsnapped = processPointsOnSegment(pointsToUpdate, newCoords, rotateValue, actions, pointsUnsnapped);

  actions.push(
    saveSegmentAction({
      id: segment.id,
      geometry: { ...segment.geometry, coordinates: newCoords },
      properties: { ...segment.properties, portions: newPortions },
    })
  );
}
