import { canBeUndefined } from '../../utils/ts/is-defined';

interface BalyoSimulationVersion {
  /** Name of the path */
  version: string;
  /** Can be selected if the project name matches exactly the project name as filled in the version */
  projectNames?: string[];
  /** Only available in dev mode */
  devOnly?: boolean;
}

export const availableBalyoSimulationVersions: BalyoSimulationVersion[] = [
  {
    version: '4.15',
  },
  {
    version: '4.16',
  },
  {
    version: '4.16-multicore-unstable',
    devOnly: true,
  },
  {
    version: '4.17',
  },
  {
    version: '4.16_v1.2',
    devOnly: true,
  },
  {
    version: 'v3.1.7_dev',
    devOnly: true,
  },
  {
    version: '4.17-better-forks-VNA',
    devOnly: true,
  },
];

const sortBalyoSimulationVersions = (a: BalyoSimulationVersion, b: BalyoSimulationVersion): 1 | 0 | -1 => {
  const parseVersion = (version: string): number[] =>
    version
      .replace(/[^0-9.]/g, '')
      .split('.')
      .map(Number);
  const [aMajor, aMinor, aPatch] = parseVersion(a.version);
  const [bMajor, bMinor, bPatch] = parseVersion(b.version);

  if (aMajor > bMajor) {
    return -1;
  } else if (aMajor < bMajor) {
    return 1;
  } else if (aMinor > bMinor) {
    return -1;
  } else if (aMinor < bMinor) {
    return 1;
  } else if (aPatch > bPatch) {
    return -1;
  } else if (aPatch < bPatch) {
    return 1;
  }

  return 0;
};

export const latestBalyoSimulationVersion = availableBalyoSimulationVersions
  .filter((version) => !version.devOnly && (!version.projectNames || version.projectNames.length === 0))
  .sort(sortBalyoSimulationVersions)[0];

/**
 * Checks the environment simulation version.
 * If the current environment is development, it returns the provided version.
 * Otherwise, it checks if the version is available and not devOnly.
 * If the version is not available or is devOnly, it returns a matching simulation version.
 *
 * @param {string} version - The version to check.
 * @returns {string} - The appropriate simulation version for the environment.
 */
export const checkEnvironmentSimulationVersion = (version: string, sdkVersion: string): string => {
  const availableVersion = availableBalyoSimulationVersions.find(
    (balyoSimulationVersion) => balyoSimulationVersion.version === version
  );

  if (!availableVersion || availableVersion.devOnly) {
    return getMatchingSimulationVersion(sdkVersion).version;
  }

  return version;
};

export function getMatchingSimulationVersion(version: string): BalyoSimulationVersion {
  const matchingVersion = canBeUndefined(
    availableBalyoSimulationVersions
      .filter((availableVersion) => {
        if (availableVersion.version === version) {
          return true;
        }

        // check if x.y are the same
        const [availableMajor, availableMinor] = availableVersion.version.split('_').join('').split('.');
        const [major, minor] = version.split('_').join('').split('.');

        if (availableMajor === major && availableMinor === minor) {
          return true;
        }

        return false;
      })
      .filter((version) => !version.devOnly)
      .sort((a, b) => sortBalyoSimulationVersions(a, b))[0]
  );

  if (!matchingVersion) {
    // eslint-disable-next-line no-console
    console.warn(`No matching simulation version found for ${version}`);

    // detect if version is < 4.15.0 and use 4.16 if so (4.16 is more stable than 4.15)
    const before4_15_0 = sortBalyoSimulationVersions({ version }, { version: '4.15' }) === 1;
    const version4_16 = availableBalyoSimulationVersions.find(
      (availableVersion) => availableVersion.version === '4.16'
    );
    if (before4_15_0 && version4_16) {
      // eslint-disable-next-line no-console
      console.warn(`Using 4.16 version instead`);

      return version4_16;
    }

    // eslint-disable-next-line no-console
    console.warn(`Using latest version instead (${latestBalyoSimulationVersion.version})`);

    return latestBalyoSimulationVersion;
  }

  return matchingVersion;
}
