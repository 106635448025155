import AddIcon from '@mui/icons-material/Add';
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
import EditIcon from '@mui/icons-material/Edit';
import WallpaperIcon from '@mui/icons-material/Wallpaper';
import { Box, Button, Divider, IconButton, ListItem, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import { changeDisplayStateMapImagePropertiesAction, closeDialogAction, importMapImageAction } from 'actions';
import { HelpIconTooltip } from 'components/utils/tooltips';
import React, { useCallback, useRef } from 'react';
import { checkPermission } from 'services/check-permission';
import store, { useAppDispatch, useAppSelector } from 'store';
import { useAsyncMemo } from 'use-async-memo';
import { theme } from 'utils/mui-theme';
import { ACCEPTED_IMAGE_FORMATS } from '../../../utils/image';

function uploadMapImage(file: File): void {
  if (file) {
    const imageURL = window.URL.createObjectURL(file);
    let name = file.name;
    const mapImages = store.getState().maps.mapImage.mapImages;
    if (mapImages && mapImages.some((mapImage) => mapImage.name === name)) {
      const indexOfExtension = name.lastIndexOf('.');
      const fileName = name.substring(0, indexOfExtension);
      const extension = name.substring(indexOfExtension);

      name = `${fileName} t.${new Date().getTime()}${extension}`;
    }

    file.arrayBuffer().then((buffer) => {
      const blob = new Blob([buffer]);
      const image = new Image();
      image.src = URL.createObjectURL(blob);
      image.onload = () => {
        const originalHeight = image.height;
        const originalWidth = image.width;

        store.dispatch(importMapImageAction({ imageURL, name, originalHeight, originalWidth }));
      };
    });
  }
}

export function ImportMapImageListItem(): JSX.Element {
  const mapImages = useAppSelector((state) => state.maps.mapImage.mapImages);

  const inputRef = useRef<HTMLInputElement | null>(null);

  const dispatch = useAppDispatch();

  const onChangeHandler = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;

    if (files && files[0]) {
      uploadMapImage(files[0]);
    }
  }, []);

  const onClickHandler = useCallback((event: React.MouseEvent<HTMLInputElement>) => {
    event.currentTarget.value = ''; //clean input to allow second upload of same file
  }, []);

  const editCircuitPerm = useAsyncMemo(async () => {
    return await checkPermission('edit:circuit');
  }, []);

  return (
    <Tooltip title={!editCircuitPerm ? 'You do not have the permission' : ''}>
      <Box component="span">
        <ListItem disabled={!editCircuitPerm}>
          <input
            type="file"
            onChange={onChangeHandler}
            onClick={onClickHandler}
            id="import-map-image-input"
            accept={ACCEPTED_IMAGE_FORMATS}
            ref={inputRef}
            style={{ display: 'none' }}
          />
          <ListItemIcon sx={{ alignSelf: 'flex-start', paddingTop: theme.spacing(1) }}>
            <WallpaperIcon />
          </ListItemIcon>
          {mapImages && mapImages.length > 0 ? (
            <ListItemText
              primary={
                <Box component="div" sx={{ paddingBottom: theme.spacing(1) }}>
                  Layout Images
                  <HelpIconTooltip
                    title={<>This file is optional. Display image(s) of the site layout.</>}
                    sx={{
                      fontSize: '1rem',
                    }}
                  />
                </Box>
              }
              secondary={
                <Box component="div" sx={{ display: 'flex', flexDirection: 'row', gap: theme.spacing(2) }}>
                  <Divider orientation="vertical" flexItem />
                  <Box component="div" sx={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(1) }}>
                    {mapImages.map((mapImage, index) => {
                      return (
                        <Box component="div" key={`layout-image-${mapImage.name}`}>
                          {mapImage?.name ? `Imported (${mapImage.name})` : 'Not imported'}
                          <Tooltip
                            title={
                              !editCircuitPerm
                                ? 'You do not have the authorization to edit this value'
                                : 'Open the settings menu of the layout image imported'
                            }
                          >
                            <Box component="span">
                              <IconButton
                                onClick={() => {
                                  dispatch(changeDisplayStateMapImagePropertiesAction({ openIndex: index }));
                                  dispatch(closeDialogAction());
                                }}
                                disabled={!editCircuitPerm}
                              >
                                <DisplaySettingsIcon
                                  sx={{
                                    fontSize: '1rem',
                                  }}
                                />
                              </IconButton>
                            </Box>
                          </Tooltip>
                        </Box>
                      );
                    })}
                    <Tooltip title={!editCircuitPerm ? 'You do not have the authorization to edit this value' : ''}>
                      <Box component="span">
                        <Button
                          variant="outlined"
                          onClick={() => {
                            const el = inputRef.current;
                            if (el) {
                              el.value = ''; // clean input to allow second upload of same file
                              el.click();
                            }
                          }}
                          disabled={!editCircuitPerm}
                        >
                          <AddIcon />
                        </Button>
                      </Box>
                    </Tooltip>
                  </Box>
                </Box>
              }
            />
          ) : (
            <ListItemText
              primary={
                <>
                  {mapImages?.[0]?.name ? `Imported (${mapImages?.[0]?.name})` : 'Not imported'}
                  <Tooltip title={!editCircuitPerm ? 'You do not have the authorization to edit this value' : ''}>
                    <Box component="span">
                      <IconButton
                        onClick={() => {
                          const el = inputRef.current;
                          if (el) {
                            el.value = ''; // clean input to allow second upload of same file
                            el.click();
                          }
                        }}
                        disabled={!editCircuitPerm}
                      >
                        <EditIcon
                          sx={{
                            fontSize: '1rem',
                          }}
                        />
                      </IconButton>
                    </Box>
                  </Tooltip>
                </>
              }
              secondary={
                <>
                  Layout Image
                  <HelpIconTooltip
                    title={<>This file is optional. Display an image of the site layout.</>}
                    sx={{
                      fontSize: '1rem',
                    }}
                  />
                </>
              }
            />
          )}
        </ListItem>
      </Box>
    </Tooltip>
  );
}
