import { Alert, Card, CardContent, CardHeader, LinearProgress } from '@mui/material';
import type { ProxyMethods, RemoteObject } from 'comlink';
import { TOOL_LIST_ALL_TOOLS } from 'components/menu-bar/tool-info';
import { isGetAllCantonsResult } from 'models/circuit.guard';
import { Tools } from 'models/tools';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { loadCircuitSimulation } from 'simulation/load-circuit';
import type { Simulation } from 'simulation/simulation.model';
import { retrieveAndSaveTrafficSwalFile } from 'simulation/traffic-cache-file';
import { useAppDispatch } from 'store';
import { addNoStopCanton, addNoStopZoneCanton, clearAllCantons } from 'traffic/traffic';
import { theme } from 'utils/mui-theme';
import { DisplayTrafficToolBox } from './display-traffic.tsx';
import { TrafficCheckToolBox } from './traffic-check.tsx';

interface TrafficToolsProps {
  loadCircuit?: boolean;
  positioning?: 'absolute' | 'element';
  displayCard?: boolean;
  displayLayerGroupSelect?: boolean;
  displayKeepDisplaySwitch?: boolean;
  displayNoStopCantons?: boolean;
  handleMouseForRobotPosition?: boolean;
  trafficOption: 'displayTraffic' | 'trafficCheck';
}
export function TrafficTools(props: TrafficToolsProps): JSX.Element {
  const {
    loadCircuit = true,
    positioning = 'absolute',
    displayCard = true,
    displayLayerGroupSelect = true,
    displayKeepDisplaySwitch = true,
    displayNoStopCantons = true,
    handleMouseForRobotPosition = true,
    trafficOption,
  } = props;

  const dispatch = useAppDispatch();

  const computationStarted = useRef(false);
  const [computing, setComputing] = useState(true);
  const [errorXml, setErrorXml] = useState(false);
  const [error, setError] = useState(false);

  const simulationServiceRef = useRef<(RemoteObject<Simulation> & ProxyMethods) | null>(null);
  const circuitPtr = useRef<number | null>(null);

  const generateNoStopCantons = useCallback(async (): Promise<void> => {
    setComputing(true);

    dispatch(clearAllCantons());

    const resLoadingCircuit = await loadCircuitSimulation({
      circuitPtr,
    });

    if (
      !resLoadingCircuit.circuitXml ||
      resLoadingCircuit.errorCircuitXml ||
      resLoadingCircuit.errorSimulationService ||
      resLoadingCircuit.errorLoadingCircuit ||
      !resLoadingCircuit.simulationService
    ) {
      setComputing(false);
      if (resLoadingCircuit.errorCircuitXml) {
        setErrorXml(true);
      } else {
        setError(true);
      }

      return;
    }

    const { simulationService } = resLoadingCircuit;
    simulationServiceRef.current = simulationService;

    // eslint-disable-next-line no-console
    console.log('Initializing the traffic simulation...');
    try {
      await simulationService._TRAFFIC_WasmWrapper_initialize();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Error while initializing the traffic simulation', e);
      setError(true);
    }

    // eslint-disable-next-line no-console
    console.log('Getting the cantons...');
    const resStrPtr = await simulationService._TRAFFIC_WasmWrapper_getAllCantons();
    const resStr = await simulationService.UTF8ToString(resStrPtr);

    const cantons = ((): unknown => {
      try {
        return JSON.parse(resStr);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error('Error while parsing the cantons JSON', e);

        return '';
      }
    })();

    if (isGetAllCantonsResult(cantons)) {
      // eslint-disable-next-line no-console
      console.log('Cantons loaded');

      setError(false);

      if ('noStopCanton' in cantons && cantons.noStopCanton) dispatch(addNoStopCanton(cantons.noStopCanton));
      if ('noStopZoneCanton' in cantons && cantons.noStopZoneCanton)
        dispatch(addNoStopZoneCanton(cantons.noStopZoneCanton));

      await retrieveAndSaveTrafficSwalFile({ simulationService });
    } else {
      // eslint-disable-next-line no-console
      console.error('Error while getting the cantons: wrong types', cantons, resStr);

      setError(true);
    }

    setComputing(false);
  }, [dispatch]);

  useEffect(() => {
    if (!loadCircuit) {
      setComputing(false);

      return;
    }

    /**
     * The goal of this useEffect is to generate the computation of the cantons
     * Only once, when the component is mounted
     */
    if (!computationStarted.current) {
      computationStarted.current = true;

      generateNoStopCantons();
    }
  }, [generateNoStopCantons, loadCircuit]);

  const isComputingAndNoError = computing && !error && !errorXml;

  const cardTitle = trafficOption === 'displayTraffic' ? 'Display Traffic' : 'Traffic Check';

  return (
    <CardTrafficTools
      displayCard={displayCard}
      isComputingAndNoError={isComputingAndNoError}
      positioning={positioning}
      title={cardTitle}
    >
      <CardContent
        sx={{
          textAlign: 'left',
        }}
      >
        <ErrorAlert error={error} errorXml={errorXml} />

        {trafficOption === 'displayTraffic' ? (
          <DisplayTrafficToolBox
            displayLayerGroupSelect={displayLayerGroupSelect}
            displayKeepDisplaySwitch={displayKeepDisplaySwitch}
            displayNoStopCantons={displayNoStopCantons}
            handleMouseForRobotPosition={handleMouseForRobotPosition}
            computing={computing}
            error={error}
            setError={setError}
            computationStarted={computationStarted}
          />
        ) : (
          <TrafficCheckToolBox isComputingAndNoError={isComputingAndNoError} error={error} />
        )}
      </CardContent>
    </CardTrafficTools>
  );
}

interface ErrorAlertProps {
  error: boolean;
  errorXml: boolean;
}
function ErrorAlert(props: ErrorAlertProps): JSX.Element {
  const { error, errorXml } = props;

  return (
    <>
      {error ? <Alert severity="error">An error happened during the computation of the traffic portions</Alert> : null}
      {errorXml ? (
        <Alert severity="error">
          An error happened during the computation of the XML circuit, please fix the circuit (it is likely due to your
          layer configuration)
        </Alert>
      ) : null}
    </>
  );
}

interface CardTrafficToolsProps {
  positioning: TrafficToolsProps['positioning'];
  isComputingAndNoError: boolean;
  children: JSX.Element;
  displayCard: boolean;
  title: string;
}
function CardTrafficTools(props: CardTrafficToolsProps): JSX.Element {
  const { children, positioning, isComputingAndNoError, displayCard, title } = props;

  const AvatarIcon = useMemo(() => {
    return TOOL_LIST_ALL_TOOLS.find((tool) => tool.tool === Tools.DisplayTraffic)?.icon;
  }, []);

  return displayCard ? (
    <Card
      sx={{
        ...(positioning === 'absolute'
          ? {
              position: 'absolute',
              right: theme.spacing(2),
              top: theme.spacing(2),
              width: '350px',
              maxHeight: '95%',
            }
          : {}),
        overflowY: 'auto',
        transition: 'background-color 0.5s ease',
        backgroundColor: isComputingAndNoError ? theme.palette.grey[100] : undefined,
      }}
    >
      {isComputingAndNoError ? <LinearProgress sx={{ marginBottom: '-4px' }} /> : null}

      <CardHeader
        title={title}
        avatar={!!AvatarIcon ? <AvatarIcon /> : undefined}
        sx={{
          paddingBottom: theme.spacing(0.5),
        }}
      ></CardHeader>
      {children}
    </Card>
  ) : (
    <>{children}</>
  );
}
