import { Button } from '@mui/material';
import { SnackbarUtils } from './snackbar.service';

declare global {
  interface Window {
    __TEST__?: boolean;
    updateAvailable?: boolean;
    registrationWorker?: ServiceWorkerRegistration;
  }
}

interface ServiceWorkerState {
  snackBarRef: ReturnType<typeof SnackbarUtils.toast> | undefined;
  updateSW: ((reloadPage?: boolean) => Promise<void>) | undefined;
}

const state: ServiceWorkerState = {
  snackBarRef: undefined,
  updateSW: undefined,
};

/**
 * Initializes the service worker for the application
 * @returns Promise that resolves when the service worker is registered
 */
export async function initializeServiceWorker(): Promise<void> {
  if (window.__TEST__) return;

  try {
    const { registerSW } = await import('virtual:pwa-register');

    state.updateSW = registerSW({
      onOfflineReady(): void {
        // eslint-disable-next-line no-console
        console.log('[ServiceWorker] App is ready for offline use');
      },
      onRegistered(registration: ServiceWorkerRegistration | undefined): void {
        // eslint-disable-next-line no-console
        console.log('[ServiceWorker] Service worker registered', registration);
      },
      onRegisterError(error: Error): void {
        // eslint-disable-next-line no-console
        console.log('[ServiceWorker] Service worker registration failed', error);
      },
      onNeedRefresh(): void {
        if (state.snackBarRef) {
          SnackbarUtils.closeSnackbar(state.snackBarRef);
        }

        state.snackBarRef = SnackbarUtils.toast(`A new version of Road Editor is available`, {
          action: (
            <Button
              onClick={() => {
                /**
                 * We want to display the alert when:
                 * - we detect and unsaved change (works only when a project is loaded)
                 * - when no project is loaded
                 *
                 * I had to use a querySelector instead of the PreferenceService because it is too
                 * soon for the app to load this piece of code.
                 * !!document.querySelector('#project-and-circuit-name') === true <=> a project is loaded
                 */
                const displayAlert =
                  !document.querySelector('#project-and-circuit-name') || sessionStorage.unsavedChanges === 'true';
                if (displayAlert) {
                  // eslint-disable-next-line no-alert
                  const userOk = window.confirm(
                    'Updating the application will erase all unsaved changes.\nDo you want to continue?'
                  );
                  if (!userOk) return;
                }

                state
                  .updateSW?.(true)
                  // eslint-disable-next-line no-console
                  .catch(console.error)
                  .then(() => {
                    window.location.reload.bind(window.location);
                    SnackbarUtils.closeSnackbar(state.snackBarRef);
                  });
              }}
              color="primary"
              variant="contained"
            >
              Update now
            </Button>
          ),
          autoHideDuration: 3600 * 1000, // 1 hr
        });

        window.updateAvailable = true;
      },
      onRegisteredSW(swUrl: string, registration: ServiceWorkerRegistration | undefined): void {
        if (registration) {
          window.registrationWorker = registration;
        }
      },
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('[ServiceWorker] Failed to initialize:', error);
  }
}
